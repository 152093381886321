import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import {normalizeModificationsOfChanges} from '../../../rest/schemata'

import {requestModificationsOfChanges} from './ChangeVcsRoots.rest'

export const fetchModificationsOfChanges = createFetchAction(
  'fetchModificationsOfChanges',
  (locator: string) =>
    requestModificationsOfChanges(restRoot, locator).then(normalizeModificationsOfChanges),
)
