import {createFetchAction} from '../reducers/fetchable'
import {deleteAgent, putAgentAuthState, putAgentState, putAgentToPool} from '../rest/agents'
import {getBranchesWithBuilds} from '../rest/branches'
import requestBuildTypeTags from '../rest/buildTypeTags'
import {restRoot} from '../rest/consts'

import requestDslOptions from '../rest/dsl'
import requestInvestigations from '../rest/investigations'
import {normalizeBranches, NormalizedBranches, normalizeInvestigation} from '../rest/schemata'
import {getPersonalBuildsLocator} from '../selectors'
import {
  AgentId,
  AgentPoolId,
  BranchType,
  BuildTypeId,
  BuildTypeInternalId,
  EnabledInfoType,
} from '../types'

import {subscribeOnBuildTypeEvents, Unsubscribe} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'

import {AppThunk} from './types'

type ChangeAgentStateArg = {
  agentId: AgentId
  payload: EnabledInfoType
}
export const changeAgentState = createFetchAction(
  'changeAgentState',
  ({agentId, payload}: ChangeAgentStateArg) => putAgentState(restRoot, agentId, payload),
)

export const changeAgentAuthState = createFetchAction(
  'changeAgentAuthState',
  ({agentId, payload}: ChangeAgentStateArg) => putAgentAuthState(restRoot, agentId, payload),
)

type AssignAgentWithPoolArg = {
  agentId: AgentId
  agentPoolId: AgentPoolId
}
export const assignAgentWithPool = createFetchAction(
  'assignAgentWithPool',
  ({agentId, agentPoolId}: AssignAgentWithPoolArg) =>
    putAgentToPool(restRoot, agentId, agentPoolId),
)

export const removeAgent = createFetchAction('removeAgent', (agentId: AgentId) =>
  deleteAgent(restRoot, agentId),
)

type FetchDslOptionsArg = {
  serverUrl: string
  force?: boolean
}
export const fetchDslOptionsAction = createFetchAction(
  'fetchDslOptions',
  ({serverUrl}: FetchDslOptionsArg) => requestDslOptions(serverUrl),
  {
    condition({force}, {getState}) {
      const {dslOptions} = getState()
      return force || !dslOptions.inited
    },
  },
)

export const fetchDslOptions = (serverUrl: string, force?: boolean) =>
  fetchDslOptionsAction({serverUrl, force})

export const fetchSingleInvestigation = createFetchAction(
  'fetchSingleInvestigation',
  (buildTypeId: BuildTypeId) =>
    requestInvestigations(restRoot, buildTypeId).then(normalizeInvestigation),
)

export const subscribeOnInvestigation =
  (
    buildTypeId: BuildTypeId,
    buildTypeInternalId: BuildTypeInternalId,
    timeout: number,
  ): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnBuildTypeEvents(
      buildTypeInternalId,
      [SubscriptionEvents.BUILD_TYPE_RESPONSIBILITY_CHANGES],
      () => {
        dispatch(fetchSingleInvestigation(buildTypeId))
      },
      timeout,
    )

export type FetchBranchesWithBuildsArg = {
  buildTypeId: BuildTypeId
  branchFilter?: BranchType | null | undefined
}
export const fetchBranchesWithBuilds = createFetchAction<
  NormalizedBranches,
  FetchBranchesWithBuildsArg
>('fetchBranchesWithBuilds', async ({buildTypeId, branchFilter}, {getState}) => {
  const state = getState()
  const personalBuildsLocator = getPersonalBuildsLocator(state) ?? false
  const data = await getBranchesWithBuilds(restRoot, {
    buildTypeId,
    branchFilter,
    personalBuildsLocator: personalBuildsLocator ? `,${personalBuildsLocator}` : '',
  })
  return normalizeBranches(data)
})

export const fetchBuildTypeTags = createFetchAction(
  'fetchBuildTypeTags',
  (buildTypeId: BuildTypeId) => requestBuildTypeTags(restRoot, buildTypeId),
)
