import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import type {WrapTextProps} from '@jetbrains/ring-ui/components/link/link'
import classnames from 'classnames'
import type {ReactElement, ReactNode} from 'react'
import * as React from 'react'

import {ComponentType} from 'react'

import Icon from '../Icon/Icon'
import Link from '../Link/Link'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import Popup from '../Popup/Popup.lazy'

import styles from './LinkWithIcon.css'

type Props = {
  readonly children?: string | null
  readonly icon?: string | null
  readonly className?: string
  readonly iconClassName?: string
  readonly iconPlaceholder: ReactElement | null
  readonly iconTitle?: ReactNode | null
  readonly textTitle?: string | null
  readonly title?: string
  readonly tailLength: number
  readonly side: 'left' | 'right'
  readonly href: string
  readonly relative?: boolean
  readonly onPlainLeftClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

function LinkWithIcon({
  children,
  icon,
  className,
  iconClassName,
  iconPlaceholder,
  side,
  tailLength,
  iconTitle,
  textTitle,
  ...restProps
}: Props) {
  const iconClasses = classnames(styles.icon, iconClassName, styles[side])
  const classes = classnames(styles.link, className)
  const popupClasses = classnames(styles.popup, 'popupDiv')
  const iconDiv =
    icon != null ? (
      <div className={iconClasses}>
        <Icon name={icon} />
      </div>
    ) : (
      iconPlaceholder
    )
  const childrenWithEllipsis = React.useMemo(
    () =>
      children != null ? (
        <MiddleEllipsis title={textTitle} tailLength={tailLength}>
          {children}
        </MiddleEllipsis>
      ) : null,
    [children, tailLength, textTitle],
  )
  return (
    <Link
      {...restProps}
      className={classes}
      data-test-link-with-icon={icon != null ? icon : 'none'}
    >
      {(WrapText: ComponentType<WrapTextProps>) => (
        <>
          {iconTitle != null ? (
            <Dropdown anchor={iconDiv ?? ''} hoverMode clickMode={false}>
              <Popup className={popupClasses}>{iconTitle}</Popup>
            </Dropdown>
          ) : (
            iconDiv
          )}
          {children != null && (
            <div className={styles.text}>
              <WrapText>{childrenWithEllipsis}</WrapText>
            </div>
          )}
        </>
      )}
    </Link>
  )
}

LinkWithIcon.defaultProps = {
  side: 'left',
  tailLength: 5,
  iconPlaceholder: null,
}
export default LinkWithIcon
