import {branch, renderNothing, defaultProps} from 'recompose'

import type {BuildNumberProps as APIProps} from '@jetbrains/teamcity-api'
import compose from 'lodash/flowRight'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import {fetchSingleBuildData} from '../../../actions/builds'

import withEffect from '../../../hocs/withEffect'
import type {State} from '../../../reducers/types'
import {getOverviewHref} from '../../../routes'
import {getBuild, getIsExperimentalUI, getIsMine} from '../../../selectors'
import {toBuildId} from '../../../types'
import type {BuildId} from '../../../types'
import {resolveWebEntityLink} from '../../../utils/entityLinks'

import BuildNumberComponent from './BuildNumber'

type OwnProps = {
  buildId: BuildId | null | undefined
  number?: string
  withLink?: boolean
  hideStar?: boolean
  className?: string
  showEmptyStar?: boolean
  withWarnings?: boolean
}

const mapStateToProps = (state: State, props: OwnProps) => {
  const build = getBuild(state, props.buildId)
  const isExperimentalUI = getIsExperimentalUI(state)
  const {number, personal, canceledInfo, queuePosition} = build || {}
  const link = isExperimentalUI
    ? getOverviewHref({
        buildId: props.buildId,
        buildTypeId: build?.buildType,
      })
    : resolveWebEntityLink(build)
  return {
    loaded: number != null || queuePosition != null || props.number != null,
    href: props.withLink === true ? link : null,
    number: number ?? props.number,
    queuePosition,
    isPersonal: personal === true,
    isMine: getIsMine(state, props.buildId),
    isQueued: build?.state === 'queued',
    isCanceled: canceledInfo != null,
  }
}

const connector = ReactRedux.connect(mapStateToProps, {
  fetchSingleBuildData,
})

type Props = OwnProps & ReactRedux.ConnectedProps<typeof connector>

const BuildNumberContainer: React.ComponentType<OwnProps> = compose(
  React.memo,
  defaultProps({
    withLink: true,
  }),
  connector,
  withEffect(
    (props: Props) => props.buildId,
    props => props.loaded,
    props => props.fetchSingleBuildData,
    (id, loaded, fetchBuild) => {
      if (id != null && !loaded) {
        fetchBuild(id)
      }
    },
  ),
  branch((props: Props) => !props.loaded, renderNothing),
)(BuildNumberComponent)
export default BuildNumberContainer
export const BuildNumberAPI: React.ComponentType<APIProps> = ({
  buildId,
  number,
  withLink,
  hideStar,
}) => (
  <BuildNumberContainer
    buildId={buildId != null ? toBuildId(buildId) : null}
    number={number}
    withLink={withLink}
    hideStar={hideStar}
  />
)
