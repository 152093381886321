import {createFetchAction} from '../../../../reducers/fetchable'
import {pinBuilds} from '../../../../rest/builds'
import {restRoot} from '../../../../rest/consts'
import type {BuildId, BuildTypeId, PinHandlerArgsAjax} from '../../../../types'
import {changeBuildTags} from '../TagDialog/TagDialog.actions'

export type PinBuildArg = {
  ids: readonly BuildId[]
  buildTypeId: BuildTypeId
  parameters: PinHandlerArgsAjax
}
export const pinBuildFromDialog = createFetchAction(
  'pinBuildFromDialog',
  ({ids, buildTypeId, parameters}: PinBuildArg, {dispatch}) => {
    const {pinComment, pin, tags, applyToChainBuilds} = parameters
    dispatch(changeBuildTags(ids, tags, buildTypeId, applyToChainBuilds))
    return pinBuilds(restRoot, ids, pin, pinComment, applyToChainBuilds)
  },
)
