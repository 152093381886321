import {createReducer} from '@reduxjs/toolkit'
import * as Redux from 'redux'

import {fetchFederationServerProjectsDataWithOptions} from '../components/common/SidebarFooter/SidebarFooter.actions'
import HTTPError from '../rest/errors/HTTPError'
import type {FederationServerData} from '../types'
import {emptyArray} from '../utils/empty'

import {toolkitFetchable} from './fetchable'

const NOT_AUTH_HTTP_STATUS = 401

export default Redux.combineReducers<FederationServerData>({
  authorized: createReducer<boolean>(true, builder => {
    builder.addCase(fetchFederationServerProjectsDataWithOptions.fulfilled, () => true)
    builder.addCase(
      fetchFederationServerProjectsDataWithOptions.rejected,
      (_, action) =>
        !(action.error instanceof HTTPError) || action.error.statusCode !== NOT_AUTH_HTTP_STATUS,
    )
  }),

  projects: toolkitFetchable(
    fetchFederationServerProjectsDataWithOptions,
    emptyArray,
    (_, action) => action.payload.result,
  ),
})
