import type {BaseReceiveAction} from '../../actions/fetchActions'
import type {
  BuildTypeId,
  BuildTypeInternalId,
  Fetchable,
  ProjectId,
  ProjectInternalId,
} from '../../types'
import type {KeyValue, WritableKeyValue} from '../../utils/object'
import type {ErrorAnswerType} from '../CleanupBuildType/Cleanup.types'

export type HolderType = 'project' | 'buildType' | 'template'

/** POLICY */
export type PolicyTemplateType = {
  name: string
  fullName: string
  projectId: ProjectInternalId
  externalId: BuildTypeId
}
export type PolicyTemplatesType = readonly PolicyTemplateType[]
type BuildTypeTemplatesType = {
  basedOnInaccessibleTemplates: boolean
  templates: PolicyTemplatesType
}
type PolicyEntryMeta = {
  internalId: ProjectInternalId | BuildTypeInternalId
  externalId: ProjectId | BuildTypeId
  name: string
  everythingPolicy: boolean | null | undefined
  buildTypeTemplates?: BuildTypeTemplatesType | null | undefined
}
export type PolicyHolderType =
  | {
      readonly holderId: ProjectInternalId
      readonly holderType: 'project'
    }
  | {
      readonly holderId: BuildTypeInternalId
      readonly holderType: 'buildType'
    }
type PolicyEntryOptions = {
  ownPrevent: boolean
  preventDependenciesArtifactsFromCleanup: boolean
  cleanupPoliciesDisabled: boolean
  ownDisabled: boolean
}
export type PolicyType = {
  ownPolicy: boolean
  levelDescription: 'Artifacts' | 'History' | 'Everything'
  policyDescription: string
  policyParameters: {
    'keepDays.count'?: string
    'keepBuilds.count'?: string
    artifactPatterns?: string
  }
}
export type PolicyEntry = PolicyEntryMeta & {
  artifactsPolicy: PolicyType | null | undefined
  historyPolicy: PolicyType | null | undefined
  everythingPolicy: PolicyType | null | undefined
  options: PolicyEntryOptions
}
export type Settings = {
  daysCount: number | null | undefined
  buildsCount: number | null | undefined
  artifactPatterns: string | null | undefined
}
export type UpdatePolicyPayload = {
  holder: HolderType
  holderId: string
  everythingSettings: Settings | null | undefined
  historySettings: Settings | null | undefined
  artifactsSettings: Settings | null | undefined
  preventDependencies: boolean | null | undefined
}
export type DiskUsageType = {
  buildType: WritableKeyValue<string, number>
  project: WritableKeyValue<string, number>
}
export type DiskUsageNode = Element & {
  nodeName: keyof DiskUsageType
}
export const emptyDiskUsage: DiskUsageType = {
  buildType: {},
  project: {},
}

/** Action Creators Props */
export type ACPGetDiskUsagePropsType = ProjectId

/** REST **/
export const REQUEST_POLICIES: 'REQUEST_POLICIES' = 'REQUEST_POLICIES'
export const RECEIVE_POLICIES: 'RECEIVE_POLICIES' = 'RECEIVE_POLICIES'
export const REQUEST_SET_DISABLED_POLICY: 'REQUEST_SET_DISABLED_POLICY' =
  'REQUEST_SET_DISABLED_POLICY'
export const RECEIVE_SET_DISABLED_POLICY: 'RECEIVE_SET_DISABLED_POLICY' =
  'RECEIVE_SET_DISABLED_POLICY'
export const REQUEST_UPDATE_POLICIES: 'REQUEST_UPDATE_POLICIES' = 'REQUEST_UPDATE_POLICIES'
export const RECEIVE_UPDATE_POLICIES: 'RECEIVE_UPDATE_POLICIES' = 'RECEIVE_UPDATE_POLICIES'
export const REQUEST_RESET: 'REQUEST_RESET' = 'REQUEST_RESET'
export const RECEIVE_RESET: 'RECEIVE_RESET' = 'RECEIVE_RESET'
export const REQUEST_DISK_USAGE: 'REQUEST_DISK_USAGE' = 'REQUEST_DISK_USAGE'
export const RECEIVE_DISK_USAGE: 'RECEIVE_DISK_USAGE' = 'RECEIVE_DISK_USAGE'
export type Policy = {
  projectInternalId: ProjectInternalId
  projectExternalId: ProjectId
  projectDescription: string
  editPermitted: boolean
  projectPolicies: PolicyEntry
  templatesPolicies: ReadonlyArray<PolicyEntry>
  buildTypesPolicies: ReadonlyArray<PolicyEntry>
}
export type PoliciesResponse = {
  policiesPerProject: ReadonlyArray<Policy>
}
type RequestPolicies = {
  type: typeof REQUEST_POLICIES
  readonly projectInternalId: ProjectInternalId
}
type ReceivePolicies = BaseReceiveAction<PoliciesResponse> & {
  type: typeof RECEIVE_POLICIES
  readonly projectInternalId: ProjectInternalId
  readonly customError?: void
}
type RequestReset = PolicyHolderType & {
  type: typeof REQUEST_RESET
}
type ReceiveReset = PolicyHolderType & {
  type: typeof RECEIVE_RESET
  readonly customError?: ErrorAnswerType
  readonly error?: Error
}
type RequestUpdate = {
  type: typeof REQUEST_UPDATE_POLICIES
}
type ReceiveUpdate = BaseReceiveAction<typeof REQUEST_UPDATE_POLICIES> & {
  type: typeof RECEIVE_UPDATE_POLICIES
  readonly customErrors?: Error[]
}
type RequestDiskUsage = {
  type: typeof REQUEST_DISK_USAGE
  readonly holderId?: ProjectId
}
type ReceiveDiskUsage = BaseReceiveAction<DiskUsageType> & {
  type: typeof RECEIVE_DISK_USAGE
  readonly holderId?: ProjectId | null | undefined
  readonly customError?: ErrorAnswerType
}
type RequestSetDisabled = PolicyHolderType & {
  type: typeof REQUEST_SET_DISABLED_POLICY
}
type ReceiveSetDisabled = PolicyHolderType & {
  type: typeof RECEIVE_SET_DISABLED_POLICY
  readonly customError?: ErrorAnswerType
  readonly error?: Error
}
export type CleanupPoliciesRequestActions =
  | typeof REQUEST_POLICIES
  | typeof REQUEST_RESET
  | typeof REQUEST_UPDATE_POLICIES
  | typeof REQUEST_DISK_USAGE
  | typeof REQUEST_SET_DISABLED_POLICY
export type CleanupPoliciesActions =
  | RequestPolicies
  | ReceivePolicies
  | RequestReset
  | ReceiveReset
  | RequestUpdate
  | ReceiveUpdate
  | RequestDiskUsage
  | ReceiveDiskUsage
  | RequestSetDisabled
  | ReceiveSetDisabled
export type LastActionType = {
  action: CleanupPoliciesRequestActions | null
  loading: boolean
  ready: boolean
}
export type CleanupPoliciesStateType = {
  entities: KeyValue<ProjectInternalId, PoliciesResponse>
  errors: ReadonlyArray<ErrorAnswerType>
  requestStatus: Fetchable<CleanupPoliciesRequestActions | null>
  diskUsage: DiskUsageType
}
export type OwnerType = {
  internalId: ProjectInternalId
  externalId: ProjectId
}
export type EntityType = {
  policy: PolicyEntry
  type: HolderType
  owner: OwnerType
}
export type OwnProps = {
  readonly projectInternalId: ProjectInternalId
  readonly projectId: ProjectId
}
export type StateProps = {
  readonly policiesAreLoaded: boolean
  readonly lastAction: LastActionType
  readonly policies: PoliciesResponse | null | undefined
}
export type ActionProps = {
  readonly fetchProjectPolicies: (
    arg0: ProjectInternalId,
    arg1: {
      includeSubprojects: boolean
    },
  ) => unknown
  readonly fetchDiskUsage: (arg0: ACPGetDiskUsagePropsType) => unknown
  readonly resetPolicy: (arg0: PolicyHolderType) => unknown
  readonly editPolicy: (arg0: UpdatePolicyPayload) => unknown
  readonly disablePolicy: (arg0: PolicyHolderType, arg1: boolean | null | undefined) => unknown
}
export type HooksProps = {
  readonly showSubprojects: boolean
  readonly toggleSubprojects: (arg0: string) => unknown
}
type HandlersProps = {
  readonly fetchPolicies: () => unknown
}
export type Props = OwnProps & StateProps & ActionProps & HooksProps & HandlersProps
