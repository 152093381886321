import batchVisible from '../../actions/batchVisible'
import {buildDetailsQueue} from '../../actions/buildDetailsQueue'
import type {AppThunk} from '../../actions/types'
import {createFetchAction} from '../../reducers/fetchable'
import {restRoot} from '../../rest/consts'
import {
  normalizeBuildChanges,
  normalizeBuildChangesRevisions,
  normalizeBuilds,
  normalizeChanges,
  NormalizedChanges,
} from '../../rest/schemata'
import type {BuildChangesRevisionsType, BuildId, BuildType} from '../../types'
import {BuildChangesType, ChangesRequestOptions, stringifyId} from '../../types'
import {Namespaces} from '../../utils/namespaces'
import {getPager} from '../common/Pager/Pager.selectors'
import {PagerGroup} from '../common/Pager/Pager.types'

import {
  requestBuildArtifactDependenciesChanges,
  requestBuildChanges,
  requestBuildChangesRevisions,
  requestChangeBuildTypes,
  requestChangeDeployments,
  requestChangeFiles,
  requestChanges,
} from './Changes.rest'
import {getBuildChangesRevisionsFetchable} from './Changes.selectors'
import type {RequestBuildChangesRevisionsParams} from './Changes.types'
import {
  RECEIVE_CHANGE_BUILD_TYPES,
  RECEIVE_CHANGE_DEPLOYMENTS,
  RECEIVE_CHANGES_FILES,
  REQUEST_CHANGE_BUILD_TYPES,
  REQUEST_CHANGE_DEPLOYMENTS,
  REQUEST_CHANGES_FILES,
} from './Changes.types'

type FetchBuildChangesRevisionsArg = {
  locator: string
  options?: RequestBuildChangesRevisionsParams
}
export const fetchBuildChangesRevisions = createFetchAction(
  'fetchBuildChangesRevisions',
  ({locator, options}: FetchBuildChangesRevisionsArg) =>
    requestBuildChangesRevisions(restRoot, locator, options).then(normalizeBuildChangesRevisions),
  {
    condition({locator}, {getState}) {
      const state = getState()
      const {loading, ready} = getBuildChangesRevisionsFetchable(state, locator)
      return !loading && !ready
    },
  },
)

export const receiveBuildChangesRevisions = (
  data: ReadonlyArray<BuildChangesRevisionsType>,
  locator: string,
) => fetchBuildChangesRevisions.fulfilled(normalizeBuildChangesRevisions(data), '', {locator})

export const fetchBuildChanges = createFetchAction('fetchBuildChanges', (buildId?: BuildId) =>
  requestBuildChanges(restRoot, `id:${stringifyId(buildId)}`).then(normalizeBuildChanges),
)
export const receiveBuildChanges = (data: ReadonlyArray<BuildChangesType>) =>
  fetchBuildChanges.fulfilled(normalizeBuildChanges(data), '', undefined)

export type FetchChangesArg = {
  locator: string
  options?: ChangesRequestOptions
}
type FetchChangesPayload = {
  data: NormalizedChanges
  loadedLessThanRequested?: boolean
  hasMore?: boolean
}
export const fetchChanges = createFetchAction(
  'fetchChanges',
  ({locator, options}: FetchChangesArg, {getState}) => {
    const state = getState()
    const {pageSize} = getPager(state, PagerGroup.CHANGE)
    return requestChanges(restRoot, locator, options).then(
      ({data, hasMore}): FetchChangesPayload => ({
        data: normalizeChanges(data),
        loadedLessThanRequested: data.length < pageSize,
        hasMore,
      }),
    )
  },
)

export const fetchChangesFiles =
  (locator: string): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: REQUEST_CHANGES_FILES,
      locator,
    })
    return requestChangeFiles(state.restRoot, locator).then(
      ({data}) =>
        dispatch({
          type: RECEIVE_CHANGES_FILES,
          data: normalizeChanges(data),
          locator,
        }),
      (error: Error) =>
        dispatch({
          type: RECEIVE_CHANGES_FILES,
          error,
          locator,
        }),
    )
  }

export const fetchBuildArtifactDependenciesChanges = createFetchAction(
  'fetchBuildArtifactDependenciesChanges',
  (locator: string) =>
    requestBuildArtifactDependenciesChanges(restRoot, locator).then(normalizeBuilds),
)

export const receiveBuildArtifactDependenciesChanges = (
  data: ReadonlyArray<BuildType>,
  locator: string,
) => fetchBuildArtifactDependenciesChanges.fulfilled(normalizeBuilds(data), '', locator)

export const fetchChangesQueue: (id: BuildId) => AppThunk<void> = batchVisible({
  queue: buildDetailsQueue,
  namespace: Namespaces.CHANGES,
  request: (_, id) => requestBuildChanges(restRoot, `id:${stringifyId(id)}`),
  action: receiveBuildChanges,
})

export const fetchChangeBuildTypes =
  (locator: string): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: REQUEST_CHANGE_BUILD_TYPES,
      locator,
    })
    return requestChangeBuildTypes(state.restRoot, locator).then(
      data =>
        dispatch({
          type: RECEIVE_CHANGE_BUILD_TYPES,
          locator,
          data,
        }),
      (error: Error) =>
        dispatch({
          type: RECEIVE_CHANGE_BUILD_TYPES,
          locator,
          error,
        }),
    )
  }

export const fetchChangeDeployments =
  (locator: string): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: REQUEST_CHANGE_DEPLOYMENTS,
      locator,
    })
    return requestChangeDeployments(state.restRoot, locator).then(
      data =>
        dispatch({
          type: RECEIVE_CHANGE_DEPLOYMENTS,
          locator,
          data,
        }),
      (error: Error) =>
        dispatch({
          type: RECEIVE_CHANGE_DEPLOYMENTS,
          locator,
          error,
        }),
    )
  }
