import type {AppThunk} from '../../actions/types'
import {base_uri} from '../../types/BS_types'

import requestInvestigationHistory from './InvestigationHistory.rest'
import {
  RECEIVE_INVESTIGATION_HISTORY,
  REQUEST_INVESTIGATION_HISTORY,
} from './InvestigationHistory.types'

export const fetchInvestigationHistory =
  (locator: string, isBackground?: boolean): AppThunk<any> =>
  dispatch => {
    dispatch({
      type: REQUEST_INVESTIGATION_HISTORY,
      locator,
      isBackground,
    })
    return requestInvestigationHistory(base_uri, locator).then(
      data =>
        dispatch({
          type: RECEIVE_INVESTIGATION_HISTORY,
          data,
          locator,
          isBackground,
        }),
      error =>
        dispatch({
          type: RECEIVE_INVESTIGATION_HISTORY,
          locator,
          error,
          isBackground,
        }),
    )
  }
