import {investigation} from '../../rest/investigations'
import processResponse from '../../rest/processResponse'
import request from '../../rest/request'
import {stringifyId} from '../../types'
import type {ProblemOccurrenceId} from '../../types'
import {objectToQuery} from '../../utils/queryParams'
import {muteFields} from '../Tests/Tests.rest'

import type {ProblemOccurrencesTreeType, ProblemOccurrenceType} from './BuildProblems.types'

const problemOccurrenceFields = `id,additionalData,newFailure,currentlyInvestigated,currentlyMuted,${muteFields},type,logAnchor,details,build(id,buildTypeId),problem(id,description,mutes(${muteFields}),investigations(${investigation}))`
export const requestProblemOccurrence = (
  serverUrl: string,
  problemOccurrenceId: ProblemOccurrenceId,
): Promise<ProblemOccurrenceType> =>
  request(
    serverUrl,
    `problemOccurrences/${encodeURIComponent(
      stringifyId(problemOccurrenceId),
    )}?fields=${problemOccurrenceFields}`,
  ).then<ProblemOccurrenceType>(processResponse)

const problemOccurrencesTreeFields = `node(type,name,parentId,id,count,newFailedCount,childrenCount),leaf(id,nodeId,problemOccurrences(problemOccurrence(${problemOccurrenceFields})))`
export const requestProblemOccurrencesTree = (
  serverUrl: string,
  endpoint: string,
  locator?: string | undefined | null,
  subTreeRootId?: string | undefined | null,
): Promise<ProblemOccurrencesTreeType> =>
  request(
    serverUrl,
    `${endpoint}?${objectToQuery({locator, subTreeRootId, fields: problemOccurrencesTreeFields})}`,
  ).then<ProblemOccurrencesTreeType>(processResponse)
