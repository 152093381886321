import memoize from 'memoize-one'
import type {Dispatch} from 'redux'

import type {Action} from '../actions/types'
import type {MiddlewareAPI} from '../store/types'

const loadStatisticsChunk = memoize(
  () =>
    import(
      /* webpackChunkName: "statistics", webpackPrefetch: true */
      '.'
    ),
)
export default (store: MiddlewareAPI) =>
  (next: Dispatch<Action>) =>
  (action: Action): Action => {
    const state = store.getState()
    loadStatisticsChunk().then(({track}) => track(state, action))
    return next(action)
  }
