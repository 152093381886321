import compose from 'lodash/flowRight'
import * as ReactRedux from 'react-redux'

import {fetchBuildTypeTags} from '../actions/fetch'
import TagsList from '../components/TagsList/TagsList'
import withEffect from '../hocs/withEffect'
import type {State} from '../reducers/types'
import {
  getBuildType,
  getBuildTypeTags,
  getBuildTypeTagsReady,
  getIsExperimentalUI,
} from '../selectors'
import type {BuildTypeId, BuildTypeInternalId} from '../types'
import {subscribeOnBuildTypeEvents} from '../utils/subscriber'
import {BUILD_TAGS_CHANGED} from '../utils/subscriptionEvents'

type OwnProps = {
  buildTypeId: BuildTypeId
  selected?: string | null | undefined
  onSelect?: (arg0: string | null | undefined) => unknown
  onReset?: () => unknown
}
type StateProps = {
  tags: ReadonlyArray<string>
  ready: boolean
  internalId: BuildTypeInternalId | null | undefined
  isExperimentalUI: boolean | null | undefined
}
type ActionProps = {
  fetchTags(buildTypeId: BuildTypeId): void
}
type Props = OwnProps & StateProps & ActionProps

const mapStateToProps = (state: State, {buildTypeId}: OwnProps): StateProps => ({
  tags: getBuildTypeTags(state, buildTypeId),
  ready: getBuildTypeTagsReady(state, buildTypeId),
  internalId: getBuildType(state, buildTypeId)?.internalId,
  isExperimentalUI: getIsExperimentalUI(state),
})

const actionCreators = {
  fetchTags: fetchBuildTypeTags,
}
export default compose(
  ReactRedux.connect(mapStateToProps, actionCreators),
  withEffect(
    (props: Props) => props.selected,
    props => props.ready,
    props => props.tags,
    props => props.onReset,
    (selected, ready, tags, onReset) => {
      if (onReset != null && selected != null && ready && !tags.includes(selected)) {
        onReset()
      }
    },
  ),
  withEffect(
    props => props.buildTypeId,
    props => props.internalId,
    props => props.fetchTags,
    (id, internalId, fetch) => {
      const handler = () => fetch(id)

      if (internalId != null) {
        return subscribeOnBuildTypeEvents(internalId, [BUILD_TAGS_CHANGED], handler)
      } else {
        handler()
        return undefined
      }
    },
  ),
)(TagsList)
