import {batch} from 'react-redux'

import * as Actions from '../../../actions/actionTypes'
import {fetchFederationServers} from '../../../actions/federation'
import {fetchArchivedProjects} from '../../../actions/projects'
import type {AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import {restRoot} from '../../../rest/consts'
import requestProjects from '../../../rest/projects'
import {normalizeProjectsData} from '../../../rest/schemata'
import {isSidebarFederationArchivedProjectsLoaded} from '../../../selectors'
import {ROOT_PROJECT_ID, stringifyId} from '../../../types'
import type {
  FederationServerId,
  ProjectId,
  ProjectReceiveMeta,
  ProjectWithDetailsType,
  RequestOptionsParams,
} from '../../../types'
import memoryCache from '../../../utils/memoryCache'

type FetchFederationServerProjectsDataWithOptionsArg = {
  requestOptions: RequestOptionsParams
  rootProjectId?: ProjectId
  force?: boolean
  receiveMeta?: ProjectReceiveMeta
  customRestRoot?: string
  withAuth?: boolean
}
export const fetchFederationServerProjectsDataWithOptions = createFetchAction(
  'fetchFederationServerProjectsDataWithOptions',
  ({
    requestOptions,
    rootProjectId = ROOT_PROJECT_ID,
    customRestRoot,
    withAuth,
  }: FetchFederationServerProjectsDataWithOptionsArg) =>
    requestProjects(customRestRoot ?? restRoot, rootProjectId, requestOptions, withAuth).then(
      data => {
        memoryCache.reset('fullPath')
        return normalizeProjectsData(data, requestOptions)
      },
    ),
  {
    condition: ({requestOptions, force}, {getState}) => {
      const {federationServersData} = getState()
      const {federationServerId} = requestOptions

      if (!federationServerId) {
        return false
      }

      const serverData = federationServersData[federationServerId]

      return force || !serverData || (!serverData.projects.inited && !serverData.projects.loading)
    },
  },
)

export const receiveFederationServerProjects = (
  data: ReadonlyArray<ProjectWithDetailsType>,
  requestOptions: RequestOptionsParams,
  receiveMeta: ProjectReceiveMeta,
) => {
  const normalizedData = normalizeProjectsData(data, requestOptions)
  return fetchFederationServerProjectsDataWithOptions.fulfilled(normalizedData, '', {
    requestOptions,
    receiveMeta,
  })
}

const fetchProjectsForFederationServer =
  (federationServerId: FederationServerId, force?: boolean): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const archivedProjectsLoaded = isSidebarFederationArchivedProjectsLoaded(
      state,
      federationServerId,
    )
    const withArchived = state.sidebar.showArchivedProjects

    if (!withArchived || (withArchived && !archivedProjectsLoaded)) {
      dispatch(
        fetchFederationServerProjectsDataWithOptions({
          force,
          withAuth: false,
          rootProjectId: ROOT_PROJECT_ID,
          receiveMeta: {
            sidebarArchivedProjectsLoaded: withArchived,
          },
          customRestRoot: `${stringifyId(federationServerId)}/app/rest`,
          requestOptions: {
            federationServerId,
            withBuildTypes: true,
            archived: withArchived ? 'any' : 'false',
          },
        }),
      )
    }
  }

export const fetchProjectsForAllFederationServers =
  (force?: boolean): AppThunk<any> =>
  async (dispatch, getState) => {
    let state = getState()

    if (!state.federationServers.inited && !state.federationServers.loading) {
      await dispatch(fetchFederationServers(force))
    }

    state = getState()
    batch(() => {
      for (let i = 0; i < state.federationServers.data.length; i++) {
        const federationServerId = state.federationServers.data[i]
        dispatch(fetchProjectsForFederationServer(federationServerId, force))
      }
    })
  }
export const toggleArchivedProjectsInSidebar = (): AppThunk<any> => (dispatch, getState) => {
  dispatch({
    type: Actions.TOGGLE_ARCHIVED_PROJECTS_IN_SIDEBAR,
  })
  const state = getState()

  if (state.sidebar.showArchivedProjects) {
    batch(() => {
      dispatch(fetchArchivedProjects())
      dispatch(fetchProjectsForAllFederationServers(true))
    })
  }
}
