import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
  CHANGE_ALL_PROJECTS_COLLAPSED,
  CHANGE_SIDEBAR_SEARCH_ACTIVE,
  TOGGLE_ARCHIVED_PROJECTS_IN_SIDEBAR,
} from '../../../actions/actionTypes'
import {Action} from '../../../actions/types'
import {ExpandState, ProjectId} from '../../../types'
import {KeyValue} from '../../../utils/object'

export const allProjectsExpandState = createSlice({
  name: 'allProjectsExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id] = item
      })
    },
  },
})

export const sidebar = createSlice({
  name: 'sidebar',
  initialState: {
    searchQuery: '',
    searchActive: false,
    allProjectsCollapsed: false,
    showArchivedProjects: false,
  },
  reducers: {
    changeSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload
    },
  },
  extraReducers: builder =>
    builder.addDefaultCase((state, action: Action) => {
      switch (action.type) {
        case CHANGE_SIDEBAR_SEARCH_ACTIVE:
          return {
            ...state,
            searchQuery: !action.active ? '' : state.searchQuery,
            searchActive: action.active,
            showArchivedProjects: !action.active ? false : state.showArchivedProjects,
          }

        case CHANGE_ALL_PROJECTS_COLLAPSED:
          return {
            ...state,
            allProjectsCollapsed: action.collapsed,
          }

        case TOGGLE_ARCHIVED_PROJECTS_IN_SIDEBAR:
          return {
            ...state,
            showArchivedProjects: !state.showArchivedProjects,
          }

        default:
          return state
      }
    }),
})

export const searchProjectsExpandState = createSlice({
  name: 'searchProjectsExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id] = item
      })
    },
  },
  extraReducers: builder => builder.addCase(sidebar.actions.changeSearchQuery, () => ({})),
})
