import * as Redux from 'redux'

import type {Action} from '../../actions/types'
import fetchable from '../../reducers/fetchable'
import {mergeIfDifferent} from '../../reducers/utils'
import type {Fetchable, ProjectInternalId} from '../../types'
import {emptyArray, emptyNullFetchable} from '../../utils/empty'
import {keyValue} from '../../utils/object'
import type {KeyValue} from '../../utils/object'
import type {ErrorAnswerType} from '../CleanupBuildType/Cleanup.types'

import {
  REQUEST_POLICIES,
  RECEIVE_POLICIES,
  REQUEST_UPDATE_POLICIES,
  RECEIVE_UPDATE_POLICIES,
  REQUEST_RESET,
  RECEIVE_RESET,
  RECEIVE_DISK_USAGE,
  REQUEST_DISK_USAGE,
  REQUEST_SET_DISABLED_POLICY,
  RECEIVE_SET_DISABLED_POLICY,
  emptyDiskUsage,
} from './CleanupPolicies.types'
import type {
  CleanupPoliciesRequestActions,
  DiskUsageType,
  PoliciesResponse,
} from './CleanupPolicies.types'

const policiesGetReducer = fetchable<CleanupPoliciesRequestActions | null, never>(
  REQUEST_POLICIES,
  RECEIVE_POLICIES,
  null,
  () => REQUEST_POLICIES,
)
const policiesUpdateReducer = fetchable<CleanupPoliciesRequestActions | null, never>(
  REQUEST_UPDATE_POLICIES,
  RECEIVE_UPDATE_POLICIES,
  null,
  () => REQUEST_UPDATE_POLICIES,
)
const policiesSetDisabledReducer = fetchable<CleanupPoliciesRequestActions | null, never>(
  REQUEST_SET_DISABLED_POLICY,
  RECEIVE_SET_DISABLED_POLICY,
  null,
  () => REQUEST_SET_DISABLED_POLICY,
)
const policiesResetReducer = fetchable<CleanupPoliciesRequestActions | null, never>(
  REQUEST_RESET,
  RECEIVE_RESET,
  null,
  () => REQUEST_RESET,
)

const entities = (state: KeyValue<ProjectInternalId, PoliciesResponse> = {}, action: Action) => {
  switch (action.type) {
    case RECEIVE_POLICIES:
      if (!action.customError && !action.error) {
        return mergeIfDifferent(
          state,
          keyValue(
            action.projectInternalId,
            action.data || {
              policiesPerProject: [],
            },
          ),
        )
      }

      return state

    default:
      return state
  }
}

const errors = (
  state: ReadonlyArray<ErrorAnswerType> = emptyArray,
  action: Action,
): ReadonlyArray<ErrorAnswerType> => {
  switch (action.type) {
    case REQUEST_POLICIES:
    case REQUEST_UPDATE_POLICIES:
    case REQUEST_RESET:
      return emptyArray

    case RECEIVE_POLICIES:
    case RECEIVE_RESET:
      return action.customError ? [action.customError] : emptyArray

    case RECEIVE_UPDATE_POLICIES:
      return action.customErrors ?? emptyArray

    default:
      return state
  }
}

const requestStatus = (
  state: Fetchable<CleanupPoliciesRequestActions | null> = emptyNullFetchable,
  action: Action,
) => {
  switch (action.type) {
    case REQUEST_POLICIES:
    case RECEIVE_POLICIES:
      return policiesGetReducer(state, action)

    case REQUEST_UPDATE_POLICIES:
    case RECEIVE_UPDATE_POLICIES:
      return policiesUpdateReducer(state, action)

    case REQUEST_RESET:
    case RECEIVE_RESET:
      return policiesResetReducer(state, action)

    case REQUEST_SET_DISABLED_POLICY:
    case RECEIVE_SET_DISABLED_POLICY:
      return policiesSetDisabledReducer(state, action)

    default:
      return state
  }
}

const diskUsage = (state: DiskUsageType = emptyDiskUsage, action: Action) => {
  switch (action.type) {
    case RECEIVE_DISK_USAGE:
      return {...state, ...action.data}

    case REQUEST_DISK_USAGE:
    default:
      return state
  }
}

const cleanupPoliciesReducer = Redux.combineReducers({
  requestStatus,
  entities,
  errors,
  diskUsage,
})
/** Add exported reducer to the end of mainReducer in ~/reducers/index.js */

export default cleanupPoliciesReducer
