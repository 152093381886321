import type {State} from '../reducers/types'
import type {SWGenerator} from '../types/generators'
import {EmptySWResponse, SWGeneratorReturn} from '../types/generators'

import type {Action, AppThunk} from './types'

type FetchActionParams<T> = {
  readonly onRequest: (request: Promise<T | null>) => Action
  readonly onSuccess: (data: T) => Action
  readonly onError: (error: Error) => Action
  readonly request: (ket: string) => Promise<T>
  readonly condition?: (state: State) => boolean
}
export function fetchAction<T>({
  onRequest,
  onSuccess,
  onError,
  request,
  condition = () => true,
}: FetchActionParams<T>): AppThunk<Promise<T | null | undefined>> {
  return (dispatch, getState) => {
    const state = getState()

    if (!condition(state)) {
      return Promise.resolve(null)
    }

    const dataRequest = request(state.restRoot).then(
      data => {
        dispatch(onSuccess(data))
        return data
      },
      error => {
        if (error instanceof Error) {
          dispatch(onError(error))
        }
        return null
      },
    )

    dispatch(onRequest(dataRequest))
    return dataRequest
  }
}

function noop() {}

type ProcessServiceWorkerResponseProps<T, F> = {
  iterator: SWGenerator<T, F>
  onStart?: () => unknown
  onSuccess: (arg0: T) => unknown
  onError?: (arg0: Error) => unknown
  onFinal?: () => unknown
  skipWaiting?: boolean
}

const checkIfNonValidResponse = <K>(event: SWGeneratorReturn<K>): event is EmptySWResponse =>
  event &&
  (event as EmptySWResponse).meta &&
  ((event as EmptySWResponse).meta.NO_NEW_DATA === true ||
    (event as EmptySWResponse).meta.timeout === true)

export async function processServiceWorkerResponse<T, F>({
  iterator,
  onStart = noop,
  onSuccess = noop,
  onError = noop,
  onFinal = noop,
}: ProcessServiceWorkerResponseProps<T, F>) {
  onStart !== noop && onStart()

  try {
    const cached = await iterator.next()

    // The first response is always fullfilled either by Cache or by Request
    {
      const {value, done} = cached

      if (!checkIfNonValidResponse<T>(value)) {
        onSuccess(value.payload)
      }

      if (done) {
        return
      }
    }

    // The second request can be received from the CacheUpdates
    {
      const {value, done} = await iterator.next()

      if (done && !checkIfNonValidResponse<T>(value)) {
        onSuccess(value.payload)
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      onError(error)
    }
  } finally {
    onFinal !== noop && onFinal()
  }
}
