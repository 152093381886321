export const REQUEST_BUILDS_DATA: 'REQUEST_BUILDS_DATA' = 'REQUEST_BUILDS_DATA'
export const REQUEST_HAS_BUILDS: 'REQUEST_HAS_BUILDS' = 'REQUEST_HAS_BUILDS'
export const REQUEST_HAS_BUILD_TYPES: 'REQUEST_HAS_BUILD_TYPES' = 'REQUEST_HAS_BUILD_TYPES'
export const REQUEST_IS_EXTERNAL_STATUS_ALLOWED: 'REQUEST_IS_EXTERNAL_STATUS_ALLOWED' =
  'REQUEST_IS_EXTERNAL_STATUS_ALLOWED'
export const RECEIVE_BUILDS_DATA_PROGRESS: 'RECEIVE_BUILDS_DATA_PROGRESS' =
  'RECEIVE_BUILDS_DATA_PROGRESS'
export const RECEIVE_HAS_BUILDS: 'RECEIVE_HAS_BUILDS' = 'RECEIVE_HAS_BUILDS'
export const RECEIVE_HAS_BUILD_TYPES: 'RECEIVE_HAS_BUILD_TYPES' = 'RECEIVE_HAS_BUILD_TYPES'
export const RECEIVE_IS_EXTERNAL_STATUS_ALLOWED: 'RECEIVE_IS_EXTERNAL_STATUS_ALLOWED' =
  'RECEIVE_IS_EXTERNAL_STATUS_ALLOWED'
export const REQUEST_PROJECT_TEST_INVESTIGATIONS: 'REQUEST_PROJECT_TEST_INVESTIGATIONS' =
  'REQUEST_PROJECT_TEST_INVESTIGATIONS'
export const RECEIVE_PROJECT_TEST_INVESTIGATIONS: 'RECEIVE_PROJECT_TEST_INVESTIGATIONS' =
  'RECEIVE_PROJECT_TEST_INVESTIGATIONS'
export const PIN_BUILD: 'PIN_BUILD' = 'PIN_BUILD'
export const MOVE_TO_TOP: 'MOVE_TO_TOP' = 'MOVE_TO_TOP'
export const CHANGE_PROJECT_BUILDTYPE_FILTER: 'CHANGE_PROJECT_BUILDTYPE_FILTER' =
  'CHANGE_PROJECT_BUILDTYPE_FILTER'
export const REQUEST_OVERVIEW_DATA: 'REQUEST_OVERVIEW_DATA' = 'REQUEST_OVERVIEW_DATA'
export const RECEIVE_PIN_BUILD_MULTIPLE: 'RECEIVE_PIN_BUILD_MULTIPLE' = 'RECEIVE_PIN_BUILD_MULTIPLE'
export const CHANGE_SIDEBAR_SEARCH_ACTIVE: 'CHANGE_SIDEBAR_SEARCH_ACTIVE' =
  'CHANGE_SIDEBAR_SEARCH_ACTIVE'
export const CHANGE_ALL_PROJECTS_COLLAPSED: 'CHANGE_ALL_PROJECTS_COLLAPSED' =
  'CHANGE_ALL_PROJECTS_COLLAPSED'
export const SHOW_QUEUED_BUILDS: 'SHOW_QUEUED_BUILDS' = 'SHOW_QUEUED_BUILDS'
export const HIDE_QUEUED_BUILDS: 'HIDE_QUEUED_BUILDS' = 'HIDE_QUEUED_BUILDS'
export const SET_SHOW_QUEUED_BUILDS_COUNT: 'SET_SHOW_QUEUED_BUILDS_COUNT' =
  'SET_SHOW_QUEUED_BUILDS_COUNT'
export const SHOW_QUEUED_BUILDS_FOR_PROJECT: 'SHOW_QUEUED_BUILDS_FOR_PROJECT' =
  'SHOW_QUEUED_BUILDS_FOR_PROJECT'
export const HIDE_QUEUED_BUILDS_FOR_PROJECT: 'HIDE_QUEUED_BUILDS_FOR_PROJECT' =
  'HIDE_QUEUED_BUILDS_FOR_PROJECT'
export const TOGGLE_ARCHIVED_PROJECTS_IN_SIDEBAR: 'TOGGLE_ARCHIVED_PROJECTS_IN_SIDEBAR' =
  'TOGGLE_ARCHIVED_PROJECTS_IN_SIDEBAR'
export const RECEIVE_ARTIFACTS_SIZE: 'RECEIVE_ARTIFACTS_SIZE' = 'RECEIVE_ARTIFACTS_SIZE'
export const RECEIVE_SERVER_INFO: 'RECEIVE_SERVER_INFO' = 'RECEIVE_SERVER_INFO'
export const REQUEST_FEDERATION_SERVERS: 'REQUEST_FEDERATION_SERVERS' = 'REQUEST_FEDERATION_SERVERS'
export const RECEIVE_FEDERATION_SERVERS: 'RECEIVE_FEDERATION_SERVERS' = 'RECEIVE_FEDERATION_SERVERS'
export const REQUEST_TABS: 'REQUEST_TABS' = 'REQUEST_TABS'
export const RECEIVE_TABS: 'RECEIVE_TABS' = 'RECEIVE_TABS'
export const DUMMY_ACTION: 'DUMMY_ACTION' = 'DUMMY_ACTION'
export const RECEIVE_BUILDTYPE_HAS_PROMPT_PARAMETER: 'RECEIVE_BUILDTYPE_HAS_PROMPT_PARAMETER' =
  'RECEIVE_BUILDTYPE_HAS_PROMPT_PARAMETER'
export const ADD_PLUGIN: 'ADD_PLUGIN' = 'ADD_PLUGIN'
export const UPDATE_PLUGIN: 'UPDATE_PLUGIN' = 'UPDATE_PLUGIN'
export const REMOVE_PLUGIN: 'REMOVE_PLUGIN' = 'REMOVE_PLUGIN'
export const REQUEST_OVERVIEW_BUILDTYPES_ORDER_RESET: 'REQUEST_OVERVIEW_BUILDTYPES_ORDER_RESET' =
  'REQUEST_OVERVIEW_BUILDTYPES_ORDER_RESET'
export const RECEIVE_OVERVIEW_BUILDTYPES_ORDER_RESET: 'RECEIVE_OVERVIEW_BUILDTYPES_ORDER_RESET' =
  'RECEIVE_OVERVIEW_BUILDTYPES_ORDER_RESET'
export const ERROR_ACTION: 'ERROR_ACTION' = 'ERROR_ACTION'
export const SET_SYNC_STORAGE_VALUE: 'SET_SYNC_STORAGE_VALUE' = 'SET_SYNC_STORAGE_VALUE'
export const COLLAPSE_AUTO_EXPANDED_QUEUED: 'COLLAPSE_AUTO_EXPANDED_QUEUED' =
  'COLLAPSE_AUTO_EXPANDED_QUEUED'
export const REQUEST_LICENSING_DATA: 'REQUEST_LICENSING_DATA' = 'REQUEST_LICENSING_DATA'
export const RECEIVE_LICENSING_DATA: 'RECEIVE_LICENSING_DATA' = 'RECEIVE_LICENSING_DATA'
export const SET_BUILD_TYPES_LIMIT: 'SET_BUILD_TYPES_LIMIT' = 'SET_BUILD_TYPES_LIMIT'
export const SAVE_AVATAR: 'SAVE_AVATAR' = 'SAVE_AVATAR'
