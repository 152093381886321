import {stringifyId} from '../types'
import type {BranchType, BuildId, BuildTypeId} from '../types'
import escapeLocatorDimension from '../utils/escapeLocatorDimension'

export const getBranchLocatorPart = (
  branch?: BranchType | null | undefined,
  inPath?: boolean,
): string | null | undefined => {
  if (branch == null || branch.wildcard === true) {
    return 'default:any'
  }

  if (branch.default === true) {
    return 'default:true'
  }

  if (branch.groupFlag === true && branch.internalName != null) {
    return `group:(${branch.internalName})`
  }

  if (branch.name && branch.name.trim().length > 0) {
    return `name:(matchType:equals,value:${escapeLocatorDimension(branch.name, inPath)})`
  }

  return null
}
export const getBranchLocator = (branch?: BranchType | null | undefined): string => {
  const branchLocatorPart = getBranchLocatorPart(branch)

  if (branchLocatorPart == null) {
    return ''
  }

  if (
    branch == null ||
    branch.wildcard === true ||
    branch.default === true ||
    branch.groupFlag === true
  ) {
    return `branch:(${branchLocatorPart})`
  }

  return `branch:(policy:ALL_BRANCHES,${branchLocatorPart})`
}
export const getBuildTypeAndBranchLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  branch: BranchType | null | undefined,
): string =>
  [`buildType:(id:${stringifyId(buildTypeId)})`, getBranchLocator(branch)].filter(Boolean).join(',')
export const getBuildLocator = (id: BuildId): string => `id:${stringifyId(id)}`
export const getBuildStatsLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  branch: BranchType | null | undefined,
  count: number,
): string => {
  const buildTypeAndBranchLocator = getBuildTypeAndBranchLocator(buildTypeId, branch)
  return `item:(${buildTypeAndBranchLocator},history:false,state:(running:true,finished:true),count:${count})`
}
export const getBuildStatsAfterAndBeforeBuildLocator = (
  buildTypeId: BuildTypeId | null | undefined,
  buildId: BuildId,
  branch: BranchType | null | undefined,
  count: number,
): string => {
  const buildTypeAndBranchLocator = getBuildTypeAndBranchLocator(buildTypeId, branch)
  const stringifyBuildId = stringifyId(buildId)
  return [
    `item:(${buildTypeAndBranchLocator},startDate(build(id:${stringifyBuildId}),condition:before,includeInitial:false),defaultFilter:false,state:(running:true,finished:true),history:false,count:${count})`,
    `item:(${stringifyBuildId})`,
    `item:(${buildTypeAndBranchLocator},ordered:(from:(id:${stringifyBuildId}),includeInitial:false),defaultFilter:false,state:(running:true,finished:true),history:false,count:${count})`,
  ].join(',')
}
