import {createFetchAction} from '../reducers/fetchable'
import requestSingleBuildType, {
  requestHasBuildTypes,
  requestIsExternalStatusAllowed,
} from '../rest/buildTypes'
import {restRoot} from '../rest/consts'
import {
  normalizeBuildType,
  normalizeBuildTypeDescription,
  normalizeBuildTypeLinks,
  normalizeBuildTypeParameters,
  normalizeBuildTypePauseComment,
} from '../rest/schemata'
import type {BuildTypeId, BuildTypeWithDetailsType} from '../types'

import * as Actions from './actionTypes'
import type {AppThunk} from './types'

const processBuildType = (data: BuildTypeWithDetailsType) => {
  const buildType = normalizeBuildType(data)
  return {
    result: buildType.result,
    entities: {
      ...buildType.entities,
      ...normalizeBuildTypeLinks(data).entities,
      ...normalizeBuildTypeDescription(data).entities,
      ...normalizeBuildTypeParameters(data).entities,
      ...normalizeBuildTypePauseComment(data).entities,
    },
  }
}
export const fetchSingleBuildTypeData = createFetchAction(
  'fetchSingleBuildTypeData',
  (buildTypeId: BuildTypeId) =>
    requestSingleBuildType(restRoot, buildTypeId).then(processBuildType),
)
export const receiveBuildType = (data: BuildTypeWithDetailsType, id?: BuildTypeId) => {
  const buildType = processBuildType(data)
  return fetchSingleBuildTypeData.fulfilled(buildType, '', id ?? data.id)
}
export const fetchHasBuildTypes =
  (locator: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch({
      type: Actions.REQUEST_HAS_BUILD_TYPES,
      locator,
    })

    try {
      dispatch({
        type: Actions.RECEIVE_HAS_BUILD_TYPES,
        locator,
        data: await requestHasBuildTypes(getState().restRoot, locator),
      })
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: Actions.RECEIVE_HAS_BUILD_TYPES,
          locator,
          error,
        })
      }
    }
  }
export const fetchIsExternalStatusAllowed =
  (buildTypeId: BuildTypeId): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch({
      type: Actions.REQUEST_IS_EXTERNAL_STATUS_ALLOWED,
      buildTypeId,
    })

    try {
      dispatch({
        type: Actions.RECEIVE_IS_EXTERNAL_STATUS_ALLOWED,
        buildTypeId,
        data: await requestIsExternalStatusAllowed(getState().restRoot, buildTypeId),
      })
    } catch (error) {
      if (error instanceof Error) {
        dispatch({
          type: Actions.RECEIVE_IS_EXTERNAL_STATUS_ALLOWED,
          buildTypeId,
          error,
        })
      }
    }
  }
