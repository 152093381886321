import requestFederationServers from '../rest/federationServers'
import {normalizeFederationServer} from '../rest/schemata'
import type {FederationServerType} from '../types'

import * as Actions from './actionTypes'
import type {AppThunk} from './types'

export const receiveFederationServers = (servers: ReadonlyArray<FederationServerType>) => {
  const normalizedData = normalizeFederationServer(servers)
  return {
    type: Actions.RECEIVE_FEDERATION_SERVERS,
    data: normalizedData,
  }
}
export const fetchFederationServers =
  (force?: boolean): AppThunk<any> =>
  (dispatch, getState) => {
    const {federationServers, restRoot} = getState()

    if (force !== true && (federationServers.inited || federationServers.loading)) {
      return Promise.resolve()
    }

    dispatch({
      type: Actions.REQUEST_FEDERATION_SERVERS,
    })
    return requestFederationServers(restRoot).then(
      data => dispatch(receiveFederationServers(data)),
      (error: Error) =>
        dispatch({
          type: Actions.RECEIVE_FEDERATION_SERVERS,
          error,
        }),
    )
  }
