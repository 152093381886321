import classnames from 'classnames'
import {useMemo} from 'react'

import type {BuildId} from '../../../types'
import BuildStatusChangeComment from '../../BuildStatusChange/BuildStatusChangeComment.container'
import MoveToTop from '../../MoveToTop/MoveToTop.container'
import BuildCommentByIcon from '../BuildCommentByIcon/BuildCommentByIcon.container'
import BuildWarning from '../BuildWarning/BuildWarning.container'
import Link from '../Link/Link'
import MiddleEllipsis from '../MiddleEllipsis/MiddleEllipsis'
import RouterLinkWrapper from '../RouterLinkWrapper/RouterLinkWrapper'
import StarBuild from '../StarBuild/StarBuild.container'

import {formatBuildNumber} from './BuildNumber.utils'

import styles from './BuildNumber.css'

type Props = {
  readonly buildId: BuildId
  readonly className?: string
  readonly number?: string | null | undefined
  readonly queuePosition?: number | null | undefined
  readonly hideStar?: boolean
  readonly showEmptyStar?: boolean
  readonly isPersonal: boolean
  readonly isMine: boolean
  readonly isQueued: boolean
  readonly isCanceled: boolean
  readonly withComment: boolean
  readonly withStatusChangeComment: boolean
  readonly withWarnings: boolean
  readonly href?: string
}

function BuildNumber({
  buildId,
  className,
  number,
  hideStar,
  showEmptyStar,
  isPersonal,
  isMine,
  isQueued,
  isCanceled,
  queuePosition,
  withComment,
  withStatusChangeComment,
  withWarnings,
  href,
}: Props) {
  const classes = classnames(styles.buildNumber, className)
  const linkTextClasses = classnames({
    [styles.myPersonal]: isPersonal && isMine,
    [styles.personal]: isPersonal && !isMine,
    [styles.canceled]: isCanceled,
  })
  const {text, title} = formatBuildNumber({
    number,
    isQueued,
    queuePosition,
  })

  const numberSpan = useMemo(
    () => (
      <MiddleEllipsis
        title={title}
        className={classnames({
          [styles.numberWithComment]: Boolean(withComment),
        })}
      >
        {text}
      </MiddleEllipsis>
    ),
    [text, title, withComment],
  )
  return (
    <div className={classes}>
      {isQueued && <MoveToTop className={styles.moveToTop} buildId={buildId} />}
      {href != null ? (
        <RouterLinkWrapper>
          <Link
            data-test-build-number-link
            className={styles.link}
            innerClassName={linkTextClasses}
            href={href}
          >
            {numberSpan}
          </Link>
        </RouterLinkWrapper>
      ) : (
        <span className={linkTextClasses}>{numberSpan}</span>
      )}
      {hideStar !== true && (
        <StarBuild className={styles.starIcon} buildId={buildId} showEmpty={showEmptyStar} />
      )}
      {withComment && <BuildCommentByIcon buildId={buildId} className={styles.comment} />}
      {withStatusChangeComment && (
        <BuildStatusChangeComment buildId={buildId} className={styles.comment} />
      )}
      {withWarnings && <BuildWarning buildId={buildId} className={styles.warning} />}
    </div>
  )
}

BuildNumber.defaultProps = {
  withComment: false,
  withWarnings: false,
}
export default BuildNumber
