import type {BaseReceiveAction} from '../../../actions/fetchActions'
import type {Fetchable} from '../../../types'

export const REQUEST_AGENT_TOKEN: 'REQUEST_AGENT_TOKEN' = 'REQUEST_AGENT_TOKEN'
export const RECEIVE_AGENT_TOKEN: 'RECEIVE_AGENT_TOKEN' = 'RECEIVE_AGENT_TOKEN'
export const REQUEST_AGENT_CONFIG: 'REQUEST_AGENT_CONFIG' = 'REQUEST_AGENT_CONFIG'
export const RECEIVE_AGENT_CONFIG: 'RECEIVE_AGENT_CONFIG' = 'RECEIVE_AGENT_CONFIG'
export const CLEAR_AGENT_AUTH: 'CLEAR_AGENT_AUTH' = 'CLEAR_AGENT_AUTH'
export type AgentAuthAction =
  | {
      type: typeof REQUEST_AGENT_TOKEN
    }
  | (BaseReceiveAction<string> & {
      type: typeof RECEIVE_AGENT_TOKEN
    })
  | {
      type: typeof REQUEST_AGENT_CONFIG
    }
  | (BaseReceiveAction<string> & {
      type: typeof RECEIVE_AGENT_CONFIG
    })
  | {
      type: typeof CLEAR_AGENT_AUTH
    }
export type AgentAuthStateType = {
  readonly token: Fetchable<string | null>
  readonly config: Fetchable<string | null>
}
export type AgentAuthProps = {
  readonly onDialogOpen: () => unknown
}
