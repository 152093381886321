import {requestBuildTypeInternalId} from '../rest/buildTypes'

import {createFetchableSlice} from './fetchable'

export const buildTypeInternalIds = createFetchableSlice({
  name: 'buildTypeInternalIds',
  request: requestBuildTypeInternalId,
  getKey: id => id,
  defaultState: null,
})
