import {$Values} from 'utility-types'

import {BuildTypeId, ChangeId} from '../../../types'

export type ChangeTab = {
  readonly id: string
  readonly title: string
}

export const ChangePageTabNamesEnum = {
  PROBLEMS_TESTS: 'problemsTests',
  BUILDS: 'builds',
  DEPLOYMENTS: 'deployments',
  FILES: 'files',
}

export const ChangePageTabTitlesEnum = {
  [ChangePageTabNamesEnum.PROBLEMS_TESTS]: 'Problems & Tests',
  [ChangePageTabNamesEnum.BUILDS]: 'Builds',
  [ChangePageTabNamesEnum.DEPLOYMENTS]: 'Deployments',
  [ChangePageTabNamesEnum.FILES]: 'Files',
}

export type ChangePageTabNamesEnumType = $Values<typeof ChangePageTabNamesEnum>

export const OldChangePageTabNamesEnum = {
  PROBLEMS_TESTS: 'vcsModificationTests',
  BUILDS: 'vcsModificationBuilds',
  DEPLOYMENTS: 'vcsModificationDeployments',
  FILES: 'vcsModificationFiles',
}
export type OldChangePageTabNamesEnumType = $Values<typeof OldChangePageTabNamesEnum>

export type Props = {
  className?: string
  changeId: ChangeId
  buildTypeId: BuildTypeId | undefined | null
  currentTab: ChangePageTabNamesEnumType
  onSelectTab: (tab: ChangePageTabNamesEnumType) => void
  withHref?: boolean
}
