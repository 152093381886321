import {createSelector} from 'reselect'

import {ActionCreator} from '../actions/types'
import {openEditDialog} from '../components/EditProjectSidebar/EditProjectSidebar'
import type {OverviewDraft, State, TogglingOverview} from '../reducers/types'
import type {Entities} from '../rest/schemata'
import {getFavoriteBuildsHref, getFavoriteProjectsHref} from '../routes'
import {
  ALL_PROJECTS_TITLE_ID,
  FAVORITES_TITLE_ID,
  NodeGroupType,
  Permission,
  ROOT_PROJECT_ID,
  stringifyId,
  Template,
  toCustomSidebarItemId,
  toProjectId,
} from '../types'
import type {
  BuildTypeId,
  BuildTypeType,
  CustomSidebarItemId,
  ExpandState,
  FederationServerData,
  FederationServerId,
  NormalizedProjectType,
  ProjectId,
  TitleId,
} from '../types'
import {base_uri} from '../types/BS_types'
import {currentServerId} from '../types/projectTrees'
import type {
  ProjectsTreeItemType,
  SearchStringMatch,
  SidebarActiveItem,
} from '../types/projectTrees'
import {getEmptyHash} from '../utils/empty'
import {objectEntries} from '../utils/object'
import type {KeyValue, WritableKeyValue} from '../utils/object'

import {
  getBuildTypesHash,
  getHasFavoriteBuilds,
  getOverviewBuildTypesHash,
  getOverviewProjectsHash,
  getProjectsFetchable,
  getProjectsHash,
  getProjectsWithoutArchivedHash,
  getProjectTemplates,
  hasPermission,
  isArchivedProject,
  isArchivedSubprojectLoadedForAllProjects,
} from '.'

export type SidebarItemId = string
type MatchInfoType = {
  foundWords: ReadonlyArray<string>
  notFoundWords: ReadonlyArray<string>
  selfMatches: ReadonlyArray<SearchStringMatch>
  childMatched?: boolean
}
export type ProjectsTreeType = {
  data: Array<ProjectsTreeItemType>
  hash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType>
}
const emptyArray: never[] = []
export const generateSidebarItemId = (
  server: FederationServerId,
  type: 'project' | 'buildType' | 'template' | 'title' | 'server' | 'link' | 'action',
  id: ProjectId | BuildTypeId | TitleId | CustomSidebarItemId | FederationServerId,
): SidebarItemId => `${stringifyId(server)}_${type}_${stringifyId(id)}`
export const getOverviewExpandState = (state: State): KeyValue<ProjectId, ExpandState> =>
  state.entities.overviewExpandState
export const getAllProjectsExpandState = (state: State): KeyValue<ProjectId, ExpandState> =>
  state.entities.allProjectsExpandState

const getSearchProjectsExpandState: (
  arg0: State,
) => KeyValue<ProjectId, ExpandState> | null | undefined = state =>
  state.entities.searchProjectsExpandState

export const getHasFavoriteProjects: (arg0: State) => boolean | null | undefined = state =>
  state.hasFavoriteProjects
export const getOverviewData: (arg0: State) => ReadonlyArray<ProjectId> = state =>
  state.overview.data

export const getOverviewDraft: (arg0: State) => OverviewDraft = state => state.overviewDraft

export const getProjectsData: (
  state: State,
  rootProjectId?: ProjectId,
) => ReadonlyArray<ProjectId> = (state, rootProjectId) => {
  const {data} = getProjectsFetchable(state, rootProjectId)
  return data.length > 0 ? data : getProjectsFetchable(state, ROOT_PROJECT_ID).data
}
export const getCanShowAllProjects: (arg0: State) => boolean = createSelector(
  getProjectsData,
  (state: State) => state.entities.buildTypes,
  (state: State) => state.buildTypesLimit,
  (projects, buildTypes, limit) => projects.length > 0 && Object.keys(buildTypes).length <= limit,
)
export const getProjectsAndOverviewReady: (state: State, rootProjectId?: ProjectId) => boolean = (
  state,
  rootProjectId,
) =>
  state.overview.ready &&
  (getProjectsFetchable(state, rootProjectId).receiveMeta?.sidebarProjectsLoaded === true ||
    getProjectsFetchable(state, ROOT_PROJECT_ID).receiveMeta?.sidebarProjectsLoaded === true)
export const getFederationServers: (state: State) => ReadonlyArray<FederationServerId> = state =>
  state.federationServers.data
export const getFederationServersReady: (arg0: State) => boolean = state =>
  state.federationServers.ready
export const getFederationServersData: (
  arg0: State,
) => KeyValue<FederationServerId, FederationServerData> = state => state.federationServersData

const getFederationServersEntities: (
  state: State,
) => KeyValue<FederationServerId, Partial<Entities>> = state => state.federationServersEntities

const favoritesTitleItem: ProjectsTreeItemType = {
  itemType: 'title',
  id: FAVORITES_TITLE_ID,
  name: 'FAVORITES',
  level: 0,
  group: 'favorites',
  serverId: currentServerId,
  editable: true,
  focusable: false,
}

const getLinkAsTreeItem: (arg0: {
  href: string
  name: string
  level?: number
  serverId?: FederationServerId
  icon?: string | null | undefined
}) => ProjectsTreeItemType = ({href, name, level = 0, serverId = currentServerId, icon}) => ({
  itemType: 'link',
  id: toCustomSidebarItemId(href),
  href,
  icon,
  name,
  level,
  group: 'favorites',
  serverId,
  focusable: true,
})

const favoriteBuildsLinkItem: ProjectsTreeItemType = getLinkAsTreeItem({
  name: 'Builds',
  href: getFavoriteBuildsHref(),
  icon: 'star-filled-14px',
})
const favoriteBuildsTextLinkItem: ProjectsTreeItemType = getLinkAsTreeItem({
  name: 'Favorite Builds',
  href: getFavoriteBuildsHref(),
})
const favoriteProjectsLinkItem = getLinkAsTreeItem({
  name: 'Projects',
  href: getFavoriteProjectsHref(),
  icon: 'star-filled-14px',
})
const allProjectsLinkItem = getLinkAsTreeItem({
  name: 'All Projects',
  href: getFavoriteProjectsHref(),
})

export const actions: Record<string, ActionCreator> = {
  addToFavorites: () => openEditDialog(),
}

const addFavoritesActionItem: ProjectsTreeItemType = {
  itemType: 'action',
  id: toCustomSidebarItemId('add_to_favorites_action'),
  action: 'addToFavorites',
  icon: 'star-filled-14px',
  name: 'Add to favorites...',
  level: 0,
  group: 'none',
  serverId: currentServerId,
}
export const createAllProjectsTitleItem = (
  collapsed: boolean,
  isEdit: boolean = false,
): ProjectsTreeItemType => ({
  itemType: 'title',
  id: ALL_PROJECTS_TITLE_ID,
  name: isEdit ? 'EVERYTHING' : 'PROJECTS',
  level: 0,
  group: 'all',
  expanded: !collapsed,
  hasChildren: !isEdit,
  serverId: currentServerId,
  focusable: true,
})
const getAllProjectsTitleItem: (arg0: State) => ProjectsTreeItemType = createSelector(
  (state: State) => state.sidebar.allProjectsCollapsed,
  collapsed => createAllProjectsTitleItem(collapsed),
)

const getServerTitleItem = (
  id: FederationServerId,
  authorized: boolean = true,
  loading: boolean = false,
): ProjectsTreeItemType => ({
  id,
  loading,
  authorized,
  level: 0,
  hasChildren: true,
  itemType: 'server',
  name: stringifyId(id),
  expanded: true,
  serverId: id,
  group: 'search',
})

export const getValidParents = ({
  data,
  allProjects,
}: {
  readonly data: ReadonlyArray<ProjectId>
  readonly allProjects: KeyValue<ProjectId, NormalizedProjectType>
}): KeyValue<ProjectId, NormalizedProjectType> => {
  const parentProjects: WritableKeyValue<ProjectId, NormalizedProjectType> = {}
  const hashMap = data.reduce((acc: {[key: ProjectId]: boolean}, projectId) => {
    acc[projectId] = true
    return acc
  }, {})

  data.forEach((projectId: ProjectId) => {
    const currentProject = allProjects[projectId]

    if (currentProject != null) {
      let parentId = currentProject.parentProjectId

      while (parentId && parentId !== ROOT_PROJECT_ID && !hashMap[parentId]) {
        parentId = allProjects[parentId]?.parentProjectId
      }

      parentProjects[projectId] = allProjects[parentId ?? ROOT_PROJECT_ID]
    }
  })
  return parentProjects
}
export const getChildrenByProject = ({
  data,
  validParents,
  projects,
}: {
  readonly data: ReadonlyArray<ProjectId>
  readonly validParents: KeyValue<ProjectId, NormalizedProjectType>
  readonly projects: KeyValue<ProjectId, NormalizedProjectType>
}): KeyValue<ProjectId, ReadonlyArray<NormalizedProjectType>> => {
  const childrenByProject: WritableKeyValue<ProjectId, Array<NormalizedProjectType>> = {}
  data.forEach((projectId: ProjectId) => {
    const project = projects[projectId]

    if (project) {
      if (!childrenByProject[projectId]) {
        childrenByProject[projectId] = []
      }

      if (projectId === ROOT_PROJECT_ID) {
        return
      }

      const validParent = validParents[project.id]
      const parentProjectId = validParent ? validParent.id : ROOT_PROJECT_ID

      const siblings = childrenByProject[parentProjectId]
      if (siblings) {
        siblings.push(project)
      } else {
        childrenByProject[parentProjectId] = [project]
      }
    }
  })
  return childrenByProject
}

const processBuildTypes = ({
  project,
  level,
  buildTypes,
  templates = [],
  treeHash,
  treeData,
  group,
  serverId = currentServerId,
  includedBuildTypes,
  excludedBuildTypes,
}: {
  readonly project: NormalizedProjectType | null | undefined
  readonly level: number
  readonly buildTypes: KeyValue<BuildTypeId, BuildTypeType>
  readonly templates?: readonly Template[]
  readonly treeHash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType>
  readonly treeData: Array<ProjectsTreeItemType>
  readonly group: NodeGroupType
  readonly serverId?: FederationServerId
  readonly includedBuildTypes?: ReadonlyArray<BuildTypeId> | null | undefined
  readonly excludedBuildTypes?: ReadonlyArray<BuildTypeId> | null | undefined
}) => {
  if (project == null) {
    return
  }

  let types: ReadonlyArray<BuildTypeId> = project.buildTypes?.buildType ?? []

  if (includedBuildTypes != null) {
    types = types.filter(id => includedBuildTypes.includes(id))
  }

  if (excludedBuildTypes != null) {
    types = types.filter(id => !excludedBuildTypes.includes(id))
  }

  types.forEach((buildTypeId: BuildTypeId) => {
    const buildType = buildTypes[buildTypeId]

    if (buildType == null) {
      return
    }

    const item: ProjectsTreeItemType = {
      itemType: 'buildType',
      internalId: buildType.internalId,
      id: buildType.id,
      name: buildType.name || '',
      level,
      parentId: project.id,
      archived: project.archived,
      type: buildType.type,
      searchMatches: null,
      group,
      serverId,
      focusable: true,
    }

    if (group === 'favorites') {
      item.paused = buildType.paused
    }

    treeHash[generateSidebarItemId(serverId, 'buildType', item.id)] = item
    treeData.push(item)
  })

  templates.forEach(({id, name}) => {
    const item: ProjectsTreeItemType = {
      itemType: 'template',
      id,
      name,
      level,
      parentId: project.id,
      archived: project.archived,
      searchMatches: null,
      group,
      serverId,
      focusable: true,
    }
    treeHash[generateSidebarItemId(serverId, 'template', item.id)] = item
    treeData.push(item)
  })
}

const processProject = ({
  project,
  parentId,
  childrenByProject,
  level,
  treeHash,
  treeData,
  group,
  serverId = currentServerId,
  removeBuildTypes,
  includedProjects,
}: {
  readonly project: NormalizedProjectType
  readonly parentId?: ProjectId
  readonly childrenByProject: KeyValue<ProjectId, ReadonlyArray<NormalizedProjectType>>
  readonly level: number
  readonly treeHash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType>
  readonly treeData: Array<ProjectsTreeItemType>
  readonly group: NodeGroupType
  readonly serverId?: FederationServerId
  readonly removeBuildTypes: boolean
  readonly includedProjects?: ReadonlyArray<ProjectId> | null | undefined
}) => {
  const projectId = project.id
  const isIncluded = includedProjects == null || includedProjects.includes(projectId)
  const buildTypes = removeBuildTypes ? [] : project.buildTypes?.buildType ?? []
  const children = childrenByProject[projectId] ?? []
  const hasChildren = buildTypes.length > 0 || children.length > 0
  const item: ProjectsTreeItemType = {
    itemType: 'project',
    id: projectId,
    internalId: project.internalId,
    name: project.name || '',
    level,
    parentId,
    actualParentId: project.parentProjectId,
    hasChildren,
    archived: project.archived,
    childrenProjectsIds: children.map(child => child.id),
    childrenBuildTypesIds: buildTypes,
    searchMatches: null,
    expanded: true,
    group,
    serverId,
    focusable: true,
    isDisabled: !isIncluded,
  }
  treeHash[generateSidebarItemId(serverId, item.itemType, item.id)] = item
  treeData.push(item)
}

const processChildren = ({
  parentId,
  childrenByProject,
  level,
  treeHash,
  treeData,
  projects,
  buildTypes,
  validParents,
  group,
  serverId = currentServerId,
  removeBuildTypes,
  includedBuildTypes,
  excludedBuildTypes,
  includedProjects,
  includedParents,
  templates,
}: {
  readonly parentId: ProjectId
  readonly childrenByProject: KeyValue<ProjectId, ReadonlyArray<NormalizedProjectType>>
  readonly level: number
  treeHash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType>
  treeData: Array<ProjectsTreeItemType>
  readonly projects: KeyValue<ProjectId, NormalizedProjectType>
  readonly buildTypes: KeyValue<BuildTypeId, BuildTypeType>
  readonly validParents: KeyValue<ProjectId, NormalizedProjectType>
  readonly group: NodeGroupType
  readonly serverId?: FederationServerId
  readonly removeBuildTypes: boolean
  readonly includedBuildTypes?: ReadonlyArray<BuildTypeId> | null | undefined
  readonly excludedBuildTypes?: ReadonlyArray<BuildTypeId> | null | undefined
  readonly includedProjects?: ReadonlyArray<ProjectId> | null | undefined
  readonly includedParents: Set<ProjectId>
  readonly templates: KeyValue<any, readonly Template[]>
}) => {
  const parentChildren = childrenByProject[parentId] ?? []
  parentChildren.forEach(child => {
    const childId = child.id
    const isIncluded = includedProjects == null || includedProjects.includes(childId)

    if (!isIncluded && !includedParents.has(childId)) {
      return
    }

    processProject({
      project: child,
      parentId,
      childrenByProject,
      level,
      treeHash,
      treeData,
      group,
      serverId,
      removeBuildTypes,
      includedProjects,
    })

    if (!removeBuildTypes && isIncluded) {
      processBuildTypes({
        project: child,
        level: level + 1,
        treeHash,
        treeData,
        buildTypes,
        templates: templates[child.id],
        group,
        serverId,
        includedBuildTypes,
        excludedBuildTypes,
      })
    }

    processChildren({
      parentId: childId,
      childrenByProject,
      level: level + 1,
      treeHash,
      treeData,
      projects,
      buildTypes,
      validParents,
      group,
      serverId,
      removeBuildTypes,
      includedBuildTypes,
      excludedBuildTypes,
      includedProjects: isIncluded ? null : includedProjects,
      includedParents,
      templates,
    })
  })
}

export type TreeFilter = {
  readonly parentId?: ProjectId
  readonly removeBuildTypes?: boolean
  readonly removeEmptyProjects?: boolean
  readonly includedBuildTypes?: ReadonlyArray<BuildTypeId> | null | undefined
  readonly excludedBuildTypes?: ReadonlyArray<BuildTypeId> | null | undefined
  readonly includedProjects?: ReadonlyArray<ProjectId> | null | undefined
  readonly includeRoot?: boolean
  readonly includeTemplates?: boolean
}
export const convertDataToTree = ({
  ready,
  data,
  buildTypes,
  projects,
  templates = {},
  group,
  allProjects = projects,
  serverId,
  filter = {},
}: {
  readonly ready: boolean
  readonly data: ReadonlyArray<ProjectId>
  readonly buildTypes: KeyValue<BuildTypeId, BuildTypeType>
  readonly projects: KeyValue<ProjectId, NormalizedProjectType>
  readonly templates?: KeyValue<ProjectId, readonly Template[]>
  readonly group: NodeGroupType
  readonly allProjects?: KeyValue<ProjectId, NormalizedProjectType>
  readonly serverId?: FederationServerId
  readonly filter?: TreeFilter
}): ProjectsTreeType | null | undefined => {
  if (!ready) {
    return null
  }

  const treeHash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType> = {}
  const treeData: Array<ProjectsTreeItemType> = []
  const {
    parentId = ROOT_PROJECT_ID,
    removeBuildTypes = false,
    removeEmptyProjects = false,
    includedBuildTypes,
    excludedBuildTypes,
    includedProjects,
    includeRoot = false,
  } = filter
  const baseLevel = group === 'all' || includeRoot ? 1 : 0
  let filteredData = data

  if (removeEmptyProjects || includedBuildTypes != null) {
    const nonEmpty = new Set()

    const markAsNonEmptyRecursively = (id: ProjectId | null | undefined) => {
      if (id != null && !nonEmpty.has(id)) {
        nonEmpty.add(id)
        markAsNonEmptyRecursively(projects[id]?.parentProjectId)
      }
    }

    data.forEach(projectId => {
      const project = projects[projectId]
      const childBuildTypes = project?.buildTypes?.buildType ?? []
      const childTemplates = templates[projectId] ?? []

      if (
        (childBuildTypes.length > 0 || childTemplates.length > 0) &&
        (includedBuildTypes == null ||
          includedBuildTypes.some(id => childBuildTypes.includes(id))) &&
        (excludedBuildTypes == null || childBuildTypes.some(id => !excludedBuildTypes.includes(id)))
      ) {
        markAsNonEmptyRecursively(project?.id)
      }
    })
    filteredData = data.filter(id => nonEmpty.has(id))
  }

  const includedParents = new Set<string>()

  if (includedProjects != null) {
    const includeParentsRecursively = (id: ProjectId) => {
      const parentProjectId = projects[id]?.parentProjectId

      if (parentProjectId != null && !includedParents.has(parentProjectId)) {
        includedParents.add(parentProjectId)
        includeParentsRecursively(parentProjectId)
      }
    }

    includedProjects.forEach(includeParentsRecursively)
  }

  const validParents = getValidParents({
    data: filteredData,
    allProjects,
  })
  const childrenByProject = getChildrenByProject({
    data: filteredData,
    validParents,
    projects,
  })

  const parent = projects[parentId]
  if (includeRoot && parent != null) {
    processProject({
      project: parent,
      childrenByProject,
      level: 0,
      treeHash,
      treeData,
      group,
      serverId,
      removeBuildTypes,
      includedProjects,
    })
  }

  if (!removeBuildTypes && (includedProjects == null || includedProjects.includes(parentId))) {
    processBuildTypes({
      project: group === 'favorites' ? projects[parentId] : allProjects[parentId],
      level: baseLevel,
      treeHash,
      treeData,
      buildTypes,
      templates: templates[parentId],
      group,
      serverId,
      includedBuildTypes,
      excludedBuildTypes,
    })
  }

  processChildren({
    parentId,
    childrenByProject,
    level: baseLevel,
    treeHash,
    treeData,
    projects,
    buildTypes,
    validParents,
    group,
    serverId,
    removeBuildTypes,
    includedBuildTypes,
    excludedBuildTypes,
    includedProjects,
    includedParents,
    templates,
  })
  return {
    data: treeData,
    hash: treeHash,
  }
}
export const upgradeTreeByExpandState = (
  defaultTree: ProjectsTreeType | null | undefined,
  expandState: KeyValue<ProjectId, ExpandState> | null | undefined,
  baseLevel: number,
  shift?: number,
): ProjectsTreeType => {
  const treeHash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType> = {}
  const treeData: Array<ProjectsTreeItemType> = []

  if (defaultTree && defaultTree.data) {
    const localExpandState: WritableKeyValue<ProjectId, boolean> = {}
    defaultTree.data.forEach((item: ProjectsTreeItemType) => {
      const newItem = {...item}

      if (item.level != null && shift != null) {
        newItem.level += shift
      }

      if (item.itemType === 'project') {
        if (item.parentId && localExpandState[item.parentId] === false) {
          localExpandState[item.id] = false
          newItem.expanded = false
        } else {
          const expanded = !!expandState && expandState[item.id]?.expandState === 'EXPANDED'
          localExpandState[item.id] = expanded
          newItem.expanded = expanded
        }
      }

      if (newItem.level === baseLevel || (item.parentId && localExpandState[item.parentId])) {
        treeHash[generateSidebarItemId(item.serverId, item.itemType, item.id)] = newItem
        treeData.push(newItem)
      }
    })
  }

  return {
    data: treeData,
    hash: treeHash,
  }
}

const setChildMatchedRecursively = (
  targetId: ProjectId,
  serverId: FederationServerId,
  matchesHash: WritableKeyValue<SidebarItemId, MatchInfoType>,
  treeHash: KeyValue<SidebarItemId, ProjectsTreeItemType>,
  words: ReadonlyArray<string>,
) => {
  const itemKey = generateSidebarItemId(serverId, 'project', targetId)
  let targetMatchInfo = matchesHash[itemKey]

  if (targetMatchInfo == null) {
    targetMatchInfo = matchesHash[itemKey] = {
      selfMatches: [],
      foundWords: [],
      notFoundWords: words,
      childMatched: undefined,
    }
  }

  if (targetMatchInfo.childMatched !== true) {
    targetMatchInfo.childMatched = true
    const target = treeHash[itemKey]

    if (target && target.parentId) {
      setChildMatchedRecursively(target.parentId, serverId, matchesHash, treeHash, words)
    }
  }
}

const findMatches = (source: string, words: ReadonlyArray<string>): MatchInfoType => {
  const foundWords: Array<string> = []
  const notFoundWords: Array<string> = []
  const selfMatches: Array<SearchStringMatch> = []
  let startIndex = 0
  let matchedWordIndex: number | void
  words.forEach(word => {
    if (matchedWordIndex !== -1) {
      matchedWordIndex = source.indexOf(word, startIndex)
    }

    if (matchedWordIndex === -1) {
      notFoundWords.push(word)
    } else {
      foundWords.push(word)
      selfMatches.push({
        index: matchedWordIndex,
        length: word.length,
      })
      startIndex = matchedWordIndex + word.length
    }
  })
  return {
    foundWords,
    notFoundWords,
    selfMatches,
  }
}

export const createDefaultOverviewTree = (
  ready: boolean,
  data: readonly ProjectId[],
  buildTypes: KeyValue<BuildTypeId, BuildTypeType>,
  projects: KeyValue<ProjectId, NormalizedProjectType>,
  allProjects: KeyValue<ProjectId, NormalizedProjectType>,
  draft: OverviewDraft,
  filter?: TreeFilter,
) =>
  convertDataToTree({
    ready,
    data: draft.projects ?? data,
    buildTypes,
    projects: Object.fromEntries(
      objectEntries(projects)
        .filter(([_, value]) => value != null)
        .map(([key, project]) => {
          const projectId = toProjectId(key)
          const buildTypesDraft = draft.buildTypes[projectId]
          return [
            projectId,
            project != null && buildTypesDraft != null
              ? {
                  ...project,
                  buildTypes: {
                    buildType: buildTypesDraft,
                  },
                }
              : project,
          ]
        }),
    ),
    group: 'favorites',
    allProjects,
    filter,
  })

export const getDefaultOverviewTree: (state: State) => ProjectsTreeType | null | undefined =
  createSelector(
    (state: State) => getProjectsAndOverviewReady(state),
    getOverviewData,
    getOverviewBuildTypesHash,
    getOverviewProjectsHash,
    getProjectsHash,
    getOverviewDraft,
    () => undefined,
    createDefaultOverviewTree,
  )
const getOverviewTree: (arg0: State) => ProjectsTreeType = createSelector(
  getDefaultOverviewTree,
  getOverviewExpandState,
  (defaultTree, expandState) => upgradeTreeByExpandState(defaultTree, expandState, 0),
)
const getOverviewTreeData: (arg0: State) => ReadonlyArray<ProjectsTreeItemType> = createSelector(
  getOverviewTree,
  tree => tree.data,
)
export const getOverviewTreeHash: (state: State) => KeyValue<SidebarItemId, ProjectsTreeItemType> =
  createSelector(getOverviewTree, tree => tree.hash)

export const getAllProjectsLoaded = (state: State) =>
  getProjectsFetchable(state, ROOT_PROJECT_ID).receiveMeta?.sidebarProjectsLoaded || false
const prepareAllProjectAndBuildTypesToConvert: (arg0: State) => {
  ready: boolean
  data: any
  buildTypes: any
  projects: any
} = createSelector(
  getAllProjectsLoaded,
  (state: State) => getProjectsData(state),
  getBuildTypesHash,
  getProjectsWithoutArchivedHash,
  (ready, data, buildTypes, projects) => ({ready, data, buildTypes, projects}),
)
export const getDefaultAllProjectsTree: (arg0: State) => ProjectsTreeType | null | undefined =
  createSelector(prepareAllProjectAndBuildTypesToConvert, ({ready, data, buildTypes, projects}) =>
    convertDataToTree({
      ready,
      data,
      buildTypes,
      projects,
      group: 'all',
    }),
  )

export const getProjectSubTree: (
  arg0: State,
  parentId: ProjectId,
) => ProjectsTreeType | null | undefined = createSelector(
  (state: State, parentId: ProjectId) => parentId,
  (state: State) => prepareAllProjectAndBuildTypesToConvert(state),
  (parentId, {ready, data, buildTypes, projects}) =>
    convertDataToTree({
      ready,
      data,
      buildTypes,
      projects,
      group: 'all',
      filter: {parentId},
    }),
)

const getAllProjectsTree: (state: State) => ProjectsTreeType = createSelector(
  getDefaultAllProjectsTree,
  getAllProjectsExpandState,
  (defaultTree, expandState) => upgradeTreeByExpandState(defaultTree, expandState, 1),
)
const getAllProjectsTreeData: (
  arg0: State,
) => ReadonlyArray<ProjectsTreeItemType> | null | undefined = createSelector(
  getAllProjectsTree,
  tree => tree?.data,
)
export const getAllProjectsTreeHash: (
  state: State,
) => KeyValue<SidebarItemId, ProjectsTreeItemType> = createSelector(
  getAllProjectsTree,
  tree => tree.hash,
)
const getMergedProjectsData: (arg0: State, arg1?: ProjectId) => ReadonlyArray<ProjectId> =
  createSelector(getProjectsData, getOverviewData, (data, overviewData) => [
    ...new Set([...overviewData, ...data]),
  ])
const makeGetDefaultSearchProjectsWithArchivedTree = (filter: TreeFilter = Object.freeze({})) =>
  createSelector(
    (state: State) => getProjectsAndOverviewReady(state, filter.parentId),
    (state: State) => getMergedProjectsData(state, filter.parentId),
    getBuildTypesHash,
    getProjectsHash,
    (state: State): KeyValue<ProjectId, readonly Template[]> =>
      filter.includeTemplates ? getProjectTemplates(state) : getEmptyHash(),
    getFederationServers,
    getFederationServersData,
    getFederationServersEntities,
    (
      ready,
      data,
      buildTypes,
      projects,
      templates,
      federationServers,
      federationServersData,
      federationServersEntities,
    ) => {
      const result: WritableKeyValue<FederationServerId, ProjectsTreeType | null | undefined> = {}
      const currentServerTree = convertDataToTree({
        ready,
        data,
        buildTypes,
        projects,
        templates,
        group: 'search',
        filter,
      })

      if (currentServerTree) {
        result[currentServerId] = currentServerTree
      }

      federationServers.forEach(serverId => {
        const serverEntities = federationServersEntities[serverId]
        const serverData = federationServersData[serverId]

        if (serverData && serverEntities && serverEntities.buildTypes && serverEntities.projects) {
          const serverTree = convertDataToTree({
            ready,
            data: serverData.projects.data,
            buildTypes: serverEntities.buildTypes,
            projects: serverEntities.projects,
            group: 'search',
            serverId,
          })

          if (serverTree) {
            result[serverId] = serverTree
          }
        }
      })
      return result
    },
  )
export const makeGetSearchProjectsWithoutArchivedTree = (filter?: TreeFilter) =>
  createSelector(
    makeGetDefaultSearchProjectsWithArchivedTree(filter),
    getFederationServers,
    (treeByServer, federationServers) => {
      const servers = [currentServerId, ...federationServers]
      const result: WritableKeyValue<FederationServerId, ProjectsTreeType> = {}
      servers.forEach((serverId: FederationServerId) => {
        const tree = treeByServer[serverId]

        if (tree && tree.data) {
          const serverResult: ProjectsTreeType = (result[serverId] = {
            hash: {},
            data: [],
          })
          tree.data.forEach(item => {
            const {archived, itemType, id} = item

            if (archived !== true) {
              serverResult.hash[generateSidebarItemId(serverId, itemType, id)] = item
              serverResult.data.push(item)
            }
          })
        }
      })
      return result
    },
  )
export const makeGetDefaultSearchProjectsTree = (filter?: TreeFilter) =>
  createSelector(
    (state: State) => state.sidebar.showArchivedProjects,
    isArchivedSubprojectLoadedForAllProjects,
    makeGetDefaultSearchProjectsWithArchivedTree(filter),
    makeGetSearchProjectsWithoutArchivedTree(filter),
    (state: State) => isArchivedProject(state, filter?.parentId),
    (
      showArchivedProjects,
      archivedReady,
      defaultTreeWithArchived,
      defaultTreeWithoutArchived,
      isParentArchived,
    ) =>
      (showArchivedProjects || isParentArchived) && archivedReady
        ? defaultTreeWithArchived
        : defaultTreeWithoutArchived,
  )
const getDefaultSearchProjectsTree = makeGetDefaultSearchProjectsTree()
export const makeHasArchivedDescendants = (filter: TreeFilter | undefined) => {
  const selector = makeGetDefaultSearchProjectsWithArchivedTree(filter)
  return (state: State) =>
    selector(state)[currentServerId]?.data.some(item => item.archived) ?? false
}
export function getIsStarred({
  item,
  togglingOverview,
  overviewData,
  overviewProjectsHash,
}: {
  readonly item: ProjectsTreeItemType
  readonly togglingOverview: TogglingOverview
  readonly overviewData: ReadonlyArray<ProjectId>
  readonly overviewProjectsHash: KeyValue<ProjectId, NormalizedProjectType>
}): boolean | null | undefined {
  if (item.isStarred != null) {
    return item.isStarred
  }

  if (item.serverId !== currentServerId) {
    return null
  }

  if (item.itemType === 'project') {
    const toggling = togglingOverview.project[item.id]
    return toggling ?? overviewData.includes(item.id)
  } else if (item.itemType === 'buildType') {
    const toggling = togglingOverview.bt[item.id]

    if (toggling != null) {
      return toggling
    } else if (overviewData.includes(item.parentId)) {
      const buildTypes = overviewProjectsHash[item.parentId]?.buildTypes?.buildType
      return buildTypes ? buildTypes.includes(item.id) : false
    } else {
      return false
    }
  }

  return null
}
type CreateSearchProjectsTreeParams = {
  readonly searchQuery: string
  readonly defaultTreeByServer: KeyValue<FederationServerId, ProjectsTreeType | null | undefined>
  readonly expandState: KeyValue<ProjectId, ExpandState> | null | undefined
  readonly togglingOverview: TogglingOverview
  readonly overviewData: ReadonlyArray<ProjectId>
  readonly overviewProjectsHash: KeyValue<ProjectId, NormalizedProjectType>
  readonly federationServers?: ReadonlyArray<FederationServerId>
  readonly parentId?: ProjectId
  readonly includeRoot?: boolean
}
export function createSearchProjectsTree({
  searchQuery,
  defaultTreeByServer,
  expandState,
  togglingOverview,
  overviewData,
  overviewProjectsHash,
  federationServers = emptyArray,
  parentId = ROOT_PROJECT_ID,
  includeRoot = false,
}: CreateSearchProjectsTreeParams): KeyValue<FederationServerId, ProjectsTreeType> {
  const servers = [currentServerId, ...federationServers]
  const result: WritableKeyValue<FederationServerId, ProjectsTreeType> = {}
  servers.forEach((serverId: FederationServerId) => {
    const defaultTree = defaultTreeByServer[serverId]
    result[serverId] = {
      hash: {},
      data: [],
    }

    if (defaultTree && defaultTree.data && defaultTree.hash) {
      const words: Array<string> = []
      searchQuery.split(' ').forEach(word => {
        if (word) {
          words.push(word.toLowerCase())
        }
      })
      const matchesHash: WritableKeyValue<string, MatchInfoType> = {}

      if (words.length > 0) {
        let matchInfo: MatchInfoType
        let parentMatchInfo: MatchInfoType | null | undefined
        let notFoundWords: ReadonlyArray<string>

        const setItemMatchInfo = (
          sidebarItemId: SidebarItemId,
          name: string | null | undefined,
          parentProjectId: ProjectId | null | undefined,
        ) => {
          parentMatchInfo = parentProjectId
            ? matchesHash[generateSidebarItemId(serverId, 'project', parentProjectId)]
            : null
          notFoundWords = parentMatchInfo ? parentMatchInfo.notFoundWords : words
          matchInfo =
            name != null && notFoundWords.length
              ? findMatches(name.toLowerCase(), notFoundWords)
              : {
                  selfMatches: [],
                  foundWords: [],
                  notFoundWords,
                }

          if (parentProjectId && notFoundWords.length > 0 && matchInfo.notFoundWords.length === 0) {
            setChildMatchedRecursively(
              parentProjectId,
              serverId,
              matchesHash,
              defaultTree.hash,
              words,
            )
          }

          matchesHash[sidebarItemId] = matchInfo
        }

        defaultTree.data.forEach((item: ProjectsTreeItemType) => {
          setItemMatchInfo(
            generateSidebarItemId(item.serverId, item.itemType, item.id),
            item.name,
            item.parentId,
          )
        })
      }

      const localExpandState: WritableKeyValue<ProjectId, boolean> = {}
      defaultTree.data.forEach((item: ProjectsTreeItemType) => {
        const itemId = item.id
        const itemParentId = item.parentId
        const itemType = item.itemType
        const serverResult = result[serverId]

        if (serverResult == null) {
          return
        }

        if (itemType === 'server') {
          const itemKey = generateSidebarItemId(item.serverId, itemType, itemId)
          serverResult.hash[itemKey] = item
          serverResult.data.push(item)
          return
        }

        const possibleRootId = includeRoot ? itemId : itemParentId

        if (possibleRootId != null) {
          if (
            possibleRootId !== parentId &&
            (itemParentId == null || localExpandState[itemParentId] !== true)
          ) {
            return
          }

          const itemKey = generateSidebarItemId(item.serverId, itemType, itemId)
          const itemParentMatchInfo =
            itemParentId != null
              ? matchesHash[generateSidebarItemId(item.serverId, 'project', itemParentId)]
              : null
          const itemMatchInfo = matchesHash[itemKey]

          if (words.length !== 0) {
            if (
              itemType === 'project' &&
              !itemMatchInfo?.childMatched &&
              (itemMatchInfo?.notFoundWords ?? words).length > 0
            ) {
              return
            }

            if (
              (itemParentMatchInfo != null &&
                itemParentMatchInfo.childMatched !== true &&
                (itemParentMatchInfo.notFoundWords ?? words).length > 0) ||
              (itemType === 'buildType' &&
                (possibleRootId === parentId || itemParentMatchInfo?.childMatched === true) &&
                (itemMatchInfo?.notFoundWords ?? words).length > 0)
            ) {
              return
            }
          }

          const clonedItem: ProjectsTreeItemType = {...item}

          if (clonedItem.itemType === 'project') {
            const itemExpandState = expandState?.[clonedItem.id]
            const expanded = itemExpandState
              ? itemExpandState.expandState === 'EXPANDED'
              : words.length !== 0
            clonedItem.expanded = expanded
            localExpandState[clonedItem.id] = expanded
          }

          clonedItem.isStarred = getIsStarred({
            item: clonedItem,
            togglingOverview,
            overviewData,
            overviewProjectsHash,
          })
          clonedItem.searchMatches = itemMatchInfo?.selfMatches || null
          clonedItem.isFullMatch =
            words.length === 0 ||
            (itemMatchInfo != null && itemMatchInfo.notFoundWords.length === 0)
          serverResult.hash[itemKey] = clonedItem
          serverResult.data.push(clonedItem)
        }
      })
    }
  })
  return result
}
const getSearchProjectsTree: (arg0: State) => KeyValue<FederationServerId, ProjectsTreeType> =
  createSelector(
    (state: State) => state.sidebar.searchQuery,
    getDefaultSearchProjectsTree,
    getSearchProjectsExpandState,
    (state: State) => state.togglingOverview,
    (state: State) => state.overview.data,
    getOverviewProjectsHash,
    getFederationServers,
    (
      searchQuery,
      defaultTreeByServer,
      expandState,
      togglingOverview,
      overviewData,
      overviewProjectsHash,
      federationServers,
    ) =>
      createSearchProjectsTree({
        searchQuery,
        defaultTreeByServer,
        expandState,
        togglingOverview,
        overviewData,
        overviewProjectsHash,
        federationServers,
      }),
  )
export const mergeSearchProjectsTree = (
  treeByServer: KeyValue<FederationServerId, ProjectsTreeType>,
  federationServers: ReadonlyArray<FederationServerId>,
  federationServersData: KeyValue<FederationServerId, FederationServerData>,
): ProjectsTreeType => {
  let treeHash: WritableKeyValue<SidebarItemId, ProjectsTreeItemType> = {}
  let treeData: Array<ProjectsTreeItemType> = []
  const servers = [currentServerId, ...federationServers]
  servers.forEach((serverId: FederationServerId) => {
    const tree = treeByServer[serverId]
    const serverData = federationServersData[serverId]

    if (
      tree &&
      tree.data &&
      (tree.data.length || (serverId !== base_uri && !serverData?.authorized))
    ) {
      if (federationServers && federationServers.length) {
        if (serverId === base_uri) {
          treeData.push(getServerTitleItem(currentServerId))
        } else {
          const serverTitle = getServerTitleItem(
            serverId,
            Boolean(serverData?.authorized),
            Boolean(serverData?.projects.loading) && !serverData?.projects.inited,
          )
          treeData.push(serverTitle)
        }
      }

      if (serverId === base_uri || Boolean(serverData?.authorized)) {
        treeHash = {...treeHash, ...tree.hash}
        treeData = [...treeData, ...tree.data]
      }
    }
  })
  return {
    hash: treeHash,
    data: treeData,
  }
}
const getSearchProjectsTreeMerged: (state: State) => ProjectsTreeType = createSelector(
  getSearchProjectsTree,
  getFederationServers,
  getFederationServersData,
  mergeSearchProjectsTree,
)
const getSearchProjectsTreeData: (arg0: State) => ReadonlyArray<ProjectsTreeItemType> =
  createSelector(getSearchProjectsTreeMerged, tree => tree?.data || emptyArray)
export const getSearchProjectsTreeHash: (
  state: State,
) => KeyValue<SidebarItemId, ProjectsTreeItemType> = createSelector(
  getSearchProjectsTreeMerged,
  tree => tree.hash,
)
export function mergeProjectsForSidebar(
  favoriteProjects: ReadonlyArray<ProjectsTreeItemType>,
  hasFavoriteProjects: boolean | null | undefined,
  allProjects: ReadonlyArray<ProjectsTreeItemType> | null | undefined,
  allProjectsTitleItem: ProjectsTreeItemType,
  hasFavoriteBuilds: boolean,
  userCanEdit: boolean,
  canShowAllProjects: boolean,
  isEdit: boolean,
  isAdmin?: boolean,
): ReadonlyArray<ProjectsTreeItemType> {
  let items: Array<ProjectsTreeItemType> = []

  if (hasFavoriteProjects === true) {
    items.push(favoritesTitleItem)

    if (!isEdit && !isAdmin) {
      items.push(favoriteProjectsLinkItem)
    }

    if (hasFavoriteBuilds && !isAdmin) {
      items.push(favoriteBuildsLinkItem)
    }

    items = items.concat(favoriteProjects)
  } else if (hasFavoriteProjects === false) {
    if (!isEdit && userCanEdit && allProjects != null && allProjects.length > 0) {
      items.push(addFavoritesActionItem)
    }

    if (!isEdit && canShowAllProjects) {
      items.push(allProjectsLinkItem)
    }

    if (hasFavoriteBuilds) {
      items.push(favoriteBuildsTextLinkItem)
    }
  }

  if (allProjects && allProjects.length) {
    items.push(allProjectsTitleItem)

    if (allProjectsTitleItem.expanded === true) {
      items = items.concat(allProjects)
    }
  }

  return items.length ? items : emptyArray
}
const getProjectsForSidebar: (
  state: State,
  isAdmin?: boolean,
) => ReadonlyArray<ProjectsTreeItemType> = createSelector(
  getOverviewTreeData,
  getHasFavoriteProjects,
  getAllProjectsTreeData,
  getAllProjectsTitleItem,
  getHasFavoriteBuilds,
  (state: State) => hasPermission(state, Permission.CHANGE_OWN_PROFILE),
  getCanShowAllProjects,
  () => false,
  (_: State, isAdmin?: boolean) => isAdmin,
  mergeProjectsForSidebar,
)
export const getProjectsForSidebarOrSearch = (
  state: State,
  isAdmin?: boolean,
): ReadonlyArray<ProjectsTreeItemType> =>
  state.sidebar.searchActive && state.sidebar.searchQuery
    ? getSearchProjectsTreeData(state)
    : getProjectsForSidebar(state, isAdmin)
export const matchSidebarItem =
  (activeItem: SidebarActiveItem | null | undefined) =>
  (node: ProjectsTreeItemType): boolean => {
    if (activeItem == null) {
      return false
    }

    if (activeItem.nodeType === 'bt' || activeItem.nodeType === 'project') {
      if (node.group !== activeItem.group && node.group !== 'all' && node.group !== 'search') {
        return false
      }
    }

    switch (node.itemType) {
      case 'project':
        return activeItem.nodeType === 'project' && activeItem.id === node.id

      case 'buildType':
        return activeItem.nodeType === 'bt' && activeItem.id === node.id

      case 'template':
        return activeItem.nodeType === 'template' && activeItem.id === node.id

      case 'link':
        return activeItem.nodeType === 'link' && activeItem.id === node.id

      case 'action':
        return activeItem.nodeType === 'all' && node.id === toCustomSidebarItemId('all item')

      default:
        return false
    }
  }
export const getOverviewExpandStateReady: (arg0: State) => boolean = state =>
  state.overviewExpandState.ready
