import * as Redux from 'redux'

import type {Action} from '../../actions/types'
import fetchable from '../../reducers/fetchable'
import {keyValueReducer} from '../../reducers/utils'
import {emptyArray, emptyArrayFetchable} from '../../utils/empty'
import stableSort from '../../utils/stableSort'

import {
  BuildLogMessagesResponse,
  BuildLogTimeline,
  RECEIVE_BUILDLOG_MESSAGES,
  RECEIVE_BUILDLOG_TIMELINE,
  REQUEST_BUILDLOG_MESSAGES,
  REQUEST_BUILDLOG_TIMELINE,
  SET_FULL_LOG_STATE,
  SET_SOFT_WRAP_LINES_IN_BUILDLOG,
  SET_USE_DARK_THEME_BUILDLOG,
} from './BuildLog.types'
import type {
  BuildLogMessage,
  BuildLogState,
  BuildLogTestTarget,
  LogSettingsState,
} from './BuildLog.types'
import {processMessage} from './BuildLog.utils'
import {searchStates} from './FullBuildLog/BuildLogHeader/BuildLogSearch/BuildLogSearch.reducers'
import {fullLogStates} from './FullBuildLog/FullBuildLog.reducers'

const buildLogMessagesReducer = fetchable<readonly BuildLogMessage[], BuildLogMessagesResponse>(
  REQUEST_BUILDLOG_MESSAGES,
  RECEIVE_BUILDLOG_MESSAGES,
  emptyArray,
  (action, state) => {
    const newMessages: ReadonlyArray<BuildLogMessage> | null | undefined = action.data?.messages

    if (action.mergeData !== true) {
      return newMessages != null
        ? newMessages.filter((message: BuildLogMessage, index: number) => {
            processMessage(message)

            if (index === newMessages.length - 1 && action.data?.lastMessageIncluded === true) {
              message.isLast = true
            }

            return message.isBroken !== true
          })
        : emptyArray
    }

    // TODO: need to extract this function to util and write unit tests
    if (newMessages == null || newMessages.length === 0) {
      return state
    }

    // TODO: possibly can be optimized to use one loop for filter existing and sorting
    const firstInsertIndex: number = newMessages[0].id
    const lastInsertIndex: number = newMessages[newMessages.length - 1].id
    const firstExistIndex: number | null | undefined = state[0]?.id
    const lastExistIndex: number | null | undefined = state[state.length - 1]?.id
    let holeExists =
      firstExistIndex != null &&
      lastExistIndex != null &&
      firstInsertIndex > firstExistIndex &&
      lastInsertIndex < lastExistIndex
    const exist = new Set()
    const result = newMessages.concat(state).filter((message: BuildLogMessage, index: number) => {
      processMessage(message)

      if (message.isBroken === true) {
        return false
      }

      if (index === newMessages.length - 1 && action.data?.lastMessageIncluded === true) {
        message.isLast = true
      }

      if (exist.has(message.id)) {
        if (holeExists && message.id === lastInsertIndex) {
          holeExists = false
        }

        return false
      }

      exist.add(message.id)
      return true
    })

    if (holeExists) {
      newMessages[newMessages.length - 1].nextIsHole = true
    }

    return stableSort(result, (a: BuildLogMessage, b: BuildLogMessage) => a.id - b.id)
  },
)
const buildLogTimelineReducer = fetchable<BuildLogTimeline | null, BuildLogTimeline>(
  REQUEST_BUILDLOG_TIMELINE,
  RECEIVE_BUILDLOG_TIMELINE,
  null,
  action => action.data,
)

const buildLogTestAnchorsReducer = (
  state: BuildLogTestTarget | null = null,
  action: Action,
): BuildLogTestTarget | null => {
  switch (action.type) {
    case RECEIVE_BUILDLOG_MESSAGES: {
      if (action.data != null && action.data.testTarget != null) {
        return {...state, ...action.data.testTarget}
      }

      return state
    }

    default:
      return state
  }
}

const buildLogLastMessageIncludedReducer = (
  state: boolean | null = null,
  action: Action,
): boolean | null => {
  switch (action.type) {
    case RECEIVE_BUILDLOG_MESSAGES: {
      if (action.data != null && action.data.lastMessageIncluded != null) {
        return action.data.lastMessageIncluded
      }

      return state
    }

    default:
      return state
  }
}

export const buildLogReducers = Redux.combineReducers<BuildLogState>({
  messages: keyValueReducer(
    action => ('buildLogKey' in action ? action.buildLogKey : action.target),
    (state = emptyArrayFetchable, action: Action) => {
      switch (action.type) {
        case REQUEST_BUILDLOG_MESSAGES:
        case RECEIVE_BUILDLOG_MESSAGES:
          return buildLogMessagesReducer(state, action)

        case SET_FULL_LOG_STATE:
          // eslint-disable-next-line eqeqeq
          if (action.buildId === null) {
            // reset messages on close full build log
            return emptyArrayFetchable
          }

          return state

        default:
          return state
      }
    },
    [REQUEST_BUILDLOG_MESSAGES, RECEIVE_BUILDLOG_MESSAGES, SET_FULL_LOG_STATE],
  ),
  messagesLoadStates: keyValueReducer(
    action => action.buildLogKey,
    keyValueReducer(
      action =>
        action.options?.target ??
        (action.options?.logAnchor != null ? action.options.logAnchor.toString() : null),
      (
        state = {
          loading: false,
          lastLoadedTime: null,
        },
        action: Action,
      ) => {
        switch (action.type) {
          case REQUEST_BUILDLOG_MESSAGES:
            return {
              loading: true,
              lastLoadedTime: action.invalidate === true ? null : state.lastLoadedTime,
            }

          case RECEIVE_BUILDLOG_MESSAGES:
            return {
              loading: false,
              lastLoadedTime: Date.now(),
            }

          default:
            return state
        }
      },
      [REQUEST_BUILDLOG_MESSAGES, RECEIVE_BUILDLOG_MESSAGES],
    ),
    [REQUEST_BUILDLOG_MESSAGES, RECEIVE_BUILDLOG_MESSAGES],
  ),
  timelines: keyValueReducer(action => action.buildId, buildLogTimelineReducer, [
    REQUEST_BUILDLOG_TIMELINE,
    RECEIVE_BUILDLOG_TIMELINE,
  ]),
  testAnchors: keyValueReducer(action => action.buildLogKey, buildLogTestAnchorsReducer, [
    REQUEST_BUILDLOG_MESSAGES,
    RECEIVE_BUILDLOG_MESSAGES,
  ]),
  lastMessageIncluded: keyValueReducer(
    action => action.buildLogKey,
    buildLogLastMessageIncludedReducer,
    [REQUEST_BUILDLOG_MESSAGES, RECEIVE_BUILDLOG_MESSAGES],
  ),
  fullLogStates,
  settings: (
    state: LogSettingsState = {
      softWrapLines: false,
      darkTheme: false,
    },
    action: Action,
  ) => {
    switch (action.type) {
      case SET_SOFT_WRAP_LINES_IN_BUILDLOG:
        return {...state, softWrapLines: action.value}

      case SET_USE_DARK_THEME_BUILDLOG:
        return {...state, darkTheme: action.value}

      default:
        return state
    }
  },
  searchStates,
})
