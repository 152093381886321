import {Redirect} from '@reach/router'
import {match} from '@reach/router/lib/utils'

import Routes, {getAgentsOverviewHref, getBaseRoute} from '../../routes'
import {getLastAgentsPage} from '../../utils/lastPage'
import {objectValues} from '../../utils/object'

export default function AgentsScreen() {
  const lastAgentsPage = getLastAgentsPage()

  if (
    lastAgentsPage == null ||
    objectValues(Routes).find(route => route !== Routes.BASE && match(route, lastAgentsPage)) ==
      null
  ) {
    return <Redirect noThrow to={getAgentsOverviewHref()} />
  }

  return <Redirect noThrow to={getBaseRoute(lastAgentsPage)} />
}
