import * as Redux from 'redux'

import type {Action} from '../../../actions/types'

import {CHANGE_PROJECT_TAB} from './ProjectPage.types'
import type {ProjectPageStateType} from './ProjectPage.types'

const projectPageReducer = Redux.combineReducers<ProjectPageStateType>({
  tab(state = null, action: Action) {
    switch (action.type) {
      case CHANGE_PROJECT_TAB:
        return action.next

      default:
        return state
    }
  },
})
export default projectPageReducer
