import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {fetchSingleBuildData, subscribeOnBuild} from '../actions/builds'
import {AppDispatch} from '../actions/types'
import {getBuild, getBuildType} from '../selectors'
import type {BuildId} from '../types'
import {subscribeOnBuildTypeEvents} from '../utils/subscriber'
import {BUILD_FINISHED, BUILD_INTERRUPTED} from '../utils/subscriptionEvents'

type Props = {
  buildId: BuildId
}

function noop() {}

const countSubscriptions: Record<BuildId, number> = {}
const unsubscribeFunctions: Record<BuildId, () => unknown> = {}
export function useRunningBuildUpdater(buildId: BuildId, disable: boolean = false) {
  const dispatch = useDispatch<AppDispatch>()
  const buildTypeInternalId = useSelector(state => {
    const {buildType} = getBuild(state, buildId) || {}
    return getBuildType(state, buildType)?.internalId
  })
  const subscribeHandler = React.useCallback(() => {
    let unsubscribeFromBuildType = noop
    const unsubscribeFromBuild = dispatch(subscribeOnBuild(buildId))

    if (buildTypeInternalId != null) {
      unsubscribeFromBuildType = subscribeOnBuildTypeEvents(
        buildTypeInternalId,
        [BUILD_FINISHED, BUILD_INTERRUPTED],
        () => dispatch(fetchSingleBuildData(buildId)),
      )
    }

    return () => {
      unsubscribeFromBuildType()
      unsubscribeFromBuild()
    }
  }, [buildId, buildTypeInternalId, dispatch])
  React.useEffect(() => {
    if (!disable) {
      const prevCount = countSubscriptions[buildId] ?? 0

      if (prevCount === 0) {
        unsubscribeFunctions[buildId] = subscribeHandler()
      }

      countSubscriptions[buildId] = prevCount + 1
      return () => {
        countSubscriptions[buildId] -= 1

        if (countSubscriptions[buildId] === 0) {
          unsubscribeFunctions[buildId]()
          delete countSubscriptions[buildId]
          delete unsubscribeFunctions[buildId]
        }
      }
    }

    return undefined
  }, [buildId, disable, subscribeHandler])
}
export default function RunningBuildUpdater({buildId}: Props) {
  useRunningBuildUpdater(buildId)
  return null
}
