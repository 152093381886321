import {stringifyId} from '../types'
import type {
  BuildTypeId,
  BuildTypeInternalId,
  BuildTypeWithDetailsType,
  RequestOptionsParams,
} from '../types'

import processResponse from './processResponse'
import request from './request'

export const BuildTypeProperties = {
  RUN_BUTTON_CAPTION: 'teamcity.ui.runButton.caption',
  DEFAULT_BUILDTYPE_TAB: 'teamcity.ui.buildType.defaultTab',
  DEFAULT_PROJECT_TAB: 'teamcity.ui.project.defaultTab',
  PROMOTE_ONLY: 'teamcity.buildType.environmentBuildType.promoteOnly',
}
const descriptionField = 'description'
const pauseCommentField = 'pauseComment(text,timestamp,user)'
export const linksField = 'links(link(type,relativeUrl))'
export const parametersField =
  'parameters($locator(name(matchType:starts-with,value:teamcity)),property(name,value))'
const snapshotDependenciesField = 'snapshot-dependencies(snapshot-dependency(id))'
const shortProjectDetailsField = 'project(id,virtual,parentProjectId)'
const basicBuildTypeFields = ['id', 'paused', 'internalId', 'projectId', 'name', 'type']
export const buildTypeFields: (options?: RequestOptionsParams) => ReadonlyArray<string> = options =>
  basicBuildTypeFields
    .concat(options?.withLinks === true ? linksField : [])
    .concat(options?.withParameters === true ? parametersField : [])
    .concat(options?.withDescription === true ? descriptionField : [])
    .concat(options?.withSnapshotDependencies === true ? snapshotDependenciesField : [])
    .concat(options?.withPauseComment === true ? pauseCommentField : [])
    .concat(options?.withShortProjectDetails === true ? shortProjectDetailsField : [])

export default (serverUrl: string, buildTypeId: BuildTypeId): Promise<BuildTypeWithDetailsType> =>
  request(
    serverUrl,
    `buildTypes/id:${stringifyId(buildTypeId)}?fields=${buildTypeFields({
      withLinks: true,
      withParameters: true,
      withDescription: true,
      withPauseComment: true,
    }).join(',')}`,
  ).then<BuildTypeWithDetailsType>(processResponse)

export const requestBuildTypeInternalId = async (
  serverUrl: string,
  id: BuildTypeId,
): Promise<BuildTypeInternalId> => {
  const response = await request(serverUrl, `buildTypes/id:${id}?fields=internalId`)
  const {internalId} = await processResponse(response)
  return internalId
}

type BuildTypesData = {
  buildType: readonly BuildTypeWithDetailsType[]
}
export async function requestBuildTypes(
  serverUrl: string,
  locator: string,
  options?: RequestOptionsParams,
): Promise<ReadonlyArray<BuildTypeWithDetailsType>> {
  const response = await request(
    serverUrl,
    `buildTypes?locator=${locator}&fields=buildType(${buildTypeFields(options).join(',')})`,
  )
  const {buildType}: BuildTypesData = await processResponse(response)
  return buildType
}

type BuildTypeIdsBuildType = {
  id: BuildTypeId
}
type BuildTypeIdsData = {
  buildType: readonly BuildTypeIdsBuildType[]
}
export async function requestBuildTypeIds(
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<BuildTypeId>> {
  const response = await request(serverUrl, `buildTypes?locator=${locator}&fields=buildType(id)`)
  const {buildType}: BuildTypeIdsData = await processResponse(response)
  return buildType.map(({id}) => id)
}

export const getDependantsLocator = (buildTypeId: BuildTypeId): string =>
  `snapshotDependency(from:(id:${stringifyId(buildTypeId)}))`
export const requestHasBuildTypes = (serverUrl: string, locator: string): Promise<boolean> =>
  request(serverUrl, `buildTypes?locator=${encodeURIComponent(locator)},count:1&fields=count`)
    .then<{count: number}>(processResponse)
    .then(data => data.count > 0)

type IsExternalStatusAllowedData = {
  externalStatusAllowed: boolean
}
export const requestIsExternalStatusAllowed = (
  serverUrl: string,
  buildTypeId: BuildTypeId,
): Promise<boolean> =>
  request(serverUrl, `buildTypes/id:${stringifyId(buildTypeId)}?fields=externalStatusAllowed`)
    .then<IsExternalStatusAllowedData>(processResponse)
    .then(({externalStatusAllowed}) => externalStatusAllowed)
