import * as Redux from 'redux'

import type {Action} from '../../../../../actions/types'
import fetchable from '../../../../../reducers/fetchable'
import {keyValueReducer} from '../../../../../reducers/utils'
import type {Fetchable, BuildTypeId} from '../../../../../types'
import {emptyArray} from '../../../../../utils/empty'
import type {KeyValue} from '../../../../../utils/object'

import {
  RECEIVE_POSSIBLE_DEPLOYMENTS,
  REQUEST_POSSIBLE_DEPLOYMENTS,
} from './Deployments.actions.types'
import type {PossibleDeployment} from './Deployments.state.types'
import {DeploymentsStateType} from './Deployments.state.types'

const possibleDeploymentsReducer = keyValueReducer(
  action => action.buildTypeId,
  fetchable<readonly PossibleDeployment[], readonly PossibleDeployment[]>(
    REQUEST_POSSIBLE_DEPLOYMENTS,
    RECEIVE_POSSIBLE_DEPLOYMENTS,
    emptyArray,
    action => action.data,
  ),
  [REQUEST_POSSIBLE_DEPLOYMENTS, RECEIVE_POSSIBLE_DEPLOYMENTS],
)
const deploymentsReducer = Redux.combineReducers<DeploymentsStateType>({
  possibleDeployments(
    state: KeyValue<BuildTypeId, Fetchable<ReadonlyArray<PossibleDeployment>>> = {},
    action: Action,
  ) {
    switch (action.type) {
      case REQUEST_POSSIBLE_DEPLOYMENTS:
      case RECEIVE_POSSIBLE_DEPLOYMENTS:
        return possibleDeploymentsReducer(state, action)

      default:
        return state
    }
  },
})
export default deploymentsReducer
