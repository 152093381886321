import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {fetchCurrentUserData} from '../actions'
import {getCurrentUserId} from '../selectors'
import type {UserId} from '../types'

export default function useMyId(): UserId | null | undefined {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCurrentUserData)
  }, [dispatch])
  return useSelector(getCurrentUserId)
}
