import Button from '@jetbrains/ring-ui/components/button/button'
import compose from 'lodash/flowRight'
import * as React from 'react'

import showOnce from '../../../hocs/showOnce'
import {UserProperties} from '../../../utils/userProperties'
import version from '../../../utils/version'

import ServiceMessage from '../../ServiceMessage/ServiceMessage'

import styles from './ExperimentalUIFeedback.css'

const feedbackURL = `https://teamcity-support.jetbrains.com/hc/en-us/requests/new?ticket_form_id=360001686659&tcv=${version}`

export const ExperimentalUIFeedback = () => {
  const [show, setShow] = React.useState(true)
  const close = React.useCallback(() => setShow(false), [])

  const buttons = React.useMemo(
    () => (
      <>
        <Button primary href={feedbackURL} target="_blank" rel="noreferrer" onClick={close}>
          {'Give feedback'}
        </Button>
        <Button text onClick={close}>
          {'No, thanks'}
        </Button>
      </>
    ),
    [close],
  )

  return show ? (
    <ServiceMessage
      containerClassName={styles.experimentalUiFeedback}
      icon="experiment-20px"
      onCloseRequest={close}
      title="Switching back?"
      controls={buttons}
    >
      {
        "We want to\u00a0understand your\u00a0experience better so we can improve TeamCity. Can you please tell us why you're switching back to\u00a0the\u00a0classic\u00a0interface?"
      }
    </ServiceMessage>
  ) : null
}
export default compose(showOnce(UserProperties.HAS_SEEN_EXPERIMENTAL_UI_FEEDBACK_FORM))(
  ExperimentalUIFeedback,
)
