import {branch, renderNothing} from 'recompose'

import compose from 'lodash/flowRight'
import * as ReactRedux from 'react-redux'

import {setUserProperty} from '../actions'
import {getBooleanUserProperty, getCurrentUserLoaded, getIsGuestOrRoot} from '../selectors'
import type {UserProperty} from '../utils/userProperties'

import withDerivedState from './withDerivedState'
import withEffect from './withEffect'

type StateProps = {
  isGuestOrRoot: boolean
  hasSeen: boolean
}

type ActionProps = {
  setUserProperty(name: UserProperty, value: string): void
}

type State = {
  show: boolean
}

export default (propertyName: UserProperty) =>
  compose(
    ReactRedux.connect(
      state => ({
        isGuestOrRoot: getIsGuestOrRoot(state),
        hasSeen: getCurrentUserLoaded(state) ? getBooleanUserProperty(state, propertyName) : true,
      }),
      {
        setUserProperty,
      },
    ),
    withDerivedState(
      {
        show: false,
      },
      (props: StateProps, prevState) =>
        !props.hasSeen && !props.isGuestOrRoot && !prevState.show
          ? {
              show: true,
            }
          : null,
    ),
    branch((props: State) => !props.show, renderNothing),
    withEffect(
      (props: ActionProps) => props.setUserProperty,
      setProperty => setProperty(propertyName, 'true'),
    ),
  )
