import {AsyncThunk, AsyncThunkOptions} from '@reduxjs/toolkit'
import {AsyncThunkFulfilledActionCreator} from '@reduxjs/toolkit/src/createAsyncThunk'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {combineReducers, ReducersMapObject} from 'redux'

import type {Action, AppThunk} from '../actions/types'
import {AsyncThunkConfig, createFetchAction, keyValueFetchable} from '../reducers/fetchable'
import type {FetchableSlicesState, State} from '../reducers/types'
import {requestBuildIds, requestBuildsDetails} from '../rest/builds'
import type {BuildId, BuildType, Fetchable, RequestOptionsParams} from '../types'
import {emptyArray} from '../utils/empty'

type FetchableSliceParams<P, T extends {}> = {
  readonly name: string
  readonly request: (restRoot: string, arg: P) => Promise<T>
  readonly getKey: (arg: P) => string
  readonly defaultState: T
  readonly condition?: (fetchable: Fetchable<T> | null | undefined) => boolean
  readonly dataReducer?: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<T, P, AsyncThunkConfig>>,
  ) => T
  readonly getPendingMeta?: AsyncThunkOptions<P, AsyncThunkConfig>['getPendingMeta']
}
export type FetchableSlice<P, T> = {
  readonly thunk: AsyncThunk<T, P, AsyncThunkConfig>
  readonly fetch: (arg: P) => AppThunk<Promise<T | null | undefined>>
  readonly useSelector: <R>(
    arg: P | null | undefined,
    selector: (arg0: Fetchable<T> | null | undefined) => R,
    equalityFn?: (arg0: R, arg1: R) => boolean,
  ) => R
  useFetch(arg: P | null | undefined, shouldFetch?: boolean): void
}
const sliceReducers: ReducersMapObject<FetchableSlicesState, Action> = {}
export function createFetchableSlice<P, T>({
  name,
  request,
  getKey,
  defaultState,
  condition = () => true,
  dataReducer = (_, action) => action.payload,
  getPendingMeta = () => ({}),
}: FetchableSliceParams<P, T>): FetchableSlice<P, T> {
  const getFetchable = (state: State, arg: P) => state.fetchableSlices[name]?.[getKey(arg)]
  const thunk = createFetchAction<T, P>(
    name,
    (arg, {getState}) => request(getState().restRoot, arg),
    {condition: (arg, {getState}) => condition(getFetchable(getState(), arg)), getPendingMeta},
  )
  sliceReducers[name] = keyValueFetchable(getKey, thunk, defaultState, dataReducer)

  return {
    thunk,
    fetch: arg => dispatch =>
      dispatch(thunk(arg))
        .unwrap()
        .catch(() => undefined),
    useSelector: <R>(
      arg: P | null | undefined,
      selector: (arg0: Fetchable<T> | null | undefined) => R,
      equalityFn?: (arg0: R, arg1: R) => boolean,
    ): R =>
      useSelector(state => selector(arg != null ? getFetchable(state, arg) : null), equalityFn),

    useFetch(arg: P | null | undefined, shouldFetch = true) {
      const dispatch = useDispatch()
      useEffect(() => {
        if (shouldFetch && arg != null) {
          dispatch(thunk(arg))
        }
      }, [arg, dispatch, shouldFetch])
    },
  }
}
export const fetchableSlicesReducer = (
  state: FetchableSlicesState = {},
  action: Action,
): FetchableSlicesState => combineReducers(sliceReducers)(state, action)
type BuildDetailsArg = {
  readonly locatorToStore: string
  readonly locatorToFetch?: string
  readonly options?: RequestOptionsParams | null | undefined
  readonly onProgress?: (arg0: BuildType) => unknown
}
export const buildsDetails: FetchableSlice<BuildDetailsArg, unknown> = createFetchableSlice<
  BuildDetailsArg,
  unknown
>({
  name: 'buildsDetails',
  request: (restRoot, {locatorToFetch, options, onProgress}) =>
    requestBuildsDetails(restRoot, locatorToFetch, options, onProgress),
  getKey: ({locatorToStore}) => locatorToStore,
  defaultState: null,
  condition: state => state?.inited !== true,
})
export const queuedBuildIds: FetchableSlice<string, ReadonlyArray<BuildId>> = createFetchableSlice({
  name: 'queuedBuildIds',
  request: (restRoot, locator) =>
    requestBuildIds(restRoot, locator, {
      customEndpoint: '/buildQueue',
    }),
  getKey: locator => locator,
  defaultState: emptyArray,
})
