import {$Values} from 'utility-types'

import type {UserId, Fetchable, ProjectInternalId} from '../../types'
import type {KeyValue} from '../../utils/object'

export const InvestigationActionTypes = {
  // TEST INVESTIGATION ACTION TYPES
  TEST_INVESTIGATION_ASSIGN: 'TEST_INVESTIGATION_ASSIGN' as 'TEST_INVESTIGATION_ASSIGN',
  TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    'TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE' as 'TEST_INVESTIGATION_REMOVE_NO_ASSIGNEE',
  TEST_MARK_AS_FIXED_NO_ASSIGNEE:
    'TEST_MARK_AS_FIXED_NO_ASSIGNEE' as 'TEST_MARK_AS_FIXED_NO_ASSIGNEE',
  TEST_INVESTIGATION_REMOVE: 'TEST_INVESTIGATION_REMOVE' as 'TEST_INVESTIGATION_REMOVE',
  TEST_MARK_AS_FIXED: 'TEST_MARK_AS_FIXED' as 'TEST_MARK_AS_FIXED',
  TEST_INVESTIGATION_ASSIGN_STICKY:
    'TEST_INVESTIGATION_ASSIGN_STICKY' as 'TEST_INVESTIGATION_ASSIGN_STICKY',
  TEST_INVESTIGATION_CHANGE: 'TEST_INVESTIGATION_CHANGE' as 'TEST_INVESTIGATION_CHANGE',
  TEST_INVESTIGATION_CHANGE_WITH_REASSIGN:
    'TEST_INVESTIGATION_CHANGE_WITH_REASSIGN' as 'TEST_INVESTIGATION_CHANGE_WITH_REASSIGN',
  TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    'TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE' as 'TEST_INVESTIGATION_ASSIGN_NO_ASSIGNEE',
  TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    'TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE' as 'TEST_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE',
  // BUILD TYPE INVESTIGATION ACTION TYPES
  BUILD_TYPE_INVESTIGATION_ASSIGN:
    'BUILD_TYPE_INVESTIGATION_ASSIGN' as 'BUILD_TYPE_INVESTIGATION_ASSIGN',
  BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    'BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE' as 'BUILD_TYPE_INVESTIGATION_REMOVE_NO_ASSIGNEE',
  BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE:
    'BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE' as 'BUILD_TYPE_MARK_AS_FIXED_NO_ASSIGNEE',
  BUILD_TYPE_INVESTIGATION_REMOVE:
    'BUILD_TYPE_INVESTIGATION_REMOVE' as 'BUILD_TYPE_INVESTIGATION_REMOVE',
  BUILD_TYPE_MARK_AS_FIXED: 'BUILD_TYPE_MARK_AS_FIXED' as 'BUILD_TYPE_MARK_AS_FIXED',
  BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY:
    'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY' as 'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY',
  BUILD_TYPE_INVESTIGATION_CHANGE:
    'BUILD_TYPE_INVESTIGATION_CHANGE' as 'BUILD_TYPE_INVESTIGATION_CHANGE',
  BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN:
    'BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN' as 'BUILD_TYPE_INVESTIGATION_CHANGE_WITH_REASSIGN',
  BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    'BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE' as 'BUILD_TYPE_INVESTIGATION_ASSIGN_NO_ASSIGNEE',
  BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE' as 'BUILD_TYPE_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE',
  // BUILD PROBLEM INVESTIGATION ACTION TYPES
  BUILD_PROBLEM_INVESTIGATION_ASSIGN:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN',
  BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE:
    'BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE' as 'BUILD_PROBLEM_INVESTIGATION_REMOVE_NO_ASSIGNEE',
  BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE:
    'BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE' as 'BUILD_PROBLEM_MARK_AS_FIXED_NO_ASSIGNEE',
  BUILD_PROBLEM_INVESTIGATION_REMOVE:
    'BUILD_PROBLEM_INVESTIGATION_REMOVE' as 'BUILD_PROBLEM_INVESTIGATION_REMOVE',
  BUILD_PROBLEM_MARK_AS_FIXED: 'BUILD_PROBLEM_MARK_AS_FIXED' as 'BUILD_PROBLEM_MARK_AS_FIXED',
  BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY',
  BUILD_PROBLEM_INVESTIGATION_CHANGE:
    'BUILD_PROBLEM_INVESTIGATION_CHANGE' as 'BUILD_PROBLEM_INVESTIGATION_CHANGE',
  BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN:
    'BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN' as 'BUILD_PROBLEM_INVESTIGATION_CHANGE_WITH_REASSIGN',
  BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN_NO_ASSIGNEE',
  BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE:
    'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE' as 'BUILD_PROBLEM_INVESTIGATION_ASSIGN_STICKY_NO_ASSIGNEE',
}
type InvestigationActionTypeType = $Values<typeof InvestigationActionTypes>
export type InvestigationHistoryType = {
  timestamp: string
  actionType: InvestigationActionTypeType
  actor?: {
    userId: UserId
    userName: string
    descriptiveName: string
    avatar20Url?: string
    avatar40Url?: string
  }
  assignee?: {
    userId: UserId
    userName: string
    descriptiveName: string
  }
  commentText: string
}
export const REQUEST_INVESTIGATION_HISTORY: 'REQUEST_INVESTIGATION_HISTORY' =
  'REQUEST_INVESTIGATION_HISTORY'
export const RECEIVE_INVESTIGATION_HISTORY: 'RECEIVE_INVESTIGATION_HISTORY' =
  'RECEIVE_INVESTIGATION_HISTORY'
export type InvestigationHistoryAction =
  | {
      type: typeof REQUEST_INVESTIGATION_HISTORY
      readonly locator: string
      readonly isBackground?: boolean
    }
  | {
      type: typeof RECEIVE_INVESTIGATION_HISTORY
      readonly locator: string
      readonly data?: ReadonlyArray<InvestigationHistoryType>
      readonly isBackground?: boolean
      readonly error?: Error
    }
export type OwnProps = {
  readonly className?: string
  readonly projectInternalId: ProjectInternalId | null | undefined
  readonly locator: string
}
export type StateProps = {
  readonly investigations: ReadonlyArray<InvestigationHistoryType>
  readonly isAutodetectTimeZone: boolean
  readonly isBackgroundLoading: boolean
  readonly isLoading: boolean
  readonly isReady: boolean
}
export type ActionProps = {
  readonly fetchInvestigationHistory: (locator: string, isBackground?: boolean) => unknown
}
type ComposeProps = {
  readonly fetchInvestigationHistoryHandler: (isBackground?: boolean) => unknown
}
export type Props = OwnProps & StateProps & ComposeProps & ActionProps
export type InvestigationHistoryStateType = {
  readonly investigations: KeyValue<string, Fetchable<ReadonlyArray<InvestigationHistoryType>>>
}
