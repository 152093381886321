import type {SubscriptionID} from '../utils/subscriptionId'

import type {
  AgentId,
  AgentPoolId,
  BranchType,
  BuildId,
  BuildTypeId,
  ChangeId,
  ProblemId,
  ProjectId,
  TestId,
} from '.'
import {KillOperationKind} from '.'

type FilterOptions = {}
type Popup = {
  hidePopup(delay?: number, force?: boolean): unknown
  stopHidingPopup(): unknown
}
export type StatusPopup = Popup & {
  showPopup(
    buildTypeId: BuildTypeId,
    finished: boolean,
    skipChangesArtifacts: boolean,
    element: EventTarget,
    options?: {
      zIndex: number
    },
  ): unknown
}
type PopupOptions = {
  shift?: {
    dy?: number
  }
}
export type PeriodicalExecutor = {
  start: () => unknown
  unscheduledExecution: () => unknown
  stop: () => unknown
}
export type RunCustomBuildStringParams = {
  readonly branchName?: string | null | undefined
  readonly stateKey?: string
  readonly redirectTo?: string
  readonly modificationId?: string
  readonly init?: string | null | undefined
  readonly initFromBuild?: string | null | undefined
  readonly dependOnPromotionIds?: string
}
type RunCustomBuildParams = RunCustomBuildStringParams & {
  readonly redirectToQueuedBuild?: boolean | null | undefined
  readonly beforeTrigger?: () => unknown
  readonly afterShowDialog?: () => unknown
  readonly isCustomRunDialogForRunButton?: boolean
}

declare global {
  interface Window {
    base_uri: string
    _tc_es: (arg0: Event) => unknown
    BS:
      | {
          helpUrlPrefix?: string
          feedbackUrl: string
          ajaxRequest(url: string, params: unknown): unknown
          reload(reload: boolean): unknown
          Activator: {
            doOpen(command: string): unknown
          }
          AdminActions: {
            setHealthItemVisibility: (
              type: string,
              categoryId: string,
              itemId: string,
              visible: boolean,
              applyGlobally: boolean,
              oncomplete: () => unknown,
            ) => unknown
          }
          Agent: {
            showChangeStatusDialog(
              enable: boolean,
              id: AgentId,
              registered: boolean,
              actionCode: 'changeAuthorizeStatus' | 'changeAgentStatus',
              poolOptions?: {
                poolId?: AgentPoolId
                cloud?: boolean
              },
              updateStatus?: () => unknown,
            ): unknown
          }
          AgentsReact: {
            refreshFetcherData(): unknown
            refreshTabsCounters(): unknown
          }
          BuildResultsPopupTracker(buildId: BuildId): StatusPopup
          PromoDetailsPopupFunc(buildId: BuildId): StatusPopup
          BuildCommentDialog: {
            showBuildCommentDialog(
              buidId: BuildId,
              comment?: string | null | undefined,
              doNotReload?: boolean,
            ): Promise<string>
          }
          ChangesPopup: Popup & {
            showBuildChangesPopup(
              nearestElement: HTMLElement,
              id: BuildId,
              isBuildId?: boolean,
            ): unknown
            showPendingChangesPopup(
              nearestElement: HTMLElement,
              buildTypeId: BuildTypeId,
              branch?: string | null | undefined,
              options?: PopupOptions,
            ): unknown
          }
          FilesPopup: Popup & {
            showPopup(
              event: Event,
              options: {
                parameters: {
                  modId: ChangeId
                  buildTypeId: BuildTypeId
                  personal: boolean
                  chain?: boolean
                }
              },
            ): unknown
          }
          DependentArtifactsPopup: Popup & {
            showPopup(
              nearestElement: EventTarget,
              buildId: BuildId,
              targetBuildId: BuildId,
              mode: string,
            ): unknown
          }
          AgentInfoPopup: Popup & {
            showAgentsPopup(nearestElement: EventTarget, promoId: BuildId, tab?: string): unknown
          }
          BuildCommentPopup: Popup & {
            showBuildCommentPopup(nearestElement: HTMLElement, parametersAsString: string): unknown
          }
          LazyTree: {
            treeUrl: string
            ignoreHashes: boolean
            options: unknown
            loadTree(elementId: string): unknown
          }
          Log: {
            debug(arg0: any, ...args: any): unknown
            log(arg0: unknown, ...args: any): unknown
            warn(arg0: unknown, ...args: any): unknown
            error(arg0: unknown, ...args: any): unknown
          }
          Logout(url: string): void
          PinBuildDialog: {
            showPinBuildDialog(
              buildId: BuildId,
              pin: boolean,
              partOfChain: boolean,
              defaultMessage: string | null | undefined,
              availableTagsContainerId: string,
              doNotReload?: boolean,
              buildTypeId?: BuildTypeId,
            ): Promise<boolean>
          }
          Popup: {
            container: HTMLElement | null | undefined
          }
          SubscriptionManager: {
            subscribe(
              topicId: string,
              onMessage: (arg0: string) => unknown,
              subscriptionId?: SubscriptionID,
            ): () => void
            unsubscribe(topicId: string, subscriptionId?: SubscriptionID): unknown
            closeSocket(message: string | null | undefined): unknown
          }
          Tags: {
            showEditDialog(
              promotionId: BuildId,
              escapedPublicTags: string,
              escapedPrivateTags: string,
              partOfChain: boolean,
              availableTagsContainerId: string,
              preventReload?: boolean,
              buildTypeId?: BuildTypeId,
            ): Promise<Array<string>>
          }
          Util: {
            showHelp(
              event: Event,
              url: string,
              options?: {
                readonly width?: number | null | undefined
                readonly height?: number | null | undefined
                readonly preservePlus?: boolean
              },
            ): unknown
          }
          TestMetadata: {
            showGraph(
              anchor: EventTarget,
              testNameId: TestId,
              buildId: BuildId,
              escapedName: string,
              buildTypeId: BuildTypeId,
            ): unknown
          }
          RunBuild: {
            runCustomBuild(
              buildTypeId: BuildTypeId,
              options: RunCustomBuildParams,
            ): Promise<BuildId | null>
          }
          RunBuildDialog: {
            showTab: (tabId: string) => unknown
          }
          ResponsibilityDialog: {
            showDialog(
              buildTypeId: BuildTypeId,
              buildTypeFullName: string,
              presetFix: boolean,
              withoutReload?: boolean,
              submitHandler?: () => unknown,
            ): unknown
          }
          EditModificationDescriptionDialog: {
            showDialog(): unknown
          }
          BulkInvestigateMuteTestDialog: {
            show(
              testsData: Readonly<Record<string, void | string>>,
              fixMode: boolean,
              flakyTestIds: Readonly<Record<string, void | boolean>>,
              withoutReload?: boolean,
              submitHandler?: () => unknown,
            ): unknown
            showForBuildProblem(
              problemId: ProblemId,
              buildId: BuildId,
              fixMode: boolean,
              withoutReload?: boolean,
              submitHandler?: () => unknown,
            ): unknown
            showForBuildProblems(
              problemsData: Readonly<Record<string, void | string>>,
              fixMode: boolean,
              withoutReload?: boolean,
              submitHandler?: () => unknown,
            ): unknown
          }
          ServerLink: {
            isConnectionAvailable: () => boolean
            getTotalFailuresNum?: () => number
          }
          CSRF: {
            getCachedToken: () => string | null | undefined
            handleCSRFError: (
              arg0: {
                readonly status: number
                readonly responseText: string
              },
              arg1: (() => unknown) | null | undefined,
              arg2: string | null | undefined,
            ) => boolean
            refreshCSRFToken: (callback?: () => unknown, force?: boolean) => unknown
          }
          enableJQueryDropDownFilter(selectId: string, filterOptions: FilterOptions): unknown
          StopBuildDialog:
            | {
                showStopBuildDialog: (
                  promoIds: ReadonlyArray<BuildId>,
                  defaultMessage: string,
                  operationKind: KillOperationKind,
                  reStopMessage?: (string | HTMLElement) | null | undefined,
                ) => Promise<boolean>
              }
            | null
            | undefined
          RestProjectsPopup?:
            | {
                height: () => unknown
              }
            | null
            | undefined
          Hider: {
            _currentZindex: () => number
            hasVisiblePopups: () => boolean
          }
          topNavPane?: {
            helpers?: {
              counters?: {
                agents?: number
                queue?: number
              }
              adminSpaceAvailable?: boolean
            }
            getActiveTab: () => {
              myTabId: string
            }
          }
          blockRefreshPermanently: (arg0: string | null | undefined) => unknown
          unblockRefresh: (arg0: string | null | undefined) => unknown
          Branch?: {
            WILDCARD_NAME: string
            parseBranch: (arg0: string | null | undefined) => BranchType | null | undefined
            stringifyBranch: (arg0: BranchType | null | undefined) => string
            registerProjectBranch: (arg0: ProjectId, arg1: string | null | undefined) => unknown
            saveBranch: (arg0: ProjectId, arg1: string | null | undefined) => unknown
            getBranch: (projectId: ProjectId, noStorage?: boolean) => string | null
          }
          periodicalExecutor: (job: () => Promise<unknown>, arg1: number) => PeriodicalExecutor
          PauseBuildTypeDialog: {
            showPauseBuildTypeDialog: (
              buildTypeId: BuildTypeId,
              pause: boolean,
              hasPermissions: boolean,
              defaultMessage: string | null | undefined,
              noReload: boolean,
            ) => Promise<void>
          }
          VcsUsername: {
            addVcsNameFromModification(id: ChangeId): void
          }
        }
      | null
      | undefined
    internalProps: Record<string, any>
  }
}

export const base_uri = window.base_uri
export const _tc_es = window._tc_es
export const BS = window.BS
export const internalProps = window.internalProps
