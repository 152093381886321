import type {CloudImageResponseType} from '../types'

import {internalProps} from '../types/BS_types'

import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

const loadProfileIds =
  internalProps['teamcity.cloudProfiles.hiddenCloudTypes'] != null &&
  internalProps['teamcity.cloudProfiles.hiddenCloudTypes'].length > 0

type Data = {
  cloudImage: readonly CloudImageResponseType[]
}
export default (
  serverUrl: string = '',
  options?: RestRequestOptions,
): Promise<ReadonlyArray<CloudImageResponseType>> =>
  request(
    serverUrl,
    `cloud/images?fields=cloudImage(id,agentPoolId,name,agentTypeId,${
      loadProfileIds ? 'profile(id),' : ''
    }instances(cloudInstance(id,name,agent(id))))`,
    options,
  )
    .then<Data>(processResponse)
    .then(data => data.cloudImage)
