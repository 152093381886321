import type {Action} from '../../actions/types'

import {emptyArray, getEmptyHash} from '../../utils/empty'

import {
  ADD_HINT,
  COLLAPSE_HINT,
  EXPAND_HINT,
  HIDE_HINT,
  HINT_CATEGORY,
  REMOVE_HINT,
  SELECT_HINT_CATEGORY,
  SHOW_HINT,
  stringifyHintId,
} from './Hints.types'
import type {HintsStateType} from './Hints.types'

const defaultState: HintsStateType = {
  registrations: getEmptyHash(),
  available: emptyArray,
  selected: emptyArray,
  expanded: emptyArray,
  selectedCategory: null,
}

const hintsReducer = (state: HintsStateType = defaultState, action: Action): HintsStateType => {
  switch (action.type) {
    case ADD_HINT: {
      const {hint} = action
      const registrations = state.registrations[hint.id]
      const hintExists = registrations != null && registrations > 0

      if (hintExists) {
        return {
          ...state,
          registrations: {
            ...state.registrations,
            [stringifyHintId(hint.id)]: registrations + 1,
          },
        }
      } else {
        return {
          ...state,
          available: [
            ...state.available,
            hint.category ? hint : {...hint, category: HINT_CATEGORY.miscellaneous},
          ],
          registrations: {...state.registrations, [stringifyHintId(hint.id)]: 1},
        }
      }
    }

    case REMOVE_HINT: {
      const {id: hintId} = action
      const currentRegistrations = state.registrations[hintId] ?? 0
      const registrations = {
        ...state.registrations,
        [stringifyHintId(hintId)]: currentRegistrations - 1,
      }
      const available =
        currentRegistrations === 1 && state.available.some(item => item.id === hintId)
          ? state.available.filter(item => item.id !== hintId)
          : state.available
      return {...state, registrations, available}
    }

    case SHOW_HINT:
      if (state.selected.includes(action.id)) {
        return state
      }

      return {...state, selected: [...state.selected, action.id]}

    case HIDE_HINT:
      if (!state.selected.includes(action.id)) {
        return state
      }
      return {...state, selected: state.selected.filter(id => id !== action.id)}

    case SELECT_HINT_CATEGORY:
      return {...state, selectedCategory: action.category}

    case EXPAND_HINT:
      return state.expanded.includes(action.id)
        ? state
        : {...state, expanded: [action.id], selected: [action.id]}

    case COLLAPSE_HINT:
      return state.expanded.includes(action.id)
        ? {...state, expanded: state.expanded.filter(hintId => hintId !== action.id)}
        : state

    default:
      return state
  }
}

export default hintsReducer
