import * as Redux from 'redux'

import type {Action} from '../../../actions/types'
import {
  RECEIVE_BUILDLOG_MESSAGES,
  REQUEST_BUILDLOG_MESSAGES,
  SET_FULL_LOG_SHOWED,
  SET_FULL_LOG_STATE,
  SET_FULL_LOG_SCROLL_BAR_INFO,
  SET_FULL_LOG_FILTER,
} from '../BuildLog.types'
import type {FullLogStates, FullLogState, FullLogTarget} from '../BuildLog.types'
import {getKeyForBuildLogData} from '../BuildLog.utils'

const defaultFullLogState: FullLogState = {
  buildId: null,
  focusLine: null,
  expandState: null,
  expandAll: false,
  showedFirstDate: null,
  showedFirstId: null,
  showedLastDate: null,
  showedLastId: null,
  scrollBarInfo: null,
  messagesExists: false,
  filter: null,
  lastLoadingDirection: null,
  logView: null,
}

const fullLogReducer = (
  state: FullLogState,
  action: Action,
  target: FullLogTarget,
): FullLogState => {
  switch (action.type) {
    case REQUEST_BUILDLOG_MESSAGES: {
      if (action.target === target && action.invalidate === true) {
        return {
          focusLine:
            action.options != null &&
            action.options.logAnchor != null &&
            action.options.logAnchor !== 0
              ? action.options.logAnchor
              : null,
          filter: action.options != null ? action.options.filter : state.filter,
          expandState: action.options?.expandState ?? null,
          expandAll: action.options?.expandAll === true,
          showedFirstDate: null,
          showedFirstId: null,
          showedLastDate: null,
          showedLastId: null,
          scrollBarInfo: null,
          buildId: action.buildId,
          messagesExists: state.messagesExists,
          lastLoadingDirection: null,
          logView: null,
        }
      }

      return state
    }

    case RECEIVE_BUILDLOG_MESSAGES: {
      if (
        state.buildId != null &&
        action.buildLogKey ===
          getKeyForBuildLogData({
            type: 'full',
            target,
          })
      ) {
        const logView = action.logView ?? state.logView

        const count = action.options?.count
        const lastLoadingDirection =
          count != null && (Array.isArray(count) ? count.every(i => i <= 0) : count < 0)
            ? 'up'
            : 'down'

        if (action.invalidate === true) {
          return {
            ...state,
            logView,
            // should compare messages length with 1 because there is a hidden root message in response
            messagesExists:
              state.messagesExists || (action.data != null && action.data.messages.length > 1),
            focusLine: action.focusLine != null ? action.focusLine : state.focusLine,
            lastLoadingDirection,
            expandState:
              action.data != null && action.data.expandedMessagesIndices != null
                ? action.data.expandedMessagesIndices.filter(i => i !== 0)
                : state.expandState,
          }
        }

        if (
          action.options?.logAnchor === -1 &&
          action.focusLine != null &&
          action.focusLine !== -1
        ) {
          return {
            ...state,
            lastLoadingDirection,
            logView,
            focusLine: action.focusLine,
          }
        }

        if (lastLoadingDirection !== state.lastLoadingDirection || logView !== state.logView) {
          return {
            ...state,
            logView,
            lastLoadingDirection,
          }
        }
      }

      return state
    }

    case SET_FULL_LOG_STATE:
      if (
        action.target === target ||
        (action.buildId != null && action.buildId === state.buildId)
      ) {
        const reset =
          action.target === target &&
          action.buildId !== undefined &&
          action.buildId !== state.buildId
        return {
          buildId: action.buildId !== undefined ? action.buildId : state.buildId,
          focusLine: action.focusLine !== undefined ? action.focusLine : state.focusLine,
          expandState: action.expandState !== undefined ? action.expandState : state.expandState,
          expandAll: action.expandAll !== undefined ? action.expandAll : state.expandAll,
          showedFirstDate: reset ? null : state.showedFirstDate,
          showedFirstId: reset ? null : state.showedFirstId,
          showedLastDate: reset ? null : state.showedLastDate,
          showedLastId: reset ? null : state.showedLastId,
          scrollBarInfo: reset ? null : state.scrollBarInfo,
          lastLoadingDirection: reset ? null : state.lastLoadingDirection,
          logView: reset ? null : state.logView,
          messagesExists: state.messagesExists,
          // eslint-disable-next-line eqeqeq
          filter: action.buildId === null ? null : state.filter,
        }
      }

      return state

    case SET_FULL_LOG_SHOWED:
      if (action.target === target) {
        return {
          ...state,
          showedFirstDate: action.firstDate,
          showedFirstId: action.firstId,
          showedLastDate: action.lastDate,
          showedLastId: action.lastId,
        }
      }

      return state

    case SET_FULL_LOG_SCROLL_BAR_INFO:
      if (action.target === target) {
        return {...state, scrollBarInfo: action.info}
      }

      return state

    case SET_FULL_LOG_FILTER: {
      return {...state, filter: action.value}
    }

    default:
      return state
  }
}

export const fullLogStates: Redux.Reducer<FullLogStates, Action> = Redux.combineReducers({
  page: (state: FullLogState = defaultFullLogState, action: Action) =>
    fullLogReducer(state, action, 'page'),
  popup: (state: FullLogState = defaultFullLogState, action: Action) =>
    fullLogReducer(state, action, 'popup'),
})
