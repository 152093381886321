import {requestUser} from '../rest/users'

import {createFetchableSlice} from './fetchable'

export const users = createFetchableSlice({
  name: 'users',
  request: requestUser,
  getKey: String,
  defaultState: null,
})
