import memoize from '@jetbrains/ring-ui/components/global/memoize'
import {CustomAnchorProps, SelectItem} from '@jetbrains/ring-ui/components/select/select'
import {TagType as RingTagType} from '@jetbrains/ring-ui/components/tags-list/tags-list'
import * as React from 'react'
import {$Keys, $ReadOnly, $Values} from 'utility-types'

import type {BuildApprovalType} from '../components/common/BuildApproval/BuildApproval.types'

import type {KeyValue} from '../utils/object'
import {WritableKeyValue} from '../utils/object'

// can't use ElementType, see https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/53864
export type HOC<I, O> = (component: React.ComponentType<I>) => React.ComponentType<O>

export type Id = number | string
export type BuildId = number
export type BuildTypeId = string
export type ProjectId = string
export type CustomSidebarItemId = string
export type BuildTypeInternalId = string
export type ProjectInternalId = string
export type UserId = number
export type AgentId = number
export type AgentPoolId = number
export type AgentTypeId = number
type CloudProfileId = string
export type ChangeId = number
export type TestId = string
export type TestNameId = string
export type TestOccurrenceId = string
export type ProblemId = string
export type ProblemOccurrenceId = string
type SystemProblemId = string
type InvestigationId = string
export type FederationServerId = string
export type TabId = string
export type RevisionId = string
export type VcsRootInstanceId = string
export type VcsRootId = string
type MuteId = number
export type TestsTreeNodeId = string
export type ProblemsTreeNodeId = string
export const stringifyId = (id: (Id | null | undefined) | string): string =>
  id != null ? String(id) : ''
export const toBuildId = (key: string | number): BuildId => Number(key)
export const toChangeId = (key: string | number): ChangeId => Number(key)
export const toBuildTypeId = (key: string): BuildTypeId => key
export const toBuildTypeInternalId = (key: string): BuildTypeInternalId => key
export const toProjectId = (key: string): ProjectId => key
export const toProjectInternalId = (key: string): ProjectInternalId => key
export const toUserId = (key: string | number): UserId => Number(key)
export const toAgentId = (key: string | number): AgentId => Number(key)
export const toAgentTypeId = (key: string | number): AgentTypeId => Number(key)
export const toAgentPoolId = (key: string | number): AgentPoolId => Number(key)
export const toTestsTreeNodeId = (key: string | number): TestsTreeNodeId => String(key)
export const toFederationServerId = (key: string): FederationServerId => key
export const toCustomSidebarItemId = (key: string): CustomSidebarItemId => key
export const toTabId = (key: string): TabId => String(key)
export const toProblemId = (key: string): ProblemId => String(key)
export const toProblemOccurrenceId = (key: string): ProblemOccurrenceId => String(key)
export const toSystemProblemId = (key: string): SystemProblemId => String(key)
export const toInvestigationId = (key: string): InvestigationId => String(key)
export const toTestId = (key: string | number): TestId => String(key)
export const toTestOccurrenceId = (key: string): TestOccurrenceId => String(key)
export const toRevisionId = (key: string): RevisionId => String(key)
export const toVcsRootInstanceId = (key: string | number): VcsRootInstanceId => String(key)
export const toVcsRootId = (key: string): VcsRootId => String(key)
export const ROOT_PROJECT_ID: ProjectId = '_Root'
export const ROOT_PROJECT_INTERNAL_ID: ProjectInternalId = '_Root'
// flowlint-next-line unclear-type:off
export const ALL_PROJECTS: ProjectId = Symbol() as any
export type NodeType = 'project' | 'bt' | 'build'
export type NodeGroupType = 'favorites' | 'all' | 'search' | 'none'
export type Property = {
  readonly name: string
  readonly value: string
}
export type Properties = {
  property?: ReadonlyArray<Property>
}
export type LinkType = 'webView' | 'webEdit' | 'webViewSettings'
export type Inexact<T> = T & {
  [K in PropertyKey]: unknown
}
type WebEntity = {
  readonly description?: string
  readonly name?: string
  readonly webUrl?: string
}
type InexactWebEntity = Inexact<WebEntity>
export type WebLinks = {
  readonly links?: {
    link: ReadonlyArray<{
      type: LinkType
      relativeUrl: string
    }>
  }
}
type WebEntityWithLinks = WebEntity & WebLinks
type InexactWebEntityWithLinks = Inexact<WebEntityWithLinks>
export type BuildTypeTypeType = 'composite' | 'deployment' | 'regular'
export type BuildTypeType = WebEntity & {
  readonly id: BuildTypeId
  readonly internalId?: BuildTypeInternalId
  readonly projectId: ProjectId
  readonly project?: {
    id?: ProjectId
    virtual?: boolean
    parentProjectId?: ProjectId
  }
  readonly paused?: boolean
  readonly type?: BuildTypeTypeType
  readonly 'snapshot-dependencies'?: {
    readonly 'snapshot-dependency'?: ReadonlyArray<{
      readonly id: BuildTypeId
    }>
  }
}
type EntityParameters = {
  readonly parameters?: Properties
  readonly pauseComment?: CommentInfo
}
export type InexactEntityParameters = Inexact<EntityParameters>
export type EntityDescription = {
  readonly description?: string | null | undefined
}
export type EntityPauseComment = {
  readonly pauseComment?: CommentInfo
}
export type EntityProjectsIdsList = ReadonlyArray<{
  id: ProjectId
}>
export type EntityArchivedSubprojectsIds = {
  projects?: {
    project: EntityProjectsIdsList
  }
}
export type BuildTypeWithDetailsType = BuildTypeType & WebLinks & EntityParameters
type BuildTypeCollection = {
  readonly buildType: ReadonlyArray<BuildTypeType>
}
type BuildTypeWithDetailsCollection = {
  readonly buildType: ReadonlyArray<BuildTypeWithDetailsType>
}
export type Template = {
  id: string
  name: string
}
type BaseProjectType = WebEntity & {
  readonly id: ProjectId
  readonly internalId: ProjectInternalId
  readonly parentProjectId?: ProjectId
  readonly virtual?: boolean
  readonly archived: boolean
  readonly readOnlyUI?: {
    value: boolean
  }
  readonly templates?: {
    buildType: readonly Template[]
  }
}
export type ProjectType = BaseProjectType & {
  readonly buildTypes?: BuildTypeCollection
}
export type ProjectWithDetailsType = BaseProjectType &
  WebLinks &
  EntityParameters & {
    readonly buildTypes?: BuildTypeWithDetailsCollection
  }
export type NormalizedProjectType = BaseProjectType & {
  buildTypes?: {
    buildType: ReadonlyArray<BuildTypeId>
  }
  projects?: {
    project:
      | ReadonlyArray<{
          id: ProjectId
        }>
      | null
      | undefined
  }
}
export type BranchType = {
  name: string
  internalName?: string
  groupFlag?: boolean
  default?: boolean
  active?: boolean
  wildcard?: boolean
  unspecified?: boolean
}
export type BranchItem = SelectItem<{
  label?: string
  selectedLabel?: React.ReactNode
  key: string
  branch?: BranchType
}>
export type OSType =
  | 'Windows'
  | 'macOS'
  | 'Linux'
  | 'Solaris'
  | 'FreeBSD'
  | 'Unix'
  | 'Unknown'
  | '%future added value'
export type AvatarSize = `urlToSize${number}`
export const getAvatarSizeKey = (size: number): AvatarSize => `urlToSize${size}`
type Avatars = KeyValue<string, string>
export type UserType = {
  readonly id: UserId
  readonly name?: string | null | undefined
  readonly username: string
  readonly avatars?: Avatars
  readonly email?: string
}
export type GroupType = {
  readonly key: string
  readonly name: string
  readonly description?: string
  readonly users?: Countable
}
export type CommentInfo = {
  readonly text?: string
  readonly timestamp?: string
  readonly user?: UserType
}
export type EnabledInfoType = {
  readonly status: boolean
  readonly comment: CommentInfo
  readonly statusSwitchTime?: string
}
export type AgentPoolType = {
  readonly id: AgentPoolId
  readonly name: string
  readonly maxAgents?: number
  readonly projects?: {
    readonly project: ReadonlyArray<ProjectType>
  }
}
export type AgentPoolsHashType = KeyValue<AgentPoolId, AgentPoolType>
type CloudProfileType = {
  readonly id: CloudProfileId
  readonly name?: string
  readonly projectId?: ProjectId
}
type BaseCloudImageType = {
  readonly name: string
  readonly agentPoolId: AgentPoolId
  readonly agentTypeId: AgentTypeId
  readonly profile?: CloudProfileType
}
export type CloudInstance = {
  readonly agent?: {
    readonly id: AgentId
  }
}
export type CloudImageResponseType = BaseCloudImageType & {
  readonly instances: {
    readonly cloudInstance: ReadonlyArray<CloudInstance>
  }
}
export type NormalizedCloudImageType = BaseCloudImageType & {
  readonly agentIds: ReadonlyArray<AgentId>
}
export type CloudImagesHashType = KeyValue<AgentTypeId, NormalizedCloudImageType>
type EnvironmentType = {
  readonly osType?: OSType | null | undefined
  readonly osName?: string | null | undefined
}
type BaseAgentPreviewType<B> = {
  readonly id: AgentId
  readonly typeId: AgentTypeId
  readonly name: string
  readonly ip?: string
  readonly enabled?: boolean
  readonly connected: boolean
  readonly authorized?: boolean
  readonly pool: AgentPoolType
  readonly build?: B | null | undefined
}
export type NormalizedAgentPreviewType = BaseAgentPreviewType<BuildId> & {
  readonly osType: OSType | null | undefined
}
export type WritableAgentPreviewsHashType = WritableKeyValue<
  AgentId,
  NormalizedAgentPreviewType | null | undefined
>
export type AgentPreviewsHashType = Readonly<WritableAgentPreviewsHashType>
type BaseAgentType<B> = WebEntityWithLinks &
  BaseAgentPreviewType<B> & {
    readonly enabledInfo?: EnabledInfoType
    readonly authorizedInfo?: EnabledInfoType
    readonly uptodate?: boolean
    readonly version?: string
    readonly outdated?: boolean
    readonly currentAgentVersion?: string
    readonly pluginsOutdated?: boolean
    readonly javaOutdated?: boolean
    readonly lastActivityTime?: string
    readonly disconnectionComment?: string
    readonly deleted?: boolean
  }
export type AgentDetails = {
  readonly host?: string | null | undefined
  readonly port?: number | null | undefined
  readonly protocol?: string | null | undefined
  readonly cpuRank?: number | null | undefined
  readonly connectedSince?: string | null | undefined
}
export type NormalizedAgentType = BaseAgentType<BuildId> & EnvironmentType
export type AgentRequestOptions = {
  readonly authorizedInfo?: boolean
  readonly disconnectionComment?: boolean
  readonly lastActivityTime?: boolean
  readonly enabledInfo?: boolean
  readonly cloudInfo?: boolean
  readonly details?: boolean
  readonly essential?: boolean
  readonly tabs?: boolean
}
type ArchivedOptionType = 'any' | 'false' | 'true'
export type RequestOptionsParams = {
  readonly withLinks?: boolean
  readonly withBuildTypes?: boolean
  readonly withTemplates?: boolean
  readonly archived?: ArchivedOptionType
  readonly withLowNesting?: boolean
  readonly withArchivedSubprojectsIds?: boolean
  readonly federationServerId?: FederationServerId
  readonly withParameters?: boolean
  readonly withDescription?: boolean
  readonly withQueuedInfo?: boolean
  readonly withRunningInfo?: boolean
  readonly withShortProjectDetails?: boolean
  readonly withBuildTypeDetails?: boolean
  readonly withSnapshotDependencies?: boolean
  readonly withVirtualDependencies?: boolean
  readonly withDownloadedArtifactsFrom?: BuildId | null
  readonly withTestOccurrencesCount?: boolean
  readonly withPauseComment?: boolean
  readonly withProgress?: boolean
  readonly fetchCount?: number
  readonly includeRoot?: boolean
  readonly customEndpoint?: string
  readonly essential?: boolean
  readonly cache?: string
}
export type ProjectReceiveMeta = {
  readonly sidebarProjectsLoaded?: boolean
  readonly sidebarArchivedProjectsLoaded?: boolean
}
type ProblemOccurrencesTreeReceiveMeta = {
  readonly depth?: number
}
type TestOccurrencesTreeReceiveMeta = {
  readonly depth?: number
}
export type SuccessReceiveMeta = ProjectReceiveMeta &
  ProblemOccurrencesTreeReceiveMeta &
  TestOccurrencesTreeReceiveMeta & {}

export type RunningInfoType = {
  readonly percentageComplete?: number
  readonly elapsedSeconds: number
  readonly estimatedTotalSeconds?: number
  readonly leftSeconds?: number
  readonly overtime?: number
  readonly lastActivityTime?: string
  readonly probablyHanging?: boolean
  readonly outdated?: boolean
  readonly outdatedReasonBuild?: InexactWebEntityWithLinks & {
    readonly number: string | null | undefined
  }
}
export type Countable = {
  readonly count?: number
}
export type InexactCountable = Inexact<Countable>
export type CompatibleAgentType = {
  readonly id: BuildId
  readonly compatibleAgents?: InexactCountable
}
export type TagType = {
  readonly name: string
  readonly private: boolean
  readonly key?: string
  readonly value?: string
}
export type BuildStatusType =
  | 'UNKNOWN'
  | 'SUCCESS' // NORMAL
  | 'WARNING'
  | 'FAILURE'
  | 'ERROR'
export type BuildStateType = 'queued' | 'running' | 'finished' | 'deleted' | 'unknown'
export type BuildArtifactsType = {
  readonly id: BuildId
  readonly artifacts?: InexactCountable
}
export type BuildTriggeredBuildType = InexactWebEntityWithLinks & {
  id: BuildId | null | undefined
  number?: string | null | undefined
}
type BaseBuildTriggeredType<BT> = {
  date?: string
  displayText?: string | null | undefined
  build?: BuildTriggeredBuildType
  buildType?: BT | null | undefined
}
export type BuildTriggeredType = BaseBuildTriggeredType<BuildTypeType>
export type NormalizedBuildTriggeredType = BaseBuildTriggeredType<BuildTypeId>
export type BuildArtifactsSizeType = {
  readonly total: string
  readonly visible: string
}
type FileChangeType = 'edited' | 'added' | 'removed'
export type ChangeFileType = {
  readonly changeType?: FileChangeType
  readonly file?: string
  readonly directory?: boolean
}
export type ChangeStatusType = {
  readonly newFailedTests?: number
  readonly otherFailedTests?: number
  readonly totalProblems?: number
  readonly cancelledBuilds?: number
  readonly finishedBuilds?: number
  readonly failedBuilds?: number
  readonly runningBuilds?: number
  readonly queuedBuildsCount?: number
  readonly pendingBuildTypes?: number
  readonly runningSuccessfullyBuilds?: number
  readonly successfulBuilds?: number
  readonly compilationErrorBuilds?: {
    readonly count?: number
  }
  readonly criticalBuilds?: {
    readonly count?: number
    readonly build?: ReadonlyArray<BuildType>
  }
  readonly newTestsFailedBuilds?: {
    readonly count?: number
    readonly build?: ReadonlyArray<BuildType>
  }
  readonly notCriticalBuilds?: {
    readonly count?: number
    readonly build?: ReadonlyArray<BuildType>
  }
}
export type ChangeBranchType = {
  readonly name: string
  readonly default?: boolean
}
export type BaseChangeType<V, C = BaseChangeType<V, {}>> = {
  readonly id: ChangeId
  readonly type?: 'SNAPSHOT_DEPENDENCY_VCS_CHANGE' | 'VCS_CHANGE'
  readonly username?: string
  readonly commiter?: {
    readonly users: {
      readonly user: readonly UserType[]
    }
  }
  readonly version?: RevisionId
  readonly parentRevisions?: {
    readonly item?: ReadonlyArray<RevisionId>
  }
  readonly mergedInfo?: {
    readonly changes?: {
      readonly change?: ReadonlyArray<C>
    }
    readonly branches?: {
      readonly branch?: ReadonlyArray<ChangeBranchType>
    }
  }
  readonly personal?: boolean
  readonly comment?: string
  readonly status?: ChangeStatusType
  readonly date?: string
  readonly webUrl?: string
  readonly isArtifactChange?: boolean
  readonly vcsRootInstance?: V | null | undefined
  readonly snapshotDependencyLink?: {
    readonly build?: BuildType
    readonly buildType?: BuildTypeType
    readonly buildTypeBranch?: string
  }
  readonly storesProjectSettings?: boolean
  readonly files?:
    | {
        readonly count?: number
        readonly file?: ReadonlyArray<ChangeFileType>
      }
    | null
    | undefined
}
export type NormalizedChangeType = BaseChangeType<VcsRootInstanceId, ChangeId>
export type VcsRootType = {
  readonly id?: VcsRootId
  readonly vcsName?: string
  readonly name?: string
}
export type NormalizedVcsRootInstanceType = {
  readonly id?: VcsRootInstanceId
  readonly 'vcs-root'?: VcsRootId
}
type VcsRootInstanceType = {
  readonly id?: VcsRootInstanceId
  readonly 'vcs-root'?: VcsRootType
}
type VcsLabelStatusType = 'SUCCESSFUL_SET' | 'FAILED'
export type VcsLabelType = {
  readonly text?: string
  readonly failureReason?: string
  readonly status?: VcsLabelStatusType
  readonly 'vcs-root-instance'?: {
    'vcs-root': {
      id: VcsRootId
    }
  }
}
export type ChangeType = BaseChangeType<VcsRootInstanceType>
type BaseRevisionType<V> = {
  version?: RevisionId
  vcsBranchName?: string
  'vcs-root-instance': V | null | undefined
}
export type NormalizedRevisionType = BaseRevisionType<VcsRootInstanceId>
type RevisionType = BaseRevisionType<VcsRootInstanceType>
type ChangesType<C> = InexactCountable & {
  readonly change?: ReadonlyArray<C>
}
type BaseBuildChangesType<C> = {
  readonly id: BuildId
  readonly changes?: ChangesType<C>
  readonly artifactDependencyChanges?: InexactCountable
}
export type BuildChangesType = BaseBuildChangesType<ChangeType>
export type BuildChangesRevisionsType = {
  readonly id: BuildId
  readonly versionedSettingsRevision?: RevisionType | null | undefined
  readonly revisions:
    | {
        revision: ReadonlyArray<RevisionType> | null | undefined
      }
    | null
    | undefined
  readonly vcsLabels: VcsLabelType[]
}
type DownloadInfo = {
  artifactInfo: {
    path: string
  }[]
}
export type DownloadInfoWithBuild = DownloadInfo & {
  build: {
    id: BuildId
  }
}
export type DownloadedArtifacts<T extends DownloadInfo = DownloadInfo> = {
  downloadedArtifacts: {
    downloadInfo: T[]
  }
}
type BaseBuildType<BT, C, R, A, DB> = InexactWebEntity &
  WebLinks &
  BaseBuildChangesType<C> &
  BuildArtifactsType &
  CompatibleAgentType &
  Partial<DownloadedArtifacts> & {
    readonly id: BuildId
    readonly agent?: A
    readonly plannedAgent?: A
    readonly comment?: CommentInfo
    readonly statusChangeComment?: CommentInfo | null | undefined
    readonly branchName?: string
    readonly defaultBranch?: boolean
    readonly buildType: BT
    readonly queuedDate?: string
    readonly startDate?: string
    readonly finishDate?: string
    readonly number?: string
    readonly status?: BuildStatusType
    readonly detachedFromAgent?: boolean
    readonly finishOnAgentDate?: string
    readonly state?: BuildStateType
    readonly canceledInfo?: CommentInfo | null | undefined
    readonly failedToStart?: boolean
    readonly statusText?: string
    readonly personal?: boolean
    readonly waitReason?: string
    readonly startEstimate?: string
    readonly finishEstimate?: string
    readonly user?: UserType
    readonly approvalInfo?: Pick<BuildApprovalType, 'status'>
    readonly 'running-info'?: RunningInfoType | null | undefined
    readonly tags?: {
      readonly tag?: ReadonlyArray<TagType>
    }
    readonly pinned?: boolean
    readonly pinInfo?: CommentInfo | null | undefined
    readonly 'artifact-dependencies'?: InexactCountable & {
      build?: ReadonlyArray<{
        id: BuildId
      }>
    }
    readonly 'snapshot-dependencies'?: InexactCountable & {
      build?: ReadonlyArray<VirtualDependencyType>
    }
    readonly versionedSettingsRevision?: R | null | undefined
    readonly revisions?:
      | {
          revision: ReadonlyArray<R> | null | undefined
        }
      | null
      | undefined
    readonly queuePosition?: number
    readonly triggered?: BaseBuildTriggeredType<BT>
    readonly history?: boolean
    readonly limitedChangesCount?: number
    readonly composite?: boolean
    readonly parallelized?: boolean
    readonly customization?: {
      changes?: {}
      parameters?: {}
      artifactDependencies?: {}
    }
    readonly testOccurrences?: Countable
    readonly delayedByBuild?: DB
  }
export type NormalizedBuildType = BaseBuildType<
  BuildTypeId,
  ChangeId,
  RevisionId,
  NormalizedAgentType,
  BuildId
>
export type BuildAgentType = WebEntityWithLinks & {
  readonly name: string
  readonly id?: AgentId
  readonly environment: EnvironmentType
  readonly typeId: AgentTypeId
  readonly connected?: boolean
  readonly pool?: AgentPoolType
}

type DelayedByBuildType = BaseBuildType<BuildTypeType, never, never, never, never>
export type BuildType = BaseBuildType<
  BuildTypeWithDetailsType,
  ChangeType,
  RevisionType,
  BuildAgentType,
  DelayedByBuildType
>
export type AgentPreviewType = BaseAgentPreviewType<{
  readonly id: BuildId
}> & {
  readonly environment: EnvironmentType
}
export type AgentType = BaseAgentType<BuildType> &
  AgentDetails & {
    readonly environment: EnvironmentType
  }
export enum Permission {
  RUN_BUILD = 'run_build',
  CANCEL_BUILD = 'cancel_build',
  CANCEL_ANY_PERSONAL_BUILD = 'cancel_any_personal_build',
  TAG_BUILD = 'tag_build',
  CHANGE_OWN_PROFILE = 'change_own_profile',
  PIN_UNPIN_BUILD = 'pin_unpin_build',
  AUTHORIZE_AGENT = 'authorize_agent',
  REORDER_BUILD_QUEUE = 'reorder_build_queue',
  COMMENT_BUILD = 'comment_build',
  ASSIGN_INVESTIGATION = 'assign_investigation',
  CHANGE_SERVER_SETTINGS = 'change_server_settings',
  VIEW_BUILD_CONFIGURATION_SETTINGS = 'view_build_configuration_settings',
  VIEW_BUILD_RUNTIME_DATA = 'view_build_runtime_data',
  MANAGE_SERVER_LICENSES = 'manage_server_licenses',
  MANAGE_BUILD_PROBLEMS = 'manage_build_problems',
  EDIT_PROJECT = 'edit_project',
  EDIT_VCS_MODIFICATION = 'edit_vcs_modification',
  CREATE_SUB_PROJECT = 'create_sub_project',
  VIEW_USER_PROFILE = 'view_user_profile',
  MANAGE_AGENT_POOLS = 'manage_agent_pools',
  PAUSE_ACTIVATE_BUILD_CONFIGURATION = 'pause_activate_build_configuration',
  CHANGE_CLEANUP_RULES = 'change_cleanup_rules',
  REMOVE_BUILD = 'remove_build',
  CHANGE_USER = 'change_user',
}

export type ChangesRequestOptions = {
  readonly withUpdatePager?: boolean
  readonly withFiles?: boolean
  readonly withStatus?: boolean
}

export type PoolPermissions = KeyValue<AgentPoolId, boolean>
export type Fetchable<T> = {
  readonly inited: boolean
  readonly loading: boolean
  readonly backgroundLoading: boolean
  readonly ready: boolean
  readonly error?: Error | null | undefined
  readonly data: T
  readonly request?: Promise<unknown> | null | undefined
  readonly receiveMeta?: SuccessReceiveMeta
}
export type FederationServerData = {
  authorized: boolean
  projects: Fetchable<ReadonlyArray<ProjectId>>
}
export type BuildTypePathItem = BuildTypeType & WebLinks
export type ProjectPathItem = NormalizedProjectType & WebLinks
export type PathItem = ProjectPathItem | BuildTypePathItem
export type FullPath = ReadonlyArray<PathItem>
export type CurrentUserType = $ReadOnly<
  UserType & {
    readonly properties?: Properties
  }
>
type ProjectStatusRequest = {
  readonly id: ProjectId
  readonly type: 'project'
  readonly branch?: BranchType | null | undefined
}
type BuildTypeStatusRequest = {
  readonly id: BuildTypeId
  readonly type: 'bt'
  readonly branch?: BranchType | null | undefined
}
export type StatusRequest = ProjectStatusRequest | BuildTypeStatusRequest
export const getProjectStatusRequest: (
  projectId: ProjectId,
  branch?: BranchType | null,
) => StatusRequest = (id, branch) => ({
  type: 'project',
  id,
  branch,
})
export const getBuildTypeStatusRequest: (
  buildTypeId: BuildTypeId,
  branch?: BranchType | null,
) => StatusRequest = (id, branch) => ({
  type: 'bt',
  id,
  branch,
})

export function getStatusRequest(
  type: 'project',
  id: ProjectId,
  branch?: BranchType | null,
): StatusRequest
export function getStatusRequest(
  type: 'bt',
  id: BuildTypeId,
  branch?: BranchType | null,
): StatusRequest
export function getStatusRequest(
  type: 'project' | 'bt',
  id: ProjectId | BuildTypeId,
  branch?: BranchType | null,
) {
  return type === 'project'
    ? getProjectStatusRequest(id, branch)
    : getBuildTypeStatusRequest(id, branch)
}

export type SystemProblem = {
  readonly id: SystemProblemId
  readonly type?: string | null | undefined
  readonly title?: string
  readonly description?: string | null | undefined
  readonly problemDetails?: string | null | undefined
  readonly webSpecifics?:
    | {
        readonly solutionLink: {
          readonly link: string
          readonly title: string | null | undefined
        }
        readonly caption: string
        readonly additionalData: string | null | undefined
      }
    | null
    | undefined
}
type FailedCounts = {
  readonly inLatestFinished?: number
  readonly inRunning?: ReadonlyArray<number>
}
export type ProjectOrBuildTypeStatusBase = {
  readonly status: 'SUCCESS' | 'FAILURE' | 'UNKNOWN'
  readonly pending?: number
  readonly pendingLimitExceeded?: boolean | null | undefined
  readonly running?: number
  readonly failing?: number
  readonly queued?: number
  readonly systemProblems?: ReadonlyArray<SystemProblem>
  readonly additionalProblems?: ReadonlyArray<SystemProblem>
  readonly investigation?: 'TAKEN' | 'FIXED' | 'GIVEN_UP' | 'NONE'
  readonly failedCounts?: FailedCounts
}
export type ProjectOrBuildTypeStatus =
  | (ProjectStatusRequest & ProjectOrBuildTypeStatusBase)
  | (BuildTypeStatusRequest & ProjectOrBuildTypeStatusBase)
export type ExpandState = {
  readonly type: 'project'
  readonly id: ProjectId
  readonly expandState: 'EXPANDED' | 'COLLAPSED'
}
export type Sorting = {
  readonly dimension: string
  readonly descending: boolean
}
export type PinHandlerArgs = {
  buildId: BuildId
  isPinned: boolean
  hasDependencies: boolean
  pinComment: string | null | undefined
  tagsContainerId: string
  buildTypeId?: BuildTypeId
}
export type PinHandlerArgsAjax = {
  pin: boolean
  pinComment: string | null | undefined
  tags: ReadonlyArray<TagType>
  applyToChainBuilds: boolean
}
export type TagsHandlerArgs = {
  buildId: BuildId
  joinedTags: string
  escapedPrivateTags?: string
  hasDependencies: boolean
  tagsContainerId: string
  buildTypeId?: BuildTypeId
}
export type CommentHandlerArgs = {
  buildId: BuildId
  comment: string | null | undefined
}
export type TitleId = '_all_projects_title_' | '_favorite_projects_title_'
export const FAVORITES_TITLE_ID: TitleId = '_favorite_projects_title_'
export const ALL_PROJECTS_TITLE_ID: TitleId = '_all_projects_title_'
export type FileType = {
  name: string
  size?: number | null | undefined
}
export type UrlExtension<Options extends {}> = {
  readonly name: string
  readonly kind?: string
  readonly serverUrl?: string
  readonly endpoint: string
  readonly options?: Options
}
export type RouteAvailabilityResponse = {
  readonly status: string
  readonly statusCode: number
  readonly message: string
}
type TabTitlesEnum = KeyValue<TabId, string>

export const MainUIProjectTabNamesEnum = {
  projectOverview: toTabId('projectOverview') as TabId,
  projectChangeLog: toTabId('projectChangeLog') as TabId,
  stats: toTabId('stats') as TabId,
  problems: toTabId('problems') as TabId,
  investigations: toTabId('investigations') as TabId,
  mutedProblems: toTabId('mutedProblems') as TabId,
  projectBuildChains: toTabId('projectBuildChains') as TabId,
  flakyTests: toTabId('flakyTests') as TabId,
}
export const MainUIBuildTypeTabNamesEnum = {
  buildTypeBranches: toTabId('buildTypeBranches') as TabId,
  buildTypeStatusDiv: toTabId('buildTypeStatusDiv') as TabId,
  buildTypeHistoryList: toTabId('buildTypeHistoryList') as TabId,
  buildTypeChangeLog: toTabId('buildTypeChangeLog') as TabId,
  buildTypeIssueLog: toTabId('buildTypeIssueLog') as TabId,
  buildTypeStatistics: toTabId('buildTypeStatistics') as TabId,
  compatibilityList: toTabId('compatibilityList') as TabId,
  pendingChangesDiv: toTabId('pendingChangesDiv') as TabId,
  buildTypeChains: toTabId('buildTypeChains') as TabId,
  buildTypeSettings: toTabId('buildTypeSettings') as TabId,
}
export const MainUIBuildTabNamesEnum = {
  queuedBuildOverviewTab: toTabId('queuedBuildOverviewTab') as TabId,
  queuedBuildCompatibilityTab: toTabId('queuedBuildCompatibilityTab') as TabId,
  buildResultsDiv: toTabId('buildResultsDiv') as TabId,
  testsInfo: toTabId('testsInfo') as TabId,
  buildChangesDiv: toTabId('buildChangesDiv') as TabId,
  dependencies: toTabId('dependencies') as TabId,
  buildParameters: toTabId('buildParameters') as TabId,
  buildIssues: toTabId('buildIssues') as TabId,
  artifacts: toTabId('artifacts') as TabId,
  perfmon: toTabId('perfmon') as TabId,
  buildLog: toTabId('buildLog') as TabId,
  buildUsedResources: toTabId('buildUsedResources') as TabId,
  dockerBuildInfo: toTabId('dockerBuildInfo') as TabId,
}

export const ProjectPageTabNamesEnum = {
  ...MainUIProjectTabNamesEnum,
  CHANGE_LOG: toTabId('changeLog') as TabId,
  OVERVIEW: toTabId('overview') as TabId,
  STATISTICS: toTabId('stats') as TabId,
  TEST_DETAILS: toTabId('testDetails') as TabId,
  INVESTIGATIONS: toTabId('investigations') as TabId,
}
export const BuildTypePageTabNamesEnum = {
  ...MainUIBuildTypeTabNamesEnum,
  CHANGE_LOG: toTabId('changeLog') as TabId,
  OVERVIEW: toTabId('overview') as TabId,
  STATISTICS: toTabId('buildTypeStatistics') as TabId,
  PENDING_CHANGES: toTabId('pendingChanges') as TabId,
}
export const BuildPageTabNamesEnum = {
  ...MainUIBuildTabNamesEnum,
  ARTIFACTS: toTabId('artifacts') as TabId,
  OVERVIEW: toTabId('overview') as TabId,
  TESTS: toTabId('tests') as TabId,
  CHANGES: toTabId('changes') as TabId,
  LOG: toTabId('log') as TabId,
  DEPENDENCIES: toTabId('dependencies') as TabId,
}
export const OverviewAgentTabNamesEnum = {
  SUMMARY: toTabId('summary') as TabId,
  HISTORY: toTabId('history') as TabId,
  COMPATIBLE_CONFIGUTATIONS: toTabId('compatible-configurations') as TabId,
  BUILD_RUNNERS: toTabId('build-runners') as TabId,
  LOGS: toTabId('logs') as TabId,
  PARAMETERS: toTabId('parameters') as TabId,
}
export const OverviewAgentsTabNamesEnum = {
  ALL_AGENTS: toTabId('all-agents') as TabId,
  PARAMETERS_REPORT: toTabId('agentsParametersReport') as TabId,
  AGENTS_STATISTICS: toTabId('agentsStatisticsTab') as TabId,
  AGENT_PUSH: toTabId('agent.push') as TabId,
}
export const OverviewCloudImageTabNamesEnum = {
  SUMMARY: toTabId('summary') as TabId,
  HISTORY: toTabId('history') as TabId,
  COMPATIBLE_CONFIGUTATIONS: toTabId('compatible-configurations') as TabId,
  BUILD_RUNNERS: toTabId('build-runners') as TabId,
  AGENT_PARAMETERS: toTabId('agent-parameters') as TabId,
}
export const OverviewAgentPoolTabNamesEnum = {
  AGENTS: toTabId('agents') as TabId,
  PROJECTS: toTabId('projects') as TabId,
  CLOUD_IMAGES: toTabId('cloud-images') as TabId,
}

export const ProjectPageTabTitlesEnum: TabTitlesEnum = {
  [ProjectPageTabNamesEnum.OVERVIEW]: 'Overview',
}
export const BuildTypePageTabTitlesEnum: TabTitlesEnum = {
  [BuildTypePageTabNamesEnum.OVERVIEW]: 'Overview',
  [BuildTypePageTabNamesEnum.PENDING_CHANGES]: 'Pending Changes',
}
export const BuildPageTabTitlesEnum: TabTitlesEnum = {
  [BuildPageTabNamesEnum.ARTIFACTS]: 'Artifacts',
  [BuildPageTabNamesEnum.OVERVIEW]: 'Overview',
  [BuildPageTabNamesEnum.TESTS]: 'Tests',
  [BuildPageTabNamesEnum.CHANGES]: 'Changes',
  [BuildPageTabNamesEnum.LOG]: 'Build Log',
  [BuildPageTabNamesEnum.DEPENDENCIES]: 'Dependencies',
}

export const MainUIAgentTabNamesEnum: KeyValue<TabId, TabId> = {
  agentSummary: OverviewAgentTabNamesEnum.SUMMARY,
  agentHistory: OverviewAgentTabNamesEnum.HISTORY,
  agentCompatibleConfigurations: OverviewAgentTabNamesEnum.COMPATIBLE_CONFIGUTATIONS,
  agentBuildRunners: OverviewAgentTabNamesEnum.BUILD_RUNNERS,
  agentLogs: OverviewAgentTabNamesEnum.LOGS,
  agentParameters: OverviewAgentTabNamesEnum.PARAMETERS,
}
export const MainUICloudImageTabNamesEnum: KeyValue<string, TabId> = {
  agentTypeSummary: OverviewCloudImageTabNamesEnum.SUMMARY,
  agentHistory: OverviewCloudImageTabNamesEnum.HISTORY,
  agentCompatibleConfigurations: OverviewCloudImageTabNamesEnum.COMPATIBLE_CONFIGUTATIONS,
  agentBuildRunners: OverviewCloudImageTabNamesEnum.BUILD_RUNNERS,
  agentParameters: OverviewCloudImageTabNamesEnum.AGENT_PARAMETERS,
}

export const SakuraUIEnabledEnum = {
  ENABLE_ALL: 'enableAll',
  ENABLE_DEFAULT: 'enableDefault',
  DISABLE_ALL: 'disableAll',
  DISABLE_DEFAULT: 'disableDefault',
}
export enum DialogType {
  COMMENT = 'comment',
  TAGS = 'tags',
  PIN = 'pin',
  EDIT_PIN_COMMENT = 'editPinComment',
  COMPARE = 'compare',
  EDIT_SIDEBAR = 'editSidebar',
  INVESTIGATION_HISTORY = 'investigationHistory',
  METADATA_DETAIL_POPUP = 'metadataDetailPopup',
  BUILD_STATUS_WIDGET = 'buildStatusWidget',
}
export type AgentFilter =
  | {
      id: AgentId
      typeId?: null
    }
  | {
      typeId: AgentTypeId
      id?: null
    }
export type ProjectOrBuildTypeNode =
  | {
      readonly nodeType: 'all'
      readonly id?: null
      group?: NodeGroupType
    }
  | {
      readonly nodeType: 'project'
      readonly id: ProjectId
      fullPath?: string
      group?: NodeGroupType
      readonly parentId?: ProjectId | null | undefined
      readonly internalId?: ProjectInternalId | null | undefined
    }
  | {
      readonly nodeType: 'bt'
      readonly id: BuildTypeId
      fullPath?: string
      group?: NodeGroupType
      readonly parentId?: ProjectId | null | undefined
      readonly internalId?: BuildTypeInternalId | null | undefined
    }
  | {
      readonly nodeType: 'template'
      readonly id: BuildTypeId
      fullPath?: string
      group?: NodeGroupType
    }
export const cloneNode = (node: ProjectOrBuildTypeNode): ProjectOrBuildTypeNode => {
  switch (node.nodeType) {
    case 'all':
      return {
        nodeType: 'all',
      }

    case 'project':
      return {
        nodeType: 'project',
        id: node.id,
        fullPath: node.fullPath,
        group: node.group,
      }

    case 'bt':
      return {
        nodeType: 'bt',
        id: node.id,
        fullPath: node.fullPath,
        group: node.group,
      }

    default:
      return {
        nodeType: 'all',
      }
  }
}
export type ActiveEntityProps = {
  readonly projectId?: ProjectId | null | undefined
  readonly buildTypeId?: BuildTypeId | null | undefined
  readonly buildId?: BuildId | null | undefined
  readonly agentId?: AgentId | null | undefined
  readonly agentPoolId?: AgentPoolId | null | undefined
  readonly agentTypeId?: AgentTypeId | null | undefined
  readonly testId?: TestId | null | undefined
  readonly isFavorites?: boolean
}
export type ActiveEntityURLProps = Omit<ActiveEntityProps, 'buildId'> & {
  readonly buildId?: (BuildId | null | undefined) | string
  readonly isAllProjects?: boolean
}
export const getBuildTypeFilter: (arg0: BuildTypeId) => ProjectOrBuildTypeNode = memoize(
  (id: BuildTypeId): ProjectOrBuildTypeNode => ({
    nodeType: 'bt',
    id,
  }),
)
export const getProjectFilter: (projectId: ProjectId) => ProjectOrBuildTypeNode = memoize(
  (id: ProjectId): ProjectOrBuildTypeNode => ({
    nodeType: 'project',
    id,
  }),
)
export const getProjectBuildTypeFilter: (
  props: ActiveEntityProps,
) => ProjectOrBuildTypeNode | null = ({projectId, buildTypeId}) => {
  if (buildTypeId != null) {
    return getBuildTypeFilter(buildTypeId)
  }

  if (projectId != null) {
    return getProjectFilter(projectId)
  }

  return null
}
export const STAR_TAG = '.teamcity.star'
export const STARRED_LOCATOR = `tag:(private:true,owner:current,condition:(value:${STAR_TAG},matchType:equals,ignoreCase:false))`
export const STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER = `defaultFilter:false,${STARRED_LOCATOR}`
export const stateLocators = {
  all: '',
  queued: 'state:queued',
  running: 'state:running',
  runningAndFinished: 'state:(running:true,finished:true)',
  failedToStart: 'failedToStart:true',
  canceled: 'canceled:true',
  successful: 'status:SUCCESS',
  failed: 'status:FAILURE,failedToStart:false,canceled:false',
  pinned: 'pinned:true',
  starred: STARRED_LOCATOR,
}
export type BuildState = $Keys<typeof stateLocators>
export type ReduxLocatorOptions = {
  locator?: string | null | undefined
  locatorReady?: boolean | null | undefined
  buildState?: BuildState | null | undefined
  projectBuildtype?: ProjectOrBuildTypeNode | null | undefined
  useRawLocator?: boolean | null | undefined
  agent?: AgentFilter | null | undefined
  agentPattern?: string | null | undefined
  tag?: string | null | undefined
  updating?: boolean | null | undefined
}
export type LocatorOptions = ReduxLocatorOptions & {
  baseLocator?: string | null | undefined
  withRunningAndQueued?: boolean | null | undefined
  branch?: BranchType | null | undefined
  pickHistory?: boolean | null | undefined
  runningAndFinished?: boolean
  onlyFavorites?: boolean
  runningCount?: number
  queuedCount?: number
  finishedCount?: number
}
export type BuildLineLayoutType = {
  readonly showAgent?: boolean
  readonly showDuration?: boolean
  readonly showExactDuration?: boolean
  readonly showBranch?: boolean
  readonly showComment?: boolean
  readonly showPath?: boolean
  readonly showTags?: boolean
  readonly artifactDependenciesMode?: boolean
}
export type DslOptionType = {
  readonly version: string
  readonly portable: boolean
  readonly current?: boolean
}
export type DslOptions = {
  readonly show: boolean
  readonly version: string
  readonly portable: boolean
}
export type ArtifactExtension = {
  readonly icon: {
    readonly name: string
  }
  readonly title: string
  readonly hrefs: Readonly<Record<string, string>>
}
export type ArtifactExtensions = KeyValue<string, Fetchable<ArtifactExtension | null>>
export type RingSelectAnchorProps = CustomAnchorProps
export const InvestigationStates = {
  TAKEN: 'TAKEN' as 'TAKEN',
  FIXED: 'FIXED' as 'FIXED',
  GIVEN_UP: 'GIVEN_UP' as 'GIVEN_UP',
  NONE: 'NONE' as 'NONE',
}
export const ResolutionType = {
  WHEN_FIXED: 'whenFixed' as 'whenFixed',
  MANUALLY: 'manually' as 'manually',
  AT_TIME: 'atTime' as 'atTime',
}
export type InvestigationType = {
  readonly id: InvestigationId
  readonly state: $Values<typeof InvestigationStates>
  readonly assignee: UserType
  readonly resolution: {
    readonly type?: $Values<typeof ResolutionType>
  }
  readonly scope?: {
    readonly project?: {
      readonly id: ProjectId
      readonly name: string
    }
    readonly buildTypes?: {
      readonly buildType: Array<{
        readonly id: BuildTypeId
        readonly name: string
      }>
    }
  }
  readonly assignment: {
    readonly timestamp: string
    readonly text?: string
    readonly user?: UserType
  }
}
export const getInvestigationBuildTypeId = ({id}: InvestigationType): string =>
  id.replace(/buildType:\(id:(.*)\)/g, '$1')
export type BuildStats = WebLinks & {
  id: BuildId
  status: BuildStatusType
  state?: BuildStateType
  queuedDate?: string
  startDate?: string
  finishDate?: string
  timeInQueue: number
  duration: number
}
export type FederationServerType = {
  url: FederationServerId
}
export type BranchWithBuilds = BranchType & {
  buildTypeId: BuildTypeId
  builds: {
    build: ReadonlyArray<BuildType>
  }
}
export type NormalizedBranchWithBuilds = BranchType & {
  builds: {
    build: ReadonlyArray<BuildId>
  }
}
export type Tab = {
  readonly id: TabId
  readonly title: string
  readonly builtIn?: boolean
  readonly url?: string
  readonly href?: string
}
export type TabParams = {
  readonly buildId?: BuildId | null | undefined
  readonly buildTypeId?: BuildTypeId | null | undefined
  readonly projectId?: ProjectId | null | undefined
  readonly changeId?: ChangeId | null | undefined
  readonly isEdit?: boolean
  readonly agentId?: AgentId
  readonly agentTypeId?: AgentTypeId
  readonly blockUpdate?: boolean
  readonly branch?: BranchType | null | undefined
  readonly personal?: boolean
}
export type TabParamsKey = string
export const AGENTS_TAB_PARAMS_KEY: TabParamsKey = 'agentsDashboard=true'
export type Enhancer<AddProps, OwnProps> = HOC<OwnProps & AddProps, OwnProps>
export type ServerInfo = {
  buildNumber?: string | number
  licenseModeName?: string
  licenseModeDetails?: string
  daysToLicenseExpiration?: number | null | undefined
  version?: string
  nodeId?: string
}
export type SnapshotDependenciesIDListType = KeyValue<BuildId, ReadonlyArray<BuildId>>
export type VirtualDependencyType = {
  readonly id: BuildId
  readonly status?: BuildStatusType
  readonly state?: BuildStateType
  readonly buildType?: BuildTypeType | undefined
}
export type VirtualDependenciesHashType = KeyValue<BuildId, Array<VirtualDependencyType>>
export type FetchSingleBuildDataOptions = {
  withRunningInfo?: boolean
  withQueuedInfo?: boolean
  withBuildTypeDetails?: boolean
  withSnapshotDependencies?: boolean
  withVirtualDependencies?: boolean
  withTestOccurrencesCount?: boolean
}
export type MuteType = {
  readonly id?: MuteId
  readonly assignment: {
    readonly timestamp: string
    readonly text?: string
    readonly user?: UserType
  }
  readonly resolution?: {
    readonly type: $Values<typeof ResolutionType>
    readonly time?: string
  }
  readonly scope: {
    readonly project?: {
      readonly id: ProjectId
      readonly name: string
    }
    readonly buildTypes?: {
      readonly buildType: Array<{
        readonly id: BuildTypeId
        readonly name: string
      }>
    }
  }
}
export type LicensingDataType = {
  readonly maxAgents?: number
  readonly agentsLeft: number
}
export type Dialog = {
  readonly id?: string
  readonly type?: string
  readonly opened?: boolean
  readonly processing?: boolean
  readonly error?: boolean
}
export type OperationResult = {
  readonly related: {
    readonly build?: Partial<BuildType> & {
      id: BuildId
    }
  }
}

export type BuildTypeHierarchyType =
  | {
      readonly type: 'PROJECT'
      readonly name: string | null | undefined
      readonly id: ProjectId
      readonly children: ReadonlyArray<BuildTypeHierarchyType>
    }
  | {
      readonly type: 'BUILD_TYPE'
      readonly name: string | null | undefined
      readonly id: BuildTypeId
    }

export type RingTag = RingTagType

export enum KillOperationKind {
  CANCEL = 1,
  STOP = 2,
  REMOVE = 3,
}
