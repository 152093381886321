import {BuildTypeHierarchyType} from '../../types'

import type {TestOccurrencesCountsType} from './Tests.types'

export const REQUEST_TEST_OCCURRENCES_COUNTS: 'REQUEST_TEST_OCCURRENCES_COUNTS' =
  'REQUEST_TEST_OCCURRENCES_COUNTS'
export const RECEIVE_TEST_OCCURRENCES_COUNTS: 'RECEIVE_TEST_OCCURRENCES_COUNTS' =
  'RECEIVE_TEST_OCCURRENCES_COUNTS'

export const REQUEST_TEST_BUILD_TYPE_HIERARCHY: 'REQUEST_TEST_BUILD_TYPE_HIERARCHY' =
  'REQUEST_TEST_BUILD_TYPE_HIERARCHY'
export const RECEIVE_TEST_BUILD_TYPE_HIERARCHY: 'RECEIVE_TEST_BUILD_TYPE_HIERARCHY' =
  'RECEIVE_TEST_BUILD_TYPE_HIERARCHY'

export type TestsAction =
  | {
      type: typeof REQUEST_TEST_OCCURRENCES_COUNTS
      readonly locator: string
    }
  | {
      type: typeof RECEIVE_TEST_OCCURRENCES_COUNTS
      readonly locator: string
      readonly data?: TestOccurrencesCountsType
      readonly error?: Error
    }
  | {
      type: typeof REQUEST_TEST_BUILD_TYPE_HIERARCHY
      readonly locator: string
    }
  | {
      type: typeof RECEIVE_TEST_BUILD_TYPE_HIERARCHY
      readonly locator: string
      readonly data?: BuildTypeHierarchyType
      readonly error?: Error
    }
