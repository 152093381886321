import {
  AsyncThunk,
  createAsyncThunk,
  createReducer,
  Draft,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
} from '@reduxjs/toolkit'
import {AsyncThunkFulfilledActionCreator} from '@reduxjs/toolkit/src/createAsyncThunk'
import deepEqual from 'fast-deep-equal'
import {original} from 'immer'
import * as Redux from 'redux'

import {AppDispatch, FetchAction} from '../actions/types'
import type {Fetchable, RequestOptionsParams, SuccessReceiveMeta} from '../types'

import {BS} from '../types/BS_types'

import {State} from './types'
import {toolkitKeyValueReducer} from './utils'

type PendingMeta = {
  invalidate?: boolean
  isBackground?: boolean
  request?: Promise<unknown>
}

export type AsyncThunkConfig = {
  state: State
  dispatch: AppDispatch
  pendingMeta: PendingMeta
  serializedErrorType: Error | null
}

export const createFetchAction = <D, P>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<D, P, AsyncThunkConfig>,
  options?: Partial<AsyncThunkOptions<P, AsyncThunkConfig>>,
) => {
  let resolveRequest: (value: unknown) => unknown
  const request = new Promise(resolve => {
    resolveRequest = resolve
  })
  const thunk = createAsyncThunk<D, P, AsyncThunkConfig>(typePrefix, payloadCreator, {
    serializeError: e => (e instanceof Error ? e : null),
    getPendingMeta: (...args) => ({request, ...options?.getPendingMeta?.(...args)}),
    ...options,
  })
  return Object.assign(
    (arg: P) => (dispatch: AppDispatch) => {
      const result = dispatch(thunk(arg))
      resolveRequest(
        result.unwrap().catch(e => {
          BS && BS.Log.error('Something went wrong: ', e)
        }),
      )
      return result
    },
    thunk,
  )
}

export const toolkitFetchable = <T, D, P>(
  thunk: AsyncThunk<D, P, AsyncThunkConfig>,
  defaultState: T,
  dataReducer: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<D, P, AsyncThunkConfig>>,
  ) => T,
  getReceiveMeta: (arg: P) => SuccessReceiveMeta | undefined = () => undefined,
) =>
  createReducer(
    (): Fetchable<T> => ({
      data: defaultState,
      loading: false,
      backgroundLoading: false,
      error: null,
      ready: false,
      inited: false,
      receiveMeta: {},
      request: null,
    }),
    builder => {
      builder.addCase(thunk.pending, (state, action) => {
        const {invalidate, isBackground, request} = action.meta
        if (invalidate) {
          state.data = defaultState as Draft<T>
          state.ready = false
        }
        state.loading = true
        state.backgroundLoading = isBackground ?? false
        state.inited = true
        if (request != null) {
          state.request = request
        }
      })
      builder.addCase(thunk.fulfilled, (state, action) => {
        const originalData = original(state)!.data as T
        const data = dataReducer(originalData, action)
        if (!deepEqual(data, originalData)) {
          state.data = data as Draft<T>
        }
        state.loading = false
        state.backgroundLoading = false
        state.error = null
        state.ready = true
        state.inited = true
        const receiveMeta = getReceiveMeta(action.meta.arg)
        if (state.receiveMeta != null) {
          Object.assign(state.receiveMeta, receiveMeta)
        } else {
          state.receiveMeta = receiveMeta
        }
      })
      builder.addCase(thunk.rejected, (state, action) => {
        state.loading = false
        state.backgroundLoading = false
        state.error = action.error
        state.ready = true
        state.inited = true
      })
    },
  )

export const keyValueFetchable = <T, D, P>(
  getKey: (arg: P) => string,
  thunk: AsyncThunk<D, P, AsyncThunkConfig>,
  defaultState: T,
  dataReducer: (
    prevState: T,
    action: ReturnType<AsyncThunkFulfilledActionCreator<D, P, AsyncThunkConfig>>,
  ) => T,
  getReceiveMeta?: (arg: P) => SuccessReceiveMeta | undefined,
) =>
  toolkitKeyValueReducer(
    (action: FetchAction<P>) => getKey(action.meta.arg),
    toolkitFetchable(thunk, defaultState, dataReducer, getReceiveMeta),
    [thunk.pending, thunk.fulfilled, thunk.rejected],
  )

export default function fetchable<T, D>(
  requestAction: string,
  receiveAction: string,
  defaultState: T,
  dataSelector: (
    arg0: {
      readonly data: D
      readonly mergeData?: boolean
      readonly offset?: number
      readonly loadedLessThanRequested?: boolean
      readonly requestOptions?: RequestOptionsParams
    },
    arg1: T,
  ) => T,
) {
  return Redux.combineReducers<Fetchable<T>>({
    data(state = defaultState, action) {
      switch (action.type) {
        case requestAction:
          return action.invalidate ? defaultState : state

        case receiveAction:
          if (action.error != null) {
            return state
          }

          const data = dataSelector(action, state)

          if (deepEqual(data, state)) {
            return state
          }

          return data

        default:
          return state
      }
    },

    loading(state = false, action) {
      switch (action.type) {
        case requestAction:
          return true

        case receiveAction:
          return false

        default:
          return state
      }
    },

    backgroundLoading(state = false, action) {
      switch (action.type) {
        case requestAction:
          return action.isBackground ?? false

        case receiveAction:
          return false

        default:
          return state
      }
    },

    error(state = null, action) {
      if (action.type === receiveAction) {
        return action.error ?? null
      } else {
        return state
      }
    },

    ready(state = false, action) {
      switch (action.type) {
        case requestAction:
          return action.invalidate === true ? false : state

        case receiveAction:
          return true

        default:
          return state
      }
    },

    inited(state = false, action) {
      switch (action.type) {
        case requestAction:
        case receiveAction:
          return true

        default:
          return state
      }
    },

    request(state = null, action) {
      switch (action.type) {
        case requestAction:
          return action.request ?? state

        default:
          return state
      }
    },

    receiveMeta(state = {}, action) {
      switch (action.type) {
        case receiveAction: {
          if (action.error == null) {
            const {receiveMeta} = action
            return receiveMeta ? {...state, ...receiveMeta} : state
          }

          return state
        }

        default:
          return state
      }
    },
  })
}
