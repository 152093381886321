import {normalize, schema} from 'normalizr'
import {$ReadOnly} from 'utility-types'

import type {ProblemOccurrenceType} from '../components/BuildProblems/BuildProblems.types'
import type {
  TestOccurrenceInvocationsType,
  TestOccurrenceMetadataType,
  TestOccurrencesCountsType,
  TestOccurrenceType,
} from '../components/Tests/Tests.types'
import {
  ChangeBranchType,
  ChangeStatusType,
  CloudInstance,
  EntityPauseComment,
  getBuildTypeStatusRequest,
  getInvestigationBuildTypeId,
  stringifyId,
  Template,
  VirtualDependenciesHashType,
} from '../types'
import type {
  AgentId,
  AgentPoolId,
  AgentPoolType,
  AgentPreviewType,
  AgentType,
  AgentTypeId,
  BranchWithBuilds,
  BuildArtifactsType,
  BuildChangesRevisionsType,
  BuildChangesType,
  BuildId,
  BuildType,
  BuildTypeId,
  BuildTypeType,
  BuildTypeWithDetailsType,
  ChangeId,
  ChangeType,
  CloudImageResponseType,
  CloudImagesHashType,
  CompatibleAgentType,
  EntityArchivedSubprojectsIds,
  EntityDescription,
  EntityProjectsIdsList,
  ExpandState,
  FederationServerId,
  FederationServerType,
  Inexact,
  InexactEntityParameters,
  InvestigationType,
  MuteType,
  NormalizedAgentPreviewType,
  NormalizedAgentType,
  NormalizedBranchWithBuilds,
  NormalizedBuildType,
  NormalizedChangeType,
  NormalizedProjectType,
  NormalizedRevisionType,
  ProblemOccurrenceId,
  ProjectId,
  ProjectOrBuildTypeStatus,
  ProjectType,
  ProjectWithDetailsType,
  RequestOptionsParams,
  RevisionId,
  SnapshotDependenciesIDListType,
  StatusRequest,
  TestOccurrenceId,
  VcsLabelType,
  VcsRootId,
  VcsRootInstanceId,
  NormalizedVcsRootInstanceType,
  VcsRootType,
  WebLinks,
  CommentInfo,
} from '../types'
import {stringifyBranch} from '../utils/branchNames'
import {emptyArray, getEmptyHash} from '../utils/empty'
import type {KeyValue} from '../utils/object'

type EntityType = BuildTypeType | ProjectType

const getMainPart = (entity: EntityType & WebLinks & InexactEntityParameters) => {
  const {links, parameters, description, projects, pauseComment, ...restProps} = entity
  return restProps
}

const getLinks = (entity: WebLinks): WebLinks => ({
  links: entity.links,
})

const getTestMetadata = (
  entity: TestOccurrenceType,
): TestOccurrenceMetadataType | null | undefined => entity.metadata

const getDescription = (entity: EntityDescription): EntityDescription => ({
  description: entity.description,
})

const getPauseComment = (entity: {pauseComment?: CommentInfo}) => ({
  pauseComment: entity.pauseComment,
})

const getArchivedSubprojectsIds = (entity: EntityArchivedSubprojectsIds): EntityProjectsIdsList =>
  entity.projects?.project ?? []

const getParameters = (entity: InexactEntityParameters): InexactEntityParameters => ({
  parameters: entity.parameters,
})

const buildType = new schema.Entity(
  'buildTypes',
  {},
  {
    processStrategy: getMainPart,
  },
)
const vcsRoots = new schema.Entity('vcsRoots')
const vcsRootInstances = new schema.Entity('vcsRootInstances', {
  'vcs-root': vcsRoots,
})
const changes = new schema.Entity('changes', {
  vcsRootInstance: vcsRootInstances,
})

const changesStatus = new schema.Entity(
  'changesStatus',
  {},
  {
    processStrategy: (entity: ChangeType): ChangeStatusType | null | undefined => entity.status,
  },
)
const modificationsOfChanges = new schema.Entity('modificationsOfChanges', {
  mergedInfo: {
    changes: {
      change: [changes],
    },
  },
})
const changesBranches = new schema.Entity(
  'changesBranches',
  {},
  {
    processStrategy: (entity: ChangeType): ReadonlyArray<ChangeBranchType> | null | undefined =>
      entity?.mergedInfo?.branches?.branch,
  },
)
const revisions = new schema.Entity(
  'revisions',
  {
    'vcs-root-instance': vcsRootInstances,
  },
  {
    idAttribute: ({version}: NormalizedRevisionType) => stringifyId(version),
  },
)
const vcsLabels = new schema.Entity(
  'vcsLabels',
  {},
  {
    idAttribute: (_, build) => stringifyId(build?.id),
    processStrategy: labels => (labels ? Object.keys(labels).map(key => labels[key]) : []),
  },
)

const processAgentStrategy = ({environment, ...rest}: AgentType) => ({...environment, ...rest})

const delayedByBuild = new schema.Entity('delayedByBuilds', {
  buildType,
})

const build = new schema.Entity(
  'builds',
  {
    buildType,
    versionedSettingsRevision: revisions,
    triggered: {
      buildType,
    },
    changes: {
      change: [changes],
    },
    revisions: {
      revision: [revisions],
    },
    vcsLabels,
    delayedByBuild,
  },
  {
    processStrategy: ({agent, ...rest}) => ({
      ...rest,
      ...(agent
        ? {
            agent: processAgentStrategy(agent),
          }
        : {}),
    }),
  },
)
const buildArtifacts = new schema.Entity('buildArtifacts')
const compatibleAgents = new schema.Entity('compatibleAgents')
const cloudImage = new schema.Entity(
  'cloudImages',
  {},
  {
    idAttribute: 'agentTypeId',
    processStrategy: ({instances, ...rest}) => ({
      ...rest,
      agentIds: instances.cloudInstance.reduce(
        (result: AgentId[], {agent}: CloudInstance) =>
          agent?.id != null ? result.concat([agent.id]) : result,
        [],
      ),
    }),
  },
)
const agentCloudImage = new schema.Entity(
  'cloudImage',
  {},
  {
    idAttribute: ({agent}) => agent?.typeId,
    processStrategy: ({image: {name, profile}, agent}) => ({
      name,
      agentTypeId: agent?.typeId,
      agentPoolId: agent?.pool?.id,
      agentIds: getEmptyHash(),
      profile: {
        id: profile.id,
        name: profile.name,
        projectId: profile.project.id,
      },
    }),
  },
)
const agent = new schema.Entity(
  'agents',
  {
    build,
    cloudInstance: agentCloudImage,
  },
  {
    processStrategy: processAgentStrategy,
  },
)

const processAgentPreviewStrategy = ({environment, ...rest}: AgentPreviewType) => ({
  ...rest,
  osType: environment?.osType,
  ...('build' in rest
    ? {
        build: rest.build?.id,
      }
    : {}),
})

const agentPreview = new schema.Entity(
  'agentPreviews',
  {},
  {
    processStrategy: processAgentPreviewStrategy,
  },
)
const overviewBuildType = new schema.Entity('overviewBuildTypes')
const federationServer = new schema.Entity(
  'federationServers',
  {},
  {
    idAttribute: 'url',
  },
)
const problemOccurrences = new schema.Entity('problemOccurrences')
const testOccurrences = new schema.Entity(
  'testOccurrences',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): TestOccurrenceType => {
      const {firstFailed, mute, test, ...restTestOccurrencesProps} = entity
      const {mutes, investigations, ...testTestProps} = test ?? {}
      return {...restTestOccurrencesProps, test: testTestProps}
    },
  },
)
const testOccurrencesNewFailure = new schema.Entity(
  'testOccurrencesNewFailure',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): boolean | null | undefined => entity.newFailure,
  },
)
const testOccurrencesInvocations = new schema.Entity('testOccurrencesInvocations', {
  invocations: {
    testOccurrence: [testOccurrences],
  },
})
const testOccurrencesMetadataCount = new schema.Entity(
  'testOccurrencesMetadataCount',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): number | null | undefined =>
      entity.metadata?.count,
  },
)
const testOccurrencesFirstFailed = new schema.Entity(
  'testOccurrencesFirstFailed',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): TestOccurrenceType | null | undefined =>
      entity.firstFailed,
  },
)
const testOccurrencesNextFixed = new schema.Entity(
  'testOccurrencesNextFixed',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): TestOccurrenceType | null | undefined =>
      entity.nextFixed,
  },
)
const testOccurrencesRunOrder = new schema.Entity(
  'testOccurrencesRunOrder',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): string | null | undefined => entity.runOrder,
  },
)
const testOccurrencesInvestigations = new schema.Entity(
  'testOccurrencesInvestigations',
  {},
  {
    processStrategy: (
      entity: TestOccurrenceType,
    ): ReadonlyArray<InvestigationType> | null | undefined =>
      entity.test?.investigations?.investigation,
  },
)
const testOccurrencesCurrentlyMutes = new schema.Entity(
  'testOccurrencesCurrentlyMutes',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): ReadonlyArray<MuteType> | null | undefined =>
      entity.test?.mutes?.mute,
  },
)
const testOccurrencesInvocationsCounters = new schema.Entity(
  'testOccurrencesInvocationsCounters',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): TestOccurrencesCountsType | null | undefined =>
      entity.invocations?.testCounters,
  },
)
const testOccurrencesMute = new schema.Entity(
  'testOccurrencesMute',
  {},
  {
    processStrategy: (entity: TestOccurrenceType): MuteType | null | undefined => entity.mute,
  },
)
const testOccurrencesBuild = new schema.Entity(
  'testOccurrences',
  {
    build,
  },
  {},
)
const testOccurrenceMetadata = new schema.Entity(
  'testOccurrenceMetadata',
  {},
  {
    processStrategy: getTestMetadata,
  },
)
const overviewProject = new schema.Entity('overviewProjects', {
  buildTypes: {
    buildType: [overviewBuildType],
  },
})
export type StatusKey = string
export const getStatusKey = (request: StatusRequest | null | undefined): StatusKey =>
  request
    ? [
        request.type,
        request.id,
        request.branch ? stringifyBranch(request.branch) : '',
        request.branch?.default === true ? 'default' : '',
      ]
        .filter(Boolean)
        .join(':')
    : ''
export const stringifyKey = (key: StatusKey): string => key
const branch = new schema.Entity(
  'branches',
  {
    builds: {
      build: [build],
    },
  },
  {
    idAttribute: item => getStatusKey(getBuildTypeStatusRequest(item.buildTypeId, item)),
    mergeStrategy: (branchA, branchB) => ({
      ...branchA,
      builds: {
        build: [...branchA.builds.build, ...branchB.builds.build],
      },
    }),
  },
)
const buildTypeStatus = new schema.Entity(
  'buildTypeStatuses',
  {},
  {
    idAttribute: getStatusKey,
  },
)
const overviewExpandState = new schema.Entity('overviewExpandState')
const buildTypeLinks = new schema.Entity(
  'buildTypeLinks',
  {},
  {
    processStrategy: getLinks,
  },
)
const buildTypeDescription = new schema.Entity(
  'buildTypeDescription',
  {},
  {
    processStrategy: getDescription,
  },
)

const buildTypePauseComment = new schema.Entity(
  'buildTypePauseComment',
  {},
  {
    processStrategy: getPauseComment,
  },
)

const buildTypeParameters = new schema.Entity(
  'buildTypeParameters',
  {},
  {
    processStrategy: getParameters,
  },
)
const projectLinks = new schema.Entity(
  'projectLinks',
  {},
  {
    processStrategy: getLinks,
  },
)
const projectDescription = new schema.Entity(
  'projectDescription',
  {},
  {
    processStrategy: getDescription,
  },
)
const projectArchivedSubprojectsIds = new schema.Entity(
  'projectArchivedSubprojectsIds',
  {},
  {
    processStrategy: getArchivedSubprojectsIds,
  },
)
const projectParameters = new schema.Entity(
  'projectParameters',
  {},
  {
    processStrategy: getParameters,
  },
)
const projectTemplates = new schema.Entity(
  'projectTemplates',
  {},
  {
    processStrategy: (project: ProjectType) => project.templates?.buildType ?? emptyArray,
  },
)
const projectWithBuildTypes = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildType],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const projectWithBuildTypesLink = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildTypeLinks],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const projectWithBuildTypesDescription = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildTypeDescription],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const projectWithBuildTypesParameters = new schema.Entity(
  'projects',
  {
    buildTypes: {
      buildType: [buildTypeParameters],
    },
  },
  {
    processStrategy: getMainPart,
  },
)
const agentPool = new schema.Entity('agentPools')
const buildsWithBuildTypesLink = new schema.Entity('builds', {
  buildType: buildTypeLinks,
})
const agentWithBuildsAndBuildTypeLinks = new schema.Entity(
  'agents',
  {
    build: buildsWithBuildTypesLink,
  },
  {
    processStrategy: processAgentStrategy,
  },
)
const investigation = new schema.Entity('investigations', undefined, {
  idAttribute: getInvestigationBuildTypeId,
})
export type Entities = {
  builds: KeyValue<BuildId, NormalizedBuildType | null>
  delayedByBuilds?: KeyValue<BuildId, NormalizedBuildType | null>
  buildArtifactDependencies: KeyValue<
    BuildId,
    {
      delivered: boolean
      downloaded: boolean
    }
  >
  buildArtifacts: KeyValue<BuildId, NormalizedBuildType | null>
  compatibleAgents: KeyValue<BuildId, CompatibleAgentType | null>
  agents: KeyValue<AgentId, NormalizedAgentType | null>
  agent: KeyValue<AgentId, NormalizedAgentType | null | undefined>
  agentPreviews: KeyValue<AgentId, NormalizedAgentPreviewType | null | undefined>
  agentPools: KeyValue<AgentPoolId, AgentPoolType>
  cloudImages: CloudImagesHashType
  cloudImage: CloudImagesHashType
  buildTypes: KeyValue<BuildTypeId, BuildTypeType>
  investigations: KeyValue<BuildTypeId, InvestigationType>
  buildTypeParameters: KeyValue<BuildTypeId, InexactEntityParameters>
  buildTypeLinks: KeyValue<BuildTypeId, WebLinks>
  buildTypeDescription: KeyValue<BuildTypeId, EntityDescription>
  buildTypePauseComment: KeyValue<BuildTypeId, EntityPauseComment>
  buildTypesWithSnapshotDependencies: KeyValue<BuildTypeId, BuildTypeType>
  projects: KeyValue<ProjectId, NormalizedProjectType>
  projectDescription: KeyValue<ProjectId, EntityDescription>
  projectArchivedSubprojectsIds: KeyValue<ProjectId, EntityProjectsIdsList>
  projectParameters: KeyValue<ProjectId, InexactEntityParameters>
  projectLinks: KeyValue<ProjectId, WebLinks>
  projectTemplates: KeyValue<ProjectId, readonly Template[]>
  overviewBuildTypes: KeyValue<BuildTypeId, BuildTypeType>
  overviewProjects: KeyValue<ProjectId, NormalizedProjectType>
  buildTypeStatuses: KeyValue<StatusKey, ProjectOrBuildTypeStatus>
  overviewExpandState: KeyValue<ProjectId, ExpandState>
  allProjectsExpandState: KeyValue<ProjectId, ExpandState>
  searchProjectsExpandState: KeyValue<ProjectId, ExpandState>
  branches: KeyValue<StatusKey, NormalizedBranchWithBuilds>
  testOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>
  multirunTestOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>
  testOccurrencesInvocations: KeyValue<TestOccurrenceId, TestOccurrenceInvocationsType>
  testOccurrencesFirstFailed: KeyValue<TestOccurrenceId, TestOccurrenceType | null | undefined>
  testOccurrencesNextFixed: KeyValue<TestOccurrenceId, TestOccurrenceType | null | undefined>
  testOccurrencesRunOrder: KeyValue<TestOccurrenceId, string | null | undefined>
  testOccurrencesNewFailure: KeyValue<TestOccurrenceId, boolean | null | undefined>
  testOccurrencesMetadataCount: KeyValue<TestOccurrenceId, number | null | undefined>
  testOccurrencesInvestigations: KeyValue<TestOccurrenceId, ReadonlyArray<InvestigationType>>
  testOccurrencesCurrentlyMutes: KeyValue<TestOccurrenceId, ReadonlyArray<MuteType>>
  testOccurrencesMute: KeyValue<TestOccurrenceId, MuteType | null | undefined>
  testOccurrencesInvocationsCounters: KeyValue<TestOccurrenceId, TestOccurrencesCountsType>
  testOccurrenceMetadata: KeyValue<TestOccurrenceId, TestOccurrenceMetadataType>
  problemOccurrences: KeyValue<ProblemOccurrenceId, ProblemOccurrenceType>
  changes: KeyValue<ChangeId, NormalizedChangeType>
  modificationsOfChanges: KeyValue<ChangeId, NormalizedChangeType>
  changesStatus: KeyValue<ChangeId, ChangeStatusType>
  changesStatusBuilds: KeyValue<ChangeId, ChangeStatusType>
  changesBranches: KeyValue<ChangeId, ReadonlyArray<ChangeBranchType>>
  revisions: KeyValue<RevisionId, NormalizedRevisionType>
  vcsLabels: KeyValue<BuildId, ReadonlyArray<VcsLabelType>>
  vcsRootInstances: KeyValue<VcsRootInstanceId, NormalizedVcsRootInstanceType>
  vcsRoots: KeyValue<VcsRootId, VcsRootType>
  snapshotDependencies: SnapshotDependenciesIDListType
  virtualDependencies: VirtualDependenciesHashType
}
export type Normalized<T> = {
  readonly result: T
  readonly entities: Partial<Entities>
}
type NormalizedBuild = Normalized<BuildId>
export type NormalizedBuilds = Normalized<ReadonlyArray<BuildId>>
export type NormalizedAgents = Normalized<ReadonlyArray<AgentId>>
export type NormalizedAgent = Normalized<AgentId>
type NormalizedAgentPools = Normalized<ReadonlyArray<AgentPoolId>>
type NormalizedCloudImages = Normalized<ReadonlyArray<AgentTypeId>>
type NormalizedSingleBuildType = Normalized<BuildTypeId>
type NormalizedBuildTypes = Normalized<ReadonlyArray<BuildTypeId>>
type NormalizedProject = Normalized<ProjectId>
export type NormalizedProjects = Normalized<ReadonlyArray<ProjectId>>
export type NormalizedOverview = Normalized<ReadonlyArray<ProjectId>>
type NormalizedStatuses = Normalized<ReadonlyArray<StatusKey>>
type NormalizedExpandState = Normalized<ReadonlyArray<ProjectId>>
type NormalizedBuildTypeLinks = Normalized<BuildTypeId>
type NormalizedBuildTypeParameters = Normalized<BuildTypeId>
type NormalizedInvestigation = Normalized<BuildTypeId>
export type NormalizedFederationServer = Normalized<ReadonlyArray<FederationServerId>>
export type NormalizedBranches = Normalized<ReadonlyArray<StatusKey>>
export type NormalizedTestOccurrences = Normalized<ReadonlyArray<TestOccurrenceId>>
type NormalizedProblemOccurrence = Normalized<ProblemOccurrenceId>
type NormalizedProblemOccurrences = Normalized<ReadonlyArray<ProblemOccurrenceId>>
export type NormalizedChanges = Normalized<ReadonlyArray<ChangeId>>
export const normalizeBuild: (response: BuildType) => NormalizedBuild = response =>
  normalize(response, build)
export const normalizePartialBuild: (response: Partial<BuildType>) => {
  readonly result: BuildId
  readonly entities: {
    readonly builds: KeyValue<BuildId, Partial<NormalizedBuildType>>
  }
} = response => normalize(response, build)
export const normalizeBuilds: (arg0: ReadonlyArray<BuildType>) => NormalizedBuilds = response =>
  normalize(response, [build])
export const normalizeBuildArtifacts: (
  arg0: ReadonlyArray<BuildArtifactsType>,
) => NormalizedBuilds = response => normalize(response, [buildArtifacts])
export const normalizeCompatibleAgents: (
  arg0: ReadonlyArray<CompatibleAgentType>,
) => NormalizedBuilds = response => normalize(response, [compatibleAgents])
export const normalizeAgents: (arg0: ReadonlyArray<AgentType>) => NormalizedAgents = response =>
  normalize(response, [agent])
export const normalizeAgentPreviews: (
  arg0: ReadonlyArray<$ReadOnly<Inexact<AgentPreviewType>>>,
) => NormalizedAgents = response => normalize(response, [agentPreview])
export const normalizeAgent: (arg0: AgentType) => NormalizedAgent = response =>
  normalize(response, agent)
export const normalizeAgentPools: (
  arg0: ReadonlyArray<AgentPoolType>,
) => NormalizedAgentPools = response => normalize(response, [agentPool])
export const normalizeCloudImages: (
  arg0: ReadonlyArray<CloudImageResponseType>,
) => NormalizedCloudImages = response => normalize(response, [cloudImage])
export const normalizeBuildType: (
  arg0: Inexact<BuildTypeType>,
) => NormalizedSingleBuildType = response => normalize(response, buildType)
export const normalizeBuildTypes: (
  arg0: ReadonlyArray<Inexact<BuildTypeType>>,
) => NormalizedBuildTypes = response => normalize(response, [buildType])
export const normalizeBuildTypeLinks: (
  arg0: BuildTypeWithDetailsType,
) => NormalizedBuildTypeLinks = response => normalize(response, buildTypeLinks)
export const normalizeBuildTypeDescription: (
  arg0: BuildTypeWithDetailsType,
) => NormalizedBuildTypeLinks = response => normalize(response, buildTypeDescription)
export const normalizeBuildTypeParameters: (
  arg0: BuildTypeWithDetailsType,
) => NormalizedBuildTypeParameters = response => normalize(response, buildTypeParameters)

export const normalizeBuildTypePauseComment = (
  response: BuildTypeWithDetailsType,
): NormalizedBuildTypeLinks => normalize(response, buildTypePauseComment)

const normalizeProjects: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypes])

export const normalizeBuildTypeLinksFromBuilds: (
  arg0: ReadonlyArray<BuildType>,
) => NormalizedBuilds = response => normalize(response, [buildsWithBuildTypesLink])
export const normalizeBuildTypeLinksFromAgentsAndBuilds: (
  arg0: ReadonlyArray<AgentType>,
) => NormalizedAgents = response => normalize(response, [agentWithBuildsAndBuildTypeLinks])

const normalizeBuildTypeLinksFromProjects: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypesLink])

const normalizeBuildTypeDescriptionFromProjects: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypesDescription])

const normalizeBuildTypeParametersFromProjects: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectWithBuildTypesParameters])

const normalizeProjectsArchivedSubprojectsIds: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProject = response => normalize(response, [projectArchivedSubprojectsIds])

const normalizeProjectsLinks: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectLinks])

const normalizeProjectTemplates = (
  response: readonly ProjectWithDetailsType[],
): NormalizedProjects => normalize(response, [projectTemplates])

const normalizeProjectsDescription: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectDescription])

const normalizeProjectsParameters: (
  arg0: ReadonlyArray<ProjectWithDetailsType>,
) => NormalizedProjects = response => normalize(response, [projectParameters])

export const normalizeOverview: (
  arg0: ReadonlyArray<ProjectType>,
) => NormalizedOverview = response => normalize(response, [overviewProject])
export const normalizeStatuses: (
  arg0: ReadonlyArray<ProjectOrBuildTypeStatus>,
) => NormalizedStatuses = response => normalize(response, [buildTypeStatus])
export const normalizeExpandState: (
  arg0: ReadonlyArray<ExpandState>,
) => NormalizedExpandState = response => normalize(response, [overviewExpandState])
export const normalizeInvestigation: (
  arg0: ReadonlyArray<InvestigationType>,
) => NormalizedInvestigation = response => normalize(response, [investigation])
export const normalizeFederationServer: (
  arg0: ReadonlyArray<FederationServerType>,
) => NormalizedFederationServer = response => normalize(response, [federationServer])
export const normalizeBranches: (
  arg0: ReadonlyArray<BranchWithBuilds>,
) => NormalizedBranches = response => normalize(response, [branch])
export const normalizeTestOccurrences: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrences])
export const normalizeTestOccurrencesInvocations: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesInvocations])
export const normalizeTestOccurrencesNewFailure: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesNewFailure])
export const normalizeTestOccurrencesMetadataCount: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesMetadataCount])
export const normalizeTestOccurrencesFirstFailed: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesFirstFailed])
export const normalizeTestOccurrencesNextFixed: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesNextFixed])
export const normalizeTestOccurrencesRunOrder: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesRunOrder])
export const normalizeTestOccurrencesInvestigations: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesInvestigations])
export const normalizeTestOccurrencesCurrentlyMutes: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesCurrentlyMutes])
export const normalizeTestOccurrencesInvocationsCounters: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response =>
  normalize(response, [testOccurrencesInvocationsCounters])
export const normalizeTestOccurrencesMute: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesMute])
export const normalizeTestOccurrencesBuild: (
  arg0: ReadonlyArray<TestOccurrenceType>,
) => NormalizedTestOccurrences = response => normalize(response, [testOccurrencesBuild])
export const normalizeTestMetadata: (
  arg0: TestOccurrenceType,
) => NormalizedTestOccurrences = response => normalize(response, testOccurrenceMetadata)
export const normalizeProblemOccurrence: (
  arg0: ProblemOccurrenceType,
) => NormalizedProblemOccurrence = response => normalize(response, problemOccurrences)
export const normalizeProblemOccurrences: (
  arg0: ReadonlyArray<ProblemOccurrenceType>,
) => NormalizedProblemOccurrences = response => normalize(response, [problemOccurrences])
export const normalizeBuildChangesRevisions: (
  arg0: ReadonlyArray<BuildChangesRevisionsType>,
) => NormalizedBuilds = response => normalize(response, [build])
export const normalizeBuildChanges: (
  arg0: ReadonlyArray<BuildChangesType>,
) => NormalizedBuilds = response => normalize(response, [build])
export const normalizeChanges: (arg0: ReadonlyArray<ChangeType>) => NormalizedChanges = response =>
  normalize(response, [changes])
export const normalizeChangesStatus: (
  arg0: ReadonlyArray<ChangeType>,
) => NormalizedTestOccurrences = response => normalize(response, [changesStatus])
export const normalizeModificationsOfChanges: (
  arg0: ReadonlyArray<ChangeType>,
) => NormalizedTestOccurrences = response => normalize(response, [modificationsOfChanges])
export const normalizeChangesBranches: (
  arg0: ReadonlyArray<ChangeType>,
) => NormalizedTestOccurrences = response => normalize(response, [changesBranches])
export const normalizeProjectsData = (
  data: ReadonlyArray<ProjectWithDetailsType>,
  options: RequestOptionsParams,
): NormalizedProjects => {
  const project = normalizeProjects(data)
  let entities = {...project.entities}

  if (options.withDescription === true) {
    entities = {
      ...entities,
      ...normalizeProjectsDescription(data).entities,
      ...normalizeBuildTypeDescriptionFromProjects(data).entities,
    }
  }

  if (options.withLinks === true) {
    entities = {...entities, ...normalizeProjectsLinks(data).entities}
  }

  if (options.withParameters === true) {
    entities = {...entities, ...normalizeProjectsParameters(data).entities}
  }

  if (options.withTemplates === true) {
    entities = {...entities, ...normalizeProjectTemplates(data).entities}
  }

  if (options.withBuildTypes === true && options.withLinks === true) {
    entities = {
      ...entities,
      ...normalizeProjectsLinks(data).entities,
      ...normalizeBuildTypeLinksFromProjects(data).entities,
    }
  }

  if (options.withBuildTypes === true && options.withParameters === true) {
    entities = {...entities, ...normalizeBuildTypeParametersFromProjects(data).entities}
  }

  if (options.withArchivedSubprojectsIds === true) {
    entities = {...entities, ...normalizeProjectsArchivedSubprojectsIds(data).entities}
  }

  return {
    result: project.result,
    entities,
  }
}
