import {AsyncThunkAction} from '@reduxjs/toolkit'

import {fetchBuildTypeTags} from '../../../../actions/fetch'
import type {AppThunk} from '../../../../actions/types'
import {AsyncThunkConfig, createFetchAction} from '../../../../reducers/fetchable'
import {addTags, addTagsToDependencies, changeTags} from '../../../../rest/builds'
import {restRoot} from '../../../../rest/consts'
import {getIsStarred, getTags} from '../../../../selectors'
import {STAR_TAG} from '../../../../types'
import type {BuildId, BuildTypeId, TagType} from '../../../../types'

type AddBuildsTagsArg = {
  buildIds: readonly BuildId[]
  tags: ReadonlyArray<TagType>
  applyToChainBuilds?: boolean
}
export const addBuildsTags = createFetchAction(
  'addBuildsTags',
  ({buildIds, tags, applyToChainBuilds = false}: AddBuildsTagsArg) =>
    addTags(restRoot, buildIds, tags, applyToChainBuilds),
)

type AddDependenciesTagsArg = {
  buildId: BuildId
  tags: ReadonlyArray<TagType>
}
export const addDependenciesTags = createFetchAction(
  'addDependenciesTags',
  ({buildId, tags}: AddDependenciesTagsArg) => addTagsToDependencies(restRoot, buildId, tags),
)

type ChangeBuildTagsArg = {
  buildId: BuildId
  tags: ReadonlyArray<TagType>
  buildTypeId?: BuildTypeId | undefined
  applyToChainBuilds: boolean
}
export const changeBuildTagsAction = createFetchAction(
  'changeBuildTags',
  ({buildId, tags, buildTypeId, applyToChainBuilds}: ChangeBuildTagsArg, {dispatch, getState}) => {
    const state = getState()
    const allTags = getIsStarred(state, buildId)
      ? tags.concat({
          name: STAR_TAG,
          private: true,
        })
      : tags
    const serverUrl = state.restRoot

    if (applyToChainBuilds && tags.length > 0) {
      dispatch(addDependenciesTags({buildId, tags}))
    }

    return changeTags(serverUrl, buildId, allTags).then(data => {
      if (buildTypeId != null) {
        dispatch(fetchBuildTypeTags(buildTypeId))
      }
      return data
    })
  },
)

export const changeBuildTags = (
  buildIds: readonly BuildId[],
  tags: ReadonlyArray<TagType>,
  buildTypeId?: BuildTypeId | undefined,
  applyToChainBuilds: boolean = false,
): AsyncThunkAction<unknown, unknown, AsyncThunkConfig> =>
  buildIds.length > 1
    ? addBuildsTags({buildIds, tags, applyToChainBuilds})
    : changeBuildTagsAction({buildId: buildIds[0], tags, buildTypeId, applyToChainBuilds})

export const removeBuildTag =
  (buildId: BuildId, name: string, buildTypeId?: BuildTypeId | undefined): AppThunk =>
  (dispatch, getState) =>
    dispatch(
      changeBuildTags(
        [buildId],
        getTags(getState(), buildId).filter(tag => tag.name !== name),
        buildTypeId,
      ),
    )
