import {createFetchableSlice} from '../../../../slices/fetchable'
import {stringifyId} from '../../../../types'

import {requestArtifactStorage} from './BuildArtifactStorageInfo.rest'

export const artifactStorages = createFetchableSlice({
  name: 'artifactStorages',
  request: requestArtifactStorage,
  getKey: stringifyId,
  defaultState: null,
})
