import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {batch} from 'react-redux'

import * as Actions from '../../../actions/actionTypes'
import type {Action, AppThunk} from '../../../actions/types'
import {createFetchAction} from '../../../reducers/fetchable'
import requestExpandState, {
  toggleExpandState as requestToggleExpandState,
} from '../../../rest/expandState'
import {normalizeExpandState} from '../../../rest/schemata'
import {getExtensionEndpoint} from '../../../selectors'
import {
  getAllProjectsExpandState,
  getAllProjectsTreeHash,
  getDefaultAllProjectsTree,
  getDefaultOverviewTree,
  getOverviewExpandState,
  getOverviewTreeHash,
  getSearchProjectsTreeHash,
  matchSidebarItem,
  SidebarItemId,
} from '../../../selectors/projectTrees'
import {ExpandState, ProjectId, stringifyId} from '../../../types'
import {base_uri, BS} from '../../../types/BS_types'
import type {ProjectsTreeItemType, SidebarActiveItem} from '../../../types/projectTrees'
import {KeyValue} from '../../../utils/object'

import {allProjectsExpandState, searchProjectsExpandState} from './ProjectsSidebar.slices'

export const fetchExpandState = createFetchAction(
  'fetchExpandState',
  (_: void, {getState}) => {
    const extension = getExtensionEndpoint(getState(), 'expandState')! // checked in condition

    return requestExpandState(extension.serverUrl ?? base_uri, extension.endpoint).then(
      normalizeExpandState,
    )
  },
  {condition: (_, {getState}) => getExtensionEndpoint(getState(), 'expandState') != null},
)
export const receiveExpandState = (data: ReadonlyArray<ExpandState>) =>
  fetchExpandState.fulfilled(normalizeExpandState(data), '')
export const toggleExpandAllProjects = (expanded: boolean): Action => ({
  type: Actions.CHANGE_ALL_PROJECTS_COLLAPSED,
  collapsed: !expanded,
})
export const overviewExpandState = createSlice({
  name: 'overviewExpandState',
  initialState: {} as KeyValue<ProjectId, ExpandState>,
  reducers: {
    toggle(state, action: PayloadAction<ExpandState[]>) {
      action.payload.forEach(item => {
        state[item.id] = item
      })
    },
  },
  extraReducers: builder =>
    builder.addCase(fetchExpandState.fulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.overviewExpandState)
    }),
})
export const toggleExpandState =
  (
    projectId: ProjectId,
    expanded: boolean,
    group: 'favorites' | 'all' | 'search' | 'none',
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()
    const data: Array<ExpandState> = [
      {
        type: 'project',
        id: projectId,
        expandState: expanded ? 'EXPANDED' : 'COLLAPSED',
      },
    ]

    if (!expanded) {
      let itemsHash: KeyValue<SidebarItemId, ProjectsTreeItemType>

      if (group === 'favorites') {
        itemsHash = getOverviewTreeHash(state)
      } else if (group === 'search') {
        itemsHash = getSearchProjectsTreeHash(state)
      } else {
        itemsHash = getAllProjectsTreeHash(state)
      }

      const addChildrenRecursively = (id: ProjectId) => {
        const item: ProjectsTreeItemType | null | undefined = itemsHash
          ? itemsHash[`project_${stringifyId(id)}`]
          : null

        if (item && item.childrenProjectsIds && item.childrenProjectsIds.length) {
          item.childrenProjectsIds.forEach(childId => {
            data.push({
              type: 'project',
              id: childId,
              expandState: 'COLLAPSED',
            })
            addChildrenRecursively(childId)
          })
        }
      }

      addChildrenRecursively(projectId)
    }

    if (group === 'search') {
      return dispatch(searchProjectsExpandState.actions.toggle(data))
    }

    if (group === 'all') {
      return dispatch(allProjectsExpandState.actions.toggle(data))
    }

    const extension = getExtensionEndpoint(getState(), 'expandState')

    if (!extension) {
      return Promise.resolve()
    }

    dispatch(overviewExpandState.actions.toggle(data))
    return requestToggleExpandState(extension.serverUrl ?? base_uri, extension.endpoint, data).then(
      () => {
        //Ignore result, changes already are in the store
      },
      (error: Error) => {
        BS && BS.Log.error(error)
      },
    )
  }
export const expandParents =
  (activeItem: SidebarActiveItem): AppThunk<any> =>
  (dispatch, getState) =>
    batch(() => {
      const state = getState()
      const overviewTree = getDefaultOverviewTree(state)?.data
      const isFavorite = overviewTree != null && overviewTree.some(matchSidebarItem(activeItem))

      if (!isFavorite) {
        dispatch(toggleExpandAllProjects(true))
      }

      const tree = isFavorite ? overviewTree : getDefaultAllProjectsTree(state)?.data
      const expandState = isFavorite
        ? getOverviewExpandState(state)
        : getAllProjectsExpandState(state)

      if (tree == null || expandState == null) {
        return
      }

      const expandSelfAndParents = (item: SidebarActiveItem) => {
        const treeItem = tree.find(matchSidebarItem(item))

        if (treeItem == null || treeItem.group === 'none') {
          return
        }

        if (item.nodeType === 'project' && item !== activeItem) {
          if (expandState[item.id]?.expandState !== 'EXPANDED') {
            dispatch(toggleExpandState(item.id, true, treeItem.group))
          }
        }

        if (treeItem.parentId != null) {
          expandSelfAndParents({
            nodeType: 'project',
            id: treeItem.parentId,
            group: treeItem.group,
          })
        }
      }

      expandSelfAndParents(activeItem)
    })
