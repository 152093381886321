import {combineReducers} from 'redux'

import {keyValueFetchable} from '../../../../reducers/fetchable'
import {NormalizedChangeType, stringifyId} from '../../../../types'

import {fetchPersonalChange} from './BuildStatusTooltip.actions'
import {BuildStatusTooltipStateType} from './BuildStatusTooltip.types'

const buildStatusTooltip = combineReducers<BuildStatusTooltipStateType>({
  changes: keyValueFetchable(
    changeId => stringifyId(changeId),
    fetchPersonalChange,
    null as NormalizedChangeType | null,
    (state, action) => action.payload,
  ),
})
export default buildStatusTooltip
