import {BuildTypeHierarchyType} from '../../../../../types'

export const REQUEST_CHANGE_BUILD_TYPE_HIERARCHY: 'REQUEST_CHANGE_BUILD_TYPE_HIERARCHY' =
  'REQUEST_CHANGE_BUILD_TYPE_HIERARCHY'
export const RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY: 'RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY' =
  'RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY'

export type ChangeProjectsSelectAction =
  | {
      type: typeof REQUEST_CHANGE_BUILD_TYPE_HIERARCHY
      readonly locator: string
    }
  | {
      type: typeof RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY
      readonly locator: string
      readonly data?: BuildTypeHierarchyType
      readonly error?: Error
    }
