import {CombinedState} from 'redux'
import {$Values} from 'utility-types'

import type {BaseReceiveAction} from '../../actions/fetchActions'
import type {BuildId, Fetchable, ProblemOccurrenceId, TestOccurrenceId} from '../../types'
import type {KeyValue} from '../../utils/object'

export type LogFilter = 'debug' | 'important' | 'err'
export type BuildLogOptionType = {
  readonly count?: (number | null | undefined) | ReadonlyArray<number>
  readonly logAnchor?: number
  readonly expandState?: ReadonlyArray<number> | null | undefined
  readonly expandAll?: boolean
  readonly stageKey?: string
  readonly target?: string
  readonly testOccurrenceId?: TestOccurrenceId
  readonly problemOccurrenceId?: ProblemOccurrenceId
  readonly filter?: LogFilter | null | undefined
  readonly searchQuery?: string
  readonly logView?: LogView
}

export enum Status {
  ERROR = 4,
  TRACE_ERROR = 3,
  WARNING = 2,
}

export type BuildLogMessage = {
  readonly id: number
  readonly parentId?: number | null | undefined
  readonly blockType?: string | null | undefined
  readonly containsMessages?: boolean | null | undefined
  text: string
  readonly status: Status
  readonly level: number
  readonly timestamp?: string
  readonly duration?: number | null | undefined
  readonly flowId?: number | null | undefined
  readonly linkedMessageId?: number | null | undefined
  readonly verbose?: boolean | null | undefined
  // appended on client properties
  processed?: boolean
  childrenEmpty?: boolean
  nextIsHole?: boolean
  containAnsi?: boolean | null | undefined
  containUrl?: boolean | null | undefined
  inaccurateSize?: boolean | null | undefined
  isBroken?: boolean | null | undefined
  isLast?: boolean | null | undefined
  linesLengths?: ReadonlyArray<number>
  maxLineLength?: number
}
export type BuildLogMessagesResponseAnchor = {
  position: number
  type: string
}
export const testTargetAnchorTypes = {
  TEST_START: 'TEST_START' as 'TEST_START',
  TEST_EXCEPTION: 'TEST_EXCEPTION' as 'TEST_EXCEPTION',
  TEST_ACTUAL: 'TEST_ACTUAL' as 'TEST_ACTUAL',
  TEST_EXPECTED: 'TEST_EXPECTED' as 'TEST_EXPECTED',
}
type TestTargetAnchorTypesType = $Values<typeof testTargetAnchorTypes>
export type BuildLogTestTargetAnchor = {
  position: number
  type: TestTargetAnchorTypesType
}
export type BuildLogTestTarget = {
  anchors: ReadonlyArray<BuildLogTestTargetAnchor>
  id: number
  type: 'TEST'
}
export type BuildLogMessagesResponse = {
  messages: ReadonlyArray<BuildLogMessage>
  expandedMessagesIndices?: ReadonlyArray<number>
  testTarget?: BuildLogTestTarget
  stageTarget?: {
    key: string
    anchors: ReadonlyArray<BuildLogMessagesResponseAnchor>
  }
  lastMessageIncluded?: boolean
  nextVisible?: number
  prevVisible?: number
  view?: LogView
  focusIndex?: number
}
type TimelinePoint = {
  id: number
  x: number
  name?: string | null | undefined
  buildAnchor: number
  type: string | null | undefined
}
export type TimelineStageStatus = 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'UNKNOWN'
export type TimelineStage = {
  key: string
  name: string
  start: number
  end: number
  // Milliseconds
  duration: number
  point?: TimelinePoint
  statuses: ReadonlyArray<TimelineStageStatus> | null | undefined
  now?: {
    x: number
  }
}
export type BuildLogTimeline = {
  stages: ReadonlyArray<TimelineStage>
  nowPoint?: number | null | undefined
}
export type BuildLogKey = string
export type FullLogScrollBarInfo = {
  readonly horizontal: boolean
  readonly size: number | null | undefined
  readonly vertical: boolean
}
export type FullLogLoadingDirection = 'up' | 'down' | null
export type LogView = 'flowAware' | 'linear' | null | undefined
export type FullLogState = {
  buildId: BuildId | null | undefined
  focusLine: number | null | undefined
  expandState: ReadonlyArray<number> | null | undefined
  expandAll: boolean
  showedFirstDate: string | null | undefined
  showedFirstId: number | null | undefined
  showedLastId: number | null | undefined
  showedLastDate: string | null | undefined
  scrollBarInfo: FullLogScrollBarInfo | null | undefined
  messagesExists: boolean
  filter: LogFilter | null | undefined
  lastLoadingDirection: FullLogLoadingDirection
  logView: LogView
}
export type FullLogTarget = 'page' | 'popup'
export type FetchMessagesParams = {
  buildId: BuildId
  buildLogKey: BuildLogKey
  options?: BuildLogOptionType
  invalidate?: boolean
  target?: FullLogTarget
  mergeData?: boolean
}
export enum SearchDirection {
  Next = 'Next',
  Previous = 'Previous',
}
export const REQUEST_BUILDLOG_MESSAGES: 'REQUEST_BUILDLOG_MESSAGES' = 'REQUEST_BUILDLOG_MESSAGES'
export const RECEIVE_BUILDLOG_MESSAGES: 'RECEIVE_BUILDLOG_MESSAGES' = 'RECEIVE_BUILDLOG_MESSAGES'
export const REQUEST_BUILDLOG_TIMELINE: 'REQUEST_BUILDLOG_TIMELINE' = 'REQUEST_BUILDLOG_TIMELINE'
export const RECEIVE_BUILDLOG_TIMELINE: 'RECEIVE_BUILDLOG_TIMELINE' = 'RECEIVE_BUILDLOG_TIMELINE'
export const SET_FULL_LOG_STATE: 'SET_FULL_LOG_STATE' = 'SET_FULL_LOG_STATE'
export const SET_FULL_LOG_SHOWED: 'SET_FULL_LOG_SHOWED' = 'SET_FULL_LOG_SHOWED'
export const SET_FULL_LOG_SCROLL_BAR_INFO: 'SET_FULL_LOG_SCROLL_BAR_INFO' =
  'SET_FULL_LOG_SCROLL_BAR_INFO'
export const SET_FULL_LOG_FILTER: 'SET_FULL_LOG_FILTER' = 'SET_FULL_LOG_FILTER'
export const SET_SOFT_WRAP_LINES_IN_BUILDLOG: 'SET_SOFT_WRAP_LINES_IN_BUILDLOG' =
  'SET_SOFT_WRAP_LINES_IN_BUILDLOG'
export const SET_USE_DARK_THEME_BUILDLOG: 'SET_USE_DARK_THEME_BUILDLOG' =
  'SET_USE_DARK_THEME_BUILDLOG'
export const REQUEST_BUILDLOG_SEARCH_MESSAGE: 'REQUEST_BUILDLOG_SEARCH_MESSAGE' =
  'REQUEST_BUILDLOG_SEARCH_MESSAGE'
export const RECEIVE_BUILDLOG_SEARCH_MESSAGE: 'RECEIVE_BUILDLOG_SEARCH_MESSAGE' =
  'RECEIVE_BUILDLOG_SEARCH_MESSAGE'
export const RECEIVE_ERROR_BUILDLOG_SEARCH_MESSAGE: 'RECEIVE_ERROR_BUILDLOG_SEARCH_MESSAGE' =
  'RECEIVE_ERROR_BUILDLOG_SEARCH_MESSAGE'
export const CLEAR_BUILDLOG_SEARCH: 'CLEAR_BUILDLOG_SEARCH' = 'CLEAR_BUILDLOG_SEARCH'
export const CHANGE_BUILDLOG_SEARCH_MATCH_POSITION: 'CHANGE_BUILDLOG_SEARCH_MATCH_POSITION' =
  'CHANGE_BUILDLOG_SEARCH_MATCH_POSITION'
export type BuildLogAction =
  | {
      type: typeof REQUEST_BUILDLOG_MESSAGES
      readonly buildLogKey: BuildLogKey
      readonly buildId: BuildId
      readonly options?: BuildLogOptionType
      readonly target?: FullLogTarget
      readonly invalidate?: boolean
    }
  | (BaseReceiveAction<BuildLogMessagesResponse> & {
      type: typeof RECEIVE_BUILDLOG_MESSAGES
      readonly buildLogKey: BuildLogKey
      readonly buildId: BuildId
      readonly options?: BuildLogOptionType
      readonly invalidate?: boolean
      readonly focusLine?: number | null | undefined
      readonly mergeData?: boolean | null | undefined
      readonly logView?: LogView
    })
  | {
      type: typeof REQUEST_BUILDLOG_TIMELINE
      readonly buildId: BuildId
    }
  | (BaseReceiveAction<BuildLogTimeline> & {
      type: typeof RECEIVE_BUILDLOG_TIMELINE
      readonly buildId: BuildId
    })
  | {
      type: typeof SET_FULL_LOG_STATE
      readonly target: FullLogTarget
      readonly buildId?: BuildId | null | undefined
      readonly focusLine?: number | null | undefined
      readonly expandState?: ReadonlyArray<number> | null | undefined
      readonly expandAll?: boolean
    }
  | {
      type: typeof SET_FULL_LOG_SHOWED
      readonly target: FullLogTarget
      readonly firstId: number | null | undefined
      readonly firstDate: string | null | undefined
      readonly lastDate: string | null | undefined
      readonly lastId: number | null | undefined
    }
  | {
      type: typeof SET_FULL_LOG_SCROLL_BAR_INFO
      readonly target: FullLogTarget
      readonly info: FullLogScrollBarInfo
    }
  | {
      type: typeof SET_FULL_LOG_FILTER
      readonly value: LogFilter | null | undefined
    }
  | {
      type: typeof SET_SOFT_WRAP_LINES_IN_BUILDLOG
      readonly value: boolean
    }
  | {
      type: typeof SET_USE_DARK_THEME_BUILDLOG
      readonly value: boolean
    }
  | {
      type: typeof REQUEST_BUILDLOG_SEARCH_MESSAGE
      readonly target: FullLogTarget
      readonly query: string
      readonly searchDirection: SearchDirection
    }
  | {
      type: typeof RECEIVE_ERROR_BUILDLOG_SEARCH_MESSAGE
      readonly target: FullLogTarget
      readonly query: string
      readonly searchDirection: SearchDirection
      readonly error?: Error
    }
  | {
      type: typeof RECEIVE_BUILDLOG_SEARCH_MESSAGE
      readonly target: FullLogTarget
      readonly query: string
      readonly foundId: number | null | undefined
      readonly matchPosition: number | null
      readonly matchesCount: number
      readonly startId: number | null | undefined
      readonly nextStartId: number | null | undefined
      readonly limitReached: boolean
      readonly notFound: boolean
      readonly searchDirection: SearchDirection
    }
  | {
      type: typeof CHANGE_BUILDLOG_SEARCH_MATCH_POSITION
      readonly target: FullLogTarget
      readonly query: string
      readonly matchPosition: number | null | undefined
    }
  | {
      type: typeof CLEAR_BUILDLOG_SEARCH
      readonly target: FullLogTarget
    }
type MessagesState = KeyValue<BuildLogKey, Fetchable<ReadonlyArray<BuildLogMessage>>>
type TimelinesState = KeyValue<BuildId, Fetchable<BuildLogTimeline | null>>
type TestAnchorsState = KeyValue<BuildLogKey, BuildLogTestTarget | null>
type LastMessageIncludedState = KeyValue<BuildLogKey, boolean | null>
export type MessagesLoadState = KeyValue<
  string,
  {
    loading: boolean
    lastLoadedTime: number | null | undefined
  }
>
export type FullLogStates = {
  readonly page: FullLogState
  readonly popup: FullLogState
}
export type LogSettingsState = {
  readonly softWrapLines: boolean | null | undefined
  readonly darkTheme: boolean | null | undefined
}
export type BuildLogSearchState = {
  readonly query: string | null | undefined
  readonly nextStartId: number | null | undefined
  readonly foundId: number | null | undefined
  readonly matchPosition: number | null | undefined
  readonly matchesCount: number
  readonly startId: number | null | undefined
  readonly limitReachedTimes: number
  readonly notFound: boolean
  readonly loading: boolean
  readonly searchDirection: SearchDirection
}
export type BuildLogSearchStates = {
  readonly page: BuildLogSearchState
  readonly popup: BuildLogSearchState
}
export type BuildLogState = CombinedState<{
  readonly messages: MessagesState
  readonly timelines: TimelinesState
  readonly testAnchors: TestAnchorsState
  readonly lastMessageIncluded: LastMessageIncludedState
  readonly fullLogStates: FullLogStates
  readonly messagesLoadStates: KeyValue<BuildLogKey, MessagesLoadState>
  readonly settings: LogSettingsState
  readonly searchStates: BuildLogSearchStates
}>
export type KeyBuildLogParams =
  | {
      readonly type: 'preview'
      readonly id: BuildId | null | undefined
    }
  | {
      readonly type: 'test'
      readonly id: TestOccurrenceId | null | undefined
    }
  | {
      readonly type: 'problem'
      readonly id: ProblemOccurrenceId | null | undefined
    }
  | {
      readonly type: 'full'
      readonly target: FullLogTarget
    }

export type BuildLogMessageSelectionStart = {
  readonly direction: 'down' | 'up'
  readonly index: number
  readonly messageId: number
  readonly timeSelected: boolean
}
