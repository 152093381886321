import * as Redux from 'redux'

import type {Action} from '../../actions/types'
import {keyValueReducer} from '../../reducers/utils'
import {getEmptyHash} from '../../utils/empty'

import {
  SET_AGENTS_TREE_ITEM_EXPAND_STATE,
  SEARCH_IN_AGENTS_TREE,
  AgentsTreeExpandState,
} from './AgentsScreen.types'
import type {AgentsTreeSearchState, AgentsScreenState} from './AgentsScreen.types'

const defaultAgentsScreenState = {
  agentsTreeExpandState: getEmptyHash(),
  agentsTreeSearchExpandState: getEmptyHash(),
  agentsTreeSearchState: {
    searchQuery: '',
  },
}
const agentsTreeExpandStateReducer: Redux.Reducer<AgentsTreeExpandState, Action> = keyValueReducer(
  action => action.agentItemType,
  keyValueReducer(
    action => action.agentItemId,
    (_, action) => action.expanded,
    [SET_AGENTS_TREE_ITEM_EXPAND_STATE],
  ),
  [SET_AGENTS_TREE_ITEM_EXPAND_STATE],
)

function agentsTreeSearchStateReducer(
  state: AgentsTreeSearchState = defaultAgentsScreenState.agentsTreeSearchState,
  action: Action,
) {
  switch (action.type) {
    case SEARCH_IN_AGENTS_TREE:
      return {
        searchQuery: action.searchQuery,
      }

    default:
      return state
  }
}

export const agentsScreenReducers: Redux.Reducer<AgentsScreenState, Action> = (
  {
    agentsTreeExpandState,
    agentsTreeSearchExpandState,
    agentsTreeSearchState,
  }: AgentsScreenState = defaultAgentsScreenState,
  action: Action,
): AgentsScreenState => {
  const {searchQuery} = agentsTreeSearchState
  return {
    agentsTreeExpandState:
      searchQuery === ''
        ? agentsTreeExpandStateReducer(agentsTreeExpandState, action)
        : agentsTreeExpandState,
    agentsTreeSearchExpandState:
      searchQuery !== ''
        ? agentsTreeExpandStateReducer(agentsTreeSearchExpandState, action)
        : defaultAgentsScreenState.agentsTreeSearchExpandState,
    agentsTreeSearchState: agentsTreeSearchStateReducer(agentsTreeSearchState, action),
  }
}
