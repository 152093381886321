import * as Redux from 'redux'

import fetchable from '../../reducers/fetchable'
import {keyValueReducer} from '../../reducers/utils'
import {emptyArray} from '../../utils/empty'

import {
  REQUEST_INVESTIGATION_HISTORY,
  RECEIVE_INVESTIGATION_HISTORY,
  InvestigationHistoryType,
} from './InvestigationHistory.types'

const investigationsReducer = fetchable<
  readonly InvestigationHistoryType[],
  readonly InvestigationHistoryType[]
>(REQUEST_INVESTIGATION_HISTORY, RECEIVE_INVESTIGATION_HISTORY, emptyArray, action => action.data)
const investigationHistoryReducer = Redux.combineReducers({
  investigations: keyValueReducer(action => action.locator, investigationsReducer, [
    REQUEST_INVESTIGATION_HISTORY,
    RECEIVE_INVESTIGATION_HISTORY,
  ]),
})
export default investigationHistoryReducer
