import {configureStore} from '@reduxjs/toolkit'

import type {Action} from '../actions/types'
import reducer from '../reducers'
import {getStateToPersist} from '../selectors/toPersist'
import statistics from '../statistics/middleware'

import {loadState, saveState} from './persistState'
import {MiddlewareAPI} from './types'

const logMe = (store: MiddlewareAPI) => (next: (action: Action) => Action) => (action: Action) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('dispatching:', action, store.getState())
  }

  return next(action)
}

const persistentState = loadState()
const store = configureStore({
  reducer,
  preloadedState: persistentState && {
    ...persistentState,
    currentUser: {
      ...persistentState.currentUser,
      // don't prevent request for up-to-date user info
      inited: false,
    },
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
    return defaultMiddleware.concat(
      [
        IS_STORYBOOK && require('./getLogicMiddleware').getLogicMiddleware(),
        logMe,
        statistics,
      ].filter(Boolean),
    )
  },
})
store.subscribe(() => {
  saveState(getStateToPersist(store.getState()))
})
export default store
