import type {BaseChangeType, NormalizedChangeType} from '../../types'
import {stringifyId} from '../../types'
import escapeLocatorDimension from '../../utils/escapeLocatorDimension'

import {FilterOptionsType} from './Changes.types'

export const getChangesLocator = (
  params: FilterOptionsType & {
    readonly baseLocator: string
    readonly unique?: boolean
    readonly currentPage?: number | null
    readonly visibleCount?: number
  },
): string => {
  const {
    versionedSettings = true,
    userId,
    username,
    comment = '',
    path = '',
    revision = '',
    projectId,
    unique,
    baseLocator,
    currentPage,
    visibleCount,
  } = params ?? {}
  return [
    baseLocator,
    unique ? `unique:true` : '',
    versionedSettings ? '' : 'versionedSettings:false',
    projectId ? `affectedProject:(id:${projectId})` : '',
    userId != null && userId !== '' ? `user:(id:${stringifyId(userId)})` : '',
    username != null && username !== '' ? `username:${username}` : '',
    comment !== ''
      ? `comment:(value:${escapeLocatorDimension(comment)},matchType:contains,ignoreCase:true)`
      : '',
    path !== ''
      ? `file:path:(value:${escapeLocatorDimension(path)},matchType:contains,ignoreCase:true)`
      : '',
    revision !== ''
      ? `version:(value:${escapeLocatorDimension(revision)},matchType:contains,ignoreCase:true)`
      : '',
    visibleCount != null ? `count:${visibleCount}` : '',
    visibleCount != null && currentPage != null ? `start:${visibleCount * (currentPage - 1)}` : '',
  ]
    .filter(Boolean)
    .join(',')
}

export const getUsers = (change: BaseChangeType<unknown, unknown> | null | undefined) =>
  change?.commiter?.users.user ?? []
export const getSingleUser = (change: BaseChangeType<unknown, unknown> | null | undefined) => {
  const users = getUsers(change)
  return users.length === 1 ? users[0] : undefined
}

export const getCommiterName = (change: NormalizedChangeType | null | undefined): string => {
  const user = getSingleUser(change)
  return (user?.name ?? user?.username ?? change?.username ?? '').trim() || '<anonymous>'
}
