import {combineReducers} from 'redux'

import {RECEIVE_POOL_QUEUE_COUNTERS} from './QueueSidebar.types'

export const queueSidebar = combineReducers({
  counters(state = {}, action) {
    switch (action.type) {
      case RECEIVE_POOL_QUEUE_COUNTERS:
        return action.data

      default:
        return state
    }
  },
})
