import {BaseReceiveAction} from '../../../../../actions/fetchActions'
import type {BuildId, Fetchable} from '../../../../../types'
import type {KeyValue} from '../../../../../utils/object'

export const REQUEST_BUILD_SNIPPET_LIST: 'REQUEST_BUILD_SNIPPET_LIST' = 'REQUEST_BUILD_SNIPPET_LIST'
export const RECEIVE_BUILD_SNIPPET_LIST: 'RECEIVE_BUILD_SNIPPET_LIST' = 'RECEIVE_BUILD_SNIPPET_LIST'
declare const $Brand: unique symbol
type Brand<T, B> = T & {[$Brand]: B}
export type BuildSnippetNameType = Brand<string, 'buildSnippetName'>
export type BuildSnippetType = {
  id: string
  name: BuildSnippetNameType
}
export type BuildSnippetStateType = KeyValue<string, Fetchable<ReadonlyArray<BuildSnippetType>>>
export type BuildSnippetsActions =
  | {
      type: typeof REQUEST_BUILD_SNIPPET_LIST
      buildId: BuildId
    }
  | (BaseReceiveAction<readonly BuildSnippetType[]> & {
      type: typeof RECEIVE_BUILD_SNIPPET_LIST
      buildId: BuildId
    })
