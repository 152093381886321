import RingAvatar, {Size} from '@jetbrains/ring-ui/components/avatar/avatar'
import classNames from 'classnames'
import type {ReactElement} from 'react'

import {useSelector} from 'react-redux'

import {getCurrentUserId, getIsHightlightMyChangesAndInvestigations} from '../../../selectors'
import {AvatarSize, getAvatarSizeKey, UserType} from '../../../types'

import {resolveRelative} from '../../../utils/url'

import styles from './Avatar.css'

type Props = {
  user: Partial<UserType> | undefined
  vcsUserName?: string
  size: Size
  srcSize?: AvatarSize
  src2xSize?: AvatarSize
  className?: string
  avatarClassName?: string
  displayPlaceholder?: boolean
  withOutline?: boolean
  relative?: boolean
}

function Avatar({
  user,
  vcsUserName,
  size,
  srcSize = getAvatarSizeKey(size),
  src2xSize = getAvatarSizeKey(2 * size),
  className,
  avatarClassName,
  displayPlaceholder = true,
  withOutline = true,
  relative = true,
}: Props): ReactElement | null {
  const isMine = useSelector(state => user?.id != null && user?.id === getCurrentUserId(state))
  const isHighlightMyChanges = useSelector(getIsHightlightMyChangesAndInvestigations)
  const maybeAvatar = user?.avatars?.[srcSize]
  const avatar = maybeAvatar && relative ? resolveRelative(maybeAvatar) : maybeAvatar
  const maybeAvatar2x = user?.avatars?.[src2xSize]
  const avatar2x = maybeAvatar2x && relative ? resolveRelative(maybeAvatar2x) : maybeAvatar2x
  const username = user?.name ?? user?.username ?? vcsUserName

  return (displayPlaceholder && username != null) || avatar2x ? (
    <span
      className={classNames(styles.wrapper, className, {
        [styles.withOutline]: withOutline,
        [styles.my]: isMine && isHighlightMyChanges,
      })}
      style={{height: size, width: size}}
    >
      <RingAvatar
        className={classNames(styles.avatar, avatarClassName)}
        url={avatar2x ?? avatar}
        srcSet={avatar && avatar2x && `${avatar}, ${avatar2x} 2x`}
        size={size}
        username={username}
        skipParams
      />
    </span>
  ) : null
}

export {Size}
export default Avatar
