import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import {ChangeType} from '../../../types'
import {getChangesFields} from '../../Changes/Changes.rest'

export const requestModificationsOfChanges = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeType>> =>
  request(
    serverUrl,
    `changes?locator=${encodeURIComponent(
      locator,
    )}&fields=change(id,mergedInfo(changes(${getChangesFields()})))`,
  )
    .then<{change: ReadonlyArray<ChangeType>}>(processResponse)
    .then(data => data.change)
