import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown'
import {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'
import classnames from 'classnames'
import * as React from 'react'
import {Ref, useMemo} from 'react'
import {useSelector} from 'react-redux'

import EntityLink from '../../../containers/EntityLink'
import {getBuild, getIsExperimentalUI, makeGetMainUILinkWithBranch} from '../../../selectors'
import type {BuildId, BuildTypeId, ProjectId} from '../../../types'
import Icon from '../../ProjectOrBuildTypeIcon/OverviewStatusIcon.container'
import iconStyles from '../../ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon.css'
import BreadcrumbsDropdown from '../BreadcrumbsDropdown/BreadcrumbsDropdown'
import Link from '../Link/Link'
import Popup from '../Popup/Popup.lazy'

import styles from './BuildPath.css'

type Props = {
  readonly className?: string
  readonly buildId?: BuildId | null | undefined
  readonly buildTypeId?: BuildTypeId
  readonly projectId?: ProjectId
  readonly name: string
  readonly itemKey: string
  readonly setRef?: (el: HTMLElement | null, id: string) => unknown
  readonly showTitle?: boolean
  readonly withIcon?: boolean
  readonly withStatusIcon?: boolean | null | undefined
  readonly secondary?: boolean
  withPopup?: boolean
}

function BuildPathLink({
  buildId,
  buildTypeId,
  projectId,
  name,
  setRef,
  showTitle,
  className,
  withIcon,
  withStatusIcon,
  itemKey,
  secondary,
  withPopup,
}: Props) {
  const isExperimentalUI = useSelector(getIsExperimentalUI)
  const branchName = useSelector(state => getBuild(state, buildId)?.branchName)
  const defaultBranch = useSelector(state => getBuild(state, buildId)?.defaultBranch)
  const getMainUIHref = React.useMemo(
    () =>
      makeGetMainUILinkWithBranch(
        {
          buildTypeId,
          projectId,
        },
        branchName != null
          ? {
              name: branchName,
              default: defaultBranch,
            }
          : null,
      ),
    [branchName, buildTypeId, defaultBranch, projectId],
  )
  const mainUIHref = useSelector(getMainUIHref)
  const ref: Ref<HTMLSpanElement> | undefined = React.useMemo(
    () => (setRef != null ? el => setRef(el, itemKey) : undefined),
    [itemKey, setRef],
  )
  const classes = classnames(styles.link, className, {
    [styles.secondary]: secondary,
  })
  const iconProps = useMemo(
    () =>
      projectId != null
        ? ({
            type: 'project',
            id: projectId,
          } as const)
        : buildTypeId != null
        ? ({
            type: 'buildType',
            id: buildTypeId,
          } as const)
        : {
            type: undefined,
          },
    [buildTypeId, projectId],
  )
  const commonProps: Pick<LinkProps, 'className' | 'data-test' | 'children'> = {
    className: classes,
    'data-test': 'path-link',
    children: name,
  }
  const link = isExperimentalUI ? (
    <EntityLink {...commonProps} buildTypeId={buildTypeId} projectId={projectId} />
  ) : (
    <Link {...commonProps} href={mainUIHref} />
  )

  const icon = useMemo(
    () => <Icon {...iconProps} withArrow={withPopup} ignoreStatus={withStatusIcon !== true} />,
    [iconProps, withPopup, withStatusIcon],
  )
  return (
    <span ref={ref} className={classnames(styles.item, {[iconStyles.arrowWrapper]: withPopup})}>
      {(withIcon === true || withStatusIcon === true) &&
        (withPopup ? (
          <BreadcrumbsDropdown projectId={projectId} buildTypeId={buildTypeId} icon={icon} />
        ) : (
          icon
        ))}
      {showTitle === true ? (
        <Dropdown
          className={styles.pathItemDropDown}
          anchor={link}
          hoverShowTimeOut={300}
          hoverHideTimeOut={300}
          clickMode={false}
          hoverMode
        >
          <Popup left={-1} directions={[Directions.TOP_RIGHT, Directions.BOTTOM_RIGHT]}>
            <div className={styles.hiddenPathPopup}>{link}</div>
          </Popup>
        </Dropdown>
      ) : (
        link
      )}
    </span>
  )
}

export default React.memo(BuildPathLink)
