import * as Redux from 'redux'

import type {Action} from '../../../actions/types'
import fetchable from '../../../reducers/fetchable'
import type {Fetchable} from '../../../types'
import {emptyNullFetchable} from '../../../utils/empty'

import {
  REQUEST_AGENT_TOKEN,
  RECEIVE_AGENT_TOKEN,
  REQUEST_AGENT_CONFIG,
  RECEIVE_AGENT_CONFIG,
  CLEAR_AGENT_AUTH,
  AgentAuthStateType,
} from './AgentAuth.types'

const tokenReducer = fetchable<string | null, string>(
  REQUEST_AGENT_TOKEN,
  RECEIVE_AGENT_TOKEN,
  null,
  action => action.data,
)
const configReducer = fetchable<string | null, string>(
  REQUEST_AGENT_CONFIG,
  RECEIVE_AGENT_CONFIG,
  null,
  action => action.data,
)
const agentAuth = Redux.combineReducers<AgentAuthStateType>({
  token: (state: Fetchable<string | null> = emptyNullFetchable, action: Action) => {
    switch (action.type) {
      case REQUEST_AGENT_TOKEN:
      case RECEIVE_AGENT_TOKEN:
        return tokenReducer(state, action)

      case CLEAR_AGENT_AUTH:
        return emptyNullFetchable

      default:
        return state
    }
  },
  config: (state: Fetchable<string | null> = emptyNullFetchable, action: Action) => {
    switch (action.type) {
      case REQUEST_AGENT_CONFIG:
      case RECEIVE_AGENT_CONFIG:
        return configReducer(state, action)

      case CLEAR_AGENT_AUTH:
        return emptyNullFetchable

      default:
        return state
    }
  },
})
export default agentAuth
