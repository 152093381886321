import type {KeyValue} from '../../utils/object'

import type {AgentBranchId, AgentBranchType} from './AgentsSidebar/AgentsSidebar.types'

export const SET_AGENTS_TREE_ITEM_EXPAND_STATE: 'SET_AGENTS_TREE_ITEM_EXPAND_STATE' =
  'SET_AGENTS_TREE_ITEM_EXPAND_STATE'
export const SEARCH_IN_AGENTS_TREE: 'SEARCH_IN_AGENTS_TREE' = 'SEARCH_IN_AGENTS_TREE'
export type AgentsTreeExpandState = KeyValue<AgentBranchType, KeyValue<AgentBranchId, boolean>>
export type AgentsTreeSearchState = {
  searchQuery: string
}
export type AgentsScreenState = {
  readonly agentsTreeExpandState: AgentsTreeExpandState
  readonly agentsTreeSearchExpandState: AgentsTreeExpandState
  readonly agentsTreeSearchState: AgentsTreeSearchState
}
export type AgentsScreenAction =
  | {
      type: typeof SET_AGENTS_TREE_ITEM_EXPAND_STATE
      readonly agentItemId: AgentBranchId
      readonly agentItemType: AgentBranchType
      readonly expanded: boolean
    }
  | {
      type: typeof SEARCH_IN_AGENTS_TREE
      readonly searchQuery: string
    }
