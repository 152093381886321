import batchVisible from '../../../actions/batchVisible'
import {buildDetailsQueue} from '../../../actions/buildDetailsQueue'
import type {AppThunk} from '../../../actions/types'
import {normalizeBuildArtifacts} from '../../../rest/schemata'
import {stringifyId} from '../../../types'
import type {BuildArtifactsType, BuildId} from '../../../types'
import {Namespaces} from '../../../utils/namespaces'

import requestBuildArtifacts from './BuildArtifacts.rest'
import {buildArtifacts} from './BuildArtifacts.slice'

export const receiveBuildArtifacts = (data: ReadonlyArray<BuildArtifactsType>) =>
  buildArtifacts.actions.receive(normalizeBuildArtifacts(data))
export const fetchBuildArtifactsCountQueue: (id: BuildId) => AppThunk<void> = batchVisible({
  queue: buildDetailsQueue,
  namespace: Namespaces.ARTIFACTS,
  request: (restRoot, id) => requestBuildArtifacts(restRoot, `id:${stringifyId(id)}`),
  action: receiveBuildArtifacts,
})
