import HTTPError, {HTTPCodesEnum} from '../../../../rest/errors/HTTPError'
import processResponse from '../../../../rest/processResponse'
import request from '../../../../rest/request'
import type {BuildId} from '../../../../types'
import {Properties, stringifyId} from '../../../../types'
import {getPropertyFromList} from '../../../../utils/getProperty'

type Storage = {
  name: string
  type: string
}
const STORAGE_FEATURE_ID = 'teamcity.storage.feature.id'
type ResultingPropertiesData = {
  resultingProperties: Properties
}
type PropertiesData = {
  properties: Properties
}
export async function requestArtifactStorage(
  serverUrl: string,
  buildId: BuildId,
): Promise<Storage | null> {
  const {resultingProperties} = await request(
    serverUrl,
    `builds/id:${stringifyId(
      buildId,
    )}?fields=resultingProperties(property(name,value),$locator:(name:${STORAGE_FEATURE_ID}))`,
  ).then<ResultingPropertiesData>(processResponse)
  const featureId = getPropertyFromList(STORAGE_FEATURE_ID, resultingProperties)

  if (featureId == null) {
    return null
  }

  try {
    const {properties} = await request(
      serverUrl,
      `projects/projectFeature:id:${featureId}/projectFeatures/id:${featureId}?fields=properties(property(name,value))`,
    ).then<PropertiesData>(processResponse)
    return {
      name: getPropertyFromList('storage.name', properties) ?? '',
      type: getPropertyFromList('storage.type', properties) ?? '',
    }
  } catch (e) {
    if (e instanceof HTTPError && e.statusCode === HTTPCodesEnum.NOT_FOUND) {
      // feature not found
      return null
    }
    throw e
  }
}
