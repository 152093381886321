import * as Redux from 'redux'

import type {Action} from '../../../actions/types'
import fetchable from '../../../reducers/fetchable'
import {keyValueReducer} from '../../../reducers/utils'
import {emptyNullFetchable} from '../../../utils/empty'
import {
  FormAction,
  RECEIVE_CREATE_CLEANUP_RULE,
  RECEIVE_UPDATE_CLEANUP_RULE,
  REQUEST_CREATE_CLEANUP_RULE,
  REQUEST_UPDATE_CLEANUP_RULE,
} from '../Cleanup.types'
import type {Form} from '../Cleanup.types'
import {getKey} from '../Cleanup.utils'

const cleanupCreateRulesReducer = fetchable<FormAction | null, never>(
  REQUEST_CREATE_CLEANUP_RULE,
  RECEIVE_CREATE_CLEANUP_RULE,
  null,
  () => 'create',
)
const cleanupUpdateRulesReducer = fetchable<FormAction | null, never>(
  REQUEST_UPDATE_CLEANUP_RULE,
  RECEIVE_UPDATE_CLEANUP_RULE,
  null,
  () => 'update',
)
const reducer: Redux.Reducer<Form, Action> = keyValueReducer(
  action => getKey(action.holderNode),
  (state = emptyNullFetchable, action) => {
    switch (action.type) {
      case REQUEST_CREATE_CLEANUP_RULE:
      case RECEIVE_CREATE_CLEANUP_RULE:
        return cleanupCreateRulesReducer(state, action)

      case REQUEST_UPDATE_CLEANUP_RULE:
      case RECEIVE_UPDATE_CLEANUP_RULE:
        return cleanupUpdateRulesReducer(state, action)

      default:
        return state
    }
  },
  [
    REQUEST_CREATE_CLEANUP_RULE,
    RECEIVE_CREATE_CLEANUP_RULE,
    REQUEST_UPDATE_CLEANUP_RULE,
    RECEIVE_UPDATE_CLEANUP_RULE,
  ],
)
export default reducer
