import {mergeIfDifferent} from '../reducers/utils'
import {requestProjectIdsByLocator, requestProjectInternalId} from '../rest/projects'
import {ProjectId, stringifyId} from '../types'
import {getEmptyHash} from '../utils/empty'
import {KeyValue} from '../utils/object'

import {createFetchableSlice, FetchableSlice} from './fetchable'

export const readOnlyProjects: FetchableSlice<
  ProjectId,
  KeyValue<ProjectId, boolean>
> = createFetchableSlice({
  name: 'readOnlyProjects',
  request: (restRoot, projectId) =>
    requestProjectIdsByLocator(
      restRoot,
      `item(readOnlyUI:true,affectedProject:(id:${stringifyId(
        projectId,
      )})),item(readOnlyUI:true,id:${stringifyId(projectId)})`,
    ).then(ids => Object.fromEntries(ids.map(id => [id, true]))),
  getKey: () => 'all',
  defaultState: getEmptyHash(),
  dataReducer: (state, {payload}) => mergeIfDifferent(state, payload),
})

export const projectInternalIds = createFetchableSlice({
  name: 'projectInternalIds',
  request: requestProjectInternalId,
  getKey: id => id,
  defaultState: null,
})
