/* eslint-disable consistent-this */
import fastdom, {Extended} from 'fastdom'
import FastdomPromised from 'fastdom/extensions/fastdom-promised'
import {batch} from 'react-redux'

type Task = () => unknown
let tasks: Task[] = []
const flushTasks = new WeakSet()
const results = new WeakMap()
const tasksByJob = new WeakMap()

const flush = () => {
  if (tasks.length > 0) {
    const currentTasks = tasks
    tasks = []
    batch(() => currentTasks.forEach(item => results.set(item, item())))
  }
}

type Fastdom = typeof fastdom

type FastdomPromisedExtension = {
  clear: typeof FastdomPromised.clear
  initialize: typeof FastdomPromised.initialize
  measure: typeof FastdomPromised.measure
  mutate: typeof FastdomPromised.mutate
}

type Context = {
  fastdom: Extended<Fastdom, FastdomPromisedExtension>
}

const extension = {
  measure<T extends Task>(this: Context, task: T) {
    return this.fastdom.measure(task)
  },

  mutate<T extends Task>(this: Context, task: T, ctx?: unknown): Promise<ReturnType<T>> {
    if (flushTasks.has(task)) {
      return this.fastdom.mutate(task, ctx)
    }

    tasks.push(task)
    let job: Promise<ReturnType<T>> | null = null
    let done = false

    const flushTask = () => {
      flush()

      if (job != null) {
        tasksByJob.delete(job)
      }

      done = true
      return results.get(task)
    }

    flushTasks.add(flushTask)
    job = this.fastdom.mutate(flushTask, ctx)

    if (!done) {
      tasksByJob.set(job, task)
    }

    return job
  },

  clear(this: Context, job: Promise<any> | null | undefined): void {
    if (job != null) {
      const taskToClear = tasksByJob.get(job)
      tasks = tasks.filter(task => task !== taskToClear)
      this.fastdom.clear(job)
    }
  },
}
export default extension
