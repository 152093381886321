import fetchable from '../../../../../reducers/fetchable'
import {keyValueReducer} from '../../../../../reducers/utils'

import {emptyArray} from '../../../../../utils/empty'

import {getBuildSnippetKey} from './BuildSnippets.selectors'
import {
  REQUEST_BUILD_SNIPPET_LIST,
  RECEIVE_BUILD_SNIPPET_LIST,
  BuildSnippetType,
} from './BuildSnippets.types'

const fetchableReducer = fetchable<readonly BuildSnippetType[], readonly BuildSnippetType[]>(
  REQUEST_BUILD_SNIPPET_LIST,
  RECEIVE_BUILD_SNIPPET_LIST,
  emptyArray,
  action => action.data,
)

export default keyValueReducer(action => getBuildSnippetKey(action.buildId), fetchableReducer, [
  REQUEST_BUILD_SNIPPET_LIST,
  RECEIVE_BUILD_SNIPPET_LIST,
])
