import experimentIcon from '@jetbrains/icons/experiment-20px.svg'
import * as React from 'react'
import {useSelector} from 'react-redux'

import showOnce from '../../hocs/showOnce'
import {getIsExperimentalUI} from '../../selectors'
import {UserProperties} from '../../utils/userProperties'
import Link from '../common/Link/Link'
import {aboutExperimentalUiHref} from '../HelpDropdown/HelpDropdown.consts'
import Message from '../Message/Message.lazy'

import styles from './ExperimentalUIWelcome.css'

const TAIL_OFFSET = 19
type Props = {
  readonly adjustTail?: number
  readonly rightOffset?: number
}
export const ExperimentalUIWelcome: React.ComponentType<Props> = React.memo(
  function ExperimentalUIWelcome({adjustTail = 0}: Props) {
    const [show, onClose] = React.useReducer(() => false, true)
    return show ? (
      <React.Suspense fallback="">
        <Message
          className={styles.container}
          icon={experimentIcon}
          tailOffset={TAIL_OFFSET + adjustTail}
          title="Welcome to Experimental UI"
          onClose={onClose}
        >
          {
            'TeamCity experimental\u00a0UI is\u00a0a\u00a0work in\u00a0progress. Refer to\u00a0our\u00a0'
          }
          <Link href={aboutExperimentalUiHref}>{'documentation'}</Link>{' '}
          {'to\u00a0learn about its\u00a0features and roadmap. Your\u00a0feedback is\u00a0welcome!'}
        </Message>
      </React.Suspense>
    ) : null
  },
)
const ExperimentalUIWelcomeContainer = showOnce(UserProperties.HAS_SEEN_EXPERIMENTAL_UI_WELCOME)(
  function ExperimentalUIWelcomeContainer({adjustTail, rightOffset}: Props) {
    return <ExperimentalUIWelcome adjustTail={adjustTail} rightOffset={rightOffset} />
  },
)
export default function ExperimentalUIWelcomeConditional(props: Props) {
  const isExperimentalUi = useSelector(getIsExperimentalUI)
  return isExperimentalUi ? <ExperimentalUIWelcomeContainer {...props} /> : null
}
