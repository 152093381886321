import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'

import {fetchBuildsCount} from '../../../../actions/builds'
import type {Action} from '../../../../actions/types'
import {showQueuedBuildsInBuildsList} from '../../../../slices'
import buildsFilters from '../../../../slices/buildsFilters'
import {submitPager} from '../Pager.actions.base'
import type {PagerType} from '../Pager.types'
import {PagerGroup} from '../Pager.types'
import {hasItemsOnPage, needsLoadMore} from '../Pager.utils'

const defaultPager: PagerType = {
  show: false,
  currentPage: 1,
  precountedPages: 4,
  pageSize: 50,
  total: 0,
  openTotal: false,
  hasMore: true,
  lookupLimit: 50000,
  lookupLimitWarning: false,
  lookupDelta: 50000,
  loadedLessThanRequested: null,
}

const pager = createSlice({
  name: 'buildPager',
  initialState: defaultPager,
  reducers: {
    changeCurrentPage(state, action: PayloadAction<number>) {
      const {lookupLimit = 0, lookupDelta = 0, lookupLimitWarning: warning, hasMore: more} = state
      const updateLimit = warning === true && needsLoadMore(action.payload, state)
      state.currentPage = action.payload
      state.lookupLimit = updateLimit ? lookupLimit + lookupDelta : lookupLimit
      state.show = hasItemsOnPage(action.payload, state) || !more
    },
    incrementLookupLimit(state) {
      const {lookupLimit = 0, lookupDelta = 0} = state
      state.lookupLimit = lookupLimit + lookupDelta
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchBuildsCount.pending, (state, action) => {
      if (!action.meta.arg.inBackground) {
        state.show = false
        state.isLoading = true
      }
    })
    function reset(state: Draft<PagerType>) {
      state.total = 0
      state.openTotal = false
      state.lookupLimitWarning = false
      state.show = false
      state.isLoading = false
    }
    builder.addCase(fetchBuildsCount.fulfilled, (state, action) => {
      const actionData = action.payload

      if (actionData == null) {
        reset(state)
        return
      }

      const hasMore = actionData.nextHref != null
      const loadedLessThanRequested = actionData.count < action.meta.arg.count
      const lookupLimitWarning = loadedLessThanRequested && hasMore
      const total = actionData.count
      state.total = total
      state.lookupLimitWarning = lookupLimitWarning
      const hasItems = state.currentPage != null && hasItemsOnPage(state.currentPage, state)
      state.hasMore = hasMore
      state.openTotal = hasMore && !loadedLessThanRequested
      state.show = (loadedLessThanRequested && total > 0) || hasItems
      state.isLoading = false
    })
    builder.addCase(fetchBuildsCount.rejected, reset)
    builder.addCase(submitPager, (state, action) => {
      if (action.meta.group === PagerGroup.BUILD) {
        Object.assign(state, action.payload)
      }
    })
    builder.addDefaultCase((state, action: Action) => {
      if (
        buildsFilters.actions.changeStateFilter.match(action) ||
        buildsFilters.actions.changeProjectBuildtypeFilter.match(action) ||
        buildsFilters.actions.setTagFilter.match(action) ||
        buildsFilters.actions.toggleAdvandedMode.match(action) ||
        showQueuedBuildsInBuildsList.actions.toggle.match(action)
      ) {
        state.currentPage = 1
        state.show = false
      }
    })
  },
})

export default pager
