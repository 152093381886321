import {batch} from 'react-redux'

import {fetchHasBuilds, fetchSingleBuildData} from '../../../actions/builds'
import {createFetchAction} from '../../../reducers/fetchable'
import {changeTags} from '../../../rest/builds'
import {getIsExperimentalUI, getTags} from '../../../selectors'
import {STAR_TAG, STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER} from '../../../types'
import type {BuildId} from '../../../types'

type StarBuildArg = {
  buildId: BuildId
  starred: boolean
}
export const starBuildAction = createFetchAction(
  'starBuild',
  async ({buildId, starred}: StarBuildArg, {dispatch, getState}) => {
    const state = getState()
    const tags = getTags(state, buildId)
    await changeTags(
      state.restRoot,
      buildId,
      starred
        ? tags.concat({
            name: STAR_TAG,
            private: true,
          })
        : tags,
    )
    batch(() => {
      if (getIsExperimentalUI(state)) {
        dispatch(fetchHasBuilds(STARRED_LOCATOR_WITHOUT_DEFAULT_FILTER))
      }

      dispatch(fetchSingleBuildData(buildId))
    })
  },
)
export const starBuild = (buildId: BuildId, starred: boolean) => starBuildAction({buildId, starred})
