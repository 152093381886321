import {createFetchAction} from '../../../reducers/fetchable'
import requestAgents from '../../../rest/agents'
import {restRoot} from '../../../rest/consts'
import {
  normalizeAgentPreviews,
  normalizeAgents,
  normalizeBuildTypeLinksFromAgentsAndBuilds,
} from '../../../rest/schemata'
import type {AgentRequestOptions, AgentType} from '../../../types'

const processAgents = (data: readonly AgentType[]) => {
  const agents = normalizeAgents(data)
  Object.assign(
    agents.entities,
    normalizeAgentPreviews(data).entities,
    normalizeBuildTypeLinksFromAgentsAndBuilds(data).entities,
  )
  return agents
}

type FetchAgentsDataArg = AgentRequestOptions & {
  locator: string
}
export const fetchAgentsData = createFetchAction(
  'fetchAgentsData',
  ({locator, ...additionalFields}: FetchAgentsDataArg) =>
    requestAgents(restRoot, locator, additionalFields).then(processAgents),
)

export const receiveAgents = (locator: string, data: ReadonlyArray<AgentType>) =>
  fetchAgentsData.fulfilled(processAgents(data), '', {locator})
