import memoize from '@jetbrains/ring-ui/components/global/memoize'
import {$Values} from 'utility-types'

import type {
  BuildTypeId,
  BuildTypeInternalId,
  BuildTypeType,
  Fetchable,
  NormalizedProjectType,
  ProjectId,
  ProjectInternalId,
} from '../../types'
import type {KeyValue} from '../../utils/object'

const FilterBranchStatus = Object.freeze({
  active: 'active',
  inactive: 'inactive',
})
const FilterBuildStatus = Object.freeze({
  any: 'any',
  successful: 'successful',
  failed: 'failed',
  failed_to_start: 'failed_to_start',
})
const FilterPersonal = Object.freeze({
  any: 'any',
  personal: 'personal',
  not_personal: 'not_personal',
})
export type FilterBranchStatusType = $Values<typeof FilterBranchStatus>
export type FilterBuildStatusType = $Values<typeof FilterBuildStatus>
export type FilterPersonalType = $Values<typeof FilterPersonal>
type EmptyObject = {} | null | undefined
export type FilterBranchActivityType = {
  readonly activity?: FilterBranchStatusType
}
export type FilterPersonalFormType = {
  personal: FilterPersonalType
}
export type FilterBranchPatternType = {
  pattern?: string
}
type FilterType = {
  activeBranch?: FilterBranchActivityType | null | undefined
  branchPattern?: FilterBranchPatternType | null | undefined
  buildStatus?:
    | {
        status: FilterBuildStatusType
      }
    | null
    | undefined
  personalBuild?: FilterPersonalFormType | null | undefined
  tags?:
    | {
        tagsList: string
      }
    | null
    | undefined
}
export type KeepBuildPartsType = {
  artifacts?:
    | {
        artifactPatterns?: string
      }
    | null
    | undefined
  everything?: EmptyObject
  logs?: EmptyObject
  history?: EmptyObject
  statistics?: EmptyObject
}
export type PartitionsType = {
  perBranch?:
    | {
        active?: boolean
      }
    | null
    | undefined
  perPersonal?:
    | {
        active?: boolean
      }
    | null
    | undefined
  perBranchAndActivity?:
    | {
        active?: boolean
      }
    | null
    | undefined
}
export type RuleId = string

/** LIMITS */
type LimitAllName = 'all'
type LimitDaysNames =
  | 'keepNDaysSinceLastBuild'
  | 'keepNDaysSinceLastSuccessfulBuild'
  | 'keepNDaysSinceToday'
type LimitBuildsName = 'keepLastNBuilds'
export type LimitNames = LimitDaysNames | LimitBuildsName | LimitAllName
export type LimitValueTypes = 'daysCount' | 'buildsCount'
export type LimitType = KeyValue<LimitNames, KeyValue<LimitValueTypes, number> | null | undefined>
type ParamsType = {}

/** CLEANUP HOLDER CALCULATION */
export type CleanupHolderNodeType =
  | {
      readonly nodeType: 'project'
      readonly id: ProjectId
      internalId: ProjectInternalId | null | undefined
      name?: string
    }
  | {
      readonly nodeType: 'bt'
      readonly id: BuildTypeId
      internalId: BuildTypeInternalId | null | undefined
      name?: string
    }
export const getCleanupBuildTypeNode = memoize(
  ({
    id,
    internalId,
    name,
  }: Partial<BuildTypeType> & {
    readonly id: BuildTypeId
  }): CleanupHolderNodeType => ({
    nodeType: 'bt',
    id,
    internalId,
    name,
  }),
)
export const getCleanupProjectNode = memoize(
  ({
    id,
    internalId,
    name,
  }: Partial<NormalizedProjectType> & {
    readonly id: ProjectId
  }): CleanupHolderNodeType => ({
    nodeType: 'project',
    id,
    internalId,
    name,
  }),
)

/** RULE */
type CleanupHierarchyTypes = 'own' | 'effective'
export type FetchRulesOptons = {
  hierarchy?: CleanupHierarchyTypes
  getChildrensRules?: ('withAllInheritors' | 'withOwnBuildTypes') | null | undefined
}
export type RuleCommon = {
  ruleId?: RuleId
  ruleDisabled: boolean
}
export type RuleParameters = {
  keepBuildParts: KeepBuildPartsType
  filters: FilterType
  partitions: PartitionsType
  limit: LimitType
  params: ParamsType
  preserveArtifacts: boolean | null | undefined
}
type RuleMeta = {
  source: 'own' | 'inherited' | 'enforced'
  holder: ('buildType' | 'project') | null | undefined
  holderExternalId: (BuildTypeId | ProjectId) | null | undefined
  overridesOwn: boolean | null | undefined
}
export type RuleType = RuleCommon & RuleMeta & RuleParameters

/** REST **/
export const REQUEST_CLEANUP_RULES: 'REQUEST_CLEANUP_RULES' = 'REQUEST_CLEANUP_RULES'
export const RECEIVE_CLEANUP_RULES: 'RECEIVE_CLEANUP_RULES' = 'RECEIVE_CLEANUP_RULES'
export const REQUEST_UPDATE_CLEANUP_RULE: 'REQUEST_UPDATE_CLEANUP_RULE' =
  'REQUEST_UPDATE_CLEANUP_RULE'
export const RECEIVE_UPDATE_CLEANUP_RULE: 'RECEIVE_UPDATE_CLEANUP_RULE' =
  'RECEIVE_UPDATE_CLEANUP_RULE'
export const REQUEST_CREATE_CLEANUP_RULE: 'REQUEST_CREATE_CLEANUP_RULE' =
  'REQUEST_CREATE_CLEANUP_RULE'
export const RECEIVE_CREATE_CLEANUP_RULE: 'RECEIVE_CREATE_CLEANUP_RULE' =
  'RECEIVE_CREATE_CLEANUP_RULE'
export const REQUEST_DELETE_CLEANUP_RULE: 'REQUEST_DELETE_CLEANUP_RULE' =
  'REQUEST_DELETE_CLEANUP_RULE'
export const RECEIVE_DELETE_CLEANUP_RULE: 'RECEIVE_DELETE_CLEANUP_RULE' =
  'RECEIVE_DELETE_CLEANUP_RULE'
const AnswerStatuses = {
  success: 'success' as 'success',
  error: 'error' as 'error',
}
export type ErrorAnswerType = {
  status: typeof AnswerStatuses.error
  code?: string
  errorId: string
  errorType?: string
  message: string
}
type SuccessAnswerType<T> = {
  status: typeof AnswerStatuses.success
  data: T
}
type JSendResponseType<T> = SuccessAnswerType<T>
export type HolderRuleType =
  | {
      holder: 'project'
      holderExternalId: ProjectId
      rules: ReadonlyArray<RuleType>
    }
  | {
      holder: 'buildType' | 'template'
      holderExternalId: BuildTypeId
      rules: ReadonlyArray<RuleType>
    }
export type GetRulesResponseType = JSendResponseType<{
  holdersWithRules: ReadonlyArray<HolderRuleType>
}>
export type CreateRuleAnswerType = JSendResponseType<{
  ruleId: RuleId
}>
type RequestRuleType = {
  type: typeof REQUEST_CLEANUP_RULES
  readonly holderNode: CleanupHolderNodeType
}
type ReceiveRuleType = {
  type: typeof RECEIVE_CLEANUP_RULES
  readonly holderNode: CleanupHolderNodeType
  readonly data?: ReadonlyArray<HolderRuleType>
  readonly customError?: ErrorAnswerType
  readonly error?: Error
}
type RequestCreateRuleType = {
  type: typeof REQUEST_CREATE_CLEANUP_RULE
  readonly holderNode: CleanupHolderNodeType
  rule: RuleType
}
type ReceiveCreateRuleType = {
  type: typeof RECEIVE_CREATE_CLEANUP_RULE
  readonly holderNode: CleanupHolderNodeType
  readonly rule?: RuleType
  readonly error?: Error
  readonly customError?: ErrorAnswerType
  readonly ruleId?: RuleId
}
type RequestUpdateRuleType = {
  type: typeof REQUEST_UPDATE_CLEANUP_RULE
  readonly holderNode: CleanupHolderNodeType
  rule: RuleType
}
type ReceiveUpdateRuleType = {
  type: typeof RECEIVE_UPDATE_CLEANUP_RULE
  readonly holderNode: CleanupHolderNodeType
  readonly rule: RuleType
  readonly error?: Error
  readonly customError?: ErrorAnswerType
}
type RequestDeleteRuleType = {
  type: typeof REQUEST_DELETE_CLEANUP_RULE
  readonly holderNode: CleanupHolderNodeType
  readonly ruleId: RuleId
}
type ReceiveDeleteRuleType = {
  type: typeof RECEIVE_DELETE_CLEANUP_RULE
  readonly holderNode: CleanupHolderNodeType
  readonly ruleId: RuleId
  readonly error?: Error | null | undefined
  readonly customError?: ErrorAnswerType | null | undefined
}
export type CleanupReceiveActions =
  | typeof RECEIVE_CLEANUP_RULES
  | typeof RECEIVE_UPDATE_CLEANUP_RULE
  | typeof RECEIVE_CREATE_CLEANUP_RULE
  | typeof RECEIVE_DELETE_CLEANUP_RULE
export type CleanupActions =
  | RequestCreateRuleType
  | ReceiveCreateRuleType
  | RequestRuleType
  | ReceiveRuleType
  | RequestUpdateRuleType
  | ReceiveUpdateRuleType
  | RequestDeleteRuleType
  | ReceiveDeleteRuleType
export type LastActionType = {
  action: CleanupReceiveActions | null
  loading: boolean
  ready: boolean
}

/** STATE **/
export type FormAction = 'create' | 'update' | 'disable'
export type Form = KeyValue<string, Fetchable<FormAction | null>>
export type CleanupStateType = {
  entities: KeyValue<string, ReadonlyArray<RuleType>>
  errors: ErrorAnswerType | null | undefined
  form: Form
  requestStatus: KeyValue<string, Fetchable<CleanupReceiveActions | null>>
}

/** PROPS **/
export type OwnProps = {
  readonly buildTypeId: BuildTypeId | null | undefined
  readonly projectId: ProjectId
  readonly isTemplate: boolean
  readonly onRuleEdit?: () => unknown
  readonly inheritedVisible?: boolean
  readonly embedded?: boolean
  readonly hideOwn?: boolean
  readonly hideInherited?: boolean
  readonly hideEnforced?: boolean
  readonly holderNode: CleanupHolderNodeType
}
type VisibilityProps = {
  readonly isVisible: boolean | null | undefined
}
export type ReduxOwnProps = OwnProps & VisibilityProps
export type StateProps = {
  readonly lastAction: LastActionType
  readonly buildTypeTagsReady: boolean
}
export type ActionProps = {
  readonly fetchTags: (arg0: BuildTypeId) => unknown
}
export type Props = ReduxOwnProps & StateProps & ActionProps
