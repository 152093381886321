import {batch} from 'react-redux'

import {fetchSingleBuildData} from '../../../actions/builds'
import type {AppThunk} from '../../../actions/types'
import {stoppingBuilds} from '../../../slices'
import type {BuildId} from '../../../types'

const markBuildAsStopping = stoppingBuilds.actions.add

export const refreshBuildAfterStopping =
  (id: BuildId): AppThunk<any> =>
  dispatch =>
    batch(() => {
      dispatch(markBuildAsStopping(id))
      return dispatch(fetchSingleBuildData(id))
    })
