import * as React from 'react'
import * as ReactRedux from 'react-redux'
import type {ResolveThunks} from 'react-redux'
import * as Redux from 'redux'

import {fetchCurrentUserData} from '../../../actions'
import {fetchProjectsData} from '../../../actions/projects'
import type {AppDispatch, AppThunk} from '../../../actions/types'
import type {AgentRequestOptions} from '../../../types'
import Fetcher from '../../Fetcher/Fetcher'
import type {FetcherProps} from '../../Fetcher/Fetcher'

import {fetchAgentsData} from './Agents.actions'

type ActionCreators = {
  readonly fetchData: (locator: string, inBackground?: boolean) => AppThunk<Promise<unknown>>
  readonly fetchProjectsData: () => unknown
  readonly fetchCurrentUserData: () => unknown
}
type ActionProps = ResolveThunks<ActionCreators>
export type AgentsFetcherProps = Omit<FetcherProps, keyof ActionProps> & {
  readonly additionalFields?: AgentRequestOptions
}
type Props = AgentsFetcherProps & ActionProps

const mapDispatchToProps = (dispatch: AppDispatch, props: AgentsFetcherProps): ActionProps =>
  Redux.bindActionCreators<ActionCreators, ActionProps>(
    {
      fetchData: locator => fetchAgentsData({locator, ...props.additionalFields}),
      fetchProjectsData,
      fetchCurrentUserData,
    },
    dispatch,
  )

class AgentsFetcher extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchProjectsData()
    this.props.fetchCurrentUserData()
  }

  render() {
    const {
      additionalFields,
      fetchCurrentUserData: _,
      fetchProjectsData: __,
      ...restProps
    } = this.props
    return <Fetcher {...restProps} />
  }
}

export default ReactRedux.connect(null, mapDispatchToProps)(AgentsFetcher)
