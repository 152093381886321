import * as Redux from 'redux'

import type {Action} from '../../actions/types'
import fetchable from '../../reducers/fetchable'
import {keyValueReducer, mergeIfDifferent} from '../../reducers/utils'
import type {Fetchable} from '../../types'
import {emptyNullFetchable} from '../../utils/empty'
import type {KeyValue, WritableKeyValue} from '../../utils/object'

import {
  REQUEST_CREATE_CLEANUP_RULE,
  RECEIVE_CREATE_CLEANUP_RULE,
  REQUEST_CLEANUP_RULES,
  RECEIVE_CLEANUP_RULES,
  REQUEST_UPDATE_CLEANUP_RULE,
  RECEIVE_UPDATE_CLEANUP_RULE,
  REQUEST_DELETE_CLEANUP_RULE,
  RECEIVE_DELETE_CLEANUP_RULE,
  getCleanupProjectNode,
  getCleanupBuildTypeNode,
} from './Cleanup.types'
import type {
  CleanupActions,
  ErrorAnswerType,
  RuleType,
  CleanupHolderNodeType,
  CleanupReceiveActions,
} from './Cleanup.types'
import {getKey} from './Cleanup.utils'
import formReducer from './CleanupForm/CleanupForm.reducers'

const cleanupGetRulesReducer = fetchable<CleanupReceiveActions | null, never>(
  REQUEST_CLEANUP_RULES,
  RECEIVE_CLEANUP_RULES,
  null,
  () => RECEIVE_CLEANUP_RULES,
)
const cleanupDeleteRulesReducer = fetchable<CleanupReceiveActions | null, never>(
  REQUEST_DELETE_CLEANUP_RULE,
  RECEIVE_DELETE_CLEANUP_RULE,
  null,
  () => RECEIVE_DELETE_CLEANUP_RULE,
)
const cleanupUpdateRulesReducer = fetchable<CleanupReceiveActions | null, never>(
  REQUEST_UPDATE_CLEANUP_RULE,
  RECEIVE_UPDATE_CLEANUP_RULE,
  null,
  () => RECEIVE_UPDATE_CLEANUP_RULE,
)
const cleanupCreateRulesReducer = fetchable<CleanupReceiveActions | null, never>(
  REQUEST_CREATE_CLEANUP_RULE,
  RECEIVE_CREATE_CLEANUP_RULE,
  null,
  () => RECEIVE_CREATE_CLEANUP_RULE,
)
const cleanupEntityReducer = keyValueReducer(
  action => getKey(action.holderNode),
  (state: ReadonlyArray<RuleType> = [], action: Action) => {
    switch (action.type) {
      case RECEIVE_CREATE_CLEANUP_RULE:
        if (!action.customError && !action.error && action.rule != null) {
          return state.concat({
            ruleId: action.ruleId,
            ...action.rule,
          })
        }

        return state

      case RECEIVE_DELETE_CLEANUP_RULE:
        if (!action.customError && !action.error) {
          return state.filter(rule => rule.ruleId !== action.ruleId)
        }

        return state

      case RECEIVE_UPDATE_CLEANUP_RULE:
        if (!action.customError && !action.error) {
          return state.map(rule => {
            if (rule.ruleId !== action.rule.ruleId) {
              return rule
            }

            return {...rule, ...action.rule}
          })
        }

        return state

      default:
        return state
    }
  },
  [RECEIVE_CREATE_CLEANUP_RULE, RECEIVE_DELETE_CLEANUP_RULE, RECEIVE_UPDATE_CLEANUP_RULE],
)

const cleanupEntitiesReducer = (
  state: KeyValue<string, ReadonlyArray<RuleType>> = {},
  action: Action,
) => {
  switch (action.type) {
    case RECEIVE_CLEANUP_RULES:
      if (!action.customError && !action.error && action.data != null) {
        const data: WritableKeyValue<string, ReadonlyArray<RuleType>> = {}

        for (const item of action.data) {
          const node: CleanupHolderNodeType =
            item.holder === 'project'
              ? getCleanupProjectNode({
                  id: item.holderExternalId,
                })
              : getCleanupBuildTypeNode({
                  id: item.holderExternalId,
                })
          const key = getKey(node)

          const currentState = state[key]
          if (item.rules.length > 0 || (currentState != null && currentState.length > 0)) {
            data[key] = item.rules
          }
        }

        return mergeIfDifferent(state, data)
      }

      return state

    default:
      return cleanupEntityReducer(state, action)
  }
}

const errorReducer = (
  state: ErrorAnswerType | null | undefined = null,
  action: Action,
): ErrorAnswerType | null | undefined => {
  switch (action.type) {
    case REQUEST_CLEANUP_RULES:
    case REQUEST_CREATE_CLEANUP_RULE:
    case REQUEST_UPDATE_CLEANUP_RULE:
    case REQUEST_DELETE_CLEANUP_RULE:
      return null

    case RECEIVE_CLEANUP_RULES:
    case RECEIVE_CREATE_CLEANUP_RULE:
    case RECEIVE_UPDATE_CLEANUP_RULE:
    case RECEIVE_DELETE_CLEANUP_RULE:
      return action.customError ?? null

    default:
      return state
  }
}

const requestStatusReducer = keyValueReducer(
  action => getKey(action.holderNode),
  (state: Fetchable<CleanupReceiveActions | null> = emptyNullFetchable, action: CleanupActions) => {
    switch (action.type) {
      case REQUEST_CLEANUP_RULES:
      case RECEIVE_CLEANUP_RULES:
        return cleanupGetRulesReducer(state, action)

      case REQUEST_DELETE_CLEANUP_RULE:
      case RECEIVE_DELETE_CLEANUP_RULE:
        return cleanupDeleteRulesReducer(state, action)

      case REQUEST_CREATE_CLEANUP_RULE:
      case RECEIVE_CREATE_CLEANUP_RULE:
        return cleanupCreateRulesReducer(state, action)

      case REQUEST_UPDATE_CLEANUP_RULE:
      case RECEIVE_UPDATE_CLEANUP_RULE:
        return cleanupUpdateRulesReducer(state, action)

      default:
        return state
    }
  },
  [
    REQUEST_CLEANUP_RULES,
    RECEIVE_CLEANUP_RULES,
    REQUEST_DELETE_CLEANUP_RULE,
    RECEIVE_DELETE_CLEANUP_RULE,
    REQUEST_CREATE_CLEANUP_RULE,
    RECEIVE_CREATE_CLEANUP_RULE,
    REQUEST_UPDATE_CLEANUP_RULE,
    RECEIVE_UPDATE_CLEANUP_RULE,
  ],
)
const cleanupReducer = Redux.combineReducers({
  errors: errorReducer,
  form: formReducer,
  entities: cleanupEntitiesReducer,
  requestStatus: requestStatusReducer,
})
export default cleanupReducer
