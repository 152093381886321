import {setDisplayName} from 'recompose'

import {useCallback} from 'react'
import type {ComponentType} from 'react'

import type {Enhancer} from '../types'

import {wrapDisplayName} from './getDisplayName'

const fromRenderProps =
  <OwnProps, RenderProps, AddProps>(
    RenderPropsComponent: ComponentType<any>,
    propsMapper: (arg0: RenderProps) => AddProps,
    renderPropName: string = 'children',
  ): Enhancer<AddProps, OwnProps> =>
  BaseComponent => {
    const FromRenderProps = (ownerProps: OwnProps) => (
      <RenderPropsComponent
        {...{
          [renderPropName]: useCallback(
            props => <BaseComponent {...ownerProps} {...propsMapper(props)} />,
            [ownerProps],
          ),
        }}
      />
    )

    if (process.env.NODE_ENV !== 'production') {
      return setDisplayName(wrapDisplayName(BaseComponent, 'fromRenderProps'))(FromRenderProps)
    }

    return FromRenderProps
  }

export default fromRenderProps
