import * as React from 'react'

import type {BaseReceiveAction} from '../../../actions/fetchActions'
import type {BuildId, Fetchable} from '../../../types'
import type {KeyValue} from '../../../utils/object'

export const REQUEST_WAIT_REASONS: 'REQUEST_WAIT_REASONS' = 'REQUEST_WAIT_REASONS'
export const RECEIVE_WAIT_REASONS: 'RECEIVE_WAIT_REASONS' = 'RECEIVE_WAIT_REASONS'
export type WaitReason = {
  reason: string
  durationInSeconds: number
}
export type WaitReasons = ReadonlyArray<WaitReason>
export type QueueInfoAction =
  | {
      type: typeof REQUEST_WAIT_REASONS
      readonly buildId: BuildId
    }
  | (BaseReceiveAction<WaitReasons> & {
      type: typeof RECEIVE_WAIT_REASONS
      readonly buildId: BuildId
    })
export type WaitReasonsMap = KeyValue<BuildId, Fetchable<WaitReasons>>
export type BuildQueueInfoStateType = {
  readonly waitReasons: WaitReasonsMap
}
export type OwnProps = {
  readonly className?: string
  readonly titleClassName?: string
  readonly reasonsClassName?: string
  readonly buildId: BuildId
  readonly inline?: boolean | null | undefined
  readonly aside?: React.ReactNode
  readonly dependenciesRange?: string | null | undefined
  readonly highlightText?: boolean
  readonly withoutTitle?: boolean
  readonly groupOwnReasons?: boolean
  readonly withIcons?: boolean
}
export type StateProps = {
  readonly queued: boolean
  readonly queuedDate: string | undefined
  readonly startDate: string | undefined
  readonly currentWaitReason: string | undefined
  readonly waitReasons: WaitReasons
  readonly waitReasonsReady: boolean
}

export type Props = OwnProps & StateProps
