import {createAction} from '@reduxjs/toolkit'

import {fetchTabs} from '../../actions'
import {removeAgent} from '../../actions/fetch'
import type {AppThunk} from '../../actions/types'
import {createFetchAction} from '../../reducers/fetchable'
import requestAgentPools from '../../rest/agentPools'
import {requestAgentExists, requestAgentPreviews, requestSingleAgent} from '../../rest/agents'
import requestCloudImages from '../../rest/cloudImages'
import {restRoot} from '../../rest/consts'
import {
  normalizeAgent,
  normalizeAgentPools,
  normalizeAgentPreviews,
  normalizeCloudImages,
  NormalizedAgent,
} from '../../rest/schemata'
import type {
  AgentDetails,
  AgentId,
  AgentPoolType,
  AgentPreviewType,
  AgentRequestOptions,
  AgentType,
  CloudImageResponseType,
} from '../../types'
import {internalProps} from '../../types/BS_types'
import {
  getTopics,
  subscribeOnAgentEvents,
  subscribeOnOverallEvents,
  Unsubscribe,
} from '../../utils/subscriber'
import * as SubscriptionEvents from '../../utils/subscriptionEvents'
import {getTabParamsKey} from '../../utils/tabs'

type FetchSingleAgentDataPayload = {
  agent: NormalizedAgent
  details: AgentDetails
}
function processAgent(data: AgentType): FetchSingleAgentDataPayload {
  const agent = normalizeAgent(data)
  const {host, port, protocol, cpuRank, connectedSince} = data
  return {
    agent,
    details: {
      host,
      port,
      protocol,
      cpuRank,
      connectedSince,
    },
  }
}
type FetchSingleAgentDataArg = {agentId: AgentId; requestOptions?: AgentRequestOptions}
export const fetchSingleAgentData = createFetchAction(
  'fetchSingleAgentData',
  ({agentId, requestOptions}: FetchSingleAgentDataArg) =>
    requestSingleAgent(restRoot, agentId, requestOptions).then(processAgent),
)
export const receiveAgent = (agentId: AgentId, data: AgentType) => {
  const payload = processAgent(data)
  return fetchSingleAgentData.fulfilled(payload, '', {agentId})
}

export const fetchAgentPreviewsData = createFetchAction(
  'fetchAgentPreviewsData',
  (essential: boolean | undefined) =>
    requestAgentPreviews(restRoot, {essential}).then(normalizeAgentPreviews),
)
export const receiveAgentPreviews = (data: ReadonlyArray<AgentPreviewType>) => {
  const agents = normalizeAgentPreviews(data)
  return fetchAgentPreviewsData.fulfilled(agents, '', false)
}
export const fetchAgentPoolsData = createFetchAction('fetchAgentPoolsData', (essential?: boolean) =>
  requestAgentPools(restRoot, {essential}).then(normalizeAgentPools),
)
export const receiveAgentPools = (data: ReadonlyArray<AgentPoolType>) => {
  const agentPools = normalizeAgentPools(data)
  return fetchAgentPoolsData.fulfilled(agentPools, '', false)
}
export const fetchCloudImagesData = createFetchAction(
  'fetchCloudImagesData',
  (essential?: boolean) => requestCloudImages(restRoot, {essential}).then(normalizeCloudImages),
)
export const receiveCloudImages = (data: ReadonlyArray<CloudImageResponseType>) => {
  const cloudImages = normalizeCloudImages(data)
  return fetchCloudImagesData.fulfilled(cloudImages, '', false)
}
const FREQUENT_AGENT_EVENT_TYPES = [
  SubscriptionEvents.AGENT_PARAMETERS_UPDATED,
  SubscriptionEvents.BUILD_STARTED,
  SubscriptionEvents.BUILD_FINISHED,
]
const REGULAR_AGENT_EVENT_TYPES = [
  SubscriptionEvents.AGENT_REGISTERED,
  SubscriptionEvents.AGENT_REMOVED,
  SubscriptionEvents.AGENT_STATUS_CHANGED,
  SubscriptionEvents.AGENT_UNREGISTERED,
  SubscriptionEvents.AGENT_POOL_TOUCHED,
]
const AGENT_EVENT_TYPES = [...FREQUENT_AGENT_EVENT_TYPES, ...REGULAR_AGENT_EVENT_TYPES]
const FREQUENT_OVERALL_AGENT_EVENT_TOPICS = getTopics('', FREQUENT_AGENT_EVENT_TYPES)
const REGULAR_OVERALL_AGENT_EVENT_TOPICS = getTopics('', REGULAR_AGENT_EVENT_TYPES)
export const OVERALL_AGENT_POOL_EVENT_TOPICS = getTopics('', [
  SubscriptionEvents.AGENT_POOL_TOUCHED,
])
export const OVERALL_PROJECT_EVENT_TOPICS = getTopics('', [
  SubscriptionEvents.PROJECT_RESTORED,
  SubscriptionEvents.PROJECT_PERSISTED,
  SubscriptionEvents.PROJECT_REMOVED,
  SubscriptionEvents.PROJECT_CREATED,
  SubscriptionEvents.PROJECT_ARCHIVED,
  SubscriptionEvents.PROJECT_DEARCHIVED,
  SubscriptionEvents.PROJECT_UPDATED,
])

const AGENT_PREVIEWS_UPDATE_TIMEOUT = Number(
  internalProps['teamcity.ui.agentPreviewsUpdateTimeout'],
)
const AGENT_DATA_UPDATE_TIMEOUT = Number(internalProps['teamcity.ui.agentDataUpdateTimeout'])
export const subscribeOnAgents = (): AppThunk<any> => dispatch => {
  const unsubscribeFromAgents = subscribeOnOverallEvents(
    REGULAR_OVERALL_AGENT_EVENT_TOPICS,
    () => {
      dispatch(fetchCloudImagesData())
      dispatch(fetchAgentPreviewsData())
    },
    AGENT_DATA_UPDATE_TIMEOUT,
  )
  const unsubscribeFromFrequentAgentEvents = subscribeOnOverallEvents(
    FREQUENT_OVERALL_AGENT_EVENT_TOPICS,
    () => {
      dispatch(fetchAgentPreviewsData())
    },
    AGENT_PREVIEWS_UPDATE_TIMEOUT,
  )
  return () => {
    unsubscribeFromAgents()
    unsubscribeFromFrequentAgentEvents()
  }
}

export const subscribeOnAgentPools = (): AppThunk<any> => dispatch =>
  subscribeOnOverallEvents(OVERALL_AGENT_POOL_EVENT_TOPICS, () => dispatch(fetchAgentPoolsData()))

export const subscribeOnAgent =
  (agentId: AgentId, requestOptions: AgentRequestOptions = {}): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnAgentEvents(
      agentId,
      AGENT_EVENT_TYPES.filter(
        eventType =>
          ![SubscriptionEvents.AGENT_REMOVED, SubscriptionEvents.AGENT_UNREGISTERED].includes(
            eventType,
          ),
      ),
      () => {
        dispatch(fetchSingleAgentData({agentId, requestOptions}))
        if (requestOptions?.tabs) {
          dispatch(fetchTabs(getTabParamsKey({agentId}), {essential: true}, false))
        }
      },
    )

export const markAgentDeleted = createAction<AgentId>('markAgentDeleted')
export const subscribeOnAgentRemoval =
  (agentId: AgentId, preserveAfterDelete: boolean = false): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnAgentEvents(agentId, [SubscriptionEvents.AGENT_REMOVED], () => {
      requestAgentExists(restRoot, agentId).catch(() => {
        if (preserveAfterDelete) {
          dispatch(markAgentDeleted(agentId))
        } else {
          dispatch(removeAgent.fulfilled('', '', agentId))
          dispatch(fetchSingleAgentData.pending('', {agentId}, {invalidate: true}))
        }
      })
    })
