import processResponse from '../../../rest/processResponse'
import request from '../../../rest/request'
import type {BuildArtifactsType} from '../../../types'

const artifactsFields = 'state,artifacts(count)'
export default (
  serverUrl: string,
  locator: string = 'state:any',
): Promise<ReadonlyArray<BuildArtifactsType>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(locator)}&fields=build(id,${artifactsFields})`,
  )
    .then<{build: ReadonlyArray<BuildArtifactsType>}>(processResponse)
    .then(
      (data): ReadonlyArray<BuildArtifactsType> =>
        data.build.map(build => {
          if (build.artifacts?.count == null) {
            return {
              ...build,
              artifacts: {
                count: 0,
              },
            }
          }

          return build
        }),
    )
