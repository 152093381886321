import AlertService, {AlertItem} from '@jetbrains/ring-ui/components/alert-service/alert-service'
import type {AlertType} from '@jetbrains/ring-ui/components/alert/alert'
import {
  ControlsHeight,
  ControlsHeightContext,
} from '@jetbrains/ring-ui/components/global/controls-height'
import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import {LocationProvider} from '@reach/router'

import classNames from 'classnames'
import React, {useContext} from 'react'

import {Provider} from 'react-redux'

import {HistoryContext} from '../../routes'
import SvgIcon from '../common/SvgIcon/SvgIcon'

import styles from './ServiceMessage.css'

type ServiceMessageType = Partial<AlertItem> & {
  readonly icon?: Icon | string
  readonly description?: string
  readonly children?: React.ReactNode
  readonly controls?: React.ReactNode
  readonly titleClassName?: string
  readonly containerClassName?: string
  readonly closeButtonClassName?: string
  readonly captionClassName?: string
  readonly descriptionClassName?: string
  readonly iconClassName?: string
  readonly title?: React.ReactNode
  readonly closeable?: boolean
  readonly onClose?: () => void
  readonly onCloseRequest?: () => void
  readonly type?: AlertType
}

function ServiceMessage({
  icon,
  captionClassName,
  descriptionClassName,
  containerClassName,
  closeButtonClassName,
  iconClassName,
  titleClassName,
  title,
  controls,
  children,
  closeable,
  onCloseRequest,
  onClose,
  type,
  ...restProps
}: ServiceMessageType) {
  const patchedHistory = useContext(HistoryContext)
  const keyRef = React.useRef<string | number>()

  const content = React.useMemo(
    () => (
      <Provider store={window.ReactUI.store}>
        <HistoryContext.Provider value={patchedHistory}>
          <LocationProvider history={patchedHistory}>
            <ControlsHeightContext.Provider value={ControlsHeight.S}>
              <div className={styles.container}>
                {icon != null && (
                  <div>
                    <SvgIcon className={classNames(styles.icon, iconClassName)} icon={icon} />
                  </div>
                )}
                <div>
                  {title != null && (
                    <h3 className={classNames(styles.title, titleClassName)}>{title}</h3>
                  )}
                  {children != null && (
                    <div className={classNames(styles.description, descriptionClassName)}>
                      {children}
                    </div>
                  )}
                  {controls}
                </div>
              </div>
            </ControlsHeightContext.Provider>
          </LocationProvider>
        </HistoryContext.Provider>
      </Provider>
    ),
    [
      patchedHistory,
      icon,
      iconClassName,
      title,
      titleClassName,
      children,
      descriptionClassName,
      controls,
    ],
  )

  React.useEffect(() => {
    if (keyRef.current != null) {
      const existingAlert = AlertService.showingAlerts.find(alert => alert.key === keyRef.current)
      if (existingAlert != null) {
        existingAlert.message = content
        AlertService.renderAlerts()
        return
      }
    }
    keyRef.current = AlertService.addAlert(content, type, 0, {
      captionClassName: classNames(styles.caption, captionClassName),
      className: containerClassName,
      closeButtonClassName,
      closeable,
      onCloseRequest,
      onClose,
      ...restProps,
    })
  }, [
    captionClassName,
    closeButtonClassName,
    closeable,
    containerClassName,
    content,
    onClose,
    onCloseRequest,
    restProps,
    type,
  ])

  React.useEffect(
    () => () => {
      if (keyRef.current) {
        AlertService.remove(keyRef.current)
      }
    },
    [],
  )

  return null
}

export default React.memo(ServiceMessage)
