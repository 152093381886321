import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import * as React from 'react'

import {useBooleanQueryParamState} from '../../../hooks/routes'
import {stringifyId} from '../../../types'
import type {FileType} from '../../../types'
import {focusSelfOrChildLink} from '../../../utils/dom'
import {useSandbox} from '../../../utils/fastdom'
import {getChildPath, getType, isOnlyFolder} from '../../../utils/fileTree'
import {notNull} from '../../../utils/guards'
import {escapeFilePathForURL} from '../../../utils/url'

import Link from '../Link/Link'

import ArtifactsDownloadAll from './ArtifactsDownloadAll/ArtifactsDownloadAll'

import BuildArtifactStorageInfo from './BuildArtifactStorageInfo/BuildArtifactStorageInfo'
import connect, {getArtifactsHref} from './BuildArtifactsTree.container'
import type {Props} from './BuildArtifactsTree.container'
import FileTreeNode, {ITEM_SELECTOR, MIN_OFFSET, STEP} from './FileTreeNode/FileTreeNode'

import styles from './BuildArtifactsTree.css'

const TEAMCITY_DIRNAME = '.teamcity'

const isHiddenArtifact = ({name}: FileType) => name === TEAMCITY_DIRNAME

const isParent = (parent: string, child: string) => child.indexOf(getChildPath(parent, '')) === 0

const ConnectedTree = connect(function BuildArtifactsTree({
  files,
  buildId,
  buildUrl,
  path = '',
  buildType,
  level,
  labelledBy,
  loading,
  showToggleHidden,
  showDownloadLink,
  showStorageInfo = false,
  hasArtifacts,
  canSelectDirs,
  extensions,
  autoWidth,
  expandedNodes,
  timeStamp,
  onSelect,
  onExpand,
  visibleArtifactsSize,
  totalArtifactsSize,
  load,
  loadExtensions,
  loadArtifactsSize,
  urlSync = false,
}: Props) {
  const showHiddenStateReact = React.useState(false)
  const showHiddenStateUrl = useBooleanQueryParamState('showAll')
  const [showHidden, setShowHidden] = urlSync ? showHiddenStateUrl : showHiddenStateReact
  const expandedNodeArray = React.useRef(
    urlSync ? location.hash.slice(1).split(';').map(decodeURIComponent) : expandedNodes,
  )
  const handleExpand = React.useCallback(
    (pathToExpand, expanded) => {
      if (urlSync) {
        if (expanded === true) {
          // remove parent paths to avoid duplication
          expandedNodeArray.current = expandedNodeArray.current
            ?.filter(node => !isParent(node, pathToExpand))
            .concat(pathToExpand)
        } else {
          // remove path and its children
          expandedNodeArray.current = expandedNodeArray.current?.filter(
            node => node !== pathToExpand && !isParent(pathToExpand, node),
          )
        }

        window.location.hash = expandedNodeArray.current?.map(encodeURIComponent).join(';') ?? ''
      } else if (onExpand != null) {
        onExpand(pathToExpand, expanded)
      }
    },
    [onExpand, urlSync],
  )
  const handleToggleHidden = React.useCallback(() => {
    setShowHidden(!showHidden)
  }, [setShowHidden, showHidden])
  const ref = React.useRef<HTMLUListElement>(null)
  const hiddenRef = React.useRef()
  const isRoot = level === 0
  React.useEffect(() => {
    load(buildId, path, isRoot)

    if (isRoot) {
      loadExtensions(buildId)
      loadArtifactsSize(buildId)
    }
  }, [buildId, isRoot, load, loadArtifactsSize, loadExtensions, path])
  const shouldFocusFirst = isRoot && files.length > 0
  const sandbox = useSandbox()
  React.useEffect(() => {
    if (shouldFocusFirst) {
      const element = ref.current

      if (element == null) {
        return
      }

      const firstItem = element.querySelector(ITEM_SELECTOR)
      sandbox.mutate(() => {
        const {documentElement} = document
        const prevScroll = documentElement?.scrollTop
        focusSelfOrChildLink(firstItem)

        if (documentElement && prevScroll != null) {
          documentElement.scrollTop = prevScroll
        }
      })
    }
  }, [sandbox, shouldFocusFirst])
  React.useEffect(() => {
    if (showHidden) {
      focusSelfOrChildLink(hiddenRef.current)
    }
  }, [showHidden])
  const onlyFolder = isOnlyFolder(files)
  const listClasses = classNames(styles.tree, {
    [styles.root]: isRoot,
    [styles.autoWidth]: autoWidth,
  })
  const toggleHiddenClasses = classNames(styles.toggleHidden, {
    [styles.secondary]: !showHidden,
  })
  const noUserArtifacts = hasArtifacts === false
  const userArtifacts = files.filter(file => !isHiddenArtifact(file))
  const hasHiddenArtifacts = files.some(isHiddenArtifact)
  const filesToRender = showHidden ? files : userArtifacts
  const artifactsSizeToShow = showHidden ? totalArtifactsSize : visibleArtifactsSize
  const artifactsHref = getArtifactsHref(buildUrl, !showHidden)
  return (
    <div className={styles.container}>
      {level === 0 &&
        !loading &&
        (noUserArtifacts || (hasHiddenArtifacts && userArtifacts.length === 0)) && (
          <div className={styles.noArtifacts}>
            <div>{'No user-defined artifacts in this build. '}</div>
          </div>
        )}
      {loading ? (
        !noUserArtifacts && (
          <div
            style={{
              paddingLeft: MIN_OFFSET + level * STEP,
            }}
          >
            <LoaderInline />
            {' Loading files...'}
          </div>
        )
      ) : (
        <>
          {filesToRender.length > 0 && (
            <>
              {showDownloadLink === true && (
                <ArtifactsDownloadAll
                  buildId={buildId}
                  buildType={buildType}
                  showHidden={showHidden}
                  className={styles.downloadLink}
                />
              )}
              <ul
                className={listClasses}
                role={isRoot ? 'tree' : 'group'}
                aria-labelledby={labelledBy}
                ref={ref}
              >
                {filesToRender.map(file => {
                  const {name, size} = file
                  const type = getType(file)
                  const childPath = getChildPath(path, name)
                  const encodedChildPath = escapeFilePathForURL(childPath)
                  const childPathWithoutLeadingSlash = childPath.slice(1)
                  const defaultExpanded =
                    onlyFolder ||
                    (expandedNodeArray.current != null &&
                      expandedNodeArray.current.some(
                        node => node === childPath || node.startsWith(getChildPath(childPath, '')),
                      ))
                  return (
                    <FileTreeNode
                      key={name}
                      name={name}
                      expandable={type !== 'file'}
                      defaultExpanded={defaultExpanded}
                      path={childPath}
                      type={type}
                      icon={type}
                      size={size}
                      level={level}
                      href={
                        type === 'folder'
                          ? undefined
                          : `/repository/download/${stringifyId(buildType)}/${stringifyId(
                              buildId,
                            )}:id${encodedChildPath}`
                      }
                      extensions={
                        extensions &&
                        Object.keys(extensions)
                          .map(key => extensions[key]?.data)
                          .filter(notNull)
                          .map(({icon, title, hrefs}) => ({
                            icon: icon.name,
                            title,
                            href: hrefs[childPathWithoutLeadingSlash],
                          }))
                          .filter(({href}) => href)
                      }
                      itemRef={isHiddenArtifact(file) ? hiddenRef : null}
                      onSelect={canSelectDirs === true || type === 'file' ? onSelect : null}
                      onExpand={handleExpand}
                    >
                      {type !== 'file' && (
                        <ConnectedTree
                          buildId={buildId}
                          path={childPath}
                          level={level + 1}
                          canSelectDirs={canSelectDirs}
                          expandedNodes={expandedNodeArray.current}
                          timeStamp={timeStamp}
                          onSelect={onSelect}
                          onExpand={handleExpand}
                        />
                      )}
                    </FileTreeNode>
                  )
                })}
              </ul>
            </>
          )}
          {showHidden && hasHiddenArtifacts && (
            <div className={styles.noteHidden}>
              {'Hidden artifacts from the .teamcity directory are displayed'}
            </div>
          )}
          {(showToggleHidden === true || userArtifacts.length === 0) &&
            hasHiddenArtifacts &&
            artifactsHref != null && (
              <Link
                href={artifactsHref}
                className={toggleHiddenClasses}
                onPlainLeftClick={handleToggleHidden}
              >
                {showHidden ? 'Hide' : 'Show hidden artifacts'}
              </Link>
            )}
          {level === 0 && artifactsSizeToShow != null && (
            <div className={styles.artifactsSize}>{`Total size: ${artifactsSizeToShow}`}</div>
          )}
          {showStorageInfo && <BuildArtifactStorageInfo buildId={buildId} />}
        </>
      )}
    </div>
  )
})
export default ConnectedTree
