import type {Action} from '../../actions/types'

import {
  ADD_HINT,
  HIDE_HINT,
  HintCategory,
  REMOVE_HINT,
  SELECT_HINT_CATEGORY,
  SHOW_HINT,
} from './Hints.types'
import type {HintType, HintId} from './Hints.types'

export const addHint = (hint: HintType): Action => ({
  type: ADD_HINT,
  hint,
})
export const removeHint = (id: HintId): Action => ({
  type: REMOVE_HINT,
  id,
})
export const selectHintCategory = (category: HintCategory): Action => ({
  type: SELECT_HINT_CATEGORY,
  category,
})
export const showHint = (id: HintId): Action => ({
  type: SHOW_HINT,
  id,
})
export const hideHint = (id: HintId): Action => ({
  type: HIDE_HINT,
  id,
})
