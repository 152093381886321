import {batch} from 'react-redux'

import {fetchSingleBuildData} from '../../../../../actions/builds'
import {fetchBuildTypeTags} from '../../../../../actions/fetch'
import {createFetchAction} from '../../../../../reducers/fetchable'
import type {PinHandlerArgs} from '../../../../../types'
import {BS} from '../../../../../types/BS_types'

export const pinBuild = createFetchAction(
  'pinBuild',
  (
    {buildId, isPinned, hasDependencies, pinComment, tagsContainerId, buildTypeId}: PinHandlerArgs,
    {dispatch},
  ) =>
    BS!.PinBuildDialog.showPinBuildDialog(
      buildId,
      !isPinned,
      hasDependencies,
      pinComment,
      tagsContainerId,
      true,
      buildTypeId,
    ).then(data => {
      batch(() => {
        dispatch(fetchSingleBuildData(buildId))

        if (buildTypeId) {
          dispatch(fetchBuildTypeTags(buildTypeId))
        }
      })
      return data
    }),
  {condition: () => BS != null},
)
