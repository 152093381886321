import 'whatwg-fetch'
import type {PlaceId} from '@jetbrains/teamcity-api'
import {createAction} from '@reduxjs/toolkit'
import {batch} from 'react-redux'

import {createFetchAction} from '../reducers/fetchable'
import {State} from '../reducers/types'
import requestArtifacts from '../rest/artifacts'
import requestBranches, {requestIsBranchPresent} from '../rest/branches'
import {moveToTop, requestBuildsStats, requestBuildsStatsAroundBuild} from '../rest/builds'
import {restRoot} from '../rest/consts'
import requestCurrentUser, {setProperty} from '../rest/currentUser'
import requestLicensingData from '../rest/licensingData'
import projectPermissionGenerator, {requestPoolPermissions} from '../rest/permission'
import processResponse, {processTextResponse} from '../rest/processResponse'
import type {RestRequestOptions} from '../rest/request'
import makeRequest from '../rest/request'
import {getStatusKey} from '../rest/schemata'
import requestTabs from '../rest/tabs'
import type {BranchesToShow} from '../selectors'
import {
  buildTypeBranchesSectionCollapsedKey,
  getAutoExpandQueuedBuilds,
  getExtensionEndpointsByKind,
  getIsBranchPresent,
  getIsBranchPresentInited,
  getIsGuestOrRoot,
  getShowQueuedBuildsInBuildsList,
} from '../selectors'
import {
  agentsInCloud,
  blocks,
  builds,
  buildTab,
  buildTypeTab,
  dialog,
  isExperimentalUI,
  permissions,
  routeAvailabilityResponse,
  showQueuedBuildsInBuildsList,
  sorting,
  urlExtensions,
} from '../slices'
import buildsFilters from '../slices/buildsFilters'
import type {
  AgentId,
  AgentPoolId,
  ArtifactExtension,
  BranchType,
  BuildId,
  BuildTypeId,
  CurrentUserType,
  LicensingDataType,
  Permission,
  PoolPermissions,
  ProblemId,
  ProjectId,
  ServerInfo,
  Tab,
  TabParamsKey,
  UrlExtension,
  UserId,
} from '../types'
import {ALL_PROJECTS, getBuildTypeStatusRequest, stringifyId} from '../types'
import {base_uri, BS} from '../types/BS_types'
import {getChildPath, isOnlyFolder} from '../utils/fileTree'
import type {Unsubscribe} from '../utils/subscriber'
import {getTopics, subscribeOnOverallEvents, subscribeOnUserEvents} from '../utils/subscriber'
import * as SubscriptionEvents from '../utils/subscriptionEvents'
import {resolveRelative} from '../utils/url'
import type {UserProperty} from '../utils/userProperties'

import * as Actions from './actionTypes'
import CollapsibleBlocks from './collapsibleBlockTypes'
import type {Action, ActionCreator, AppThunk} from './types'
import {processServiceWorkerResponse} from './utils'

export const resetState = createAction<State>('resetState')

export const fetchCurrentUserData = createFetchAction(
  'fetchCurrentUserData',
  (): Promise<CurrentUserType | null> => requestCurrentUser(restRoot),
  {
    condition(force: boolean | void, {getState}) {
      const {currentUser} = getState()
      return force || !currentUser.inited
    },
  },
)

type FetchArtifactExtensionArg = {
  extension: UrlExtension<{}>
  id: BuildId
}
export const fetchArtifactExtension = createFetchAction(
  'fetchArtifactExtension',
  async ({extension: {serverUrl = base_uri, endpoint}, id}: FetchArtifactExtensionArg) => {
    const response = await makeRequest(serverUrl, `${endpoint}?buildId=${stringifyId(id)}`)
    const data: ArtifactExtension = await processResponse(response)
    return data
  },
)

export const fetchArtifactExtensions =
  (id: BuildId): AppThunk<any> =>
  (dispatch, getState) =>
    getExtensionEndpointsByKind(getState(), 'artifacts').forEach(extension =>
      dispatch(fetchArtifactExtension({extension, id})),
    )

type FetchArtifactsListArg = {
  id: BuildId
  path: string
  withHidden?: boolean
}
export const fetchArtifactsListAction = createFetchAction(
  'fetchArtifactsList',
  async ({id, path, withHidden}: FetchArtifactsListArg, {dispatch}) => {
    const data = await requestArtifacts(restRoot, id, path, withHidden)
    if (isOnlyFolder(data)) {
      await dispatch(fetchArtifactsListAction({id, path: getChildPath(path, data[0].name)}))
    }
    return data
  },
)
export const fetchArtifactsList = (id: BuildId, path: string, withHidden?: boolean) =>
  fetchArtifactsListAction({id, path, withHidden})
export const receiveCurrentUserData = (data: CurrentUserType | null) =>
  fetchCurrentUserData.fulfilled(data, '')
export const receiveAgentsInCloud = agentsInCloud.actions.receive
export const subscribeOnPermission =
  (permission: Permission, projectId: ProjectId, userId: UserId): AppThunk<Unsubscribe> =>
  dispatch =>
    subscribeOnUserEvents(
      `${permission}:${projectId === ALL_PROJECTS ? '' : stringifyId(projectId)}`,
      userId,
      [SubscriptionEvents.USER_PERMISSIONS_CHANGED],
      () => {
        processServiceWorkerResponse({
          iterator: projectPermissionGenerator(restRoot, permission, projectId),
          onSuccess: data => dispatch(permissions.actions.receive({data, permission, projectId})),
        })
      },
    )

type PoolPermissionsPayload = {
  canChangeStatus?: PoolPermissions
  canAuthorize?: PoolPermissions
}
export const fetchPoolPermissions = createFetchAction(
  'fetchPoolPermissions',
  (essential?: boolean): Promise<PoolPermissionsPayload> =>
    requestPoolPermissions(resolveRelative('/overview'), {
      essential,
    }),
)
export const receiveCanChangeStatusPoolPermissions = (canChangeStatus: PoolPermissions) =>
  fetchPoolPermissions.fulfilled({canChangeStatus}, '', undefined)
export const receiveCanAuthorizePoolPermissions = (canAuthorize: PoolPermissions) =>
  fetchPoolPermissions.fulfilled({canAuthorize}, '', undefined)

type FetchHtmlArg = {
  path: string
  method?: string | null
  absolute?: boolean
}
export const fetchHtmlAction = createFetchAction(
  'fetchHtml',
  async ({path, method, absolute}: FetchHtmlArg): Promise<string | null> => {
    const res = await makeRequest(absolute === true ? null : base_uri, path, {
      method: method ?? 'GET',
      headers: {
        Accept: '*/*',
      },
    })
    return processTextResponse(res)
  },
)
export const fetchHtml = (path: string, method?: string | null, absolute?: boolean) =>
  fetchHtmlAction({path, method, absolute})
type FetchBuildStatsArg = {
  locator: string
  buildId?: BuildId
  statCount?: number
}
export const fetchBuildStatsAction = createFetchAction(
  'fetchBuildStats',
  ({locator, buildId, statCount}: FetchBuildStatsArg) =>
    buildId != null && statCount != null
      ? requestBuildsStatsAroundBuild(restRoot, locator, buildId, statCount)
      : requestBuildsStats(restRoot, locator),
)
export const fetchBuildStats = (locator: string) => fetchBuildStatsAction({locator})
export const fetchBuildStatsAroundBuild = (locator: string, buildId: BuildId, statCount: number) =>
  fetchBuildStatsAction({locator, buildId, statCount})

export const moveBuildToTop =
  (buildId: BuildId): AppThunk<Promise<unknown>> =>
  (dispatch, getState) =>
    moveToTop(getState().restRoot, buildId).then(
      data => {
        dispatch({
          type: Actions.MOVE_TO_TOP,
          data,
          buildId,
        })
      },
      (error: Error) =>
        dispatch({
          type: Actions.MOVE_TO_TOP,
          error,
          buildId,
        }),
    )
export const openDialog = dialog.actions.open
export const closeDialog = dialog.actions.close
type SetUserPropertyArg = {
  name: UserProperty
  value: string
}
export const setUserPropertyAction = createFetchAction(
  'setUserProperty',
  ({name, value}: SetUserPropertyArg) => setProperty(restRoot, name, value),
  {
    condition(_, {getState}) {
      const state = getState()
      return !getIsGuestOrRoot(state)
    },
  },
)
export const setUserProperty = (name: UserProperty, value: string) =>
  setUserPropertyAction({name, value})
export const updateResults =
  (update: () => unknown): ActionCreator =>
  () =>
  dispatch =>
    batch(() => {
      dispatch(buildsFilters.actions.updateResults())
      update()
    })
export const {
  changeStateFilter,
  changeProjectBuildtypeFilter,
  setTagFilter,
  toggleAdvandedMode: setAdvancedMode,
  changeLocator,
  setLocatorReady,
  setAgentIdFilter,
  setAgentTypeIdFilter,
  setAgentPatternFilter,
} = buildsFilters.actions
export const changeBuildTypeTab = buildTypeTab.actions.change
export const changeBuildTab = buildTab.actions.change
export const fetchBranches = createFetchAction('fetchBranches', (endpoint: string) =>
  requestBranches(restRoot, endpoint),
)

type FetchIsBranchPresentArg = {
  endpoint: string
  restOptions?: RestRequestOptions
}
export const fetchIsBranchPresentAction = createFetchAction(
  'fetchIsBranchPresent',
  ({endpoint, restOptions}: FetchIsBranchPresentArg) =>
    requestIsBranchPresent(restRoot, endpoint, restOptions),
  {condition: ({endpoint}, {getState}) => !getIsBranchPresentInited(getState(), endpoint)},
)
export const fetchIsBranchPresent = (endpoint: string, restOptions?: RestRequestOptions) =>
  fetchIsBranchPresentAction({endpoint, restOptions})
export const fetchOrGetIsBranchPresent =
  (
    endpoint: string,
    restOptions?: RestRequestOptions,
  ): AppThunk<Promise<boolean | null | undefined>> =>
  async (dispatch, getState) => {
    await dispatch(fetchIsBranchPresent(endpoint, restOptions))
    return getIsBranchPresent(getState(), endpoint)
  }
export const setProjectFilter = (id: ProjectId | null | undefined) =>
  changeProjectBuildtypeFilter(
    id
      ? {
          nodeType: 'project',
          id,
        }
      : {
          nodeType: 'all',
        },
  )
export const setBuildtypeFilter = (id: BuildTypeId | null | undefined) =>
  changeProjectBuildtypeFilter(
    id
      ? {
          nodeType: 'bt',
          id,
        }
      : {
          nodeType: 'all',
        },
  )
export const toggleAdvancedMode = () => setAdvancedMode()
export const setIsExperimentalUI = isExperimentalUI.actions.set
export const changeSortingDimension = sorting.actions.changeDimension
export const changeSortingDirection = sorting.actions.changeDirection
export const changeSorting =
  (dimension: string, descending: boolean): AppThunk<any> =>
  dispatch =>
    batch(() => {
      dispatch(changeSortingDimension(dimension))
      dispatch(changeSortingDirection(descending))
    })
export const showAuthorizeAgentDialog =
  (agentId: AgentId, poolId: AgentPoolId, isCloud: boolean): AppThunk<any> =>
  () => {
    // Ensure immutability to make Flow happy
    const _BS = BS

    if (_BS) {
      _BS.Agent.showChangeStatusDialog(
        true,
        agentId,
        false,
        'changeAuthorizeStatus',
        {
          cloud: isCloud,
          poolId,
        },
        () => {
          _BS.AgentsReact.refreshFetcherData()

          _BS.AgentsReact.refreshTabsCounters()
        },
      )
    }
  }

const addBlocks = blocks.actions.add

const removeBlocks = blocks.actions.remove

export const collapseBuildTypeBranchesSection = (id: BuildTypeId, branchesToShow: BranchesToShow) =>
  addBlocks(CollapsibleBlocks.COLLAPSED_BRANCHES_SECTION, [
    buildTypeBranchesSectionCollapsedKey(id, branchesToShow),
  ])
export const expandBuildTypeBranchesSection = (id: BuildTypeId, branchesToShow: BranchesToShow) =>
  removeBlocks(CollapsibleBlocks.COLLAPSED_BRANCHES_SECTION, [
    buildTypeBranchesSectionCollapsedKey(id, branchesToShow),
  ])
export const collapseBuildTypeBuildsSection = (id: BuildTypeId | ReadonlyArray<BuildTypeId>) =>
  addBlocks(CollapsibleBlocks.COLLAPSED_PROJECT_BUILDTYPELINE, Array.isArray(id) ? id : [id])
export const expandBuildTypeBuildsSection = (id: BuildTypeId | ReadonlyArray<BuildTypeId>) =>
  removeBlocks(CollapsibleBlocks.COLLAPSED_PROJECT_BUILDTYPELINE, Array.isArray(id) ? id : [id])
export const expandSubproject = (id: ProjectId | ReadonlyArray<ProjectId>) =>
  removeBlocks(CollapsibleBlocks.COLLAPSED_SUBPROJECT, Array.isArray(id) ? id : [id])
export const collapseSubproject = (id: ProjectId | ReadonlyArray<ProjectId>) =>
  addBlocks(CollapsibleBlocks.COLLAPSED_SUBPROJECT, Array.isArray(id) ? id : [id])
export const toggleSubproject = (id: ProjectId, expand: boolean) =>
  expand ? expandSubproject(id) : collapseSubproject(id)
export const resetBuildsLocatorState = builds.actions.reset
export const storeUrlExtensions = urlExtensions.actions.set
export const setRouteAvailabilityResponse = routeAvailabilityResponse.actions.set
export const changeInvestigation =
  (
    buildTypeId: BuildTypeId,
    buildTypeName: string,
    presetFix: boolean,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () =>
    BS &&
    BS.ResponsibilityDialog.showDialog(buildTypeId, buildTypeName, presetFix, true, submitHandler)
export const showQueuedBuildsForBranch = (
  buildTypeId: BuildTypeId,
  branch: BranchType,
): Action => ({
  type: Actions.SHOW_QUEUED_BUILDS,
  buildTypeId,
  branch,
})
export const hideQueuedBuildsForBranch = (
  buildTypeId: BuildTypeId,
  branch: BranchType,
): Action => ({
  type: Actions.HIDE_QUEUED_BUILDS,
  buildTypeId,
  branch,
})
export const setShowQueuedBuildsCount = (
  count: number,
  buildTypeId: BuildTypeId,
  branch?: BranchType,
): Action => ({
  type: Actions.SET_SHOW_QUEUED_BUILDS_COUNT,
  buildTypeId,
  branch,
  count,
})
export const showQueuedBuildsForProject = (buildTypeId: BuildTypeId): Action => ({
  type: Actions.SHOW_QUEUED_BUILDS_FOR_PROJECT,
  buildTypeId,
})
export const hideQueuedBuildsForProject =
  (buildTypeId: BuildTypeId, branch?: BranchType | null): AppThunk =>
  (dispatch, getState) => {
    const state = getState()
    const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))

    if (getAutoExpandQueuedBuilds(state, statusKey)) {
      return dispatch({
        type: Actions.COLLAPSE_AUTO_EXPANDED_QUEUED,
        key: statusKey,
      })
    }

    return dispatch({
      type: Actions.HIDE_QUEUED_BUILDS_FOR_PROJECT,
      buildTypeId,
    })
  }
export const toggleQueuedVisibility =
  (
    buildTypeId: BuildTypeId | null | undefined,
    branch: BranchType | null | undefined,
  ): AppThunk<any> =>
  (dispatch, getState) => {
    const state = getState()

    if (buildTypeId != null && !getShowQueuedBuildsInBuildsList(state)) {
      const statusKey = getStatusKey(getBuildTypeStatusRequest(buildTypeId, branch))

      if (getAutoExpandQueuedBuilds(state, statusKey)) {
        return dispatch({
          type: Actions.COLLAPSE_AUTO_EXPANDED_QUEUED,
          key: statusKey,
        })
      }
    }

    return dispatch(showQueuedBuildsInBuildsList.actions.toggle())
  }
export const fetchTabs =
  (
    tabParamsKey: TabParamsKey,
    restOptions?: RestRequestOptions,
    cacheTabs: boolean = true,
  ): AppThunk<any> =>
  dispatch => {
    if (window.GLOBAL_FETCH_DISABLED === true && restOptions?.essential !== true) {
      return
    }

    processServiceWorkerResponse<ReadonlyArray<Tab>, any>({
      iterator: requestTabs(tabParamsKey, restOptions, cacheTabs),
      onStart: () =>
        dispatch({
          type: Actions.REQUEST_TABS,
          tabParamsKey,
        }),
      onSuccess: data => {
        dispatch({
          type: Actions.RECEIVE_TABS,
          tabParamsKey,
          data,
        })
      },
      onError: error =>
        dispatch({
          type: Actions.RECEIVE_TABS,
          tabParamsKey,
          error,
        }),
    })
  }

type SizeData = {
  size: string
}
export const fetchArtifactsSize =
  (buildId: BuildId): AppThunk<any> =>
  async dispatch => {
    const path = `buildArtifactsDetails.html?buildId=${stringifyId(buildId)}`
    const visibleArtifactsSize = await makeRequest(base_uri, `${path}&showAll=false`)
    const totalArtifactsSize = await makeRequest(base_uri, `${path}&showAll=true`)

    if (visibleArtifactsSize.ok && totalArtifactsSize.ok) {
      const visible: SizeData = await processResponse(visibleArtifactsSize)
      const total: SizeData = await processResponse(totalArtifactsSize)
      dispatch({
        type: Actions.RECEIVE_ARTIFACTS_SIZE,
        buildId,
        visible: visible.size,
        total: total.size,
      })
    }
  }
export const receiveServerInfo = (serverInfo: ServerInfo): Action => ({
  type: Actions.RECEIVE_SERVER_INFO,
  data: serverInfo,
})
export const dummyAction = (): Action => ({
  type: Actions.DUMMY_ACTION,
})

export const addPlugin = (placeId: PlaceId, name: string): Action => ({
  type: Actions.ADD_PLUGIN,
  placeId,
  name,
})
export const removePlugin = (placeId: PlaceId, name: string): Action => ({
  type: Actions.REMOVE_PLUGIN,
  placeId,
  name,
})
export const assignProblemInvestigations =
  (
    problemId: ProblemId,
    buildId: BuildId,
    presetFix: boolean,
    submitHandler?: () => unknown,
  ): AppThunk<any> =>
  () =>
    BS &&
    BS.BulkInvestigateMuteTestDialog.showForBuildProblem(
      problemId,
      buildId,
      presetFix,
      true,
      () => submitHandler != null && submitHandler(),
    )
export const setSyncStorageValue = (key: string, value: string | null): Action => ({
  type: Actions.SET_SYNC_STORAGE_VALUE,
  value,
  key,
})
export const receiveLicensingData = (licensingData: LicensingDataType): Action => ({
  type: Actions.RECEIVE_LICENSING_DATA,
  data: licensingData,
})
const fetchLicensingData = (): AppThunk<any> => (dispatch, getState) => {
  dispatch({
    type: Actions.REQUEST_LICENSING_DATA,
  })
  return requestLicensingData(getState().restRoot).then(
    data => dispatch(receiveLicensingData(data)),
    (error: Error) =>
      dispatch({
        type: Actions.RECEIVE_LICENSING_DATA,
        error,
      }),
  )
}
const REMAINING_AGENTS_TIMEOUT = 1000
const REMAINING_AGENT_EVENT_TYPE_TOPICS = getTopics('', [
  SubscriptionEvents.AGENT_REGISTERED,
  SubscriptionEvents.AGENT_UNREGISTERED,
  SubscriptionEvents.AGENT_STATUS_CHANGED,
  SubscriptionEvents.AGENT_REMOVED,
])
export const subscribeOnRemainingAgents = (): AppThunk<() => void> => dispatch =>
  subscribeOnOverallEvents(
    REMAINING_AGENT_EVENT_TYPE_TOPICS,
    () => {
      dispatch(fetchLicensingData())
    },
    REMAINING_AGENTS_TIMEOUT,
  )
export const setBuildTypesLimit = (limit: number): Action => ({
  type: Actions.SET_BUILD_TYPES_LIMIT,
  limit,
})
