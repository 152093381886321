import fetchable from '../../../../../reducers/fetchable'
import {keyValueReducer} from '../../../../../reducers/utils'
import {BuildTypeHierarchyType} from '../../../../../types'

import {
  RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY,
  REQUEST_CHANGE_BUILD_TYPE_HIERARCHY,
} from './ChangeBuildTypeSelect.actions.types'

const changeBuildTypeHierarchyFetchable = fetchable<
  BuildTypeHierarchyType | null,
  BuildTypeHierarchyType
>(
  REQUEST_CHANGE_BUILD_TYPE_HIERARCHY,
  RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY,
  null,
  action => action.data,
)

const changeProjectsSelectReducer = keyValueReducer(
  action => action.locator,
  changeBuildTypeHierarchyFetchable,
  [REQUEST_CHANGE_BUILD_TYPE_HIERARCHY, RECEIVE_CHANGE_BUILD_TYPE_HIERARCHY],
)

export default changeProjectsSelectReducer
