import {$Values} from 'utility-types'

import type {BaseReceiveAction} from '../../actions/fetchActions'
import type {NormalizedChanges} from '../../rest/schemata'
import type {
  BuildId,
  BuildTypeId,
  ChangeFileType,
  ChangeId,
  Fetchable,
  InexactCountable,
  ProjectId,
  RevisionId,
  UserId,
} from '../../types'
import {BuildTypeTypeType} from '../../types'
import type {KeyValue} from '../../utils/object'

export const changesTypes = {
  CHANGES: 'changes' as 'changes',
  ARTIFACTS: 'artifacts' as 'artifacts',
}
type ChangesTypeType = $Values<typeof changesTypes>

export type ChangeBuildTypeType = {
  readonly id: BuildTypeId
  readonly type: BuildTypeTypeType
}
export type ChangeDeploymentType = {
  readonly id: BuildTypeId
}

export const REQUEST_CHANGES_FILES: 'REQUEST_CHANGES_FILES' = 'REQUEST_CHANGES_FILES'
export const RECEIVE_CHANGES_FILES: 'RECEIVE_CHANGES_FILES' = 'RECEIVE_CHANGES_FILES'
export const REQUEST_CHANGE_BUILD_TYPES: 'REQUEST_CHANGE_BUILD_TYPES' = 'REQUEST_CHANGE_BUILD_TYPES'
export const RECEIVE_CHANGE_BUILD_TYPES: 'RECEIVE_CHANGE_BUILD_TYPES' = 'RECEIVE_CHANGE_BUILD_TYPES'
export const REQUEST_CHANGE_DEPLOYMENTS: 'REQUEST_CHANGE_DEPLOYMENTS' = 'REQUEST_CHANGE_DEPLOYMENTS'
export const RECEIVE_CHANGE_DEPLOYMENTS: 'RECEIVE_CHANGE_DEPLOYMENTS' = 'RECEIVE_CHANGE_DEPLOYMENTS'

export type ChangesAction =
  | {
      type: typeof REQUEST_CHANGES_FILES
      readonly locator: string
    }
  | (BaseReceiveAction<NormalizedChanges> & {
      type: typeof RECEIVE_CHANGES_FILES
      readonly locator: string
    })
  | {
      type: typeof REQUEST_CHANGE_BUILD_TYPES
      readonly locator: string
    }
  | (BaseReceiveAction<ReadonlyArray<ChangeBuildTypeType>> & {
      type: typeof RECEIVE_CHANGE_BUILD_TYPES
      readonly locator: string
    })
  | {
      type: typeof REQUEST_CHANGE_DEPLOYMENTS
      readonly locator: string
    }
  | (BaseReceiveAction<ReadonlyArray<ChangeDeploymentType>> & {
      type: typeof RECEIVE_CHANGE_DEPLOYMENTS
      readonly locator: string
    })

export type ChangesStateType = {
  readonly buildRevisions: KeyValue<BuildId, ReadonlyArray<RevisionId>>
  readonly buildSettingsRevisions: KeyValue<BuildId, RevisionId | null | undefined>
  readonly buildChangesRevisionsByLocator: KeyValue<string, Fetchable<ReadonlyArray<BuildId>>>
  readonly changesByLocator: KeyValue<string, Fetchable<ReadonlyArray<ChangeId>>>
  readonly buildArtifactDependencyChangesByLocator: KeyValue<
    string,
    Fetchable<ReadonlyArray<BuildId>>
  >
  readonly buildChangesByBuildId: KeyValue<BuildId, ReadonlyArray<ChangeId>>
  readonly changesFiles: KeyValue<ChangeId, ReadonlyArray<ChangeFileType>>
  readonly artifactDependencyChangesCount: KeyValue<BuildId, InexactCountable>
  readonly changeBuildTypes: KeyValue<string, Fetchable<ReadonlyArray<ChangeBuildTypeType>>>
  readonly changeDeployments: KeyValue<string, Fetchable<ReadonlyArray<ChangeDeploymentType>>>
}

export type RequestBuildChangesRevisionsParams = {
  withSettingsRevisions?: boolean
}

type ContentFilterOptionsType = {
  readonly comment?: string
  readonly path?: string
  readonly revision?: string
}

export type FilterOptionsType = ContentFilterOptionsType & {
  readonly userId?: UserId | string | null | undefined
  readonly username?: string | null | undefined
  readonly versionedSettings?: boolean
  readonly changesType?: ChangesTypeType | null | undefined
  readonly projectId?: ProjectId | null | undefined
}
