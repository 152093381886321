import * as Redux from 'redux'

import type {Action} from '../../../actions/types'
// import GraphQLError from '../../rest/errors/GraphQLError'
// import HTTPError, {HTTPCodesEnum} from '../../rest/errors/HTTPError'

import {CLOSE_ERROR_ALERT} from './ErrorAlerts.actions.types'
import type {ErrorAlertType} from './ErrorAlerts.types'
import {ErrorAlertsStateType} from './ErrorAlerts.types'

const errorAlertsReducer = Redux.combineReducers<ErrorAlertsStateType>({
  filedErrors(state: readonly ErrorAlertType[] = [], action: Action): readonly ErrorAlertType[] {
    switch (action.type) {
      case CLOSE_ERROR_ALERT:
        return state.filter(item => item.key !== action.key)

      default:
        // const error = 'error' in action && action.error != null ? action.error : null
        //
        // if (
        //   ('manualErrorHadling' in action && action.manualErrorHadling === true) ||
        //   error == null
        // ) {
        //   return state
        // }
        //
        // let key = error.message ?? error.toString()
        // let errorAlert: ErrorAlertType | null = null
        //
        // if (
        //   error instanceof HTTPError &&
        //   error.statusCode != null &&
        //   error.statusCode !== 0 &&
        //   error.statusCode !== HTTPCodesEnum.UNAUTHORIZED &&
        //   error.statusCode !== HTTPCodesEnum.FORBIDDEN
        // ) {
        //   if (key.length === 0) {
        //     key = `Network error: ${error.statusCode}`
        //   }
        //
        //   errorAlert = {
        //     type: 'http-error',
        //     statusCode: error.statusCode,
        //     showedDateTimeId: error.dateTimeId,
        //     actionType: action.type,
        //     message: error.message,
        //     url: error.url,
        //     key,
        //   }
        // }
        //
        // if (error instanceof GraphQLError) {
        //   errorAlert = {
        //     type: 'graphql-error',
        //     showedDateTimeId: error.dateTimeId,
        //     message: error.message,
        //     key: error.key || key,
        //   }
        // }
        //
        // if (errorAlert != null && !state.some(item => item.key === key)) {
        //   return [...state, errorAlert]
        // }

        return state
    }
  },
})
export default errorAlertsReducer
