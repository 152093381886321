import type {AgentPoolId} from '../../../types'
import type {KeyValue} from '../../../utils/object'

type QueueCounters = {
  readonly total: number
  readonly noCompatibleAgents: number
}
export type QueueCountersByPool = KeyValue<AgentPoolId, QueueCounters>
export type QueueSidebarState = {
  readonly counters: QueueCountersByPool
}
export const RECEIVE_POOL_QUEUE_COUNTERS: 'RECEIVE_POOL_QUEUE_COUNTERS' =
  'RECEIVE_POOL_QUEUE_COUNTERS'
export type QueueSidebarAction = {
  type: typeof RECEIVE_POOL_QUEUE_COUNTERS
  readonly data: QueueCountersByPool
}
type QueueSidebarTreeItem = {
  readonly title: string
  readonly level: number
  readonly key?: string
}
export type QueueSidebarTitleType = QueueSidebarTreeItem & {
  readonly type: 'queue-sidebar-title'
  readonly id: string
}
export type QueueSidebarLoaderType = QueueSidebarTreeItem & {
  readonly type: 'queue-sidebar-loader'
  readonly id: string
}
export type QueueSidebarLinkType = QueueSidebarTreeItem & {
  readonly type: 'queue-sidebar-link'
  readonly id: string
  readonly to: string
  readonly active: boolean
}
export type QueueSidebarPoolType = QueueSidebarTreeItem & {
  readonly type: 'queue-sidebar-pool'
  readonly id: AgentPoolId
  readonly active: boolean
  readonly counters: QueueCounters | null | undefined
}
export type QueueSidebarItemType =
  | QueueSidebarTitleType
  | QueueSidebarLoaderType
  | QueueSidebarLinkType
  | QueueSidebarPoolType
