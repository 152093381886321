import type {BaseReceiveAction} from '../../../actions/fetchActions'
import type {BuildId} from '../../../types'

import type {
  BuildForCompareData,
  CompareBuildsInfo,
  CompareBuildsList,
} from './CompareBuildsPage.types'

export const SET_SHOW_CHANGED_ONLY: 'SET_SHOW_CHANGED_ONLY' = 'SET_SHOW_CHANGED_ONLY'
export const REQUEST_COMPARE_BUILDS_LIST: 'REQUEST_COMPARE_BUILDS_LIST' =
  'REQUEST_COMPARE_BUILDS_LIST'
export const RECEIVE_COMPARE_BUILDS_LIST: 'RECEIVE_COMPARE_BUILDS_LIST' =
  'RECEIVE_COMPARE_BUILDS_LIST'
export const REQUEST_COMPARE_BUILDS_INFO: 'REQUEST_COMPARE_BUILDS_INFO' =
  'REQUEST_COMPARE_BUILDS_INFO'
export const RECEIVE_COMPARE_BUILDS_INFO: 'RECEIVE_COMPARE_BUILDS_INFO' =
  'RECEIVE_COMPARE_BUILDS_INFO'
export const REQUEST_BUILDS_FOR_COMPARE: 'REQUEST_BUILDS_FOR_COMPARE' = 'REQUEST_BUILDS_FOR_COMPARE'
export const RECEIVE_BUILDS_FOR_COMPARE: 'RECEIVE_BUILDS_FOR_COMPARE' = 'RECEIVE_BUILDS_FOR_COMPARE'
export type CompareBuildsAction =
  | {
      type: typeof REQUEST_COMPARE_BUILDS_LIST
      readonly sourceId: BuildId
      readonly compareWithId: BuildId
    }
  | (BaseReceiveAction<CompareBuildsList> & {
      type: typeof RECEIVE_COMPARE_BUILDS_LIST
      readonly sourceId: BuildId
      readonly compareWithId: BuildId
    })
  | {
      type: typeof REQUEST_COMPARE_BUILDS_INFO
      readonly compareType: string
      readonly sourceId: BuildId
      readonly compareWithId: BuildId
    }
  | (BaseReceiveAction<CompareBuildsInfo> & {
      type: typeof RECEIVE_COMPARE_BUILDS_INFO
      readonly compareType: string
      readonly sourceId: BuildId
      readonly compareWithId: BuildId
    })
  | {
      type: typeof REQUEST_BUILDS_FOR_COMPARE
      readonly buildId: BuildId
    }
  | (BaseReceiveAction<BuildForCompareData> & {
      type: typeof RECEIVE_BUILDS_FOR_COMPARE
      readonly buildId: BuildId
    })
  | {
      type: typeof SET_SHOW_CHANGED_ONLY
      readonly value: boolean
    }
