import type {BaseReceiveAction} from '../../../../../actions/fetchActions'
import type {BuildTypeId} from '../../../../../types'

import type {PossibleDeployment} from './Deployments.state.types'

export const REQUEST_POSSIBLE_DEPLOYMENTS: 'REQUEST_POSSIBLE_DEPLOYMENTS' =
  'REQUEST_POSSIBLE_DEPLOYMENTS'
export const RECEIVE_POSSIBLE_DEPLOYMENTS: 'RECEIVE_POSSIBLE_DEPLOYMENTS' =
  'RECEIVE_POSSIBLE_DEPLOYMENTS'
export type DeploymentsAction =
  | {
      type: typeof REQUEST_POSSIBLE_DEPLOYMENTS
      readonly buildTypeId: BuildTypeId
    }
  | (BaseReceiveAction<ReadonlyArray<PossibleDeployment>> & {
      type: typeof RECEIVE_POSSIBLE_DEPLOYMENTS
      readonly buildTypeId: BuildTypeId
    })
