import type {CurrentUserType, UserId} from '../types'

import processResponse, {processTextResponse} from './processResponse'
import request from './request'

export default (serverUrl: string): Promise<CurrentUserType> =>
  request(
    serverUrl,
    'users/current?fields=id,name,username,properties(property),avatars',
  ).then<CurrentUserType>(processResponse)
export const requestSaveAvatar = async (serverUrl: string, id: UserId, blob: Blob) => {
  const formData = new FormData()
  formData.append('avatar', blob)
  const response = await request(serverUrl, `avatars/id:${id}`, {method: 'PUT', body: formData})
  return processTextResponse(response)
}
export const requestDeleteAvatar = async (serverUrl: string, id: UserId) => {
  const response = await request(serverUrl, `avatars/id:${id}`, {method: 'DELETE'})
  return processTextResponse(response)
}
export const setProperty = async (
  serverUrl: string,
  name: string,
  value: string,
): Promise<string> => {
  const response = await request(serverUrl, `users/current/properties/${name}`, {
    method: 'PUT',
    body: value,
    headers: {
      Accept: 'text/plain',
      'Content-Type': 'text/plain',
    },
  })

  if (!response.ok) {
    const message = await response.text()
    throw new Error(message)
  }

  return response.text()
}
