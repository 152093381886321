import {useCallback, useEffect, useMemo, useRef} from 'react'
import type {ReactNode} from 'react'

import {LocatorContext} from '../../contexts/locator'
import {UpdateProvider} from '../../contexts/update'
import {BS, PeriodicalExecutor} from '../../types/BS_types'
import createSimpleStream from '../../utils/simpleStream'
import type {SimpleStream} from '../../utils/simpleStream'

export type FetcherProps = {
  readonly locator: string | null | undefined
  readonly update$?: SimpleStream<void>
  readonly updatePeriod?: number | null | undefined
  readonly fetchData: (locator: string, inBackground?: boolean) => Promise<unknown>
  readonly resetLocatorState?: (locator: string) => unknown
  readonly children?: ReactNode
  readonly pause?: boolean | undefined
  readonly hasSubscription?: boolean | undefined
}
type DefaultProps = {
  readonly updatePeriod: number | null | undefined
  readonly pause: boolean
}
type Props = FetcherProps & DefaultProps
export const DEBOUNCE_PERIOD = 200
export default function Fetcher({
  locator,
  update$,
  updatePeriod,
  fetchData,
  resetLocatorState,
  children,
  pause,
  hasSubscription,
}: Props) {
  const executor = useRef<PeriodicalExecutor>()
  const unmounted = useRef(false)
  const defaultUpdate$ = useMemo(() => createSimpleStream<void>(DEBOUNCE_PERIOD), [])
  const hasScheduled = useRef(false)
  const job = useRef<Promise<unknown>>()
  const fetch = useCallback(
    async (inBackground?: boolean) => {
      if (locator == null || unmounted.current || hasScheduled.current) {
        return
      }

      if (job.current != null) {
        hasScheduled.current = true
        await job.current
        hasScheduled.current = false
      }

      job.current = fetchData(locator, inBackground)
      await job.current
      job.current = undefined
    },
    [fetchData, locator],
  )
  useEffect(() => {
    if (pause || hasSubscription) {
      return undefined
    }

    if (BS == null || updatePeriod == null) {
      fetch()
      return undefined
    }

    let isFirstCall = true
    const currentExecutor = BS.periodicalExecutor(() => {
      const promise = fetch(!isFirstCall)
      isFirstCall = false
      return promise
    }, updatePeriod)
    executor.current = currentExecutor
    currentExecutor.start()
    return () => {
      currentExecutor.stop()
    }
  }, [fetch, pause, updatePeriod, hasSubscription])
  const currentUpdate$ = update$ ?? defaultUpdate$
  useEffect(
    () =>
      pause
        ? undefined
        : currentUpdate$.subscribe(() => {
            executor.current ? executor.current.unscheduledExecution() : fetch(true)
          }),
    [currentUpdate$, fetch, pause],
  )
  useEffect(
    () => () => {
      unmounted.current = true
    },
    [],
  )
  useEffect(
    () => () => {
      if (resetLocatorState && locator != null && !unmounted.current) {
        resetLocatorState(locator)
      }
    },
    [locator, resetLocatorState],
  )
  useEffect(() => {
    hasScheduled.current = false
    job.current = undefined
  }, [locator])
  return useMemo(
    () =>
      children != null ? (
        <UpdateProvider update={currentUpdate$.fire}>
          <LocatorContext.Provider value={locator ?? null}>{children}</LocatorContext.Provider>
        </UpdateProvider>
      ) : null,
    [children, currentUpdate$, locator],
  )
}
Fetcher.defaultProps = {
  updatePeriod: 60000,
  pause: false,
}
