import {PreloadedState} from 'redux'

import {getBuildlogStateToPersist} from '../components/BuildLog/BuildLog.selectors'
import {getCompareBuildsStateToPersist} from '../components/pages/CompareBuildsPage/CompareBuildsPage.selectors'
import type {State} from '../reducers/types'

export type PersistedState = PreloadedState<State>
export const getStateToPersist = (state: State): PersistedState => {
  const {
    blocks,
    clientId,
    currentUser,
    userProperties,
    overviewDraft,
    hasFavoriteProjects,
    sidebar,
  }: State = state
  return {
    blocks,
    sidebar,
    compareBuilds: getCompareBuildsStateToPersist(state),
    buildLog: getBuildlogStateToPersist(state),
    clientId,
    currentUser,
    userProperties,
    overviewDraft,
    hasFavoriteProjects,
  }
}
