export type WritableKeyValue<K extends PropertyKey, V> = Partial<Record<K, V>>
export type KeyValue<K extends PropertyKey, V> = Readonly<WritableKeyValue<K, V>>
export const objectFromEntries = <T>(entries: [keyof T, T[keyof T]][]) =>
  Object.fromEntries(entries) as Partial<T>
export const keyValue = <K extends PropertyKey, V>(key: K, value: V): KeyValue<K, V> =>
  objectFromEntries([[key, value]])
// Workaround for https://github.com/Microsoft/TypeScript/issues/12870
export const objectKeys = <T>(object: T) => Object.keys(object) as (keyof T)[]
export const objectValues = <T>(object: T): Required<T>[keyof T][] => Object.values(object)
export const objectEntries = <T>(object: T) =>
  Object.entries(object) as [keyof T, Required<T>[keyof T]][]

export const mapValues = <T, R extends Record<keyof T, unknown>>(
  object: T,
  callback: <K extends keyof T>(value: T[K], key: K) => R[K],
): R =>
  objectFromEntries<R>(
    objectEntries(object).map(([key, value]) => [key, callback(value, key)]),
  ) as R
