import {ALL_PROJECTS, stringifyId} from '../types'
import type {AgentPoolId, Permission, PoolPermissions, ProjectId} from '../types'
import type {SWGenerator} from '../types/generators'
import {keyValue} from '../utils/object'
import type {KeyValue} from '../utils/object'
import {CACHE_PROJECT_PERMISSION, REVALIDATION_HEADER_NAME} from '../workers/sw.constants'

import {subscribeOnServiceWorkerMessage} from './caching/events'
import processResponse from './processResponse'
import {
  requestProjectIdsByLocator,
  requestProjectIdsByLocatorEndpoint,
  requestProjectIdsByLocatorResolver,
} from './projects'
import type {RequestProjectIdsByLocatorResponseType} from './projects'
import request from './request'
import type {RestRequestOptions} from './request'

type ResponseType = ReadonlyArray<ProjectId>

const projectPermissionsResolver = (payload: ResponseType, projectId: ProjectId) => {
  if (payload.length === 0) {
    return projectId !== ALL_PROJECTS ? keyValue(projectId, false) : {}
  }

  return Object.fromEntries(payload.map(id => [id, true]))
}

type ResultType = KeyValue<ProjectId, boolean>
export default async function* projectPermissionGenerator(
  serverUrl: string,
  permission: Permission,
  projectId: ProjectId,
): SWGenerator<ResultType, void> {
  const locator = `${
    projectId !== ALL_PROJECTS ? `id:${stringifyId(projectId)},` : ''
  }userPermission:(permission:${permission},user:current)`
  const options = {
    headers: {
      [REVALIDATION_HEADER_NAME]: CACHE_PROJECT_PERMISSION,
    },
  }
  const subscription = subscribeOnServiceWorkerMessage<
    ResultType,
    RequestProjectIdsByLocatorResponseType
  >({
    serverUrl,
    endpoint: requestProjectIdsByLocatorEndpoint(locator),
    resolver: data => {
      const preparedData = requestProjectIdsByLocatorResolver(data)
      return projectPermissionsResolver(preparedData, projectId)
    },
  })
  const cachedResponse = await requestProjectIdsByLocator(serverUrl, locator, options)
  const payload = projectPermissionsResolver(cachedResponse, projectId)
  yield {payload}
  return await subscription
}
type PoolPermission = {
  readonly poolId: AgentPoolId
  readonly canChangeStatus: boolean
  readonly canAuthorized: boolean
}

export const requestPoolPermissions: (
  arg0: string,
  arg1: RestRequestOptions | null | undefined,
) => Promise<{
  canChangeStatus: PoolPermissions
  canAuthorize: PoolPermissions
}> = (serverUrl, options) =>
  request(serverUrl, '?poolPermissions=true', options)
    .then<ReadonlyArray<PoolPermission>>(processResponse)
    .then(data =>
      data.reduce(
        (result, item) => ({
          canChangeStatus: {
            ...result.canChangeStatus,
            ...keyValue(item.poolId, item.canChangeStatus),
          },
          canAuthorize: {...result.canAuthorize, ...keyValue(item.poolId, item.canAuthorized)},
        }),
        {canChangeStatus: {}, canAuthorize: {}},
      ),
    )
