import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {castDraft} from 'immer'

import {
  fetchBuildsAction,
  fetchSingleBuildAction,
  receiveBuildWSDataAction,
} from '../actions/builds'
import {CollapsibleBlock} from '../actions/collapsibleBlockTypes'
import {Action} from '../actions/types'
import {fetchAgentsData} from '../components/common/Agents/Agents.actions'
import {saveComment} from '../components/common/BuildActionsDropdown/CommentDialog/CommentDialog.actions'
import {pinBuildFromDialog} from '../components/common/BuildActionsDropdown/PinDialog/PinDialog.actions'
import {
  addBuildsTags,
  changeBuildTagsAction,
} from '../components/common/BuildActionsDropdown/TagDialog/TagDialog.actions'
import {keyValueFetchable} from '../reducers/fetchable'
import {CollapsibleBlockItems} from '../reducers/types'
import {
  AgentId,
  ALL_PROJECTS,
  BuildId,
  Dialog,
  DialogType,
  Fetchable,
  Id,
  Permission,
  ProjectId,
  RouteAvailabilityResponse,
  TabId,
  UrlExtension,
} from '../types'
import {emptyArray, emptyArrayFetchable} from '../utils/empty'
import {KeyValue, objectEntries} from '../utils/object'

export const isExperimentalUI = createSlice({
  name: 'isExperimentalUI',
  initialState: false,
  reducers: {
    set: () => true,
  },
})

export const urlExtensions = createSlice({
  name: 'urlExtensions',
  initialState: [] as ReadonlyArray<UrlExtension<any>>,
  reducers: {
    set: (state, action: PayloadAction<readonly UrlExtension<{}>[]>) =>
      state.concat(action.payload),
  },
})

type RouteAvailabilityResponseMeta = {
  readonly path: string
}
export const routeAvailabilityResponse = createSlice({
  name: 'routeAvailabilityResponse',
  initialState: {} as KeyValue<string, RouteAvailabilityResponse>,
  reducers: {
    set: {
      reducer(
        state,
        action: PayloadAction<RouteAvailabilityResponse, string, RouteAvailabilityResponseMeta>,
      ) {
        state[action.meta.path] = action.payload
      },
      prepare: (payload: RouteAvailabilityResponse, path: string) => ({
        payload,
        meta: {path},
      }),
    },
  },
})

export const showQueuedBuildsInBuildsList = createSlice({
  name: 'showQueuedBuildsInBuildsList',
  initialState: false,
  reducers: {
    toggle: state => !state,
  },
})

export const blocks = createSlice({
  name: 'blocks',
  initialState: {} as CollapsibleBlockItems,
  reducers: {
    add: {
      reducer(
        state,
        action: PayloadAction<ReadonlyArray<string | Id>, string, {block: CollapsibleBlock}>,
      ) {
        let prevState = state[action.meta.block]
        if (prevState == null) {
          prevState = state[action.meta.block] = {}
        }
        for (const id of action.payload) {
          prevState[id] = true
        }
      },
      prepare: (block: CollapsibleBlock, ids: ReadonlyArray<string | Id>) => ({
        payload: ids,
        meta: {block},
      }),
    },
    remove: {
      reducer(
        state,
        action: PayloadAction<ReadonlyArray<string | Id>, string, {block: CollapsibleBlock}>,
      ) {
        let prevState = state[action.meta.block]
        if (prevState == null) {
          prevState = state[action.meta.block] = {}
        }
        for (const id of action.payload) {
          prevState[id] = false
        }
      },
      prepare: (block: CollapsibleBlock, ids: ReadonlyArray<string | Id>) => ({
        payload: ids,
        meta: {block},
      }),
    },
  },
})

type OpenDialogPayload = {
  dialogId: string
  dialogType: DialogType
}

export const dialog = createSlice({
  name: 'dialog',
  initialState: Object.freeze({}) as Dialog,
  reducers: {
    open: {
      reducer(state, action: PayloadAction<OpenDialogPayload>) {
        state.opened = true
        state.error = false
        state.id = action.payload.dialogId
        state.type = action.payload.dialogType
      },
      prepare: (dialogId: string, dialogType: DialogType) => ({payload: {dialogId, dialogType}}),
    },
    close(state) {
      if (!state.processing) {
        state.opened = false
      }
    },
  },
  extraReducers: builder =>
    builder.addDefaultCase((state, action: Action) => {
      if (
        changeBuildTagsAction.pending.match(action) ||
        saveComment.pending.match(action) ||
        pinBuildFromDialog.pending.match(action) ||
        addBuildsTags.pending.match(action)
      ) {
        state.processing = true
      } else if (
        changeBuildTagsAction.fulfilled.match(action) ||
        saveComment.fulfilled.match(action) ||
        pinBuildFromDialog.fulfilled.match(action) ||
        addBuildsTags.fulfilled.match(action)
      ) {
        state.processing = false
        state.opened = false
        state.error = false
      } else if (
        changeBuildTagsAction.rejected.match(action) ||
        saveComment.rejected.match(action) ||
        pinBuildFromDialog.rejected.match(action) ||
        addBuildsTags.rejected.match(action)
      ) {
        state.processing = false
        state.opened = true
        state.error = true
      }
    }),
})

const buildsReducer = keyValueFetchable(
  arg => arg.locator,
  fetchBuildsAction,
  emptyArray,
  (_, action) => action.payload.result,
)

export const builds = createSlice({
  name: 'builds',
  initialState: {} as KeyValue<string, Fetchable<ReadonlyArray<BuildId>>>,
  reducers: {
    reset(state, action: PayloadAction<string>) {
      const prev = state[action.payload]
      if (prev != null) {
        prev.loading = false
        prev.ready = false
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSingleBuildAction.fulfilled, (state, action) => {
      const {locator} = action.meta.arg
      if (locator != null) {
        const prevState = state[locator] ?? {...emptyArrayFetchable}
        prevState.data = [...new Set(action.payload.result)]
        if (state[locator] == null) {
          state[locator] = castDraft(prevState)
        }
      }
    })
    builder.addDefaultCase(buildsReducer)
  },
})

type ReceivePermissionPayload = {
  data: KeyValue<ProjectId, boolean>
  permission: Permission
  projectId?: ProjectId
}

export const permissions = createSlice({
  name: 'permissions',
  initialState: {} as KeyValue<Permission, KeyValue<ProjectId, boolean>>,
  reducers: {
    receive(state, action: PayloadAction<ReceivePermissionPayload>) {
      const {projectId, permission, data} = action.payload
      if (projectId === ALL_PROJECTS) {
        state[permission] = data
      } else {
        state[permission] ??= {}
        Object.assign(state[permission], data)
      }
    },
  },
})

export const stoppingBuilds = createSlice({
  name: 'stoppingBuilds',
  initialState: {} as KeyValue<BuildId, boolean>,
  reducers: {
    add(state, action: PayloadAction<BuildId>) {
      state[action.payload] = true
    },
  },
  extraReducers: builder =>
    builder.addDefaultCase((state, action: Action) => {
      const receivedBuilds =
        fetchAgentsData.fulfilled.match(action) ||
        fetchSingleBuildAction.fulfilled.match(action) ||
        fetchBuildsAction.fulfilled.match(action)
          ? action.payload.entities.builds
          : receiveBuildWSDataAction.match(action)
          ? action.payload
          : undefined
      if (receivedBuilds != null) {
        objectEntries(receivedBuilds).forEach(([id, build]) => {
          if (build) {
            state[id] = false
          }
        })
      }
    }),
})

export const sorting = createSlice({
  name: 'sorting',
  initialState: {dimension: '', descending: false},
  reducers: {
    changeDimension(state, action: PayloadAction<string>) {
      state.dimension = action.payload
    },
    changeDirection(state, action: PayloadAction<boolean>) {
      state.descending = action.payload
    },
  },
})

export const agentsInCloud = createSlice({
  name: 'agentsInCloud',
  initialState: {},
  reducers: {
    receive: (_, action: PayloadAction<readonly AgentId[]>) =>
      Object.fromEntries(action.payload.map(id => [id, true])),
  },
})

export const haveDependants = createSlice({
  name: 'haveDependants',
  initialState: {} as KeyValue<BuildId, boolean>,
  reducers: {
    receive(state, action: PayloadAction<readonly [BuildId, boolean][]>) {
      action.payload.forEach(([buildId, hasDependants]) => {
        state[buildId] = hasDependants
      })
    },
  },
})

export const buildTypeTab = createSlice({
  name: 'buildTypeTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})

export const buildTab = createSlice({
  name: 'buildTab',
  initialState: null as TabId | null,
  reducers: {
    change: (_, action: PayloadAction<TabId | null>) => action.payload,
  },
})
