import {combineReducers, Reducer} from 'redux'

import type {Action} from '../../../../../actions/types'
import type {
  BuildLogSearchState,
  BuildLogSearchStates,
  FullLogTarget,
} from '../../../BuildLog.types'
import {
  CHANGE_BUILDLOG_SEARCH_MATCH_POSITION,
  CLEAR_BUILDLOG_SEARCH,
  RECEIVE_BUILDLOG_SEARCH_MESSAGE,
  RECEIVE_ERROR_BUILDLOG_SEARCH_MESSAGE,
  REQUEST_BUILDLOG_SEARCH_MESSAGE,
  SearchDirection,
  SET_FULL_LOG_STATE,
} from '../../../BuildLog.types'

const defaultSearchState: BuildLogSearchState = Object.freeze({
  query: null,
  nextStartId: null,
  foundId: null,
  matchPosition: null,
  matchesCount: 0,
  startId: null,
  limitReachedTimes: 0,
  notFound: false,
  loading: false,
  searchDirection: SearchDirection.Next,
})

const buildLogSearchReducer = (
  state: BuildLogSearchState,
  action: Action,
  target: FullLogTarget,
): BuildLogSearchState => {
  if ('target' in action && action.target !== target) {
    return state
  }

  switch (action.type) {
    case REQUEST_BUILDLOG_SEARCH_MESSAGE: {
      if (action.query !== state.query || action.searchDirection !== state.searchDirection) {
        return {
          ...defaultSearchState,
          query: action.query,
          searchDirection: action.searchDirection,
          loading: true,
        }
      }

      return {...state, loading: true}
    }

    case RECEIVE_ERROR_BUILDLOG_SEARCH_MESSAGE: {
      if (action.query === state.query && action.searchDirection === state.searchDirection) {
        return {...state, loading: false}
      }

      return state
    }

    case RECEIVE_BUILDLOG_SEARCH_MESSAGE: {
      if (action.query === state.query && action.searchDirection === state.searchDirection) {
        return {
          query: state.query,
          nextStartId: action.nextStartId,
          foundId: action.foundId,
          matchPosition: action.matchPosition,
          matchesCount: action.matchesCount,
          startId: action.startId,
          limitReachedTimes: action.limitReached ? state.limitReachedTimes + 1 : 0,
          notFound: action.notFound,
          loading: false,
          searchDirection: action.searchDirection,
        }
      }

      return state
    }

    case CHANGE_BUILDLOG_SEARCH_MATCH_POSITION: {
      return action.query === state.query ? {...state, matchPosition: action.matchPosition} : state
    }

    case CLEAR_BUILDLOG_SEARCH: {
      return defaultSearchState
    }

    case SET_FULL_LOG_STATE: {
      // eslint-disable-next-line eqeqeq
      if (action.buildId === null) {
        return defaultSearchState
      }

      if (action.focusLine != null && action.focusLine !== state.foundId) {
        return {
          ...state,
          matchPosition: null,
          nextStartId: action.focusLine + (state.searchDirection === SearchDirection.Next ? 1 : -1),
        }
      }

      return state
    }

    default:
      return state
  }
}

export const searchStates: Reducer<BuildLogSearchStates, Action> = combineReducers({
  page: (state: BuildLogSearchState = defaultSearchState, action: Action) =>
    buildLogSearchReducer(state, action, 'page'),
  popup: (state: BuildLogSearchState = defaultSearchState, action: Action) =>
    buildLogSearchReducer(state, action, 'popup'),
})
