import {combineReducers} from 'redux'

import {
  RECEIVE_PROJECT_TEST_INVESTIGATIONS,
  REQUEST_PROJECT_TEST_INVESTIGATIONS,
} from '../../../../actions/actionTypes'
import fetchable from '../../../../reducers/fetchable'

import {InvestigationsCounter, ProjectInvestigations} from './ProjectInvesigations.types'

const testInvestigations = fetchable<InvestigationsCounter | null, InvestigationsCounter>(
  REQUEST_PROJECT_TEST_INVESTIGATIONS,
  RECEIVE_PROJECT_TEST_INVESTIGATIONS,
  null,
  action => action.data,
)
const projectInvestigationsReducers = combineReducers<ProjectInvestigations>({
  testInvestigations,
})

export default projectInvestigationsReducers
