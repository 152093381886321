import {createSelector} from 'reselect'

import type {State} from '../../reducers/types'
import {getBuild, getBuildsHash, getCurrentUserId} from '../../selectors'
import type {
  BuildId,
  ChangeFileType,
  ChangeId,
  Fetchable,
  InexactCountable,
  NormalizedBuildType,
  NormalizedChangeType,
  NormalizedRevisionType,
  NormalizedVcsRootInstanceType,
  RevisionId,
  VcsLabelType,
  VcsRootId,
  VcsRootInstanceId,
  VcsRootType,
} from '../../types'
import {emptyArray, emptyArrayFetchable, getEmptyHash} from '../../utils/empty'
import {notNull} from '../../utils/guards'
import type {KeyValue, WritableKeyValue} from '../../utils/object'

import type {ChangeDeploymentType} from './Changes.types'
import {ChangeBuildTypeType} from './Changes.types'
import {getSingleUser} from './Changes.utils'

export const getBuildChangesRevisionsFetchable: (
  state: State,
  locator: string,
) => Fetchable<ReadonlyArray<BuildId>> = (state, locator) =>
  state.changesReducer.buildChangesRevisionsByLocator[locator] || emptyArrayFetchable

const getBuildChangesIdsByBuildId: (
  state: State,
  buildId: BuildId,
) => ReadonlyArray<ChangeId> | null | undefined = (state, buildId) =>
  state.changesReducer.buildChangesByBuildId[buildId]

export const getIsReadyBuildChangesIdsByBuildId: (arg0: State, arg1: BuildId) => boolean = (
  state,
  buildId,
) => getBuildChangesIdsByBuildId(state, buildId) != null

export const getChangesIdsByLocator: (
  state: State,
  locator: string,
) => Fetchable<ReadonlyArray<ChangeId>> = (state, locator) =>
  state.changesReducer.changesByLocator[locator] ?? emptyArrayFetchable

export const getBuildArtifactDependencyChangesByLocator: (
  state: State,
  locator: string,
) => Fetchable<ReadonlyArray<BuildId>> = (state, locator) =>
  state.changesReducer.buildArtifactDependencyChangesByLocator[locator] ?? emptyArrayFetchable

export const makeGetBuildArtifactDependencyChanges: () => (
  state: State,
  locator: string,
) => ReadonlyArray<NormalizedBuildType> = () =>
  createSelector(
    [
      getBuildsHash,
      (state: State, locator: string) =>
        getBuildArtifactDependencyChangesByLocator(state, locator).data,
    ],
    (hash: KeyValue<BuildId, NormalizedBuildType | null>, data: ReadonlyArray<BuildId>) =>
      data.map(id => hash[id]).filter(notNull),
  )

export const getChangesHash: (state: State) => KeyValue<ChangeId, NormalizedChangeType> = state =>
  state.entities.changes

export const getChange: (
  state: State,
  changeId: ChangeId | null | undefined,
) => NormalizedChangeType | null | undefined = (state, changeId) =>
  changeId != null ? getChangesHash(state)[changeId] : null

export const getHasMyChanges: (arg0: State, arg1: string) => boolean = (state, locator) => {
  const myId = getCurrentUserId(state)
  return getChangesIdsByLocator(state, locator).data.some(
    id => getSingleUser(getChange(state, id))?.id === myId,
  )
}

export const getChangesByLocator = (state: State, locator: string) =>
  getChangesIdsByLocator(state, locator)
    .data.map(id => getChange(state, id))
    .filter(notNull)

export const getBuildChanges: (
  state: State,
  buildId: BuildId,
) => ReadonlyArray<NormalizedChangeType> = createSelector(
  [getBuildChangesIdsByBuildId, getChangesHash],
  (
    ids: ReadonlyArray<ChangeId> | null | undefined,
    changes: KeyValue<ChangeId, NormalizedChangeType>,
  ) => (ids != null ? ids.map((id: ChangeId) => changes[id]).filter(Boolean) : emptyArray),
)

export const getBuildArtifactDependenciesChangesCount: (
  state: State,
  buildId: BuildId,
) => InexactCountable | null | undefined = (state, buildId) =>
  state.changesReducer.artifactDependencyChangesCount[buildId]

export const hasChangeFiles: (arg0: State, arg1: ChangeId | null | undefined) => boolean = (
  state,
  changeId,
) => (changeId != null ? state.changesReducer.changesFiles[changeId] != null : false)

export const getChangeFiles: (
  state: State,
  changeId: ChangeId | null | undefined,
) => ReadonlyArray<ChangeFileType> = (state, changeId) =>
  changeId != null ? state.changesReducer.changesFiles[changeId] ?? emptyArray : emptyArray

export const getBuildSettingsRevisionId = (
  state: State,
  buildId: BuildId,
): RevisionId | null | undefined => state.changesReducer.buildSettingsRevisions[buildId]

const getBuildRevisionsIds: (
  state: State,
  buildId: BuildId | null | undefined,
) => ReadonlyArray<RevisionId> | null | undefined = (state, buildId) =>
  buildId != null ? state.changesReducer.buildRevisions[buildId] || emptyArray : null

export const getRevision: (
  state: State,
  revisionId: RevisionId | null | undefined,
) => NormalizedRevisionType | null | undefined = (state, revisionId) =>
  revisionId != null ? state.entities.revisions[revisionId] : null

export const makeGetVcsLabels: () => (
  state: State,
  buildId?: BuildId | null,
  vcsRootId?: VcsRootId | null,
) => ReadonlyArray<VcsLabelType> = () =>
  createSelector(
    [
      (_: State, buildId?: BuildId | null) => buildId,
      (_: State, __?: BuildId | null, vcsRootId?: VcsRootId | null) => vcsRootId,
      (state: State) => state.entities.vcsLabels,
    ],
    (
      buildId: BuildId | null | undefined,
      vcsRootId: VcsRootId | null | undefined,
      vcsLabelsHash: KeyValue<BuildId, ReadonlyArray<VcsLabelType>>,
    ): readonly VcsLabelType[] => {
      const vcsLabels = buildId != null ? vcsLabelsHash[buildId] ?? emptyArray : emptyArray
      return vcsLabels.filter(
        vcsRoot => vcsRoot?.['vcs-root-instance']?.['vcs-root']?.id === vcsRootId,
      )
    },
  )

const getBuildRevisions: (
  state: State,
  buildId: BuildId | null | undefined,
) => ReadonlyArray<NormalizedRevisionType> = createSelector(
  getBuildRevisionsIds,
  (state: State) => state.entities.revisions,
  (ids: readonly RevisionId[] | null | undefined, revisions): readonly NormalizedRevisionType[] =>
    revisions != null && ids != null && ids.length > 0
      ? ids.map(id => revisions[id]).filter(Boolean)
      : emptyArray,
)

export const getVcsRoots: (state: State) => KeyValue<VcsRootId, VcsRootType> = state =>
  state.entities.vcsRoots

export const getVcsRootInstances: (
  state: State,
) => KeyValue<VcsRootInstanceId, NormalizedVcsRootInstanceType> = state =>
  state.entities.vcsRootInstances

export const getVcsRoot: (
  state: State,
  vcsRootId: VcsRootId | null | undefined,
) => VcsRootType | null | undefined = (state, vcsRootId) =>
  vcsRootId != null ? getVcsRoots(state)[vcsRootId] : null

export const getBuildRevisionIdsByVcsRootId: (
  state: State,
  buildId: BuildId | null | undefined,
) => KeyValue<VcsRootId, ReadonlyArray<RevisionId>> = createSelector(
  getBuildRevisions,
  getVcsRootInstances,
  (
    mainRevisions: ReadonlyArray<NormalizedRevisionType>,
    vcsRootInstances,
  ): KeyValue<VcsRootId, ReadonlyArray<RevisionId>> => {
    if (mainRevisions.length === 0) {
      return getEmptyHash()
    }

    const mainRevisionIdsByVcsRootId: WritableKeyValue<VcsRootId, ReadonlyArray<RevisionId>> = {}
    mainRevisions.forEach(({version, 'vcs-root-instance': vcsRootInstanceId}) => {
      if (version != null && vcsRootInstanceId != null) {
        const vcsRootId = vcsRootInstances[vcsRootInstanceId]?.['vcs-root']

        if (vcsRootId != null) {
          const revisionIds = mainRevisionIdsByVcsRootId[vcsRootId] ?? []

          if (!revisionIds.includes(version)) {
            mainRevisionIdsByVcsRootId[vcsRootId] = revisionIds.concat(version)
          }
        }
      }
    })
    return mainRevisionIdsByVcsRootId
  },
)

export const getBuildsRevisionIdsByVcsRootId: (
  state: State,
  locator: string,
) => KeyValue<VcsRootId, ReadonlyArray<RevisionId>> = createSelector(
  (state: State, locator: string) => getBuildChangesRevisionsFetchable(state, locator).data,
  (state: State) => state.entities.revisions,
  (state: State) => state.entities.vcsRootInstances,
  (state: State) => state.changesReducer.buildRevisions,
  (
    buildsData: ReadonlyArray<BuildId>,
    revisions,
    vcsRootInstances,
    buildRevisions: KeyValue<BuildId, ReadonlyArray<RevisionId>>,
  ): KeyValue<VcsRootId, ReadonlyArray<RevisionId>> => {
    if (buildsData.length === 0) {
      return getEmptyHash()
    }

    const dependencyRevisionsByVcsRootId: WritableKeyValue<
      VcsRootId,
      ReadonlyArray<RevisionId>
    > = {}
    buildsData.forEach(buildId => {
      const buildRevisionsIds = buildRevisions[buildId] ?? []
      buildRevisionsIds.forEach(revisionId => {
        const vcsRootInstanceId = revisions[revisionId]?.['vcs-root-instance']

        if (vcsRootInstanceId != null) {
          const vcsRootId = vcsRootInstances[vcsRootInstanceId]?.['vcs-root']

          if (vcsRootId != null) {
            const revisionsArray = dependencyRevisionsByVcsRootId[vcsRootId] ?? []

            if (!revisionsArray.includes(revisionId)) {
              dependencyRevisionsByVcsRootId[vcsRootId] = revisionsArray.concat(revisionId)
            }
          }
        }
      })
    })
    return dependencyRevisionsByVcsRootId
  },
)

export const getVcsRootInstance: (
  state: State,
  vcsRootInstanceId: VcsRootInstanceId | null | undefined,
) => NormalizedVcsRootInstanceType | null | undefined = (state, vcsRootInstanceId) =>
  vcsRootInstanceId != null ? state.entities.vcsRootInstances[vcsRootInstanceId] : null

export const getPersonalChangeId: (
  state: State,
  buildId: BuildId,
) => ChangeId | null | undefined = (state, buildId) => {
  const build = getBuild(state, buildId)

  if (build?.personal !== true) {
    return null
  }

  const [lastChange] = getBuildChanges(state, buildId)
  const {personal = false, id} = lastChange || {}
  return personal ? id : null
}

export const getChangeBuildTypesFetchable = (
  state: State,
  locator: string,
): Fetchable<ReadonlyArray<ChangeBuildTypeType>> =>
  state.changesReducer.changeBuildTypes[locator] ?? emptyArrayFetchable

export const getChangeDeploymentsFetchable = (
  state: State,
  locator: string,
): Fetchable<ReadonlyArray<ChangeDeploymentType>> =>
  state.changesReducer.changeDeployments[locator] ?? emptyArrayFetchable
