import * as Redux from 'redux'

import type {Action} from '../../../actions/types'
import fetchable from '../../../reducers/fetchable'
import {keyValueReducer} from '../../../reducers/utils'
import {emptyArray} from '../../../utils/empty'

import {
  BuildQueueInfoStateType,
  RECEIVE_WAIT_REASONS,
  REQUEST_WAIT_REASONS,
  WaitReasons,
} from './BuildQueueInfo.types'
import type {WaitReasonsMap} from './BuildQueueInfo.types'

const waitReasonsReducer = keyValueReducer(
  action => action.buildId,
  fetchable<WaitReasons, WaitReasons>(
    REQUEST_WAIT_REASONS,
    RECEIVE_WAIT_REASONS,
    emptyArray,
    action => action.data,
  ),
  [REQUEST_WAIT_REASONS, RECEIVE_WAIT_REASONS],
)
const queueInfoReducer = Redux.combineReducers<BuildQueueInfoStateType>({
  waitReasons: (state: WaitReasonsMap = {}, action: Action) => {
    switch (action.type) {
      case REQUEST_WAIT_REASONS:
      case RECEIVE_WAIT_REASONS:
        return waitReasonsReducer(state, action)

      default:
        return state
    }
  },
})
export default queueInfoReducer
