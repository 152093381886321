import {$Values} from 'utility-types'

import {BuildSnippetNameType} from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.types'

import version from './version'

export const UserProperties = {
  HIGHLIGHT_MY_CHANGES: 'hightlightMyChanges',
  AUTODETECT_TIMEZONE: 'autodetectTimeZone',
  SHOW_ALL_PERSONAL_BUILDS: 'showAllPersonalBuilds',
  SHOW_ALL_CHANGES_FILES: 'showAllChangesFiles',
  USE_EXPERIMENTAL_OVERVIEW: 'useExperimentalOverview',
  HAS_SEEN_EXPERIMENTAL_UI_MESSAGE: 'hasSeenExperimentalUIMessage',
  HAS_SEEN_EXPERIMENTAL_UI_WELCOME: 'hasSeenExperimentalUIWelcomeMessage',
  HAS_SEEN_EXPERIMENTAL_OVERVIEW: 'hasSeenExperimentalOverview',
  EXPAND_BUILD_PROBLEMS_SECTION: 'expandBuildProblemsSection',
  EXPAND_CHANGE_PROBLEMS_SECTION: 'expandChangeProblemsSection',
  EXPAND_BUILD_TESTS_SECTION: 'expandBuildTestsSection',
  EXPAND_CHANGE_TESTS_SECTION: 'expandChangeTestsSection',
  EXPAND_PROJECT_INVESTIGATIONS_TESTS_SECTION: 'expandProjectInvestigationsTestsSection',
  EXPAND_BUILD_CHANGES_SECTION: 'expandBuildChangesSection',
  EXPAND_BUILD_DEPLOYMENTS_SECTION: 'expandBuildDeploymentsSection',
  EXPAND_REVISIONS_SECTION: 'expandRevisionsSection',
  EXPAND_TEST_HISTORY_CHART_SECTION: 'expandTestHistoryChartSection',
  EXPAND_TEST_HISTORY_INVESTIGATIONS_SECTION: 'expandTestHistoryInvestigationsSection',
  EXPAND_TEST_HISTORY_MUTES_SECTION: 'expandTestHistoryMutesSection',
  HIDE_PROBLEMS_FROM_DEPENDENCIES: 'hideProblemsFromDependencies',
  HIDE_TESTS_FROM_DEPENDENCIES: 'hideTestsFromDependencies',
  BUILD_TESTS_TREE_VIEW: 'buildTestsTreeView',
  PROJECT_INVESTIGATIONS_TESTS_TREE_VIEW: 'projectInvestigationsTestsTreeView',
  HAS_USED_EXPERIMENTAL_UI_OPTOUT_FORM: `hasUsedExperimentalUIOptoutForm_${version}`,
  HAS_USED_TRY_CLOUD: 'hasUsedTryCloud',
  HAS_SEEN_EXPERIMENTAL_UI_FEEDBACK_FORM: `hasSeenExperimentalUIFeedbackForm_${version}`,
  HAS_SEEN_TIPS_REMINDER: 'hasSeenTipsReminder',
  TIPS_MODE: 'tipsMode',
  DEPENDENCIES_TYPE: 'dependenciesType',
  DEPENDENCIES_MODE: 'dependenciesMode',
  PROJECT_OVERVIEW_MODE: 'projectOverviewMode',
  BUILD_TYPE_OVERVIEW_MODE: 'buildTypeOverviewMode',
  CLEANUP_SHOW_SUBPROJECTS: 'cleanupShowSubprojects',
  ADD_TRIGGERED_BUILD_TO_FAVORITES: 'addTriggeredBuildToFavorites',
  USE_SERVICE_WORKER: 'useServiceWorker',
  SHOW_CHAIN_DETAILS: 'showChainDetails',
  UNGROUP_COMPOSITE_BUILDS_IN_CHAIN: 'ungroupCompositeBuildsInChain',
  GROUP_PROJECTS_IN_CHAIN: 'groupProjectsInChain',
  CHANGE_BUILDS_SHOW_ONLY_FAILED: 'changeBuildsShowOnlyFailed',
  CHANGE_BUILDS_SHOW_HIERARCHY: 'changeBuildsShowHierarchy',
} as const
export type UserProperty = $Values<typeof UserProperties> | `expand${BuildSnippetNameType}`
