import TagIcon from '@jetbrains/icons/tag.svg'
import Tag, {TagRenderProps} from '@jetbrains/ring-ui/components/tag/tag'
import classnames from 'classnames'
import * as React from 'react'
import {ReactNode} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {getBuildTypeHref} from '../../routes'
import {getBuildTypeLinks, getIsExperimentalUI} from '../../selectors'
import type {BuildId, BuildTypeId} from '../../types'
import {resolveWebEntityLink} from '../../utils/entityLinks'
import {removeBuildTag} from '../common/BuildActionsDropdown/TagDialog/TagDialog.actions'
import Link from '../common/Link/Link'
import MiddleEllipsis from '../common/MiddleEllipsis/MiddleEllipsis'
import RouterLink from '../common/RouterLink/RouterLink'
import SvgIcon from '../common/SvgIcon/SvgIcon'
import {Modes} from '../pages/BuildTypePage/BuildTypeOverviewTab/BuildTypeOverviewTab.modes'

import styles from './BuildTag.css'

type Props = {
  readonly onSelect?: (() => unknown) | undefined
  readonly className?: string
  readonly buildTypeId?: BuildTypeId | undefined
  readonly buildId?: BuildId | undefined
  readonly label?: string | null | undefined
  readonly multiple?: boolean
  readonly selected?: boolean
  readonly href?: string
  readonly removable?: boolean
}

const useHref = ({href, label, buildTypeId, selected = false}: Props) => {
  const baseHref = useSelector(state => {
    const links = getBuildTypeLinks(state, buildTypeId)
    return resolveWebEntityLink(links) ?? ''
  })

  if (href != null) {
    return href
  }

  if (label == null) {
    return '#'
  }

  const params = new URLSearchParams(location.search)
  params.set('tab', 'buildTypeHistoryList')

  if (selected) {
    params.delete('tag')
  } else {
    params.set('tag', label)
  }

  return `${baseHref}&${params.toString()}`
}

const BuildTag = (props: Props) => {
  const dispatch = useDispatch()
  const {
    className,
    label,
    multiple,
    selected = false,
    buildTypeId,
    onSelect,
    removable,
    buildId,
  } = props
  const stopPropagation = React.useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])
  const isExperimentalUI = useSelector(getIsExperimentalUI)
  const href = useHref(props)
  const classes = classnames(styles.tag, className, {
    [styles.selected]: selected,
    [styles.multiple]: multiple,
  })

  if (label == null) {
    return null
  }

  if (multiple === true) {
    return (
      <span className={classes} onClick={stopPropagation} role="presentation">
        <span>
          <SvgIcon icon={TagIcon} className={styles.icon} />
        </span>
        {label}
      </span>
    )
  }

  let render: ((props: TagRenderProps) => ReactNode) | undefined
  if (onSelect != null) {
    render = undefined
  } else if (isExperimentalUI && buildTypeId != null) {
    // eslint-disable-next-line react/display-name
    render = ({children, ref, ...tagProps}) => (
      <RouterLink
        innerClassName={styles.linkInner}
        to={getBuildTypeHref(buildTypeId)}
        params={{
          tag: selected ? null : label,
          mode: Modes.BUILDS,
        }}
        withMergeParams
        {...tagProps}
      >
        {() => children}
      </RouterLink>
    )
  } else {
    // eslint-disable-next-line react/display-name
    render = ({children, ref, ...tagProps}) => (
      <Link href={href} {...tagProps}>
        {() => children}
      </Link>
    )
  }

  return (
    <Tag
      angled
      className={classes}
      onClick={onSelect}
      onRemove={
        buildId != null ? () => dispatch(removeBuildTag(buildId, label, buildTypeId)) : undefined
      }
      readOnly={!removable || buildId == null}
      render={render}
    >
      <MiddleEllipsis tailLength={2}>{label}</MiddleEllipsis>
    </Tag>
  )
}

export default React.memo<Props>(BuildTag)
