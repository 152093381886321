import {getBuildFields} from '../../rest/builds'
import processResponse from '../../rest/processResponse'
import request from '../../rest/request'
import type {
  BuildChangesRevisionsType,
  BuildChangesType,
  BuildType,
  ChangesRequestOptions,
  ChangeType,
} from '../../types'
import {internalProps} from '../../types/BS_types'

import type {
  ChangeBuildTypeType,
  ChangeDeploymentType,
  RequestBuildChangesRevisionsParams,
} from './Changes.types'

const CHANGES_LIMIT: number = internalProps['teamcity.internal.buildChangesPopupLimit']
const vcsRootParams = 'id,vcs-root(id,name,vcsName)'
const revisionParams = `version,vcsBranchName,vcs-root-instance(${vcsRootParams})`

export const getChangesFields = (options?: ChangesRequestOptions): string =>
  'change(' +
  'id,' +
  'type,' +
  'version,' +
  'parentRevisions,' +
  'personal,' +
  'username,' +
  'name,' +
  'date,' +
  'commiter(users(user(id,name,username,avatars))),' +
  `vcsRootInstance(${vcsRootParams}),` +
  `${
    options?.withFiles === true ? 'files(count,file(file,directory,changeType)),' : 'files(count),'
  }` +
  'comment,' +
  'webUrl,' +
  'storesProjectSettings,' +
  'snapshotDependencyLink(build(id,buildType(id,projectId,name),number),buildType(id,projectId,name),buildTypeBranch)' +
  ')'

const buildChangesFields = `changes($locator(count:${CHANGES_LIMIT}),${getChangesFields()}),artifactDependencyChanges(count)`

type BuildChangesRevisionsData = {
  build: BuildChangesRevisionsType[]
}
export const requestBuildChangesRevisions = (
  serverUrl: string,
  locator: string,
  options?: RequestBuildChangesRevisionsParams,
): Promise<ReadonlyArray<BuildChangesRevisionsType>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(
      locator,
    )}&fields=build(id,vcsLabels(vcs-root-instance(vcs-root(id)),failureReason,status,text),${[
      options?.withSettingsRevisions === true && `versionedSettingsRevision(${revisionParams})`,
      `revisions(revision(${revisionParams}))`,
    ]
      .filter(Boolean)
      .join(',')})`,
  )
    .then<BuildChangesRevisionsData>(processResponse)
    .then(data => data.build)

type BuildChangesData = {
  build: BuildChangesType[]
}
export const requestBuildChanges = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<BuildChangesType>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(locator)}&fields=build(id,${buildChangesFields})`,
  )
    .then<BuildChangesData>(processResponse)
    .then(data => data.build)

type ChangesData = {
  change: ChangeType[]
  nextHref?: string
}
export const requestChanges = (
  serverUrl: string,
  locator: string,
  options?: ChangesRequestOptions,
): Promise<{
  data: ReadonlyArray<ChangeType>
  hasMore: boolean
}> =>
  request(
    serverUrl,
    `changes?${
      locator !== '' ? `locator=${encodeURIComponent(locator)}` : ''
    }&fields=nextHref,${getChangesFields(options)}`,
  )
    .then<ChangesData>(processResponse)
    .then(data => ({
      data: data.change,
      hasMore: data.nextHref != null,
    }))

type ChangeFilesData = {
  change: ChangeType[]
}
export const requestChangeFiles = (
  serverUrl: string,
  locator: string,
): Promise<{
  data: ReadonlyArray<ChangeType>
}> =>
  request(
    serverUrl,
    `changes?locator=${encodeURIComponent(
      locator,
    )}&fields=change(id,files(count,file(file,directory,changeType)))`,
  )
    .then<ChangeFilesData>(processResponse)
    .then(data => ({
      data: data.change,
    }))

type BuildArtifactDependenciesChangesItem = {
  nextBuild: BuildType
}
type BuildArtifactDependenciesChangesData = {
  artifactDependencyChanges: {
    buildChange?: BuildArtifactDependenciesChangesItem[]
  }
}
export const requestBuildArtifactDependenciesChanges = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<BuildType>> =>
  request(
    serverUrl,
    `builds/${encodeURIComponent(
      locator,
    )}?fields=artifactDependencyChanges(buildChange($locator(count:100),nextBuild(${getBuildFields()})))`,
  )
    .then<BuildArtifactDependenciesChangesData>(processResponse)
    .then(data => data?.artifactDependencyChanges?.buildChange)
    .then(data => (data != null ? data.map(item => item?.nextBuild) : []))

type BuildTypeIdsData = {
  buildType: readonly ChangeBuildTypeType[]
}
export const requestChangeBuildTypes = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeBuildTypeType>> =>
  request(serverUrl, `changes/${encodeURIComponent(locator)}/buildTypes?fields=buildType(id,type)`)
    .then<BuildTypeIdsData>(processResponse)
    .then(data => {
      const buildTypes = data.buildType
      return buildTypes.filter(
        (buildType, index) => !buildTypes.slice(0, index).some(other => other.id === buildType.id),
      )
    })

type DeploymentsIdsData = {
  buildType: readonly ChangeDeploymentType[]
}
export const requestChangeDeployments = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<ChangeDeploymentType>> =>
  request(
    serverUrl,
    `changes/${encodeURIComponent(locator)}/deploymentConfigurations?fields=buildType(id)`,
  )
    .then<DeploymentsIdsData>(processResponse)
    .then(data => data?.buildType)
