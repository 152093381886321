import {LocationProvider, NavigateOptions} from '@reach/router'
import * as React from 'react'
import {useDispatch} from 'react-redux'

import {preloadRoute} from '../components/App/App.routes'
import usePrevious from '../hooks/usePrevious'

import {HistoryContext, reachHistory} from '.'
import type {QReachHistory} from '.'

declare module '@reach/router' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface NavigateOptions<TState> {
    isQuerySync?: boolean
  }
}

type HistoryProviderProps = {
  readonly history?: QReachHistory
  readonly children: React.ReactNode
}
export default function HistoryProvider({
  history = reachHistory,
  children,
}: HistoryProviderProps): React.ReactElement {
  const dispatch = useDispatch()
  const patchedHistory: QReachHistory = {
    ...history,

    // flowlint unsafe-getters-setters:off
    get location() {
      return history.location
    },

    get transitioning() {
      return history.transitioning
    },

    // flowlint unsafe-getters-setters:error
    navigate(to: string | number, options?: NavigateOptions<{}>) {
      if (!options?.isQuerySync) {
        dispatch(preloadRoute(String(to), history.location))
      }
      history.navigate(String(to), options)
      return Promise.resolve()
    },
  }
  const prevLocation = usePrevious(history.location)
  React.useEffect(
    () =>
      history.listen(event => {
        if (event?.action === 'POP') {
          dispatch(preloadRoute(event.location, prevLocation))
        }
      }),
    [dispatch, history, prevLocation],
  )
  return (
    <HistoryContext.Provider value={patchedHistory}>
      <LocationProvider history={patchedHistory}>{children}</LocationProvider>
    </HistoryContext.Provider>
  )
}
