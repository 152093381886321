import type {TabId, ProjectId, BranchType} from '../../../types'

export type ProjectPageStateType = {
  readonly tab: TabId | null
}
type OwnProps = {
  readonly projectId?: ProjectId
  readonly path?: string
}
export type ReduxOwnProps = OwnProps & {
  readonly projectId: ProjectId
  readonly branch: BranchType | null | undefined
}
export type StateProps = {
  readonly error: Error | null | undefined
  readonly actualId: ProjectId | null | undefined
  readonly idChanged: boolean
  readonly projectLoaded: boolean
}
export type ActionProps = {
  readonly fetchProject: (projectId: ProjectId) => unknown
}
export type Props = ReduxOwnProps & StateProps & ActionProps
export const CHANGE_PROJECT_TAB: 'CHANGE_PROJECT_TAB' = 'CHANGE_PROJECT_TAB'

export type ProjectPageAction = {
  type: typeof CHANGE_PROJECT_TAB
  next: TabId | null
}
