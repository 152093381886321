import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'
import {batch} from 'react-redux'

import {useDerivedState} from '../../hocs/withDerivedState'
import {UserProperties, UserProperty} from '../../utils/userProperties'
import Link from '../common/Link/Link'
import ServiceMessage from '../ServiceMessage/ServiceMessage'

import ExperimentalUIFeedback from './ExperimentalUIFeedback/ExperimentalUIFeedback'

import styles from './ExperimentalUIOptout.css'

type Props = {
  readonly hide?: boolean
  readonly setUserProperty?: (property: UserProperty, value: string) => unknown
}
const profileHref = '/profile.html?init=1'

function noop() {}

const ExperimentalUIOptout = ({setUserProperty = noop, hide = false}: Props) => {
  const [showFeedbackForm, setShowFeedbackForm] = React.useState(false)
  const show = useDerivedState(false, prevShow => (prevShow && !hide) || !hide)

  const onClose = React.useCallback(() => {
    setUserProperty(UserProperties.HAS_USED_EXPERIMENTAL_UI_OPTOUT_FORM, 'true')
  }, [setUserProperty])

  const onOptout = React.useCallback(() => {
    batch(() => {
      setUserProperty(UserProperties.USE_EXPERIMENTAL_OVERVIEW, 'false')
      onClose()
    })
    setShowFeedbackForm(true)
  }, [onClose, setUserProperty])

  const content = React.useMemo(
    () => (
      <>
        {
          'You have switched to\u00a0the\u00a0classic\u00a0UI. You’re using it temporarily and\u00a0want to\u00a0continue with\u00a0the\u00a0experimental\u00a0UI as\u00a0default? If you change your mind later, you can restore the\u00a0“Use experimental\u00a0UI” setting in\u00a0'
        }
        <Link relative href={profileHref} target="_blank" rel="noreferrer" className={styles.link}>
          {'your profile'}
        </Link>
        {' at\u00a0any time.'}
      </>
    ),
    [],
  )

  const buttons = React.useMemo(
    () => (
      <>
        <Button primary onClick={onClose}>
          {'Continue with experimental UI'}
        </Button>
        <Button text onClick={onOptout} className={styles.optoutButton}>
          {'Switch to classic UI as default'}
        </Button>
      </>
    ),
    [onClose, onOptout],
  )

  return (
    <>
      {show && (
        <ServiceMessage
          captionClassName={styles.caption}
          containerClassName={styles.experimentalUiOptout}
          icon="experiment-20px"
          closeable={false}
          title="Continue with experimental UI as default?"
          controls={buttons}
        >
          {content}
        </ServiceMessage>
      )}
      {showFeedbackForm && <ExperimentalUIFeedback />}
    </>
  )
}

export default ExperimentalUIOptout
