import {connect} from 'react-redux'

import {setUserProperty} from '../../actions'

import {getBooleanUserProperty, getCurrentUserLoaded, getIsGuestOrRoot} from '../../selectors'
import {UserProperties} from '../../utils/userProperties'

import ExperimentalUIOptout from './ExperimentalUIOptout'

export default connect(
  state => ({
    hide:
      getIsGuestOrRoot(state) ||
      !getCurrentUserLoaded(state) ||
      getBooleanUserProperty(state, UserProperties.HAS_USED_EXPERIMENTAL_UI_OPTOUT_FORM),
  }),
  {
    setUserProperty,
  },
)(ExperimentalUIOptout)
