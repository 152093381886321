import * as Redux from 'redux'

import type {Action} from '../../../actions/types'
import fetchable from '../../../reducers/fetchable'
import {keyValueReducer} from '../../../reducers/utils'
import {stringifyId} from '../../../types'
import {emptyArray} from '../../../utils/empty'
import {keyValue} from '../../../utils/object'

import {
  RECEIVE_BUILDS_FOR_COMPARE,
  RECEIVE_COMPARE_BUILDS_INFO,
  RECEIVE_COMPARE_BUILDS_LIST,
  REQUEST_BUILDS_FOR_COMPARE,
  REQUEST_COMPARE_BUILDS_INFO,
  REQUEST_COMPARE_BUILDS_LIST,
  SET_SHOW_CHANGED_ONLY,
} from './CompareBuildsPage.actions.types'
import type {
  BuildForCompareData,
  CompareBuildsList,
  CompareBuildsInfo,
  ListsState,
  SettingsState,
} from './CompareBuildsPage.types'
import {CompareBuildsState} from './CompareBuildsPage.types'

const listReducer = fetchable<CompareBuildsList, CompareBuildsList>(
  REQUEST_COMPARE_BUILDS_LIST,
  RECEIVE_COMPARE_BUILDS_LIST,
  emptyArray,
  action => action.data,
)
const infoReducer = fetchable<CompareBuildsInfo, CompareBuildsInfo>(
  REQUEST_COMPARE_BUILDS_INFO,
  RECEIVE_COMPARE_BUILDS_INFO,
  Object.freeze({}),
  action => action.data,
)
const buildsReducer = fetchable<BuildForCompareData, BuildForCompareData>(
  REQUEST_BUILDS_FOR_COMPARE,
  RECEIVE_BUILDS_FOR_COMPARE,
  Object.freeze({}),
  action => action.data,
)
export const compareBuildsPageReducers = Redux.combineReducers<CompareBuildsState>({
  settings(
    state: SettingsState = {
      showChangedOnly: true,
    },
    action: Action,
  ) {
    switch (action.type) {
      case SET_SHOW_CHANGED_ONLY:
        return {...state, showChangedOnly: action.value}

      default:
        return state
    }
  },

  lists(state: ListsState = {}, action: Action) {
    switch (action.type) {
      case REQUEST_COMPARE_BUILDS_LIST:
      case RECEIVE_COMPARE_BUILDS_LIST: {
        const key = `${stringifyId(action.sourceId)}:${stringifyId(action.compareWithId)}`
        return {...state, ...keyValue(key, listReducer(state[key], action))}
      }

      default:
        return state
    }
  },

  info: keyValueReducer(
    action =>
      `${stringifyId(action.sourceId)}:${stringifyId(action.compareWithId)}:${action.compareType}`,
    infoReducer,
    [REQUEST_COMPARE_BUILDS_INFO, RECEIVE_COMPARE_BUILDS_INFO],
  ),
  builds: keyValueReducer(action => action.buildId, buildsReducer, [
    REQUEST_BUILDS_FOR_COMPARE,
    RECEIVE_BUILDS_FOR_COMPARE,
  ]),
})
