import type {AppThunk} from '../../../actions/types'
import {getAgentPool} from '../../../selectors'
import {AgentPoolId} from '../../../types'
import {objectKeys} from '../../../utils/object'
import {subscribeOnOverallEvents} from '../../../utils/subscriber'
import type {Unsubscribe} from '../../../utils/subscriber'
import {fetchAgentPoolsData} from '../../AgentsScreen/AgentsScreen.actions'

import {RECEIVE_POOL_QUEUE_COUNTERS} from './QueueSidebar.types'
import type {QueueCountersByPool} from './QueueSidebar.types'

export const subscribeOnPoolCounters = (): AppThunk<Unsubscribe> => (dispatch, getState) => {
  let prevPoolIds: AgentPoolId[] = []
  return subscribeOnOverallEvents(
    ['buildQueue/countersByPool'],
    (data: QueueCountersByPool | null | undefined) => {
      if (data == null) {
        return
      }

      dispatch({
        type: RECEIVE_POOL_QUEUE_COUNTERS,
        data,
      })
      const poolIds = objectKeys(data)
      const state = getState()

      if (poolIds.some(id => !prevPoolIds.includes(id) && getAgentPool(state, id) == null)) {
        dispatch(fetchAgentPoolsData())
      }

      prevPoolIds = poolIds
    },
  )
}
