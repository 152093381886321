import {fetchCurrentUserData} from '../../../actions'
import {SAVE_AVATAR} from '../../../actions/actionTypes'
import {AppThunk} from '../../../actions/types'
import {requestDeleteAvatar, requestSaveAvatar} from '../../../rest/currentUser'

import {getCurrentUserId} from '../../../selectors'
import {users} from '../../../slices/users'
import {UserId} from '../../../types'

export const saveAvatar =
  (id: UserId, blob: Blob): AppThunk =>
  async (dispatch, getState) => {
    try {
      await requestSaveAvatar(getState().restRoot, id, blob)
      await dispatch(users.fetch(id))
      if (id === getCurrentUserId(getState())) {
        await dispatch(fetchCurrentUserData(true))
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch({type: SAVE_AVATAR, error})
      } else {
        throw error
      }
    }
  }

export const deleteAvatar =
  (id: UserId): AppThunk =>
  async (dispatch, getState) => {
    await requestDeleteAvatar(getState().restRoot, id)
    await dispatch(users.fetch(id))
    if (id === getCurrentUserId(getState())) {
      await dispatch(fetchCurrentUserData(true))
    }
  }
