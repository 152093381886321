import type {SWGeneratorReturn} from '../../types/generators'
import {UPDATE_MESSAGE_TYPE} from '../../workers/sw.constants'
import {requestSWUpdate} from '../../workers/sw.lifecycle'

export const subscribeOnServiceWorkerMessage = <ResultType, ResponseType>({
  type = UPDATE_MESSAGE_TYPE,
  serverUrl,
  endpoint,
  resolver,
}: {
  type?: string
  serverUrl: string
  endpoint: string
  resolver?: ((arg0: ResponseType) => ResultType) | null | undefined
}): Promise<SWGeneratorReturn<ResultType>> =>
  new Promise(resolve => {
    if (navigator.serviceWorker && navigator.serviceWorker.controller != null) {
      const handler = ({data}: MessageEvent) => {
        if (data != null && data.type === type) {
          if (data.meta.url === `${serverUrl}/${endpoint}`) {
            navigator.serviceWorker?.removeEventListener('message', handler)
            const {payload, meta} = data

            if (!meta || meta.version !== window.ReactUI.buildNumber) {
              requestSWUpdate()
            }

            if (meta.NO_NEW_DATA) {
              resolve({meta})
              return
            }

            const result = typeof resolver === 'function' ? resolver(payload) : payload
            resolve({payload: result, meta})
          }
        }
      }

      navigator.serviceWorker.addEventListener('message', handler)
    } else {
      resolve({
        meta: {
          category: '',
          timeout: true,
          url: `${serverUrl}/${endpoint}`,
        },
      })
    }
  })
