/* eslint-disable prefer-template */
import {AgentPoolType, stringifyId} from '../types'
import type {
  EnabledInfoType,
  AgentRequestOptions,
  AgentId,
  AgentPreviewType,
  AgentType,
  AgentPoolId,
} from '../types'

import {buildTypeFields} from './buildTypes'
import processResponse, {processTextResponse} from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'

const getAgentFields = ({
  authorizedInfo,
  disconnectionComment,
  lastActivityTime,
  enabledInfo,
  cloudInfo,
  details,
}: AgentRequestOptions) =>
  'id,typeId,ip,name,enabled,connected,authorized,uptodate,version,outdated,currentAgentVersion,pluginsOutdated,javaOutdated,' +
  (details === true ? 'host,port,protocol,cpuRank,connectedSince,' : '') +
  (lastActivityTime === true ? 'lastActivityTime,' : '') +
  'links(link(type,relativeUrl)),' +
  'pool(id,name),' +
  (enabledInfo === true
    ? 'enabledInfo(status,comment(text,timestamp,user(id,name,username)),statusSwitchTime),'
    : '') +
  'environment(osType,osName),' +
  (disconnectionComment === true ? 'disconnectionComment,' : '') +
  (authorizedInfo === true
    ? 'authorizedInfo(status,comment(text,timestamp,user(id,name,username))),'
    : '') +
  (cloudInfo === true
    ? 'cloudInstance(image(name,profile(id,name,project(id))),agent(typeId,pool(id))),'
    : '') +
  `build(` +
  `id,number,branchName,defaultBranch,startDate,` +
  `running-info(percentageComplete,elapsedSeconds,estimatedTotalSeconds,leftSeconds,overtimeSeconds),` +
  `links(link(type,relativeUrl)),` +
  `statusText,status,state,personal,` +
  `user(id,name,username),` +
  `canceledInfo(text,user(id,name,username)),` +
  `tags(tag(name,private),$locator(private:true,owner:current)),` +
  `buildType(${buildTypeFields({
    withLinks: true,
  }).join(',')})` +
  `)`

export default (
  serverUrl: string = '',
  locator: string = 'state:any',
  {
    authorizedInfo = false,
    disconnectionComment = false,
    lastActivityTime = true,
    enabledInfo = true,
    cloudInfo = false,
  }: AgentRequestOptions = {},
): Promise<ReadonlyArray<AgentType>> =>
  request(
    serverUrl,
    `agents?${locator && `locator=${encodeURIComponent(locator)}&`}fields=agent(${getAgentFields({
      authorizedInfo,
      disconnectionComment,
      lastActivityTime,
      enabledInfo,
      cloudInfo,
    })})`,
  )
    .then<{agent: ReadonlyArray<AgentType>}>(processResponse)
    .then(data => data.agent)
export const requestSingleAgent = (
  serverUrl: string = '',
  id: AgentId,
  {
    authorizedInfo = true,
    disconnectionComment = true,
    lastActivityTime = true,
    enabledInfo = true,
    cloudInfo = false,
    details = true,
    essential = false,
  }: AgentRequestOptions = {},
): Promise<AgentType> =>
  request(
    serverUrl,
    `agents/id:${String(id)}?fields=${getAgentFields({
      authorizedInfo,
      disconnectionComment,
      lastActivityTime,
      enabledInfo,
      cloudInfo,
      details,
    })}`,
    {
      essential,
    },
  ).then<AgentType>(processResponse)
export const requestAgentExists = (serverUrl: string = '', id: AgentId): Promise<AgentType> =>
  request(serverUrl, `agents/id:${String(id)}?fields=id`, {skipInTests: false}).then<AgentType>(
    processResponse,
  )
export const requestAgentPreviews = (
  serverUrl: string = '',
  options?: RestRequestOptions,
): Promise<ReadonlyArray<AgentPreviewType>> =>
  request(
    serverUrl, // prettier-ignore
    'agents?locator=defaultFilter:false&fields=' +
      'agent(' +
      'id,name,typeId,enabled,connected,authorized,ip,' +
      'pool(id,name),' +
      'build(id),' +
      'environment,' +
      ')',
    options,
  )
    .then<{agent: ReadonlyArray<AgentPreviewType>}>(processResponse)
    .then(data => data.agent)
export const putAgentState = (
  serverUrl: string = '',
  agentId: AgentId,
  payload: EnabledInfoType,
): Promise<EnabledInfoType> =>
  request(serverUrl, `agents/id:${stringifyId(agentId)}/enabledInfo`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then<EnabledInfoType>(processResponse)
export const putAgentAuthState = (
  serverUrl: string = '',
  agentId: AgentId,
  payload: EnabledInfoType,
): Promise<EnabledInfoType> =>
  request(serverUrl, `agents/id:${stringifyId(agentId)}/authorizedInfo`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then<EnabledInfoType>(processResponse)
export const putAgentToPool = (
  serverUrl: string = '',
  agentId: AgentId,
  poolId: AgentPoolId,
): Promise<AgentPoolType> =>
  request(serverUrl, `agents/id:${stringifyId(agentId)}/pool?fields=id,name`, {
    method: 'PUT',
    body: JSON.stringify({
      id: poolId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then<AgentPoolType>(processResponse)
export const deleteAgent = (serverUrl: string = '', agentId: AgentId): Promise<string> =>
  request(serverUrl, `agents/id:${stringifyId(agentId)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(processTextResponse)
