import type {ProjectType, RequestOptionsParams} from '../types'
import type {SWGenerator} from '../types/generators'
import {REVALIDATION_HEADER_NAME} from '../workers/sw.constants'

import {buildTypeFields} from './buildTypes'
import {subscribeOnServiceWorkerMessage} from './caching/events'
import processResponse from './processResponse'
import {projectFields} from './projects'
import request, {RestRequestOptions} from './request'

type ResultType = ReadonlyArray<ProjectType>
type ResponseType = {
  project: ReadonlyArray<ProjectType>
}

const resolver = (payload: ResponseType): ResultType => payload.project

export default async function* overviewFetchGenerator(
  serverUrl: string,
  options?: RequestOptionsParams,
): SWGenerator<ResultType, void> {
  const endpoint =
    'projects?locator=archived:false,selectedByUser:(user:(current),mode:selected)' +
    `&fields=project(${projectFields({
      archived: 'any',
    }).join(',')},` +
    `buildTypes(buildType(${buildTypeFields().join(',')}),` +
    '$locator(selectedByUser:(user:current))))'
  const requestOptions: RestRequestOptions = {}

  if (options?.cache != null) {
    requestOptions.headers = {
      [REVALIDATION_HEADER_NAME]: options.cache,
    }
  }

  const subscription = subscribeOnServiceWorkerMessage<ResultType, ResponseType>({
    serverUrl,
    endpoint,
    resolver,
  })
  const cachedResponse = await request(serverUrl, endpoint, requestOptions)
  const payload: ResponseType = await processResponse(cachedResponse)
  yield {payload: resolver(payload)}
  return await subscription
}
