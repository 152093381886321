import {createSelector} from 'reselect'

import type {State} from '../../reducers/types'
import type {
  BuildId,
  BuildTypeId,
  Fetchable,
  InvestigationType,
  MuteType,
  BuildTypeHierarchyType,
  TestId,
  TestOccurrenceId,
  TestsTreeNodeId,
} from '../../types'
import {emptyArray, emptyArrayFetchable, emptyNullFetchable, getEmptyHash} from '../../utils/empty'
import type {KeyValue} from '../../utils/object'

import type {
  TestFlakyType,
  TestOccurrenceMetadataType,
  TestOccurrencesCountsType,
  TestOccurrencesTreeLeaf,
  TestOccurrencesTreeNode,
  TestOccurrencesTreeType,
  TestOccurrenceType,
  TestScopesKeyType,
  TestScopeType,
} from './Tests.types'
import {getLeavesHash, mergeTreeAndSubTrees, sortByDepthSubtreesKeys} from './Tests.utils'

export const getTestOccurrencesFetchable: (
  state: State,
  locator: string,
) => Fetchable<ReadonlyArray<TestOccurrenceId>> = (state, locator) =>
  state.testOccurrencesByLocator[locator] ?? emptyArrayFetchable

export const getTestScopesFetchable: (
  state: State,
  scope: TestScopesKeyType,
  locator: string,
) => Fetchable<ReadonlyArray<TestScopeType>> = (state, scope, locator) =>
  state.tests.testScopes[scope]?.[locator] ?? emptyArrayFetchable

export const getTestOccurrencesTreeFetchable: (
  state: State,
  locator: string,
) => Fetchable<TestOccurrencesTreeType | null | undefined> = (state, locator) =>
  state.tests.testOccurrencesTree[locator] ?? emptyNullFetchable

const getTestOccurrencesSubtreesFetchable: (
  state: State,
  locator: string,
) => KeyValue<string, Fetchable<TestOccurrencesTreeType | null | undefined>> | undefined | null = (
  state,
  locator,
) => state.tests.testOccurrencesSubtree[locator]

export const getTestOccurrencesSubtreeFetchable: (
  state: State,
  locator: string,
  subTreeRootId: string,
) => Fetchable<TestOccurrencesTreeType | null | undefined> = (state, locator, subTreeRootId) =>
  getTestOccurrencesSubtreesFetchable(state, locator)?.[subTreeRootId] ?? emptyNullFetchable

const getSortedByDepthSubtreesKeys: (state: State, locator: string) => ReadonlyArray<string> =
  createSelector([getTestOccurrencesSubtreesFetchable], sortByDepthSubtreesKeys)

export const getTestOccurrencesTreeNodes: (
  state: State,
  locator: string,
) => ReadonlyArray<TestOccurrencesTreeNode> | undefined | null = createSelector(
  [
    getTestOccurrencesTreeFetchable,
    getTestOccurrencesSubtreesFetchable,
    getSortedByDepthSubtreesKeys,
  ],
  mergeTreeAndSubTrees,
)

export const getTestOccurrencesLeavesHash: (
  state: State,
  locator: string,
) => KeyValue<TestsTreeNodeId, TestOccurrencesTreeLeaf> = createSelector(
  [
    getTestOccurrencesTreeFetchable,
    getTestOccurrencesSubtreesFetchable,
    getSortedByDepthSubtreesKeys,
  ],
  getLeavesHash,
)

export const getTestOccurrencesNodesHash: (
  state: State,
  locator: string,
) => KeyValue<TestsTreeNodeId, TestOccurrencesTreeNode> = createSelector(
  [getTestOccurrencesTreeNodes],
  (
    nodes: ReadonlyArray<TestOccurrencesTreeNode> | undefined | null,
  ): KeyValue<TestsTreeNodeId, TestOccurrencesTreeNode> =>
    nodes != null ? nodes.reduce((acc, node) => ({...acc, [node.id]: node}), {}) : getEmptyHash(),
)

const getBaseTestOccurrencesHash: (
  state: State,
) => KeyValue<TestOccurrenceId, TestOccurrenceType> = state => state.entities.testOccurrences

const getMultirunTestOccurrencesHash: (
  state: State,
) => KeyValue<TestOccurrenceId, TestOccurrenceType> = state =>
  state.entities.multirunTestOccurrences

const getTestOccurrencesHash: (state: State) => KeyValue<TestOccurrenceId, TestOccurrenceType> =
  createSelector(
    getBaseTestOccurrencesHash,
    getMultirunTestOccurrencesHash,
    (
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>,
      multirunTestOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>,
    ) => ({...testOccurrences, ...multirunTestOccurrences}),
  )

export const getBaseTestOccurrence: (
  state: State,
  id: TestOccurrenceId,
) => TestOccurrenceType | null | undefined = (state, id) => getBaseTestOccurrencesHash(state)[id]

const getGroupedTestOccurrence: (
  state: State,
  id: TestOccurrenceId,
) => TestOccurrenceType | null | undefined = (state, id) =>
  getMultirunTestOccurrencesHash(state)[id]

export const getTestOccurrence: (
  state: State,
  id: TestOccurrenceId,
) => TestOccurrenceType | null | undefined = (state, id) =>
  getGroupedTestOccurrence(state, id) ?? getBaseTestOccurrence(state, id)

export const getTestOccurrencesInvocationsFetchable: (
  state: State,
  locator: string,
  invocationsLocator: string,
) => Fetchable<ReadonlyArray<TestOccurrenceId>> = (state, locator, invocationsLocator) =>
  state.tests.testOccurrencesInvocations[`${locator}.${invocationsLocator}`] ?? emptyArrayFetchable

const getTestOccurrencesInvocationsIds: (
  state: State,
  id: TestOccurrenceId,
) => ReadonlyArray<TestOccurrenceId> = (state, id) =>
  state.entities.testOccurrencesInvocations[id]?.invocations?.testOccurrence ?? emptyArray

export const getTestOccurrenceFirstFailed: (
  state: State,
  id: TestOccurrenceId,
) => TestOccurrenceType | null | undefined = (state, id) =>
  state.entities.testOccurrencesFirstFailed[id]

export const getTestOccurrenceRunOrder: (
  state: State,
  id: TestOccurrenceId,
) => string | null | undefined = (state, id) => state.entities.testOccurrencesRunOrder[id]

export const getTestOccurrenceNewFailure: (
  state: State,
  id: TestOccurrenceId,
) => boolean | null | undefined = (state, id) => state.entities.testOccurrencesNewFailure[id]

export const getTestOccurrenceMetadataCount: (
  state: State,
  id: TestOccurrenceId,
) => number | null | undefined = (state, id) => state.entities.testOccurrencesMetadataCount[id]

export const getTestOccurrenceNextFixed: (
  state: State,
  id: TestOccurrenceId,
) => TestOccurrenceType | null | undefined = (state, id) =>
  state.entities.testOccurrencesNextFixed[id]

export const getTestOccurrenceInvestigations: (
  state: State,
  id: TestOccurrenceId,
) => ReadonlyArray<InvestigationType> = (state, id) =>
  state.entities.testOccurrencesInvestigations[id] ?? emptyArray

export const getTestOccurrenceCurrentlyMutes: (
  state: State,
  id: TestOccurrenceId,
) => ReadonlyArray<MuteType> = (state, id) =>
  state.entities.testOccurrencesCurrentlyMutes[id] ?? emptyArray

export const getTestOccurrenceMute: (
  state: State,
  id: TestOccurrenceId,
) => MuteType | null | undefined = (state, id) => state.entities.testOccurrencesMute[id]

export const getTestOccurrenceInvocationsCounters: (
  state: State,
  id: TestOccurrenceId,
) => TestOccurrencesCountsType | null | undefined = (state, id) =>
  state.entities.testOccurrencesInvocationsCounters[id]

export const getTestOccurrencesCountsFetchable: (
  state: State,
  locator: string,
) => Fetchable<TestOccurrencesCountsType | null | undefined> = (state, locator) =>
  state.tests.testOccurrencesCounts[locator] ?? emptyNullFetchable

const getTestOccurrenceMetadataHash: (
  state: State,
) => KeyValue<TestOccurrenceId, TestOccurrenceMetadataType> = state =>
  state.entities.testOccurrenceMetadata

export const getTestOccurrenceMetadata: (
  state: State,
  id: TestOccurrenceId | null | undefined,
) => TestOccurrenceMetadataType | null | undefined = (state, id) =>
  id != null ? getTestOccurrenceMetadataHash(state)[id] : null

export const getFlakyTestDataByBuildHash: (
  state: State,
  buildId: BuildId | null | undefined,
) => KeyValue<TestId, TestFlakyType> = (state, buildId) =>
  (buildId != null ? state.flakyTests[buildId]?.data : null) ?? getEmptyHash()

export const getFlakyTestData: (
  state: State,
  buildId: BuildId | null | undefined,
  testId: TestId | null | undefined,
) => TestFlakyType | null | undefined = (state, buildId, testId) => {
  const testData = getFlakyTestDataByBuildHash(state, buildId)
  return testId != null && testData[testId] != null ? testData[testId] : null
}

const getTestBuildTypeHierarchyHash: (
  state: State,
) => KeyValue<string, Fetchable<BuildTypeHierarchyType | null>> = state =>
  state.tests.testBuildTypeHierarchy

export const getTestBuildTypeHierarchy: (
  state: State,
  locator: string,
) => Fetchable<BuildTypeHierarchyType | null> = (state, locator) =>
  getTestBuildTypeHierarchyHash(state)[locator] ?? emptyNullFetchable

const hierarchyToBuildTypesList = (item: BuildTypeHierarchyType | null): BuildTypeId[] =>
  item?.type === 'BUILD_TYPE' ? [item.id] : item?.children.flatMap(hierarchyToBuildTypesList) ?? []

export const getTestBuildTypeList: (state: State, locator: string) => ReadonlyArray<BuildTypeId> =
  createSelector(
    (state: State, locator: string) => getTestBuildTypeHierarchy(state, locator).data,
    hierarchyToBuildTypesList,
  )

export const makeGetTestOccurrencesList: () => (
  state: State,
  locator: string,
) => ReadonlyArray<TestOccurrenceType> = () =>
  createSelector(
    [
      (state: State, locator: string) => getTestOccurrencesFetchable(state, locator).data,
      getTestOccurrencesHash,
    ],
    (
      ids: ReadonlyArray<TestOccurrenceId>,
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>,
    ) =>
      ids.length > 0
        ? ids.map((id: TestOccurrenceId) => testOccurrences[id]).filter(Boolean)
        : emptyArray,
  )

export const makeGetTestIdsByTestOccurranceIds: () => (
  state: State,
  ids: ReadonlyArray<TestOccurrenceId>,
) => ReadonlyArray<TestId> = () =>
  createSelector(
    [getTestOccurrencesHash, (state: State, ids: ReadonlyArray<TestOccurrenceId>) => ids],
    (
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>,
      ids: ReadonlyArray<TestOccurrenceId>,
    ) =>
      ids.length > 0
        ? ids.map((id: TestOccurrenceId) => testOccurrences[id]?.test?.id).filter(Boolean)
        : emptyArray,
  )

export const makeGetTestOccurrenceInvocations: () => (
  state: State,
  testOccurrenceId: TestOccurrenceId,
) => ReadonlyArray<TestOccurrenceType> = () =>
  createSelector(
    [getTestOccurrencesInvocationsIds, getBaseTestOccurrencesHash],
    (
      ids: ReadonlyArray<TestOccurrenceId>,
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrenceType>,
    ) => (ids.length > 0 ? ids.map(id => testOccurrences[id]).filter(Boolean) : null) ?? emptyArray,
  )
