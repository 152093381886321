import {combineReducers} from 'redux'

import {keyValueFetchable} from '../../../reducers/fetchable'
import {emptyArray} from '../../../utils/empty'

import {fetchModificationsOfChanges} from './ChangeVcsRoots.actions'
import {ChangeVcsRootsType} from './ChangeVcsRoots.types'

const changeVcsRoots = combineReducers<ChangeVcsRootsType>({
  modificationsOfChanges: keyValueFetchable(
    locator => locator,
    fetchModificationsOfChanges,
    emptyArray,
    (state, action) => action.payload.result,
  ),
})
export default changeVcsRoots
