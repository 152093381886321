import * as Redux from 'redux'

import {FetchAction} from '../../actions/types'
import fetchable, {keyValueFetchable} from '../../reducers/fetchable'
import {keyValueReducer, toolkitKeyValueReducer} from '../../reducers/utils'
import {BuildTypeHierarchyType} from '../../types'
import {emptyArray} from '../../utils/empty'

import {
  fetchTestOccurrencesInvocations,
  fetchTestOccurrenceSubtree,
  FetchTestOccurrenceSubTreeParamsType,
  fetchTestOccurrenceTree,
  fetchTestScopes,
  FetchTestScopesArg,
} from './Tests.actions'
import {
  RECEIVE_TEST_BUILD_TYPE_HIERARCHY,
  RECEIVE_TEST_OCCURRENCES_COUNTS,
  REQUEST_TEST_BUILD_TYPE_HIERARCHY,
  REQUEST_TEST_OCCURRENCES_COUNTS,
} from './Tests.actions.types'
import {TestOccurrencesCountsType, TestOccurrencesTreeType, TestsStateType} from './Tests.types'

const testOccurrencesCountsFetchable = fetchable<
  TestOccurrencesCountsType | null,
  TestOccurrencesCountsType
>(REQUEST_TEST_OCCURRENCES_COUNTS, RECEIVE_TEST_OCCURRENCES_COUNTS, null, action => action.data)

const testBuildTypeHierarchyFetchable = fetchable<
  BuildTypeHierarchyType | null,
  BuildTypeHierarchyType
>(REQUEST_TEST_BUILD_TYPE_HIERARCHY, RECEIVE_TEST_BUILD_TYPE_HIERARCHY, null, action => action.data)

const testOccurrencesInvocations = keyValueFetchable(
  arg => `${arg.locator}.${arg.invocationsLocator}`,
  fetchTestOccurrencesInvocations,
  emptyArray,
  (_, action) => action.payload.result,
)
const testOccurrencesCounts = keyValueReducer(
  action => action.locator,
  testOccurrencesCountsFetchable,
  [REQUEST_TEST_OCCURRENCES_COUNTS, RECEIVE_TEST_OCCURRENCES_COUNTS],
)
const testBuildTypeHierarchy = keyValueReducer(
  action => action.locator,
  testBuildTypeHierarchyFetchable,
  [REQUEST_TEST_BUILD_TYPE_HIERARCHY, RECEIVE_TEST_BUILD_TYPE_HIERARCHY],
)
const testScopes = toolkitKeyValueReducer(
  (action: FetchAction<FetchTestScopesArg>) => action.meta.arg.scope,
  keyValueFetchable(
    arg => arg.locator,
    fetchTestScopes,
    emptyArray,
    (_, action) => action.payload.data,
  ),
  [fetchTestScopes.pending, fetchTestScopes.fulfilled, fetchTestScopes.rejected],
)
const testOccurrencesTree = keyValueFetchable(
  arg => arg.locator,
  fetchTestOccurrenceTree,
  null as TestOccurrencesTreeType | null,
  (_, action) => action.payload.tree,
)
const testOccurrencesSubtree = keyValueFetchable(
  arg => arg.subTreeRootId,
  fetchTestOccurrenceSubtree,
  null as TestOccurrencesTreeType | null,
  (_, action) => action.payload.tree,
)

const testsReducer = Redux.combineReducers<TestsStateType>({
  testOccurrencesInvocations,
  testOccurrencesCounts,
  testBuildTypeHierarchy,
  testScopes,
  testOccurrencesTree,
  testOccurrencesSubtree: toolkitKeyValueReducer(
    (action: FetchAction<FetchTestOccurrenceSubTreeParamsType>) => action.meta.arg.treeLocator,
    testOccurrencesSubtree,
    [
      fetchTestOccurrenceSubtree.pending,
      fetchTestOccurrenceSubtree.fulfilled,
      fetchTestOccurrenceSubtree.rejected,
    ],
  ),
})
export default testsReducer
