import {createReducer, PayloadAction} from '@reduxjs/toolkit'
import produce, {castDraft, original} from 'immer'
import {DefaultRootState} from 'react-redux'
import * as Redux from 'redux'

import {
  fetchArtifactExtension,
  fetchArtifactsListAction,
  fetchBranches,
  fetchBuildStatsAction,
  fetchCurrentUserData,
  fetchHtmlAction,
  fetchIsBranchPresentAction,
  fetchPoolPermissions,
  resetState,
  setUserPropertyAction,
} from '../actions'
import {
  ADD_PLUGIN,
  COLLAPSE_AUTO_EXPANDED_QUEUED,
  DUMMY_ACTION,
  HIDE_QUEUED_BUILDS,
  HIDE_QUEUED_BUILDS_FOR_PROJECT,
  RECEIVE_ARTIFACTS_SIZE,
  RECEIVE_FEDERATION_SERVERS,
  RECEIVE_HAS_BUILD_TYPES,
  RECEIVE_HAS_BUILDS,
  RECEIVE_IS_EXTERNAL_STATUS_ALLOWED,
  RECEIVE_LICENSING_DATA,
  RECEIVE_SERVER_INFO,
  RECEIVE_TABS,
  REMOVE_PLUGIN,
  REQUEST_FEDERATION_SERVERS,
  REQUEST_HAS_BUILD_TYPES,
  REQUEST_HAS_BUILDS,
  REQUEST_IS_EXTERNAL_STATUS_ALLOWED,
  REQUEST_LICENSING_DATA,
  REQUEST_TABS,
  SET_BUILD_TYPES_LIMIT,
  SET_SHOW_QUEUED_BUILDS_COUNT,
  SET_SYNC_STORAGE_VALUE,
  SHOW_QUEUED_BUILDS,
  SHOW_QUEUED_BUILDS_FOR_PROJECT,
} from '../actions/actionTypes'
import {fetchBuildsAction, fetchSingleBuildAction, FetchSingleBuildArg} from '../actions/builds'
import {fetchSingleBuildTypeData} from '../actions/buildTypes'
import {
  fetchBranchesWithBuilds,
  fetchBuildTypeTags,
  fetchDslOptionsAction,
  fetchSingleInvestigation,
  removeAgent,
} from '../actions/fetch'
import {fetchOverviewAction, toggleOverviewAction} from '../actions/overview'
import {fetchProjectsDataAction, fetchSingleProjectDataAction} from '../actions/projects'
import type {Action, FetchAction} from '../actions/types'
import {
  fetchAgentPoolsData,
  fetchAgentPreviewsData,
  fetchCloudImagesData,
  fetchSingleAgentData,
} from '../components/AgentsScreen/AgentsScreen.actions'
import {agentsScreenReducers} from '../components/AgentsScreen/AgentsScreen.reducers'
import {fetchExpandState} from '../components/App/ProjectsSidebar/ProjectsSidebar.actions'
import {sidebar} from '../components/App/ProjectsSidebar/ProjectsSidebar.slices'
import {queueSidebar} from '../components/App/QueueSidebar/QueueSidebar.reducers'
import {buildLogReducers} from '../components/BuildLog/BuildLog.reducers'
import buildProblems from '../components/BuildProblems/BuildProblems.reducers'
import changesReducer from '../components/Changes/Changes.reducers'
import cleanup from '../components/CleanupBuildType/Cleanup.reducers'
import cleanupPolicies from '../components/CleanupProject/CleanupPolicies.reducers'
import agentAuth from '../components/common/AgentAuth/AgentAuth.reducers'
import {fetchAgentsData} from '../components/common/Agents/Agents.actions'
import buildApprovalsSlice from '../components/common/BuildApproval/BuildApproval.slices'
import queueInfo from '../components/common/BuildQueueInfo/BuildQueueInfo.reducers'
import buildStatusTooltip from '../components/common/BuildStatusLink/BuildStatusTooltip/BuildStatusTooltip.reducers'
import {fetchBuildTriggerBuild} from '../components/common/BuildTriggeredBy/BuildTriggeredBy.actions'
import changeProjectsSelect from '../components/common/ChangeDetailsTabs/FilesTab/ChangeBuildTypeSelect/ChangeBuildTypeSelect.reducers'
import changes from '../components/common/Changes/Changes.reducers'
import changeVcsRoots from '../components/common/ChangeVcsRoots/ChangeVcsRoots.reducers'
import errorAlerts from '../components/common/ErrorAlerts/ErrorAlerts.reducers'
import pagerReducer from '../components/common/Pager/Pager.reducer'
import {
  getTriggerBuildKey,
  triggerBuildAction,
  TriggerBuildArg,
} from '../components/common/RunBuild/RunBuild.actions'
import {fetchFederationServerProjectsDataWithOptions} from '../components/common/SidebarFooter/SidebarFooter.actions'
import {starBuildAction} from '../components/common/StarBuild/StarBuild.actions'
import userSelect from '../components/common/UserSelect/UserSelect.reducers'
import {
  DslActionPayload,
  dslFragment,
  dslOptions,
  dslPortable,
  dslVersion,
  toggleDsl,
} from '../components/Dsl/Dsl.slices'
import {
  requestBuildTypesReorderAction,
  requestProjectsReorderAction,
} from '../components/EditProjectSidebar/EditProjectSidebar.actions'
import hintsReducer from '../components/Hints/Hints.reducer'
import investigationHistory from '../components/InvestigationHistory/InvestigationHistory.reducers'
import buildSnippets from '../components/pages/BuildPage/BuildOverviewTab/BuildSnippets/BuildSnippets.reducer'
import deployments from '../components/pages/BuildPage/BuildOverviewTab/Deployments/Deployments.reducers'
import {fetchBuildTypesWithSnapshotDependencies} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.actions'
import {getDeliveredArtifactsKey} from '../components/pages/BuildPage/DependenciesTab/DependenciesTab.utils'
import {compareBuildsPageReducers} from '../components/pages/CompareBuildsPage/CompareBuildsPage.reducers'
import projectInvestigationsReducers from '../components/pages/ProjectPage/ProjectInvestigationsTab/ProjectInvestigations.reducers'
import projectPage from '../components/pages/ProjectPage/ProjectPage.reducers'
import {fetchFlakyTestsAction, fetchTestOccurrences} from '../components/Tests/Tests.actions'
import tests from '../components/Tests/Tests.reducers'
import {TestFlakyType} from '../components/Tests/Tests.types'
import {restRoot} from '../rest/consts'
import type {Entities} from '../rest/schemata'
import {getStatusKey, NormalizedFederationServer, StatusKey} from '../rest/schemata'
import {
  agentsInCloud,
  blocks,
  builds as buildsSlice,
  buildTab,
  buildTypeTab,
  dialog,
  haveDependants,
  isExperimentalUI,
  permissions,
  routeAvailabilityResponse,
  showQueuedBuildsInBuildsList,
  sorting,
  stoppingBuilds,
  urlExtensions,
} from '../slices'
import buildsFilters from '../slices/buildsFilters'
import {fetchableSlicesReducer} from '../slices/fetchable'
import type {
  AgentId,
  ArtifactExtensions,
  BuildId,
  BuildTriggeredBuildType,
  BuildTypeId,
  FederationServerData,
  FederationServerId,
  Fetchable,
  ProjectId,
  ServerInfo,
  TestId,
} from '../types'
import {
  AgentDetails,
  ArtifactExtension,
  BuildArtifactsSizeType,
  CurrentUserType,
  LicensingDataType,
  Property,
  Tab,
} from '../types'
import {internalProps} from '../types/BS_types'
import {emptyArray, getEmptyHash} from '../utils/empty'
import type {KeyValue} from '../utils/object'

import entities from './entities'
import federationServersDataReducer from './federationServersData'
import fetchable, {keyValueFetchable, toolkitFetchable} from './fetchable'
import {clientId, getArtifactsKey, OverviewDraft, TogglingOverview} from './types'
import {keyValueReducer, toolkitKeyValueReducer} from './utils'

const artifactsExtensionsReducer = toolkitFetchable(
  fetchArtifactExtension,
  null as ArtifactExtension | null,
  (_, action) => action.payload,
)
const agentPreviewsReducer = toolkitFetchable(
  fetchAgentPreviewsData,
  emptyArray,
  (_, action) => action.payload.result,
)
const currentUserReducer = toolkitFetchable(
  fetchCurrentUserData,
  null as CurrentUserType | null,
  (_, action) => action.payload,
)
const hasBuildsReducer = fetchable<boolean, boolean>(
  REQUEST_HAS_BUILDS,
  RECEIVE_HAS_BUILDS,
  false,
  action => action.data,
)

const buildReducer = (i: number) =>
  toolkitFetchable(
    fetchSingleBuildAction,
    null as BuildId | null,
    (_, action) => action.payload.result[i] ?? null,
  )

const licensingDataReducer = fetchable<LicensingDataType | null, LicensingDataType>(
  REQUEST_LICENSING_DATA,
  RECEIVE_LICENSING_DATA,
  null,
  action => action.data,
)

const mainReducer = Redux.combineReducers({
  clientId: () => clientId,

  entities,

  buildTriggerBuilds: createReducer<KeyValue<BuildId, BuildTriggeredBuildType>>({}, builder =>
    builder.addCase(fetchBuildTriggerBuild.fulfilled, (state, action) => {
      state[action.meta.arg] = castDraft(action.payload)
    }),
  ),

  fetchableSlices: fetchableSlicesReducer,

  federationServersData: createReducer<KeyValue<FederationServerId, FederationServerData>>(
    {},
    builder =>
      builder.addDefaultCase((state, action) => {
        if (
          fetchFederationServerProjectsDataWithOptions.pending.match(action) ||
          fetchFederationServerProjectsDataWithOptions.fulfilled.match(action) ||
          fetchFederationServerProjectsDataWithOptions.rejected.match(action)
        ) {
          const federationServerId = action.meta.arg.requestOptions.federationServerId
          if (federationServerId != null) {
            state[federationServerId] = castDraft(
              federationServersDataReducer(original(state)![federationServerId], action),
            )
          }
        }
      }),
  ),

  federationServersEntities: createReducer<KeyValue<FederationServerId, Partial<Entities>>>(
    {},
    builder =>
      builder.addCase(fetchFederationServerProjectsDataWithOptions.fulfilled, (state, action) => {
        const federationServerId = action.meta.arg.requestOptions.federationServerId
        if (federationServerId != null) {
          state[federationServerId] = castDraft(action.payload.entities)
        }
      }),
  ),

  restRoot: (state: string = restRoot): string => state,

  isExperimentalUI: isExperimentalUI.reducer,

  urlExtensions: urlExtensions.reducer,

  routeAvailabilityResponse: routeAvailabilityResponse.reducer,
  pager: pagerReducer,
  blocks: blocks.reducer,

  dialog: dialog.reducer,

  builds: buildsSlice.reducer,
  artifacts: keyValueFetchable(
    arg => getArtifactsKey(arg.id, arg.path, arg.withHidden),
    fetchArtifactsListAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  artifactExtensions: createReducer<KeyValue<BuildId, ArtifactExtensions>>({}, builder =>
    builder.addDefaultCase((state, action) => {
      if (
        fetchArtifactExtension.pending.match(action) ||
        fetchArtifactExtension.fulfilled.match(action) ||
        fetchArtifactExtension.rejected.match(action)
      ) {
        const {
          id,
          extension: {name},
        } = action.meta.arg

        if (!id || name == null) {
          return
        }

        let prevState = state[id]
        if (prevState == null) {
          prevState = state[id] = {}
        }
        prevState[name] = artifactsExtensionsReducer(original(prevState)![name], action)
      }
    }),
  ),

  agents: keyValueFetchable(
    arg => arg.locator || '',
    fetchAgentsData,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  agentPreviews: agentPreviewsReducer,

  agent: createReducer<KeyValue<AgentId, Fetchable<AgentId | null>>>({}, builder => {
    builder.addCase(removeAgent.fulfilled, (state, action) => {
      state[action.meta.arg] = {
        data: null,
        loading: false,
        backgroundLoading: false,
        inited: false,
        ready: false,
      }
    })
    builder.addDefaultCase(
      keyValueFetchable(
        arg => String(arg.agentId),
        fetchSingleAgentData,
        null as AgentId | null,
        (_, action) => action.payload.agent.result,
      ),
    )
  }),
  agentPools: toolkitFetchable(
    fetchAgentPoolsData,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  cloudImages: toolkitFetchable(fetchCloudImagesData, emptyArray, (state, action) => [
    ...new Set([...state, ...action.payload.result]),
  ]),
  branches: keyValueFetchable(
    arg => arg,
    fetchBranches,
    emptyArray,
    (_, action) => action.payload,
  ),
  isBranchPresent: keyValueFetchable(
    arg => arg.endpoint,
    fetchIsBranchPresentAction,
    null as boolean | null,
    (_, action) => action.payload,
  ),
  flakyTests: keyValueFetchable(
    arg => String(arg.buildId),
    fetchFlakyTestsAction,
    getEmptyHash<TestId, TestFlakyType>(),
    (state, action) =>
      produce(state, draft =>
        action.payload.forEach(flakyTest => {
          draft[flakyTest.id] = castDraft(flakyTest)
        }),
      ),
  ),
  projects: keyValueFetchable(
    arg => arg.rootProjectId,
    fetchProjectsDataAction,
    emptyArray,
    (state, action) => {
      const archived = action.meta.arg.options?.archived
      return archived == null || archived === 'false' ? action.payload.result : state
    },
    arg => arg.meta,
  ),
  buildTypes: keyValueFetchable(
    arg => arg,
    fetchBuildTypesWithSnapshotDependencies,
    emptyArray,
    (_, action) => action.payload.result,
  ),

  hasFavoriteProjects: createReducer<boolean | null>(null, builder =>
    builder.addCase(
      fetchOverviewAction.fulfilled,
      (state, action) => action.payload.result.length > 0,
    ),
  ),

  overview: toolkitFetchable(fetchOverviewAction, emptyArray, (_, action) => action.payload.result),
  overviewDraft: Redux.combineReducers<OverviewDraft>({
    projects: createReducer<ReadonlyArray<ProjectId> | null>(null, builder => {
      builder.addCase(requestProjectsReorderAction.pending, (_, action) =>
        action.meta.arg.map(item => item.id),
      )
      builder.addCase(requestProjectsReorderAction.fulfilled, () => null)
      builder.addCase(fetchOverviewAction.fulfilled, () => null)
    }),

    buildTypes: createReducer<KeyValue<ProjectId, ReadonlyArray<BuildTypeId> | null | undefined>>(
      {},
      builder => {
        builder.addCase(requestBuildTypesReorderAction.pending, (state, action) => {
          const {projectId, order} = action.meta.arg
          state[projectId] = order?.map(item => item.id)
        })
        builder.addCase(requestBuildTypesReorderAction.fulfilled, (state, action) => {
          state[action.meta.arg.projectId] = null
        })
        builder.addCase(fetchOverviewAction.fulfilled, () => ({}))
      },
    ),
  }),

  project: keyValueFetchable(
    arg => arg.projectId,
    fetchSingleProjectDataAction,
    null as string | null,
    (_, action) => action.payload.result,
  ),

  buildType: keyValueFetchable(
    arg => arg,
    fetchSingleBuildTypeData,
    null as BuildTypeId | null,
    (_, action) => action.payload.result,
  ),

  build: createReducer<KeyValue<string, Fetchable<BuildId | null>>>({}, builder =>
    builder.addMatcher(
      (action): action is FetchAction<FetchSingleBuildArg> =>
        [
          fetchSingleBuildAction.pending,
          fetchSingleBuildAction.fulfilled,
          fetchSingleBuildAction.rejected,
        ].some(type => type.match(action)),
      (state, action) => {
        action.meta.arg.locators.forEach((locator, i) => {
          state[locator] = castDraft(buildReducer(i)(original(state)![locator], action))
        })
      },
    ),
  ),

  permissions: permissions.reducer,

  poolPermissions: createReducer(
    {
      canChangeStatus: {},
      canAuthorize: {},
    },
    builder =>
      builder.addCase(fetchPoolPermissions.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      }),
  ),

  html: createReducer<KeyValue<string, string | null>>({}, builder =>
    builder.addCase(fetchHtmlAction.fulfilled, (state, action) => {
      state[action.meta.arg.path] = action.payload
    }),
  ),

  stoppingBuilds: stoppingBuilds.reducer,

  startingBuilds: toolkitKeyValueReducer(
    (action: FetchAction<TriggerBuildArg>) => getTriggerBuildKey(action.meta.arg),
    createReducer(false, builder => {
      builder.addCase(triggerBuildAction.pending, () => true)
      builder.addCase(triggerBuildAction.fulfilled, () => false)
      builder.addCase(triggerBuildAction.rejected, () => false)
    }),
    [triggerBuildAction.pending, triggerBuildAction.fulfilled, triggerBuildAction.rejected],
  ),

  starringBuilds: createReducer<KeyValue<BuildId, boolean | null | undefined>>({}, builder => {
    builder.addCase(fetchSingleBuildAction.fulfilled, (state, action) =>
      action.payload.result.forEach(id => {
        delete state[id]
      }),
    )
    builder.addCase(starBuildAction.pending, (state, action) => {
      const {buildId, starred} = action.meta.arg
      state[buildId] = starred
    })
    builder.addCase(starBuildAction.rejected, (state, action) => {
      state[action.meta.arg.buildId] = null
    })
  }),

  togglingOverview: createReducer<TogglingOverview>(
    {
      bt: {},
      project: {},
    },
    builder => {
      builder.addCase(toggleOverviewAction.pending, (state, action) => {
        const {node, on} = action.meta.arg
        if (node.nodeType === 'bt' || node.nodeType === 'project') {
          state[node.nodeType][node.id] = on
        }
      })
      builder.addDefaultCase((state, action) => {
        if (
          toggleOverviewAction.fulfilled.match(action) ||
          toggleOverviewAction.rejected.match(action)
        ) {
          const {node} = action.meta.arg
          if (node.nodeType === 'bt' || node.nodeType === 'project') {
            state[node.nodeType][node.id] = null
          }
        }
      })
    },
  ),

  buildsFilters: buildsFilters.reducer,
  currentUser: currentUserReducer,

  userProperties: createReducer<KeyValue<string, string>>({}, builder => {
    builder.addCase(fetchCurrentUserData.fulfilled, (state, action) => {
      const properties = action.payload?.properties?.property

      if (properties == null) {
        return state
      }

      return Object.fromEntries(properties.map(({name, value}: Property) => [name, value]))
    })
    builder.addCase(setUserPropertyAction.pending, (state, action) => {
      const {name, value} = action.meta.arg
      state[name] = value
    })
  }),

  sorting: sorting.reducer,

  agentsInCloud: agentsInCloud.reducer,

  buildTypeTags: keyValueFetchable(
    arg => arg,
    fetchBuildTypeTags,
    emptyArray,
    (_, action) => action.payload,
  ),

  buildsStats: keyValueFetchable(
    arg => arg.locator,
    fetchBuildStatsAction,
    emptyArray,
    (_, action) => action.payload,
  ),

  haveDependants: haveDependants.reducer,

  buildTypeTab: buildTypeTab.reducer,

  buildTab: buildTab.reducer,

  projectPage,

  sidebar: sidebar.reducer,

  dslOptions: createReducer({}, builder =>
    builder.addDefaultCase(
      toolkitKeyValueReducer(
        (action: PayloadAction<DslActionPayload>) => action.payload.controlId,
        dslOptions.reducer,
        [
          toggleDsl.actions.show,
          toggleDsl.actions.hide,
          dslVersion.actions.set,
          dslPortable.actions.set,
          dslOptions.actions.init,
        ],
      ),
    ),
  ),

  dslFragment: dslFragment.reducer,

  availableDslOptions: toolkitFetchable(
    fetchDslOptionsAction,
    emptyArray,
    (_, action) => action.payload,
  ),
  showQueuedBuildsPerBranch: keyValueReducer(
    action =>
      getStatusKey({
        type: 'bt',
        branch: action.branch,
        id: action.buildTypeId,
      }),
    (state: boolean = false, action) => {
      switch (action.type) {
        case HIDE_QUEUED_BUILDS:
          return false

        case SHOW_QUEUED_BUILDS:
          return true

        default:
          return state
      }
    },
    [HIDE_QUEUED_BUILDS, SHOW_QUEUED_BUILDS],
  ),
  showQueuedBuildsCount: keyValueReducer(
    action =>
      getStatusKey({
        type: 'bt',
        branch: action.branch,
        id: action.buildTypeId,
      }),
    (_: number | undefined, action) => action.count,
    [SET_SHOW_QUEUED_BUILDS_COUNT],
  ),
  showQueuedBuildsInProject: keyValueReducer(
    action => action.buildTypeId,
    (state: boolean = false, action: Action) => {
      switch (action.type) {
        case HIDE_QUEUED_BUILDS_FOR_PROJECT:
          return false

        case SHOW_QUEUED_BUILDS_FOR_PROJECT:
          return true

        default:
          return state
      }
    },
    [HIDE_QUEUED_BUILDS_FOR_PROJECT, SHOW_QUEUED_BUILDS_FOR_PROJECT],
  ),

  showQueuedBuildsInBuildsList: showQueuedBuildsInBuildsList.reducer,

  branchesWithBuilds: keyValueFetchable(
    arg => arg.buildTypeId,
    fetchBranchesWithBuilds,
    emptyArray,
    // deduplicate by converting to Set and back
    (_, action) => Array.from(new Set(action.payload.result)),
  ),

  federationServers: fetchable<readonly FederationServerId[], NormalizedFederationServer>(
    REQUEST_FEDERATION_SERVERS,
    RECEIVE_FEDERATION_SERVERS,
    emptyArray,
    action => action.data.result,
  ),
  hasBuilds: keyValueReducer(action => action.locator, hasBuildsReducer, [
    REQUEST_HAS_BUILDS,
    RECEIVE_HAS_BUILDS,
  ]),
  hasBuildTypes: keyValueReducer(
    action => action.locator,
    fetchable<boolean, boolean>(
      REQUEST_HAS_BUILD_TYPES,
      RECEIVE_HAS_BUILD_TYPES,
      false,
      action => action.data,
    ),
    [REQUEST_HAS_BUILD_TYPES, RECEIVE_HAS_BUILD_TYPES],
  ),
  isExternalStatusAllowed: keyValueReducer(
    action => action.buildTypeId,
    fetchable<boolean, boolean>(
      REQUEST_IS_EXTERNAL_STATUS_ALLOWED,
      RECEIVE_IS_EXTERNAL_STATUS_ALLOWED,
      false,
      action => action.data,
    ),
    [REQUEST_IS_EXTERNAL_STATUS_ALLOWED, RECEIVE_IS_EXTERNAL_STATUS_ALLOWED],
  ),
  tabs: keyValueReducer(
    action => action.tabParamsKey,
    fetchable<readonly Tab[], readonly Tab[]>(
      REQUEST_TABS,
      RECEIVE_TABS,
      emptyArray,
      action => action.data,
    ),
    [REQUEST_TABS, RECEIVE_TABS],
  ),

  serverInfo(state: ServerInfo | null | undefined = null, action): ServerInfo | null {
    switch (action.type) {
      case RECEIVE_SERVER_INFO:
        if (action.error == null) {
          return action.data
        }

        return state

      default:
        return state
    }
  },

  dummyCalls(state: number = 0, action): number {
    switch (action.type) {
      case DUMMY_ACTION:
        return state + 1

      default:
        return state
    }
  },

  overviewExpandState: toolkitFetchable(
    fetchExpandState,
    [] as readonly string[],
    (_, action) => action.payload.result,
  ),

  testOccurrencesByLocator: keyValueFetchable(
    arg => arg.locator,
    fetchTestOccurrences,
    emptyArray,
    (_, action) => action.payload.data.result,
  ),
  cachedPlugins: keyValueReducer(
    action => action.placeId,
    (state: readonly string[] = emptyArray, action) => {
      const {name, placeId} = action

      switch (action.type) {
        case REMOVE_PLUGIN:
          return [...state.filter(pluginName => pluginName !== name)]

        case ADD_PLUGIN:
        default:
          let extensionExist: boolean = false
          const nextExtensions = []
          state.forEach((extensionName: string) => {
            if (extensionName === name) {
              extensionExist = true
              // eslint-disable-next-line no-console
              console.warn(`Plugin ${name} has already been registered in Place ${placeId}`)
              nextExtensions.push(name)
            } else {
              nextExtensions.push(extensionName)
            }
          })

          if (!extensionExist) {
            nextExtensions.push(name)
          }

          return nextExtensions
      }
    },
    [ADD_PLUGIN, REMOVE_PLUGIN],
  ),
  syncStorageValues: keyValueReducer(
    action => action.key,
    (state: string | null | undefined, action) => action.value,
    [SET_SYNC_STORAGE_VALUE],
  ),
  queuedToggler: Redux.combineReducers({
    autoExpand: createReducer<KeyValue<StatusKey, boolean | null>>({}, builder => {
      builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
        if (action.payload.autoExpandQueued) {
          state[getTriggerBuildKey(action.meta.arg)] = true
        }
      })
      builder.addDefaultCase((state, action) => {
        switch (action.type) {
          case COLLAPSE_AUTO_EXPANDED_QUEUED:
            state[action.key] = false
            break

          default:
        }
      })
    }),
    hasTriggeredByMeBuilds: createReducer<KeyValue<BuildTypeId, boolean | null>>({}, builder =>
      builder.addCase(triggerBuildAction.fulfilled, (state, action) => {
        state[action.meta.arg.buildTypeId] = true
      }),
    ),
  }),
  compareBuilds: compareBuildsPageReducers,
  cleanup,
  cleanupPolicies,
  buildLog: buildLogReducers,
  projectInvestigations: projectInvestigationsReducers,
  buildSnippets,
  agentsPage: agentsScreenReducers,
  queueInfo,
  deployments,
  changesReducer,
  buildApprovals: buildApprovalsSlice.reducer,
  changes,
  changeVcsRoots,
  buildStatusTooltip,
  changeProjectsSelect,
  agentAuth,
  userSelect,
  errorAlerts,
  investigationHistory,
  tests,
  buildProblems,
  queueSidebar,
  hints: hintsReducer,
  licensingData: licensingDataReducer,

  buildTypesLimit(state = internalProps['teamcity.overviewPage.buildTypes.limit'], action) {
    switch (action.type) {
      case SET_BUILD_TYPES_LIMIT:
        return action.type

      default:
        return state
    }
  },

  artifactSizes: keyValueReducer(
    action => action.buildId,
    (_: BuildArtifactsSizeType | undefined, {visible, total}) => ({
      visible,
      total,
    }),
    [RECEIVE_ARTIFACTS_SIZE],
  ),
  agentDetails: createReducer({}, builder =>
    builder.addCase(
      fetchSingleAgentData.fulfilled,
      (state: Record<AgentId, AgentDetails>, action) => {
        const {agentId} = action.meta.arg
        state[agentId] = action.payload.details
      },
    ),
  ),
  buildTypeInvestigations: keyValueFetchable(
    arg => arg,
    fetchSingleInvestigation,
    false,
    () => true,
  ),
  deliveredArtifacts: createReducer<KeyValue<string, readonly string[]>>({}, builder =>
    builder.addCase(fetchBuildsAction.fulfilled, (state, action) => {
      const from = action.meta.arg.requestOptions.withDownloadedArtifactsFrom
      if (from != null) {
        for (const to of action.payload.result) {
          const download =
            action.payload.entities.builds?.[to]?.downloadedArtifacts?.downloadInfo[0]
          if (download != null) {
            state[getDeliveredArtifactsKey(from, to)] = download.artifactInfo.map(item => item.path)
          }
        }
      }
    }),
  ),
})
export default (state: DefaultRootState | undefined, action: Action) =>
  resetState.match(action) ? action.payload : mainReducer(state, action)
