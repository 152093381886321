import {mapProps} from 'recompose'

import buttonStyles from '@jetbrains/ring-ui/components/button/button.css'
import type {LinkProps} from '@jetbrains/ring-ui/components/link/link'
import {linkHOC} from '@jetbrains/ring-ui/components/link/link'
import type {
  RouterLinkProps as RouterLinkAPIProps,
  RouterButtonProps as RouterButtonAPIProps,
} from '@jetbrains/teamcity-api'
import {Link, LinkGetProps} from '@reach/router'
import classNames from 'classnames'
import compose from 'lodash/flowRight'
import type {ComponentType, ComponentProps} from 'react'

import {getHrefWithQueryParams, LocationProps, withLocation} from '../../../routes'
import type {QueryParams} from '../../../utils/queryParams'

import styles from './RouterLink.css'

export type RouterLinkProps = LinkProps &
  Omit<ComponentProps<typeof Link>, 'to'> & {
    readonly to?: string
    readonly params?: QueryParams | ((prevParams: QueryParams) => QueryParams)
    readonly hash?: string
    readonly activeClassName?: string | null
    readonly withMergeParams?: boolean
  }
const RouterLink = compose(
  withLocation,
  mapProps(
    ({
      to,
      params,
      hash,
      location,
      activeClassName,
      className,
      navigate,
      withMergeParams,
      onPlainLeftClick,
      ...restProps
    }: RouterLinkProps & LocationProps) => {
      const paramsToPass =
        withMergeParams === true
          ? (prevParams: QueryParams) => ({...prevParams, ...params})
          : params
      return {
        getProps: ({isCurrent}: LinkGetProps): Partial<ComponentProps<typeof Link>> => ({
          className: classNames(className, styles.link, {
            [activeClassName ?? '']: isCurrent,
          }),
        }),
        to:
          paramsToPass || hash != null
            ? getHrefWithQueryParams(location, to, paramsToPass, hash)
            : to,
        ...restProps,
      }
    },
  ),
)(Link)
const RouterLinkContainer: ComponentType<RouterLinkProps> = linkHOC(RouterLink)
export default RouterLinkContainer
export const RouterLinkAPI = linkHOC(Link) as ComponentType<RouterLinkAPIProps>
type RouterButtonProps = RouterLinkProps & {
  readonly isActive?: boolean
}
const buttonClassName = classNames(styles.button, buttonStyles.button, buttonStyles.heightS)
export const RouterButton: ComponentType<RouterButtonProps> = mapProps(
  ({isActive, ...restProps}: RouterButtonProps) => ({
    ...restProps,
    className: classNames(buttonClassName, {
      [buttonStyles.active]: isActive,
    }),
  }),
)(RouterLink)
export const RouterButtonAPI: ComponentType<RouterButtonAPIProps> = ({
  className,
  children,
  ...restProps
}) => (
  <Link
    getProps={() => ({
      className: classNames(className, buttonClassName),
    })}
    {...restProps}
  >
    {children}
  </Link>
)
