import type {CompatibleAgentType} from '../types'

import processResponse from './processResponse'
import request from './request'

export const compatibleAgentsFields = `compatibleAgents($locator(count:16),count)`

type Data = {
  build: readonly CompatibleAgentType[]
}
export default (
  serverUrl: string,
  locator: string = 'state:queued',
): Promise<ReadonlyArray<CompatibleAgentType>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(locator)}&fields=build(id,${compatibleAgentsFields})`,
  )
    .then<Data>(processResponse)
    .then(data => data.build)
