import type {EntityPathProps as APIProps} from '@jetbrains/teamcity-api'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import {fetchSingleBuildTypeData} from '../actions/buildTypes'
import {fetchProjectWithAllParentsData} from '../actions/projects'
import {AppThunk} from '../actions/types'
import BuildPath from '../components/common/BuildPath/BuildPath'
import type {BuildPathOwnProps} from '../components/common/BuildPath/BuildPath.types'
import {useProjectOrBuildTypeNodeFromUrl} from '../hooks/routes'
import {getBuildType, getBuildTypeRequest, getFullPath} from '../selectors'
import {getProjectBuildTypeFilter, toBuildId, toBuildTypeId, toProjectId} from '../types'
import type {BuildId, BuildTypeId, ProjectId, PathItem} from '../types'

type OwnProps = BuildPathOwnProps & {
  readonly buildId?: string | BuildId
  // string for API
  readonly projectId?: ProjectId | null
  readonly buildTypeId?: BuildTypeId | null
  readonly path?: ReadonlyArray<PathItem>
}

const loadEntityWithAllParents =
  ({
    buildTypeId,
    projectId,
  }: {
    projectId?: ProjectId | null
    buildTypeId?: BuildTypeId | null
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    let projectIdToLoad = projectId

    if (buildTypeId != null) {
      let buildType = getBuildType(state, buildTypeId)

      if (buildType == null) {
        const request =
          getBuildTypeRequest(state, buildTypeId) || dispatch(fetchSingleBuildTypeData(buildTypeId))
        await request
        buildType = getBuildType(getState(), buildTypeId)
      }

      if (buildType != null && projectIdToLoad == null) {
        projectIdToLoad = buildType.projectId
      }
    }

    if (projectIdToLoad != null) {
      dispatch(fetchProjectWithAllParentsData(projectIdToLoad))
    }
  }

export default function EntityPath({
  projectId,
  buildTypeId,
  buildId,
  path,
  ...restProps
}: OwnProps) {
  const fullPath = ReactRedux.useSelector(
    state =>
      path ??
      getFullPath(
        state,
        getProjectBuildTypeFilter({
          projectId,
          buildTypeId,
        }),
      ),
  )
  const dispatch = ReactRedux.useDispatch()
  React.useEffect(() => {
    dispatch(
      loadEntityWithAllParents({
        projectId,
        buildTypeId,
      }),
    )
  }, [buildTypeId, dispatch, projectId])
  const projectOrBuildTypeNode = useProjectOrBuildTypeNodeFromUrl()
  const buildIdProperlyTyped = typeof buildId === 'string' ? toBuildId(buildId) : buildId
  return (
    <BuildPath
      withLeafStatusIcon
      projectOrBuildTypeNode={projectOrBuildTypeNode}
      buildId={buildIdProperlyTyped}
      {...restProps}
      path={fullPath}
    />
  )
}
export const EntityPathAPI: React.ComponentType<APIProps> = ({
  buildId,
  projectId,
  buildTypeId,
  ...restProps
}) => (
  <EntityPath
    buildId={buildId != null ? toBuildId(buildId) : undefined}
    projectId={projectId != null ? toProjectId(projectId) : undefined}
    buildTypeId={buildTypeId != null ? toBuildTypeId(buildTypeId) : undefined}
    {...restProps}
  />
)
