import type {Directions} from '@jetbrains/ring-ui/components/popup/popup.consts'

import {KeyValue} from '../../utils/object'

export const ADD_HINT: 'ADD_HINT' = 'ADD_HINT'
export const REMOVE_HINT: 'REMOVE_HINT' = 'REMOVE_HINT'
export const SHOW_HINT: 'SHOW_HINT' = 'SHOW_HINT'
export const HIDE_HINT: 'HIDE_HINT' = 'HIDE_HINT'
export const EXPAND_HINT: 'EXPAND_HINT' = 'EXPAND_HINT'
export const COLLAPSE_HINT: 'COLLAPSE_HINT' = 'COLLAPSE_HINT'
export const SELECT_HINT_CATEGORY: 'SELECT_HINT_CATEGORY' = 'SELECT_HINT_CATEGORY'
export const HINT_CATEGORY = {
  tabs: 'Tabs',
  buildLog: 'Build Log',
  sidebar: 'Sidebar',
  dependencyChain: 'Dependencies',
  header: 'Header',
  navigation: 'Navigation',
  miscellaneous: 'Miscellaneous',
  whatsNew: "What's new",
  projectOverview: 'Project',
  buildOverview: 'Build',
  agents: 'Agents',
  administration: 'Administration',
  guide: 'Guide',
} as const
type HintCategoryType = typeof HINT_CATEGORY
export type HintCategory = HintCategoryType[keyof HintCategoryType]
export type HintId = string
export const toHintId = (id: string): HintId => id
export const stringifyHintId = (id: HintId): string => (id != null ? String(id) : '')
type HintImage = {
  readonly src: string
  readonly alt: string
}
export type HintType = {
  readonly id: HintId
  readonly name: string
  readonly text: string
  readonly category?: HintCategory
  readonly className?: string
  readonly directions?: ReadonlyArray<Directions>
  readonly offset?: number
  readonly viewed?: boolean
  readonly lessonId?: string
  readonly highlightTop?: boolean
  readonly helpLink?: string
  readonly selector?: string
  readonly image?: HintImage
}
export type HintPopupProps = {
  readonly id: HintId
  readonly dark?: boolean
  readonly hoverOverDark?: boolean
  readonly btnPrevText?: string
  readonly btnNextText?: string
  readonly currentIndex?: number
  readonly total?: number
  readonly onClickPrev?: () => void
  readonly onClickNext?: () => void
  readonly onClose?: () => void
}
export type HintDataHookType = {
  onSelectNext: () => unknown
  onSelectPrevious: () => unknown
  onSelectCategory: (category: HintCategory) => unknown
  currentCategoryHints: {
    category?: HintCategory | null
    collection: Array<HintType>
  }
  registeredCategories: HintCategory[]
  selectedHints: ReadonlyArray<HintId>
}
export type HintsStateType = {
  registrations: KeyValue<HintId, number>
  available: ReadonlyArray<HintType>
  selected: ReadonlyArray<HintId>
  expanded: ReadonlyArray<HintId>
  selectedCategory: HintCategory | null
}
type ShowHintAction = {
  type: typeof SHOW_HINT
  id: HintId
}
type HideHintAction = {
  type: typeof HIDE_HINT
  id: HintId
}
type AddHintAction = {
  type: typeof ADD_HINT
  hint: HintType
}
type RemoveHintAction = {
  type: typeof REMOVE_HINT
  id: HintId
}
type ExpandHintAction = {
  type: typeof EXPAND_HINT
  id: HintId
}
type ColapseHintAction = {
  type: typeof COLLAPSE_HINT
  id: HintId
}
type SelectHintCategoryAction = {
  type: typeof SELECT_HINT_CATEGORY
  category: HintCategory
}
export type HintsActions =
  | AddHintAction
  | RemoveHintAction
  | ShowHintAction
  | HideHintAction
  | SelectHintCategoryAction
  | ExpandHintAction
  | ColapseHintAction
